<template>
  <v-col v-show="visible" v-bind="$attrs">
    <v-row v-if="noMenu == null">
      <v-chip
        class="ml-3"
        :color="color"
        outlined
      >
        {{ title }}
      </v-chip>

      <v-btn 
        @click="refreshCamera" 
        icon 
        :color="color"
        class="ml-2"
        v-tooltip="'視点初期化'"
      >
        <v-icon>mdi-eye-refresh</v-icon>
      </v-btn>

      <v-btn 
        @click="isGridVisible = !isGridVisible" 
        icon 
        :color="color" 
        class="ml-2"
        v-tooltip="gridCaption"
      >
        <v-icon>{{ isGridVisible ? 'mdi-grid' : 'mdi-grid-off' }}</v-icon>
      </v-btn>

      <v-btn 
        @click="zoomInGraph" 
        icon 
        :color="color" 
        class="ml-2"
      >
        <v-icon>mdi-magnify-plus</v-icon>
      </v-btn>
      
      <v-btn 
        @click="zoomOutGraph" 
        icon 
        :color="color" 
        class="ml-2"
      >
        <v-icon>mdi-magnify-minus</v-icon>
      </v-btn>
    </v-row>
    <v-row>
      <v-col class="pt-1">
        <TrunkTrajectoryGraph
          ref="graph"
          :id="id"
          :gait-id="gaitId"
          :trajectory="trajectory"
          :selection="selection"
          :initial-roll="initialRoll"
          :initial-pitch="initialPitch"
          :initial-yaw="initialYaw"
          :initial-camera-position="initialCameraPosition"
          :max-camera-position="maxCameraPosition"
          :min-camera-position="minCameraPosition"
          :vertical-offset="verticalOffset"
          :horizontalOffset="horizontalOffset"
          :aspect-ratio="aspectRatio"
          :loading="loading"
          :scale-x="scaleX"
          :scale-y="scaleY"
          :scale-z="scaleZ"
          :perspective="perspective"
          :grid="isGridVisible"
          :grid-mode="gridMode"
          :dragging-mode="draggingMode"
          :line-opacity="lineOpacity"

          :comparable="comparable"
          :averaged="averaged"
          :color="graphColor"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import TrunkTrajectoryGraph from '@components/parts/graph/TrunkTrajectoryGraph.vue'

export default {
  components: {
    TrunkTrajectoryGraph,
  },
  props: {
    id: {
      default: null,
    },
    visible: {
      default: true,
    },
    gaitId: {
      default: null,
    },
    title: {
    },
    trajectory: {
      required: true,
    },
    selection: {
    },
    perspective: {
      default: false,
    },
    initialRoll: {
      default: 25,
    },
    initialPitch: {
      default: 45,
    },
    initialYaw: {
      default: 0,
    },
    draggingMode: {
      default: 'translation',
    },
    scaleX: {
      default: 1.0,
    },
    scaleY: {
      default: 1.0,
    },
    scaleZ: {
      default: 1.0,
    },
    gridMode: {
      default: 'all', // top | side | front | all
    },
    initialCameraPosition: {
      default: 0.15,
    },
    maxCameraPosition: {
      default: 1.0,
    },
    minCameraPosition: {
      default: 0.1,
    },
    loading: {
      default: false,
    },

    verticalOffset: {
      default: 0,
    },
    horizontalOffset: {
      default: 0,
    },
    

    aspectRatio: {
      default: 1.92
    },
    lineOpacity: {
      default: 0.65,
    },

    comparable: {
      default: null,
    },
    averaged: {
      default: null,
    },

    color: {
      default: 'secondary',
    },
    graphColor: {
      default: '#4444CC',
    },
    noMenu: {
      default: null,
    },
    gridCaption: {
      default: '1目盛 - 1cm',
    },
  },
  data: function(){
    return {
      isGridVisible: true,
    }
  },
  computed: {
  },
  methods: {
    refreshCamera: function(){
      if(!this.$refs.graph){
        return;
      }
      this.$refs.graph.refreshCamera();
    },
    zoomInGraph: function(){
      this.$refs.graph?.zoomIn();
    },
    zoomOutGraph: function(){
      this.$refs.graph?.zoomOut();
    },
    setCameraAngle: function(roll, pitch, yaw){
      this.$refs.graph?.setCameraAngle(roll, pitch, yaw);
    },
  }
}
</script>