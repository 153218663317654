var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"mt-3",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"karte-cell header",class:{
          neutral: _vm.comparableId,
          comparable: _vm.isComparableColor,
        },attrs:{"cols":"3"}}),_vm._l((_vm.coordinates),function(coordinate){return _c('v-col',{key:("coordinate" + (coordinate.key)),staticClass:"karte-cell header text-center text-caption",class:{
          neutral: _vm.comparableId,
          comparable: _vm.isComparableColor,
        }},[_vm._v(" "+_vm._s(coordinate.label)+" ")])})],2),(_vm.comparableId && _vm.comparableGeometricFeatures)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"karte-cell header neutral",staticStyle:{"border-top":"none"},attrs:{"cols":"3"}}),_vm._l((_vm.coordinates),function(coordinate){return [_c('v-col',{key:("coordinate" + (coordinate.key) + "-" + _vm.id),staticClass:"karte-cell header text-center text-caption"},[_vm._v(" ID: "+_vm._s(_vm.id)+" ")]),_c('v-col',{key:("coordinate" + (coordinate.key) + "-comparable" + _vm.comparableId),staticClass:"karte-cell comparable header text-center text-caption"},[_vm._v(" ID: "+_vm._s(_vm.comparableId)+" ")]),_c('v-col',{key:("coordinate" + (coordinate.key) + "-ratio-" + _vm.id + "-" + _vm.comparableId),staticClass:"karte-cell neutral header text-center text-caption"},[_vm._v(" ID: "+_vm._s(_vm.id)+"/"+_vm._s(_vm.comparableId)+" ")])]})],2):_vm._e(),_vm._l((_vm.geometricFeatures),function(feature,featureIndex){return _c('v-row',{key:("geometricFeature" + (feature.name)),attrs:{"dense":""}},[_c('v-col',{staticClass:"karte-cell text-start text-caption",class:{
          comparable: _vm.isComparableColor,
        },attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(feature.name)+" ")]),_vm._l((feature.vals),function(val,i){return [_c('v-col',{key:((feature.name) + "-" + i),staticClass:"karte-cell text-center text-caption"},[_vm._v(" "+_vm._s(val != null ? val.toExponential(2) : null)+" ")]),(_vm.comparableGeometricFeatures)?[_c('v-col',{key:((feature.name) + "-comparable" + i),staticClass:"karte-cell text-center text-caption"},[_vm._v(" "+_vm._s(_vm.comparableGeometricFeatures[featureIndex].vals[i] != null ? _vm.comparableGeometricFeatures[featureIndex].vals[i].toExponential(2) : null)+" ")]),_c('v-col',{key:((feature.name) + "-ratio-" + i),staticClass:"karte-cell text-center text-caption"},[_vm._v(" "+_vm._s(_vm.comparableGeometricFeatures[featureIndex].vals[i] != null && val != null ? (val/_vm.comparableGeometricFeatures[featureIndex].vals[i]).toExponential(2) : null)+" ")])]:_vm._e()]})],2)})],2),(!_vm.comparableId)?_c('v-col',{staticClass:"mt-3",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"karte-cell header",class:{
          neutral: _vm.comparableId,
          comparable: _vm.isComparableColor,
        },attrs:{"cols":"3"}}),_vm._l((_vm.coordinates),function(coordinate){return _c('v-col',{key:("coordinate" + (coordinate.key)),staticClass:"karte-cell header text-center text-caption",class:{
          comparable: _vm.isComparableColor,
        }},[_vm._v(" "+_vm._s(coordinate.label)+" ")])})],2),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"karte-cell header",class:{
          neutral: _vm.comparableId,
          comparable: _vm.isComparableColor,
        },staticStyle:{"border-top":"none"},attrs:{"cols":"3"}}),_vm._l((_vm.coordinates),function(coordinate){return _vm._l((_vm.visibleDirectionTypes),function(directionType){return _c('v-col',{key:("coordinate" + (coordinate.key) + "-directionType" + (directionType.key)),staticClass:"karte-cell header text-center text-caption",class:{
            comparable: _vm.isComparableColor,
          }},[_vm._v(" "+_vm._s(directionType.label)+" ")])})})],2),_vm._l((_vm.directionalFeatures),function(feature){return _c('v-row',{key:("geometricFeature" + (feature.name)),attrs:{"dense":""}},[_c('v-col',{staticClass:"karte-cell text-start text-caption",attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(feature.name)+" ")]),_vm._l((feature.vals),function(val,i){return _c('v-col',{key:((feature.name) + "-" + i),staticClass:"karte-cell text-center text-caption"},[_vm._v(" "+_vm._s(val != null ? val.toExponential(2) : null)+" ")])})],2)})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }