<template>
  <v-row v-if="!comparableId">
    <v-col cols="3">
      <v-row dense>
        <v-col 
          class="header karte-cell text-center text-caption"
          :class="[
            color,
          ]"
        >
          アカウント名
        </v-col>
      </v-row>
      <v-row dense>
        <v-col 
          class="karte-cell text-center text-caption"
        >
          {{ participant ? participant.name : '' }}
        </v-col>
      </v-row>

      <v-row dense>
        <v-col 
          class="mt-1 header karte-cell text-center text-caption"
          :class="[
            color,
          ]"
        >
          分析対象区間 [s]
        </v-col>
      </v-row>
      <v-row dense>
        <v-col 
          class="karte-cell text-center text-caption"
          :class="[
            color,
          ]"
        >
          {{ selectedDuration.toFixed(1) }}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="5">
      <v-row dense>
        <v-col 
          class="header karte-cell text-center text-caption"
          :class="[
            color,
          ]"
        >
          レポート作成日時
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="karte-cell text-center text-caption">
          {{ sway ? sway.createdTime : '' }}
        </v-col>
      </v-row>

      <v-row dense>
        <v-col 
          class="mt-1 header karte-cell text-center text-caption"
          :class="[
            color,
          ]"
        >
          分析方向
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="karte-cell text-center text-caption">
          {{ viewNameDict[view] }}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="4">
      <v-row dense>
        <v-col 
          class="header karte-cell text-center text-caption"
          :class="[
            color,
          ]"
        >
          データタイトル
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="karte-cell text-center text-caption">
          {{ sway ? sway.name : '' }}
        </v-col>
      </v-row>

      <v-row dense>
        <v-col 
          class="mt-1 header karte-cell text-center text-caption"
          :class="[
            color,
          ]"
        >
          プライバシーポリシー
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="karte-cell text-center text-caption">
          {{ participant && participant.hasPrivacyPolicyAccepted ? '同意' : '同意なし' }}
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <v-row v-else>
    <v-col cols="6">
      <v-row dense>
        <v-col class="text-center text-caption">ID: {{ id }}</v-col>
      </v-row>
      <v-row dense>
        <v-col cols="5">
          <v-row dense>
            <v-col class="header karte-cell text-center text-caption">
              アカウント名
            </v-col>
          </v-row>
          <v-row dense>
            <v-col 
              class="karte-cell text-center text-caption"
            >
              {{ participant ? participant.name : '' }}
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="mt-1 header karte-cell text-center text-caption">
              分析対象区間 [s]
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="karte-cell text-center text-caption">
              {{ selectedDuration.toFixed(1) }}
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="mt-1 header karte-cell text-center text-caption">
              分析方向
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="karte-cell text-center text-caption">
              {{ viewNameDict[view] }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="7" class="pl-3">
          <v-row dense>
            <v-col class="header karte-cell text-center text-caption">
              レポート作成日時
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="karte-cell text-center text-caption">
              {{ sway ? sway.createdTime : '' }}
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="mt-1 header karte-cell text-center text-caption">
              データタイトル
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="karte-cell text-center text-caption">
              {{ sway ? sway.name : '' }}
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="mt-1 header karte-cell text-center text-caption">
              プライバシーポリシー
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="karte-cell text-center text-caption">
              {{ participant && participant.hasPrivacyPolicyAccepted ? '同意' : '同意なし' }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="6">
      <v-row dense>
        <v-col class="text-center text-caption">ID: {{ comparableId }}</v-col>
      </v-row>
      <v-row dense>
        <v-col cols="5">
          <v-row dense>
            <v-col class="header comparable karte-cell text-center text-caption">
              アカウント名
            </v-col>
          </v-row>
          <v-row dense>
            <v-col 
              class="karte-cell text-center text-caption"
            >
              {{ comparableParticipant ? comparableParticipant.name : '' }}
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="mt-1 header comparable karte-cell text-center text-caption">
              分析対象区間 [s]
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="karte-cell text-center text-caption">
              {{ selectedComparableDuration.toFixed(1) }}
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="mt-1 header comparable karte-cell text-center text-caption">
              分析方向
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="karte-cell text-center text-caption">
              {{ viewNameDict[view] }}
            </v-col>
          </v-row>

        </v-col>
        <v-col cols="7" class="pl-3">
          <v-row dense>
            <v-col class="header comparable karte-cell text-center text-caption">
              レポート作成日時
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="karte-cell text-center text-caption">
              {{ comparableSway ? comparableSway.createdTime : '' }}
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="mt-1 header comparable karte-cell text-center text-caption">
              データタイトル
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="karte-cell text-center text-caption">
              {{ comparableSway ? comparableSway.name : '' }}
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="mt-1 header comparable karte-cell text-center text-caption">
              プライバシーポリシー
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="karte-cell text-center text-caption">
              {{ comparableParticipant && comparableParticipant.hasPrivacyPolicyAccepted ? '同意' : '同意なし' }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    color: {
      default: null,
    },
    id: {
      required: true,
    },
    comparableId: {
      default: null,
    },
    view: {
      default: 'top',
    },
    sway: {

    },
    comparableSway: {

    },
    selectedSwayDuration: {
    },
    selectedComparableSwayDuration: {
    },
    swayTrajectory: {
    },
    comparableSwayTrajectory: {
    },
  },
  data: function(){
    return {
      viewNameDict: {
        top: '左右・前後',
        side: '前後・上下',
        front: '左右・上下',
      },
    }
  },
  computed: {
    ...mapState([
      'participants',
    ]),
    participant: function(){
      return this.participants.find(p => p.id == this.sway?.participantId);
    },
    comparableParticipant: function(){
      return this.participants.find(p => p.id == this.comparableSway?.participantId);
    },
    selectedDuration: function(){
      let indexFrom = this.selectedSwayDuration?.from ?? 0;
      let indexTo = this.selectedSwayDuration?.to ?? 0;

      return (indexTo - indexFrom)*0.01;
    },
    selectedComparableDuration: function(){
      let indexFrom = this.selectedComparableSwayDuration?.from ?? 0;
      let indexTo = this.selectedComparableSwayDuration?.to ?? 0;

      return (indexTo - indexFrom)*0.01;
    },
  }
}
</script>