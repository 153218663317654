<template>
  <div
    class="background"
    :class="{
      clickable: highlighted,
    }"
    style="position: relative;"
    :style="{
      height: `${containerHeight}px`
    }"
    @mousedown.prevent="startDragging"
    @mousemove.prevent="rotateGraph"
    @wheel.ctrl.prevent="scaleGraph"
    ref="container"
    v-resize="resizeContainer"
    v-observe-visibility="isVisible => (isVisible ? resizeContainer() : false)"

    v-tooltip="{
      content: `ドラッグで${isRotatable ? '回転' : '移動'}<br/>ctrl+wheel、またはマウスオーバしてctrl+「+」/ctrl+「-」で拡大/縮小`,
      delay: {
        show: 2000,
        hide: 0
      },
    }"
    @mouseenter="addKeyEventListeners"
    @mouseleave="removeKeyEventListeners"
  >
    <VglDefs ref="defs" v-if="isGraphVisible">
      <template v-slot:[`grid${id}`]>
        <VglLine 
          v-for="(line, index) in subGridLines" 
          :key="`sub-grid-line-${index}`"
        >
          <template #geometry>
            <VglGeometry>
              <template #position>
                <VglFloat32Attribute
                  :array="line"
                  :item-size="3"
                />
              </template>
            </VglGeometry>
          </template>
          <template #material>
            <VglLineBasicMaterial
              :linewidth="2"
              color="#DDDDDD"
            />
          </template>
        </VglLine>

        <VglLine v-for="(line, index) in gridLines" :key="`grid-line-${index}`">
          <template #geometry>
            <VglGeometry>
              <template #position>
                <VglFloat32Attribute
                  :array="line"
                  :item-size="3"
                />
              </template>
            </VglGeometry>
          </template>
          <template #material>
            <VglLineBasicMaterial
              :linewidth="12"
              color="#888888"
            />
          </template>
        </VglLine>
      </template>
    </VglDefs>
    <VglRenderer 
      v-if="isGraphVisible"
      style="width: 100%; height: 100%;"
      :alpha="true"
      :antialias="true"
      :preserve-drawing-buffer="true"
      ref="renderer"
      :id="`ankleTrajectory${id}`"
    >
      <template #scene>
        <VglScene ref="scene">
          <VglGroup
            rotation="quaternion"
            :scale-x="scaleX"
            :scale-y="scaleY"
            :scale-z="scaleZ"
            :rotation-w="quaternion[0]"
            :rotation-x="quaternion[1]"
            :rotation-y="quaternion[2]"
            :rotation-z="quaternion[3]"
          >
            <VglUse v-if="grid" :href="`grid${id}`" />

            <template v-if="!isAveraged">
              <VglGroup
                :position-z="-stepWidth"
              >
                <VglLine 
                  v-for="i of leftTrajectories.length"
                  :ref="`leftTrajectory${i}`"
                  :key="`leftTrajectory${i}`"
                  :hidden="!editing && leftTrajectories[i - 1].isInvalid"
                >
                  <template #geometry>
                    <VglUse
                      :href="`leftTrajectory${i - 1}-${gaitId}`"
                    />
                  </template>
                  <template #material>
                    <TransparentLineMaterial
                      :linewidth="2"
                      :color="leftTrajectories[i - 1].isInvalid ? '#CCCCCC' : '#CC4444'"
                      :opacity="highlighted && highlighted.part === 'left' && highlighted.index == i - 1 ? 1 : lineOpacity"
                    />
                  </template>
                </VglLine>
              </VglGroup>
              
              <VglGroup
                :position-z="stepWidth"
              >
                <VglLine 
                  v-for="i of rightTrajectories.length"
                  :key="`rightTrajectory${i}`"
                  :ref="`rightTrajectory${i}`"
                  :hidden="!editing && rightTrajectories[i - 1].isInvalid"
                >
                  <template #geometry>
                    <VglUse
                      :href="`rightTrajectory${i - 1}-${gaitId}`"
                    />
                  </template>
                  <template #material>
                    <TransparentLineMaterial
                      :linewidth="2"
                      :color="rightTrajectories[i - 1].isInvalid ? '#CCCCCC' : '#4444CC'"
                      :opacity="highlighted && highlighted.part === 'right' && highlighted.index == i - 1 ? 1 : lineOpacity"
                    />
                  </template>
                </VglLine>
              </VglGroup>
            </template>
            <template v-else>
              <VglGroup
                :position-z="-stepWidth"
              >
                <VglLine >
                  <template #geometry>
                    <VglUse
                      :href="`averaged${comparable != null ? 'Comparable' : ''}LeftTrajectory-${gaitId}`"
                    />
                  </template>
                  <template #material>
                    <TransparentLineMaterial
                      :linewidth="2"
                      color="#CC4444"
                      :opacity="1.0"
                    />
                  </template>
                </VglLine>
              </VglGroup>

              <VglGroup
                :position-z="stepWidth"
              >
                <VglLine >
                  <template #geometry>
                    <VglUse
                      :href="`averaged${comparable != null ? 'Comparable' : ''}RightTrajectory-${gaitId}`"
                    />
                  </template>
                  <template #material>
                    <TransparentLineMaterial
                      :linewidth="2"
                      color="#4444CC"
                      :opacity="1.0"
                    />
                  </template>
                </VglLine>
              </VglGroup>
            </template>
          </VglGroup>
        </VglScene>
      </template>
      <template #camera>
        <VglPerspectiveCamera 
          v-if="perspective"
          ref="camera"
          :position-z="cameraPosition" 
          :rotation="isRotatable ? 'lookAt' : null" 
        />
        <VglOrthographicCamera 
          v-else
          ref="camera"
          :left="-cameraPosition" 
          :right="cameraPosition" 
          :top="cameraPosition/aspectRatio" 
          :bottom="-cameraPosition/aspectRatio"
          :position-x="translationX"
          :position-y="translationY"
          :position-z="cameraPosition" 
          :rotation="isRotatable ? 'lookAt' : null"
        />
      </template>
    </VglRenderer>

    <v-overlay :value="loading || isLoading" absolute>
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>


<script>
import { calcQuaternionFromEulerAngles, calcQuaternionMultiplication } from '@js/utils/MatrixUtil.js';
import TransparentLineMaterial from '@components/parts/materials/TransparentLineMaterial.vue';
import { Raycaster, Vector2 } from 'three';

import { 
  VglDefs,
  VglGroup,
  VglUse,
  VglRenderer, 
  VglScene, 
  VglPerspectiveCamera,
  VglOrthographicCamera,
  VglLine,
  VglLineBasicMaterial,
  VglGeometry,
  VglFloat32Attribute,
} from 'vue-gl';

import { mapState } from 'vuex';


export default {
  components: {
    VglDefs,
    VglGroup,
    VglUse,
    VglRenderer,
    VglScene,
    VglPerspectiveCamera,
    VglOrthographicCamera,
    VglLine,
    VglLineBasicMaterial,
    VglGeometry,
    VglFloat32Attribute,
    TransparentLineMaterial,
  },
  props: {
    id: {
      default: null,
    },
    gaitId: {
      required: true,
    },
    leftTrajectories: {
      required: true,
    },
    rightTrajectories: {
      required: true,
    },
    selection: {
      required: true,
    },
    perspective: {
      default: false,
    },
    initialRoll: {
      default: 25,
    },
    initialPitch: {
      default: 45,
    },
    initialYaw: {
      default: 0,
    },
    draggingMode: {
      default: 'rotation',
    },
    grid: {
      default: true,
    },
    gridMode: {
      default: 'all', // top | side | front | all
    },
    initialCameraPosition: {
      default: 1.5,
    },
    maxCameraPosition: {
      default: 3.0,
    },
    minCameraPosition: {
      default: 0.5,
    },
    scaleX: {
      default: 1,
    },
    scaleY: {
      default: 1,
    },
    scaleZ: {
      default: 1,
    },
    loading: {
      default: false,
    },

    verticalOffset: {
      default: 0,
    },
    horizontalOffset: {
      default: 0,
    },

    aspectRatio: {
      default: 1.92
    },

    lineOpacity: {
      default: 0.65,
    },

    averaged: {
      default: null,
    },
    comparable: {
      default: null,
    },

    editing: {
      default: false,
    },
  },
  data: function(){
    return {
      cameraPosition: 1.0,
      stepWidth: 0.25,
      isDragging: false,
      quaternion: [1, 0, 0, 0],
      previousQuaternion: [1, 0, 0, 0],
      containerWidth: 0,
      translationX: 0,
      translationY: 0,
      gridInterval: 0.1,

      loadingCount: 0,

      raycaster: null,

      highlighted: null,
    }
  },
  computed: {
    ...mapState([
      'isGraphVisible',
    ]),
    isAveraged: function(){
      return this.averaged != null;
    },
    containerHeight: function(){
      return parseInt(this.containerWidth/this.aspectRatio);
    },
    isLoading: function(){
      return this.loadingCount > 0;
    },
    isRotatable: function(){
      return this.draggingMode === 'rotation';
    },

    maxGridX: function(){
      return this.isRotatable ? 2.0 : 6.0;
    },
    minGridX: function(){
      return this.isRotatable ? -0.5 : -2.5;
    },
    maxGridY: function(){
      return this.isRotatable ? 0.4 : 1.2;
    },
    minGridY: function(){
      return this.isRotatable ? 0.0 : -1.0;
    },
    maxGridZ: function(){
      return this.isRotatable ? 0.75 : 2.25;
    },
    minGridZ: function(){
      return this.isRotatable ? -0.75 : -2.25;
    },
    subGridLines: function(){
      let rtn = [];
      const interval = this.gridInterval;

      if(['top', 'all'].some(m => m === this.gridMode)){
        for(let i=this.minGridX/interval, iMax=this.maxGridX/interval; i<=iMax; i++){
          rtn.push([
            -this.horizontalOffset + interval*i, 
            -this.verticalOffset, 
            this.minGridZ, 

            -this.horizontalOffset + interval*i, 
            -this.verticalOffset, 
            this.maxGridZ,
          ]);
        }

        for(let i=this.minGridZ/interval, iMax=this.maxGridZ/interval; i<=iMax; i++){
          rtn.push([
            this.minGridX - this.horizontalOffset, 
            -this.verticalOffset, 
            interval*i, 

            this.maxGridX - this.horizontalOffset, 
            -this.verticalOffset, 
            interval*i,
          ]);
        }
      }

      if(['front', 'all'].some(m => m === this.gridMode)){
        for(let i=this.minGridY/interval, iMax=this.maxGridY/interval; i<=iMax; i++){
          rtn.push([
            this.minGridX - this.horizontalOffset, 
            -this.verticalOffset + interval*i, 
            this.minGridZ, 

            this.minGridX - this.horizontalOffset, 
            -this.verticalOffset + interval*i, 
            this.maxGridZ,
          ]);
        }

        for(let i=this.minGridZ/interval, iMax=this.maxGridZ/interval; i<=iMax; i++){
          rtn.push([
            this.minGridX - this.horizontalOffset, 
            -this.verticalOffset + this.minGridY, 
            interval*i, 

            this.minGridX - this.horizontalOffset, 
            -this.verticalOffset + this.maxGridY, 
            interval*i,
          ]);
        }
      }

      if(['side', 'all'].some(m => m === this.gridMode)){
        for(let i=this.minGridX/interval, iMax=this.maxGridX/interval; i<=iMax; i++){
          rtn.push([
            -this.horizontalOffset + interval*i, 
            -this.verticalOffset + this.minGridY, 
            this.minGridZ, 

            -this.horizontalOffset + interval*i, 
            -this.verticalOffset + this.maxGridY, 
            this.minGridZ,
          ]);
        }

        for(let i=this.minGridY/interval, iMax=this.maxGridY/interval; i<=iMax; i++){
          rtn.push([
            this.minGridX - this.horizontalOffset, 
            -this.verticalOffset + interval*i, 
            this.minGridZ, 
            
            this.maxGridX - this.horizontalOffset, 
            -this.verticalOffset + interval*i, 
            this.minGridZ,
          ]);
        }
      }

      return rtn;
    },
    gridLines: function(){
      let rtn = [];

      if(['top', 'all'].some(m => m === this.gridMode)){
        rtn.push([
          -this.horizontalOffset, 
          -this.verticalOffset, 
          this.minGridZ, 

          -this.horizontalOffset, 
          -this.verticalOffset, 
          this.maxGridZ,
        ]);

        rtn.push([
          this.minGridX - this.horizontalOffset, 
          -this.verticalOffset, 
          -this.stepWidth, 

          this.maxGridX - this.horizontalOffset, 
          -this.verticalOffset, 
          -this.stepWidth,
        ]);

        rtn.push([
          this.minGridX - this.horizontalOffset, 
          -this.verticalOffset, 
          this.stepWidth, 

          this.maxGridX - this.horizontalOffset, 
          -this.verticalOffset, 
          this.stepWidth,
        ]);

      }

      if(['front', 'all'].some(m => m === this.gridMode)){
        rtn.push([
          this.minGridX - this.horizontalOffset, 
          -this.verticalOffset, 
          this.minGridZ, 

          this.minGridX - this.horizontalOffset, 
          -this.verticalOffset, 
          this.maxGridZ,
        ]);

        rtn.push([
          this.minGridX - this.horizontalOffset, 
          -this.verticalOffset + this.minGridY, 
          -this.stepWidth, 

          this.minGridX - this.horizontalOffset, 
          -this.verticalOffset + this.maxGridY, 
          -this.stepWidth,
        ]);

        rtn.push([
          this.minGridX - this.horizontalOffset, 
          -this.verticalOffset + this.minGridY, 
          this.stepWidth, 

          this.minGridX - this.horizontalOffset, 
          -this.verticalOffset + this.maxGridY, 
          this.stepWidth,
        ]);
      }


      if(['side', 'all'].some(m => m === this.gridMode)){
        rtn.push([
          this.minGridX - this.horizontalOffset, 
          -this.verticalOffset, 
          this.minGridZ, 

          this.maxGridX - this.horizontalOffset, 
          -this.verticalOffset, 
          this.minGridZ,
        ]);

        rtn.push([
          -this.horizontalOffset, 
          -this.verticalOffset + this.minGridY, 
          this.minGridZ, 
          
          -this.horizontalOffset, 
          -this.verticalOffset + this.maxGridY, 
          this.minGridZ,
        ]);
      }

      return rtn;
    },
  },
  beforeDestroy: function(){
    if(this.$root.$_vglDefs){
      this.$root.$_vglDefs.use = 0;
    }
  },
  mounted: function(){
    this.refreshCamera();
    this.$nextTick(() => {
      this.containerWidth = this.$refs.container.clientWidth;
    });

    this.raycaster = new Raycaster();
  },
  watch: {
    selection: {
      handler(){
        this.$forceUpdate();
      },
      deep: true,
      immediate: true,
    },
    gaitId: function(){
      this.refreshCamera();
    },
    gridMode: function(){
      this.refreshCamera();
    },
  },
  methods: {
    startDragging: function(e){
      if(this.editing){
        const x = e.offsetX / e.target.clientWidth * 2.0 - 1.0;
        const y = e.offsetY / e.target.clientHeight * 2.0 - 1.0;
        const v = new Vector2(x, -y);
        this.raycaster.setFromCamera(v, this.$refs.camera.inst);
        this.raycaster.params.Line.threshold = 0.01;

        let targetObjects = [
          ...this.leftTrajectories.map((t, i) => {
            let rtn = this.$refs[`leftTrajectory${i+1}`][0].inst;
            rtn.userData = {
              index: i,
              part: 'left',
            };
            return rtn;
          }),
          ...this.rightTrajectories.map((t, i) => {
            let rtn = this.$refs[`rightTrajectory${i+1}`][0].inst;
            rtn.userData = {
              index: i,
              part: 'right',
            };
            return rtn;
          }),
        ]
        targetObjects = targetObjects.filter(t => t.geometry.attributes.position)
        const intersects = this.raycaster.intersectObjects(targetObjects);
        const clickedObject = intersects.length > 0 ? intersects[0].object.userData : null;
        let target = null;
        if(clickedObject?.part === 'left'){
          target = this.leftTrajectories[clickedObject.index];
        }
        if(clickedObject?.part === 'right'){
          target = this.rightTrajectories[clickedObject.index];
        }

        if(target){
          this.$set(target, 'isInvalid', !target.isInvalid);
          this.$emit('update-invalid');
        }
      }

      this.previousQuaternion = [...this.quaternion];
      this.dx = 0;
      this.dy = 0;

      this.isDragging = true;
      window.addEventListener('mouseup', this.endDragging);
    },
    rotateGraph: function(e){
      if(this.editing){
        const x = e.offsetX / e.target.clientWidth * 2.0 - 1.0;
        const y = e.offsetY / e.target.clientHeight * 2.0 - 1.0;
        const v = new Vector2(x, -y);
        this.raycaster.setFromCamera(v, this.$refs.camera.inst);
        this.raycaster.params.Line.threshold = 0.01;

        let targetObjects = [
          ...this.leftTrajectories.map((t, i) => {
            let rtn = this.$refs[`leftTrajectory${i+1}`][0].inst;
            rtn.userData = {
              index: i,
              part: 'left',
            };
            return rtn;
          }),
          ...this.rightTrajectories.map((t, i) => {
            let rtn = this.$refs[`rightTrajectory${i+1}`][0].inst;
            rtn.userData = {
              index: i,
              part: 'right',
            };
            return rtn;
          }),
        ]
        targetObjects = targetObjects.filter(t => t.geometry.attributes.position)
        const intersects = this.raycaster.intersectObjects(targetObjects);
        this.highlighted = intersects.length > 0 ? intersects[0].object.userData : null;        
      }

      if(this.isDragging){
        if(this.isRotatable){
          this.dx += e.movementX;
          this.dy += e.movementY;

          const dx = this.dx/this.containerWidth;
          const dy = this.dy/this.containerHeight;
          const distance = Math.sqrt(dx*dx + dy*dy);
          if(distance != 0){
            const theta = distance*Math.PI;
            const sinTheta = Math.sin(theta)/distance;
            this.quaternion = calcQuaternionMultiplication(
              [
                Math.cos(theta), 
                dy*sinTheta,
                dx*sinTheta, 
                0.0
              ],
              this.previousQuaternion
            )
          }
        }else{
          this.translationX -= 2*this.cameraPosition*e.movementX/this.containerWidth;
          this.translationY += 2/this.aspectRatio*this.cameraPosition*e.movementY/this.containerHeight;
        }
      }
    },
    scaleGraph: function(e){
      let val = this.cameraPosition*(1.0 + e.deltaY*0.001);
      this.cameraPosition = Math.max(Math.min(this.maxCameraPosition, val), this.minCameraPosition);
    },
    endDragging: function(){
      window.removeEventListener('mouseup', this.endDragging);
      this.isDragging = false;
    },
    refreshCamera: function(){
      this.translationX = 0;
      this.translationY = 0;
      this.quaternion = calcQuaternionFromEulerAngles(
        this.initialRoll*Math.PI/180, 
        this.initialPitch*Math.PI/180, 
        this.initialYaw*Math.PI/180
      );
      this.cameraPosition = this.initialCameraPosition;
    },
    setCameraAngle: function(roll, pitch, yaw, cameraPosition=null){
      this.quaternion = calcQuaternionFromEulerAngles(
        roll*Math.PI/180, 
        pitch*Math.PI/180, 
        yaw*Math.PI/180
      );
      if(cameraPosition != null){
        this.cameraPosition = cameraPosition;
      }
    },
    resizeContainer: function(){
      this.$nextTick(() => {
        this.containerWidth = this.$refs.container.clientWidth;
      });
    },

    addKeyEventListeners: function(){
      window.addEventListener('keydown', this.executeKeyEvent);
    },
    removeKeyEventListeners: function(){
      this.highlighted = null;
      window.removeEventListener('keydown', this.executeKeyEvent);
    },
    zoomIn: function(){
      this.scaleGraph({deltaY: -100.0});
    },
    zoomOut: function(){
      this.scaleGraph({deltaY: 100.0});
    },
    executeKeyEvent: function(e){
      if(e.ctrlKey){
        switch(e.key){
          case '+':
            e.preventDefault();
            this.zoomIn();
            break;
          case '-':
            e.preventDefault();
            this.zoomOut();
            break;
        }
      }
    },

  }
}
</script>

<style lang="scss" scoped>
.background{
//  background: black;
  border: 1px solid black;
}

.clickable{
  cursor: pointer;
}
</style>