<template>
  <div
    class="background"
    style="position: relative;"
    :style="{
      height: `${containerHeight}px`
    }"
    @mousedown.prevent="startDragging"
    @mousemove.prevent="rotateGraph"
    @wheel.ctrl.prevent="scaleGraph"
    ref="container"
    v-resize="resizeContainer"

    v-tooltip="{
      content: `ドラッグで${isRotatable ? '回転' : '移動'}<br/>ctrl+wheel、またはマウスオーバしてctrl+「+」/ctrl+「-」で拡大/縮小`,
      delay: {
        show: 2000,
        hide: 0
      },
    }"
    @mouseenter="addKeyEventListeners"
    @mouseleave="removeKeyEventListeners"
  >
    <VglRenderer 
      v-observe-visibility="isVisible => (isVisible ? resizeContainer() : false)"
      v-if="isGraphVisible"
      style="width: 100%; height: 100%;"
      :alpha="true"
      :antialias="true"
      :preserve-drawing-buffer="true"
      ref="renderer"
      :id="`continuousAnkleTrajectory${id}`"
    >
      <VglDefs ref="defs">
        <template v-slot:[`continuousGrid${id}`]>
          <VglLine v-for="(line, index) in subGridLines" :key="`sub-grid-line-${index}`">
            <template #geometry>
              <VglGeometry>
                <template #position>
                  <VglFloat32Attribute
                    :array="line"
                    :item-size="3"
                  />
                </template>
              </VglGeometry>
            </template>
            <template #material>
              <VglLineBasicMaterial
                :linewidth="2"
                color="#DDDDDD"
              />
            </template>
          </VglLine>

          <VglLine v-for="(line, index) in gridLines" :key="`grid-line-${index}`">
            <template #geometry>
              <VglGeometry>
                <template #position>
                  <VglFloat32Attribute
                    :array="line"
                    :item-size="3"
                  />
                </template>
              </VglGeometry>
            </template>
            <template #material>
              <VglLineBasicMaterial
                :linewidth="12"
                color="#888888"
              />
            </template>
          </VglLine>
        </template>


        <template v-slot:[`continuousLines${id}`]>
          <VglGeometry
            :draw-range-start="visibleFrom"
            :draw-range-count="visibleTo - visibleFrom"
          >
            <template #position>
              <VglFloat32Attribute
                :array="points"
                :item-size="3"
              />
            </template>
          </VglGeometry>
        </template>


        <template v-slot:[`invisibleHeadContinuousLines${id}`]>
          <VglGeometry
            :draw-range-start="0"
            :draw-range-count="visibleFrom + 1"
          >
            <template #position>
              <VglFloat32Attribute
                :array="points"
                :item-size="3"
              />
            </template>
          </VglGeometry>
        </template>

        <template v-slot:[`invisibleTailContinuousLines${id}`]>
          <VglGeometry
            :draw-range-start="visibleTo"
            :draw-range-count="visibleMax - visibleTo"
          >
            <template #position>
              <VglFloat32Attribute
                :array="points"
                :item-size="3"
              />
            </template>
          </VglGeometry>
        </template>



        <template 
          v-for="point in splittedPoints" 
          v-slot:[`splittedPoint${id}-${point.index}`]
        >
          <VglSphereGeometry
            :key="`splittedPoint${point.index}`"
            :radius="dotSize"
            :width-segments="16"
            :height-segments="16"
          />
        </template>
      </VglDefs>
      <template #scene>
        <VglScene>
          <VglGroup
            rotation="quaternion"
            :scale-x="scale*scaleX"
            :scale-y="scale*scaleY"
            :scale-z="scale*scaleZ"
            :rotation-w="quaternion[0]"
            :rotation-x="quaternion[1]"
            :rotation-y="quaternion[2]"
            :rotation-z="quaternion[3]"
          >
            <VglUse v-if="grid" :href="`continuousGrid${id}`" />


            <VglLine>
              <template #geometry>
                <VglUse :href="`invisibleHeadContinuousLines${id}`" />
              </template>
              <template #material>
                <VglLineBasicMaterial
                  :linewidth="1"
                  color="#CCC"
                />
              </template>
            </VglLine>

            <VglLine>
              <template #geometry>
                <VglUse :href="`invisibleTailContinuousLines${id}`" />
              </template>
              <template #material>
                <VglLineBasicMaterial
                  :linewidth="1"
                  color="#CCC"
                />
              </template>
            </VglLine>


            <VglLine>
              <template #geometry>
                <VglUse :href="`continuousLines${id}`" />
              </template>
              <template #material>
                <VglLineBasicMaterial
                  :linewidth="3"
                  :color="color"
                />
              </template>
            </VglLine>

            <VglMesh
              v-for="point in splittedPoints"
              :key="`splittedPoint${point.index}`"
              :scale-y="1/scaleY"
              :position-x="point.x"
              :position-y="point.y"
              :position-z="point.z"
            >
              <template #geometry>
                <VglUse :href="`splittedPoint${id}-${point.index}`" />
              </template>
              <template #material>
                <VglMeshBasicMaterial
                  :color="visibleFrom < point.index && point.index <= visibleTo ? color : '#CCCCCC'"
                />
              </template>
            </VglMesh>
          </VglGroup>
        </VglScene>
      </template>
      <template #camera>
        <VglPerspectiveCamera 
          v-if="perspective" 
          :position-z="cameraPosition" 
        />
        <VglOrthographicCamera 
          v-else
          :left="-cameraPosition" 
          :right="cameraPosition" 
          :top="cameraPosition/aspectRatio" 
          :bottom="-cameraPosition/aspectRatio"
          :position-x="translationX"
          :position-y="translationY"
          :position-z="cameraPosition" 
        />
      </template>
    </VglRenderer>

    <v-overlay :value="loading" absolute>
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>


<script>
import { calcQuaternionFromEulerAngles, calcQuaternionMultiplication } from '@js/utils/MatrixUtil.js';

import { 
  VglDefs,
  VglGroup,
  VglUse,
  VglRenderer, 
  VglScene, 
  VglPerspectiveCamera,
  VglOrthographicCamera,
  VglLine,
  VglLineBasicMaterial,
  VglSphereGeometry,
  VglMesh,
  VglMeshBasicMaterial,
  VglGeometry,
  VglFloat32Attribute,
} from 'vue-gl';
import { mapState } from 'vuex';


export default {
  components: {
    VglDefs,
    VglGroup,
    VglUse,
    VglRenderer,
    VglScene,
    VglPerspectiveCamera,
    VglOrthographicCamera,
    VglLine,
    VglLineBasicMaterial,
    VglSphereGeometry,
    VglGeometry,
    VglFloat32Attribute,
    VglMesh,
    VglMeshBasicMaterial,
  },
  props: {
    id: {
      default: null,
    },
    color: {
      default: '#CCCCCC',
    },
    dotSize: {
      default: 0.05,
    },
    trajectory: {
      required: true,
    },
    selection: {
      required: true,
    },
    perspective: {
      default: false,
    },
    initialRoll: {
      default: 25,
    },
    initialPitch: {
      default: 45,
    },
    initialYaw: {
      default: 0,
    },
    draggingMode: {
      default: 'rotation',
    },
    grid: {
      default: true,
    },
    initialCameraPosition: {
      default: 1.5,
    },
    maxCameraPosition: {
      default: 3.0,
    },
    minCameraPosition: {
      default: 0.5,
    },
    scale: {
      default: 1,
    },
    scaleX: {
      default: 1,
    },
    scaleY: {
      default: 1,
    },
    scaleZ: {
      default: 1,
    },
    loading: {
      default: false,
    },

    verticalOffset: {
      default: 0,
    },

    aspectRatio: {
      default: 4.0
    },
  },
  data: function(){
    return {
      cameraPosition: 1.0,
      isDragging: false,
      quaternion: [1, 0, 0, 0],
      previousQuaternion: [1, 0, 0, 0],
      containerWidth: 0,
      translationX: 0,
      translationY: 0,
      gridInterval: 1,

      loadingCount: 0,
    }
  },
  computed: {
    ...mapState([
      'isGraphVisible',
    ]),
    visibleMax: function(){
      return this.points.length - 1;
    },
    visibleFrom: function(){
      return parseInt(Math.max(0, this.selection?.from ?? 0));
    },
    visibleTo: function(){
      return parseInt(Math.min(this.visibleMax, this.selection?.to ?? 0));
    },
    containerHeight: function(){
      return parseInt(this.containerWidth/this.aspectRatio);
    },
    isLoading: function(){
      return this.loadingCount > 0;
    },
    isRotatable: function(){
      return this.draggingMode === 'rotation';
    },

    maxGridX: function(){
      return 100;
    },
    minGridX: function(){
      return -30;
    },
    maxGridY: function(){
      return 10;
    },
    minGridY: function(){
      return -10;
    },
    maxGridZ: function(){
      return 30;
    },
    minGridZ: function(){
      return -30;
    },
    horizontalOffset: function(){
      return this.farthestPoint.d/2;
    },
    subGridLines: function(){
      let rtn = [];
      const interval = this.gridInterval;

      for(let i=this.minGridX/interval, iMax=this.maxGridX/interval; i<=iMax; i++){
        rtn.push([
          -this.horizontalOffset + interval*i, 
          -this.verticalOffset, 
          this.minGridZ, 

          -this.horizontalOffset + interval*i, 
          -this.verticalOffset, 
          this.maxGridZ,
        ]);
      }

      for(let i=this.minGridZ/interval, iMax=this.maxGridZ/interval; i<=iMax; i++){
        rtn.push([
          this.minGridX - this.horizontalOffset, 
          -this.verticalOffset, 
          interval*i, 

          this.maxGridX - this.horizontalOffset, 
          -this.verticalOffset, 
          interval*i,
        ]);
      }

      return rtn;
    },
    gridLines: function(){
      let rtn = [];
      rtn.push([
        -this.horizontalOffset, 
        -this.verticalOffset, 
        this.minGridZ, 

        -this.horizontalOffset, 
        -this.verticalOffset, 
        this.maxGridZ,
      ]);

      rtn.push([
        this.minGridX - this.horizontalOffset, 
        -this.verticalOffset, 
        0, 

        this.maxGridX - this.horizontalOffset, 
        -this.verticalOffset, 
        0,
      ]);

      rtn.push([
        this.minGridX - this.horizontalOffset, 
        -this.verticalOffset, 
        0, 

        this.maxGridX - this.horizontalOffset, 
        -this.verticalOffset, 
        0,
      ]);
      return rtn;
    },
    rotatedTrajectory: function(){
      let theta = Math.atan2(this.farthestPoint.z, this.farthestPoint.y);
      let rtn = [];

      for(let i=0, iMax=this.trajectory.x.length; i < iMax; i++){
        let dY = this.trajectory.y[i];
        let dZ = this.trajectory.z[i];

        rtn.push({
          x: dY*Math.cos(theta) + dZ*Math.sin(theta),
          y: this.trajectory.x[i],
          z: -dY*Math.sin(theta) + dZ*Math.cos(theta),

          index: i,
        });
      }

      return rtn;
    },
    farthestPoint: function(){
      let rtn = {
        x: 0,
        y: 0,
        z: 0,
        d: 0,
      };
      this.trajectory.splitedIndexes.forEach(i => {
        let d = Math.sqrt(this.trajectory.y[i]**2 + this.trajectory.z[i]**2)
        if(rtn.d < d){
          rtn = {
            x: this.trajectory.x[i],
            y: this.trajectory.y[i],
            z: this.trajectory.z[i],
            d: d
          }
        }
      });
      return rtn;
    },
    splittedPoints: function(){
      return this.trajectory.splitedIndexes.map(i => ({
        x: this.rotatedTrajectory[i].x - this.horizontalOffset, 
        y: this.rotatedTrajectory[i].y - this.verticalOffset, 
        z: this.rotatedTrajectory[i].z,

        index: this.rotatedTrajectory[i].index,
      }))
    },
    points: function(){
      let rtn = [];

      this.rotatedTrajectory.forEach(p => {
        rtn.push(p.x - this.horizontalOffset);
        rtn.push(p.y - this.verticalOffset);
        rtn.push(p.z);
      });

      return rtn;
    },
  },
  beforeDestroy: function(){
    if(this.$root.$_vglDefs){
      this.$root.$_vglDefs.use = 0;
    }
  },
  mounted: function(){
    this.refreshCamera();
  },
  watch: {
    selection: {
      handler(){
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    startDragging: function(){
      this.previousQuaternion = [...this.quaternion];
      this.dx = 0;
      this.dy = 0;

      this.isDragging = true;
      window.addEventListener('mouseup', this.endDragging);
    },
    rotateGraph: function(e){
      if(this.isDragging){
        if(this.isRotatable){
          this.dx += e.movementX;
          this.dy += e.movementY;

          const dx = this.dx/this.containerWidth;
          const dy = 0.25*this.dy/this.containerHeight;
          const distance = Math.sqrt(dx*dx + dy*dy);
          if(distance != 0){
            const theta = distance*Math.PI;
            const sinTheta = Math.sin(theta)/distance;
            this.quaternion = calcQuaternionMultiplication(
              [
                Math.cos(theta), 
                dy*sinTheta,
                dx*sinTheta, 
                0.0
              ],
              this.previousQuaternion
            )
          }
        }else{
          this.translationX -= 2*this.cameraPosition*e.movementX/this.containerWidth;
          this.translationY += 2/this.aspectRatio*this.cameraPosition*e.movementY/this.containerHeight;
        }
      }
    },
    scaleGraph: function(e){
      let val = this.cameraPosition*(1.0 + e.deltaY*0.001);
      this.cameraPosition = Math.max(Math.min(this.maxCameraPosition, val), this.minCameraPosition);
    },
    endDragging: function(){
      window.removeEventListener('mouseup', this.endDragging);
      this.isDragging = false;
    },
    refreshCamera: function(){
      this.translationX = 0;
      this.translationY = 0;
      this.quaternion = calcQuaternionFromEulerAngles(
        this.initialRoll*Math.PI/180, 
        this.initialPitch*Math.PI/180, 
        this.initialYaw*Math.PI/180
      );
      this.cameraPosition = this.initialCameraPosition === 'auto'
        ? this.farthestPoint.d*1.1/20
        : this.initialCameraPosition;
    },
    setCameraAngle: function(roll, pitch, yaw, cameraPosition=null){
      this.quaternion = calcQuaternionFromEulerAngles(
        roll*Math.PI/180, 
        pitch*Math.PI/180, 
        yaw*Math.PI/180
      );
      if(cameraPosition != null){
        this.cameraPosition = cameraPosition;
      }
    },
    resizeContainer: function(){
      this.containerWidth = this.$refs.container.clientWidth;
    },
    addKeyEventListeners: function(){
      window.addEventListener('keydown', this.executeKeyEvent);
    },
    removeKeyEventListeners: function(){
      window.removeEventListener('keydown', this.executeKeyEvent);
    },
    zoomIn: function(){
      this.scaleGraph({deltaY: -100.0});
    },
    zoomOut: function(){
      this.scaleGraph({deltaY: 100.0});
    },
    executeKeyEvent: function(e){
      if(e.ctrlKey){
        switch(e.key){
          case '+':
            e.preventDefault();
            this.zoomIn();
            break;
          case '-':
            e.preventDefault();
            this.zoomOut();
            break;
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.background{
  border: 1px solid black;
}
</style>