import Vue from 'vue';
import Router from 'vue-router';

import Top from '@pages/Top.vue';
import Postures from '@pages/Postures.vue';
import PostureDetail from '@pages/PostureDetail.vue';
import Gaits from '@pages/Gaits.vue';
import GaitReport from '@pages/GaitReport.vue';
import GaitGroupDetail from '@pages/GaitGroupDetail.vue';
import SwayGroupDetail from '@pages/SwayGroupDetail.vue';
import Sway from '@pages/Sway.vue';
import SwayReport from '@pages/SwayReport.vue';
import Users from '@pages/Users.vue';
import Projects from '@pages/Projects.vue';
import ProjectDetail from '@pages/ProjectDetail.vue';
import Clients from '@pages/Clients.vue';
import ClientDetail from '@pages/ClientDetail.vue';
import Participants from '@pages/Participants.vue';
import ParticipantDetail from '@pages/ParticipantDetail.vue';
import Members from '@pages/Members.vue';
import Apks from '@pages/Apks.vue';

Vue.use(Router);

const createRouter = () => {
	return new Router({
		mode: 'history',
		routes: [
			{ 
				path: '/', 
				component: Top,
				meta: {
					isKeepPage: true,
				}
			},
			{ 
				path: '/login/:userId/:verificationKey', 
				component: Top,
				meta: {
					isKeepPage: true,
					verification: true,
				},
			},
			{ 
				path: '/participants', 
				component: Participants,
				meta: {
					title: '計測参加者管理',
					isKeepPage: true,
				}
			},
			{ 
				path: '/participant/:id', 
				component: ParticipantDetail,
				meta: {
					title: '計測参加者詳細',
					isKeepPage: true,
				},
        props: (route) => {
          const id = Number.parseInt(route.params.id);
          if (Number.isNaN(id)) {
            return 0
          }
          return { id }
				}
			},
			{ 
				path: '/postures', 
				component: Postures,
				meta: {
					title: '姿勢分析レポート一覧',
					isKeepPage: true,
				}
			},
			{ 
				path: '/gaits', 
				component: Gaits,
				meta: {
					title: '歩行分析レポート一覧',
					isKeepPage: true,
				}
			},
			{ 
				path: '/sway', 
				component: Sway,
				meta: {
					title: '重心動揺レポート一覧',
					isKeepPage: true,
				}
			},
			{ 
				path: '/members', 
				component: Members,
				meta: {
					title: 'メンバー管理',
					isKeepPage: true,
				}
			},
			{ 
				path: '/apks', 
				component: Apks,
				meta: {
					title: 'APK管理',
					isKeepPage: true,
				}
			},
			{ 
				path: '/posture/:id', 
				component: PostureDetail,
				meta: {
					title: '姿勢分析レポート',
					isKeepPage: true,
				},
        props: (route) => {
          const id = Number.parseInt(route.params.id);
          if (Number.isNaN(id)) {
            return 0
          }
          return { id }
				}
			},
			{ 
				path: '/gait/:id', 
				component: GaitReport,
				meta: {
					title: '歩行分析レポート',
					isKeepPage: true,
				},
        props: (route) => {
          const id = Number.parseInt(route.params.id);
          if (Number.isNaN(id)) {
            return 0
          }
          return { id }
				}
			},
			{ 
				path: '/gait/:id/:comparableId', 
				component: GaitReport,
				meta: {
					title: '歩行分析レポート比較',
					isKeepPage: true,
				},
        props: (route) => {
          let id = Number.parseInt(route.params.id);
          if (Number.isNaN(id)) {
            id = 0;
          }

					let comparableId = Number.parseInt(route.params.comparableId);
          if (Number.isNaN(comparableId)) {
            comparableId = 0;
          }
          return { id, comparableId }
				}
			},
			{ 
				path: '/gait_group/:id', 
				component: GaitGroupDetail,
				meta: {
					title: '歩行グループ',
					isKeepPage: true,
				},
        props: (route) => {
          const id = Number.parseInt(route.params.id);
          if (Number.isNaN(id)) {
            return 0
          }
          return { id }
				}
			},
			{ 
				path: '/gait_group/:id/:comparableId', 
				component: GaitGroupDetail,
				meta: {
					title: '歩行グループ比較',
					isKeepPage: true,
				},
        props: (route) => {
          let id = Number.parseInt(route.params.id);
          if (Number.isNaN(id)) {
            id = 0;
          }

					let comparableId = Number.parseInt(route.params.comparableId);
          if (Number.isNaN(comparableId)) {
            comparableId = 0;
          }
          return { id, comparableId }
				}
			},
			{ 
				path: '/sway_group/:id', 
				component: SwayGroupDetail,
				meta: {
					title: '重心動揺グループ',
					isKeepPage: true,
				},
        props: (route) => {
          const id = Number.parseInt(route.params.id);
          if (Number.isNaN(id)) {
            return 0
          }
          return { id }
				}
			},
			{ 
				path: '/sway_group/:id/:comparableId', 
				component: SwayGroupDetail,
				meta: {
					title: '重心動揺グループ比較',
					isKeepPage: true,
				},
        props: (route) => {
          let id = Number.parseInt(route.params.id);
          if (Number.isNaN(id)) {
            id = 0;
          }

					let comparableId = Number.parseInt(route.params.comparableId);
          if (Number.isNaN(comparableId)) {
            comparableId = 0;
          }
          return { id, comparableId }
				}
			},
			{ 
				path: '/sway/:id', 
				component: SwayReport,
				meta: {
					title: '重心動揺レポート',
					isKeepPage: true,
				},
        props: (route) => {
          const id = Number.parseInt(route.params.id);
          if (Number.isNaN(id)) {
            return 0
          }
          return { id }
				}
			},
			{ 
				path: '/sway/:id/:comparableId', 
				component: SwayReport,
				meta: {
					title: '重心動揺レポート比較',
					isKeepPage: true,
				},
        props: (route) => {
          let id = Number.parseInt(route.params.id);
          if (Number.isNaN(id)) {
            id = 0;
          }

					let comparableId = Number.parseInt(route.params.comparableId);
          if (Number.isNaN(comparableId)) {
            comparableId = 0;
          }
          return { id, comparableId }
				}
			},
			{ 
				path: '/projects', 
				component: Projects,
				meta: {
					title: 'プロジェクト管理',
					isKeepPage: true,
				}
			},
			{ 
				path: '/project/:id', 
				component: ProjectDetail,
				meta: {
					title: 'プロジェクト詳細',
					isKeepPage: true,
				},
				props: (route) => {
          const id = Number.parseInt(route.params.id);
          if (Number.isNaN(id)) {
            return 0
          }
          return { id }
				}
			},
			{ 
				path: '/clients', 
				component: Clients,
				meta: {
					title: '顧客管理',
					isKeepPage: true,
				}
			},
			{ 
				path: '/client/:id', 
				component: ClientDetail,
				meta: {
					title: '顧客詳細',
					isKeepPage: true,
				},
				props: (route) => {
          const id = Number.parseInt(route.params.id);
          if (Number.isNaN(id)) {
            return 0
          }
          return { id }
				}
			},
			{ 
				path: '/users', 
				component: Users,
				meta: {
					title: 'アカウント管理',
					isKeepPage: true,
				}
			},

			
		],
	});
};

export { createRouter };