<template>
  <VglLineBasicMaterial
    ref="material"
    v-bind="$attrs"
  >
  </VglLineBasicMaterial>
</template>

<script>
import { 
  VglLineBasicMaterial,
} from 'vue-gl';

export default {
  components: {
    VglLineBasicMaterial,
  },
  props: {
    opacity: {
      default: 1,
    },
  },
  watch: {
    opacity: function(newVal){
      this.$refs.material.inst.opacity = newVal;
    },
  },
  mounted: function(){
    this.$refs.material.inst.opacity = this.opacity;
    this.$refs.material.inst.transparent = true;
  }
}
</script>