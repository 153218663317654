<template>
  <v-container fluid>
    <v-row justify="center" dense>
      <v-btn absolute left icon large color="primary" class="mr-3" @click="loadUsers">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
      <v-dialog
        ref="dialog"
        v-model="isPasswordModalOpened"
        width="600px"
      >
        <v-card>
          <v-card-title class="info font-weight-bold justify-start">
            パスワード変更
          </v-card-title>
          <v-container dense>
            <v-form 
              ref="passwordForm" 
              @submit.prevent
              autocomplete="off" 
              lazy-validation
            >
              <v-row dense align="center">
                <v-col dense>
                  以下のアカウントのパスワードを変更します
                </v-col>
              </v-row>
              <v-row dense align="center">
                <v-col dense class="text-h5 pl-5">
                  {{ target.mailAddress }}
                </v-col>
              </v-row>
              <v-row dense align="center">
                <v-col dense class="text-h5 pl-5">
                  {{ target.name }}
                </v-col>
              </v-row>
              <v-row dense align="center">
                <v-col dense>
                  <v-text-field 
                    dense
                    type="password"
                    class="mt-5"
                    autocomplete="off"
                    v-model="newPassword" 
                    label="新しいパスワード"
                    :rules="[required]"
                  />
                </v-col>
              </v-row>
              <v-row dense align="center">
                <v-col dense>
                  <v-text-field 
                    dense
                    type="password"
                    class="mt-5" 
                    v-model="newPasswordConfirmation" 
                    label="パスワード確認用"
                    :rules="[newPasswordConfirmationRule]"
                  />
                </v-col>
              </v-row>
              <v-row dense align="center">
                <v-col dense align="center">
                  <v-btn
                    color="primary"
                    @click="updatePassword"
                    :disabled="!isNewPasswordValid"
                  >
                    変更
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog
        ref="dialog"
        v-model="isAccountEditModalOpened"
        width="600px"
      >
        <v-card>
          <v-card-title class="info font-weight-bold justify-start">
            アカウント情報更新
          </v-card-title>
          <v-container dense>
            <v-form 
              ref="accountEditForm" 
              autocomplete="off" 
              lazy-validation
              @submit.prevent
            >
              <v-row dense align="center">
                <v-col dense>
                  <v-text-field
                    dense 
                    class="mt-5" 
                    autocomplete="off"
                    v-model="target.mailAddress" 
                    label="メールアドレス"
                    :rules="[required, emailValidation]"
                  />
                </v-col>
              </v-row>
              <v-row dense align="center">
                <v-col dense>
                  <v-text-field 
                    dense 
                    class="mt-5" 
                    autocomplete="off"
                    v-model="target.name" 
                    label="名前"
                    :rules="[required]"
                  />
                </v-col>
              </v-row>
              <v-row dense align="center">
                <v-col dense>
                  <v-select
                    dense 
                    class="mt-5" 
                    autocomplete="off"
                    v-model="target.roleId" 
                    :items="roles"
                    label="権限"
                    item-text="name"
                    item-value="id"
                    :rules="[required]"
                  />
                </v-col>
              </v-row>
              <v-row dense align="center">
                <v-col dense align="center">
                  <v-btn 
                    color="primary"
                    :disabled="!isAccountEditValid"
                    @click="updateAccount"
                  >
                    更新
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog
        ref="dialog"
        v-model="isNewAccountModalOpened"
        width="600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            outlined
            v-bind="attrs"
            v-on="on"
          >
            <v-icon class="mr-2">mdi-account</v-icon>
            アカウント追加
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="info font-weight-bold justify-start">
            アカウント追加
          </v-card-title>
          <v-container dense>
            <v-form 
              ref="newAccountForm" 
              autocomplete="off" 
              lazy-validation
              @submit.prevent
            >
              <v-row dense align="center">
                <v-col dense>
                  <v-text-field
                    dense 
                    class="mt-5" 
                    autocomplete="new-mail-address"
                    v-model="newAccountData.mailAddress" 
                    label="メールアドレス"
                    :rules="[required, emailValidation]"
                  />
                </v-col>
              </v-row>
              <v-row dense align="center">
                <v-col dense>
                  <v-text-field 
                    dense 
                    class="mt-5" 
                    autocomplete="new-name"
                    v-model="newAccountData.name" 
                    label="名前"
                    :rules="[required]"
                  />
                </v-col>
              </v-row>
              <v-row dense align="center">
                <v-col dense>
                  <v-text-field 
                    dense
                    type="password"
                    class="mt-5"
                    autocomplete="new-password"
                    v-model="newAccountData.password" 
                    label="パスワード"
                    :rules="[required]"
                  />
                </v-col>
              </v-row>
              <v-row dense align="center">
                <v-col dense>
                  <v-text-field 
                    dense
                    type="password"
                    class="mt-5" 
                    v-model="newAccountData.passwordConfirmation" 
                    label="パスワード確認用"
                    :rules="[passwordConfirmationRule]"
                  />
                </v-col>
              </v-row>
              <v-row dense align="center">
                <v-col dense>
                  <v-select
                    dense 
                    class="mt-5" 
                    autocomplete="new-role-id"
                    v-model="newAccountData.roleId" 
                    :items="roles"
                    label="権限"
                    item-text="name"
                    item-value="id"
                    :rules="[required]"
                  />
                </v-col>
              </v-row>
              <v-row dense align="center">
                <v-col dense align="center">
                  <v-btn 
                    color="primary"
                    :disabled="!isNewAccountValid"
                    @click="createNewAccount"
                  >
                    作成
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>

    <UsersTable
      class="mt-5"
      height="calc(100vh - 196px)"
      :users="users"
      :roles="roles"
    >
      <template #itemPrepend="{ props }">
        <v-btn @click="openPasswordModal(props.item)" icon small color="primary" class="mr-1" :disabled="props.item.id == 1">
          <v-icon>mdi-key</v-icon>
        </v-btn>
        <v-btn @click="openAccountEditModal(props.item)" icon small color="primary" class="mr-3" :disabled="props.item.id == 1">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
    </UsersTable>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

import UsersTable from "@components/parts/tables/UsersTable.vue";

export default {
  components: {
    UsersTable,
  },
  data: function(){
    return {
      loadingCount: 0,
      isNewAccountModalOpened: false,
      isPasswordModalOpened: false,
      isAccountEditModalOpened: false,
      roles: [],
      users: [],
      required: value => !!value || "※必須",
      emailValidation: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || '※メールアドレスが正しくありません',
      passwordConfirmationRule: v => !this.newAccountData.password || v === this.newAccountData.password || '※パスワードが一致しません',
      newPasswordConfirmationRule: v => !this.newPassword || v === this.newPassword || '※パスワードが一致しません',
      newPassword: null,
      newPasswordConfirmation: null,
      newAccountData: {},
      isNewAccountValid: false,
      isNewPasswordValid: false,
      isAccountEditValid: false,
      target: {},
    }
  },
  computed: {
    ...mapState([
      'user',
    ]),
    isLoading: function(){
      return this.loadingCount > 0;
    },
  },
  watch: {
    newAccountData: {
      handler: function(){
        this.$nextTick(() => {
          this.isNewAccountValid = this.$refs.newAccountForm?.validate();
        });
      },
      deep: true,
    },
    target: {
      handler: function(){
        this.isAccountEditValid = this.$refs.accountEditForm?.validate();
      },
      deep: true,
    },
    isAccountEditModalOpened: function(newVal){
      if(newVal){
        this.$nextTick(() => {
          this.isAccountEditValid = this.$refs.accountEditForm?.validate();
        });
      }
    },
    isNewAccountModalOpened: function(newVal){
      if(newVal){
        this.newAccountData = {};
        this.$nextTick(() => {
          this.isNewAccountValid = this.$refs.newAccountForm.validate();
        });
      }
    },
    newPassword: function(){
      this.isNewPasswordValid = this.$refs.passwordForm.validate();
    },
    newPasswordConfirmation: function(){
      this.isNewPasswordValid = this.$refs.passwordForm.validate();
    },
    isPasswordModalOpened: function(newVal){
      if(newVal){
        this.$nextTick(() => {
          this.isNewPasswordValid = this.$refs.passwordForm.validate();
        })
      }
    },
  },
  destroyed: function(){
  },
  mounted: function(){
    if(!this.user?.role?.isAdmin){
      this.$router.push('/');
      return;
    }

    this.loadUsers();
    this.loadRoles();
  },
  methods: {
    loadUsers: function(){
      this.loadingCount++;
      this.axios.get(`/api/users`).then(response => {
        this.users = response.data.users;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.loadingCount--;
      })
    },
    loadRoles: function(){
      this.loadingCount++;
      this.axios.get(`/api/roles`).then(response => {
        this.roles = response.data.roles;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.loadingCount--;
      })
    },
    createNewAccount: function(){
      this.axios.post(`/api/user`, this.newAccountData).then(response => {
        if(response.data.result === 'exists'){
          alert('そのメールアドレスは既に使用されています');
          return;
        }
        this.isNewAccountModalOpened = false;
        this.loadUsers();
      }).catch(error => {
        console.log(error);
        alert('アカウント作成に失敗しました');
      });
    },
    openPasswordModal: function(user){
      this.target = user;
      this.newPassword = null;
      this.newPasswordConfirmation = null;
      this.isPasswordModalOpened = true;
    },
    updatePassword: function(){
      this.axios.put(`/api/password`, {
        ...this.target,
        newPassword: this.newPassword
      }).then(response => {
        if(response.data.result === 'not_found'){
          alert('該当のアカウントが存在しません');
          return;
        }
      }).catch(error => {
        console.log(error);
        alert('パスワード変更に失敗しました');
      }).finally(() => {
        this.isPasswordModalOpened = false;
      });
    },
    openAccountEditModal: function(user){
      this.target = {...user};
      this.isAccountEditModalOpened = true;
    },
    updateAccount: function(){
      this.axios.put(`/api/user`, this.target).then(response => {
        if(response.data.result === 'not_found'){
          alert('該当のアカウントが存在しません');
          return;
        }
        this.loadUsers();
      }).catch(error => {
        console.log(error);
        alert('アカウント更新に失敗しました');
      }).finally(() => {
        this.isAccountEditModalOpened = false;
      });
    },
  }
}
</script>

<style lang="scss" scoped>

</style>