<template>
  <Dialog
    width="640px"
    block
    small
    outlined
    color="secondary"
    @open="initNewSensor"
    @apply="createNewClientSensor"
  >
    <v-icon>mdi-plus</v-icon>
    <template #title>
      <slot name="title">
        {{ position.name }}センサ追加
      </slot>
    </template>
    <template #contents>
      <v-container 
        class="pt-5"
        dense
      >
        <v-form 
          @submit.prevent
          ref="newSensorForm" 
          autocomplete="off" 
          lazy-validation
        >
          <v-row dense align="center" class="mt-3">
            <v-col>
              <v-text-field
                dense
                outlined
                v-model="newSensorData.macAddress"
                label="MACアドレス"
                :rules="[required]"
                @input="correctMacAddress"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "@components/parts/dialogs/Dialog.vue";

export default {
  props: {
    client: {
      required: true,
    },
    position: {
      required: true,
    }
  },
  components: {
    Dialog,
  },
  data: function(){
    return {
      required: value => !!value || "※必須",
      newSensorData: {
        macAddress: null,
      },
      isNewSensorValid: false,
    }
  },
  computed: {
    macAddress: {
      get(){
        if(!this.newSensorData.macAddress){
          return null;
        }

        let macAddress = this.newSensorData.macAddress.toUpperCase();

        let splitted = [];
        for(let i=0, iMax=macAddress.length; i < iMax; i+=2) {
          splitted.push(macAddress.slice(i, i + 2));
        }

        return splitted.join(':');
      },
      set(newVal){
        if(!newVal){
          this.newSensorData.macAddress = null;
          return;
        }

        this.newSensorData.macAddress = newVal.toUpperCase().replace(/[^A-F]/gi, '');
        this.$forceUpdate();
      }
    },
  },
  watch: {
    newSensorData: {
      handler: function(){
        this.validateNewSensor();
      },
      deep: true,
    },
  },
  methods: {
    initNewSensor: function(){
      this.newSensorData = {
        macAddress: null,
      };
      this.validateNewSensor();
    },
    validateNewSensor: function(){
      this.$nextTick(() => {
        this.isNewSensorValid = this.$refs.newSensorForm.validate();
      });
    },
    correctMacAddress: function(){
      if(!this.newSensorData.macAddress){
        return;
      }

      let macAddress = this.newSensorData.macAddress.toUpperCase().replace(/[^A-F]/gi, '');
      let splitted = [];
      for(let i=0, iMax=macAddress.length; i < iMax; i+=2) {
        splitted.push(macAddress.slice(i, i + 2));
      }

      this.newSensorData.macAddress = splitted.join(':');
    },
    createNewClientSensor: function(){
      console.log(this.position);
      this.axios.post(`/api/client_sensor`, {
        ...this.newSensorData,
        clientId: this.client.id,
        position: this.position.key,
      }).then(response => {
        this.$emit('create-sensor', response.data.clientSensor);
      }).catch(error => {
        console.log(error);
        alert('センサ追加に失敗しました');
      });
    },
  }
}
</script>