<template>
  <v-row class="d-flex align-end" style="margin-top: -1mm;">
    <div class="text-h5 ml-2">報告書</div>
    <div class="text-subtitle-2 ml-2">作成</div>
    <div class="text-subtitle-2 ml-2">{{ today }}</div>
    <v-spacer />
    <div class="text-subtitle-2 mr-2 grey--text">WM GAIT CHECKER Web</div>

    <v-img 
      max-width="48" 
      class="mr-3" 
      contain 
      src="@/assets/images/logo_lg.png"
    />
  </v-row>
</template>

<script>
import moment from "moment";

export default {
  data: function(){
    return {
      today: moment().format("YYYY年MM月DD日"),
    }
  },
}
</script>