<template>
  <v-container fluid>
    <v-row justify="center" dense>
      <v-btn absolute left icon large color="primary" class="mr-3" @click="fetchAll">
        <v-icon>mdi-reload</v-icon>
      </v-btn>

      <Dialog
        width="600px"
        :disabled="!isUploadable"
        @apply="uploadPosture"
        outlined
      >
        <v-icon class="mr-2">mdi-upload</v-icon>
        アップロード

        <template #title>
          姿勢撮影データ選択
        </template>

        <template #contents>
          <v-container 
            dense
            fluid 
            @dragleave.prevent 
            @dragover.prevent 
            @drop.prevent="selectFiles"
          >
            <v-row>
              <v-col>
                <v-select
                  label="計測参加者"
                  :items="participants"
                  item-text="name"
                  item-value="id"
                  v-model="participantId"
                />
              </v-col>
            </v-row>
            <v-row 
              dense
              align="center"
            >
              <v-col>
                <v-file-input
                  accept="image/*,video/mp4"
                  dense
                  hide-details
                  :label="`${maxFileSize}MB以下`"
                  outlined
                  v-model="selectedFile"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  dense
                  v-model="postureName"
                  label="登録名"
                />
              </v-col>
            </v-row>
          </v-container>

          <v-alert 
            v-if="isFileSizeExceeded"
            type="error"
            dense
          >
            ファイルサイズが上限を超えています
          </v-alert>
        </template>

        <template #apply>アップロード</template>
      </Dialog>
    </v-row>
    <v-row dense>
      <v-col>
        <PosturesTable
          :loading="isLoading"
          :postures="postures"
          height="calc(100vh - 196px)"
          :items-per-page.sync="itemsPerPage"
          :page.sync="currentPage"
          :filter-params="filterParams"
          class="mt-3"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

import PosturesTable from "@components/parts/tables/PosturesTable.vue";
import Dialog from "@components/parts/dialogs/Dialog.vue";

export default {
  components: {
    PosturesTable,
    Dialog,
  },
  data: function(){
    return {
      itemsPerPage: 15,
      currentPage: 1,
      isLoading: false,
      isUploading: false,
      postureName: null,
      selectedFile: null,
      participantId: null,

      filterParams: {
        isDeleted: false,
      },
      postures: [],

      maxFileSize: 100, //MB
    }
  },
  computed: {
    ...mapState([
      'user',
      'project',
      'participants',
    ]),
    isVideo: function(){
      if(!this.selectedFile){
        return false;
      }
      return this.selectedFile.type === "video/mp4";
    },
    isFileSizeExceeded: function(){
      if(!this.selectedFile){
        return false;
      }
      if(this.selectedFile.size > this.maxFileSize*1000*1000) {
        return true;
      }
      return false;
    },
    isUploadable: function(){
      if(!this.postureName || !this.selectedFile || !this.participantId){
        return false;
      }

      if(this.isFileSizeExceeded){
        return false;
      }

      return true;
    },
  },
  watch: {
    project: {
      handler: function(newVal){
        if(newVal){
          this.fetchAll();
        }
      },
    },
    selectedFile: function(newVal){
      if(!newVal){
        return;
      }

      if(!this.postureName){
        this.postureName = newVal.name.split('.').slice(0, -1).join('.');
      }
    },
  },
  destroyed: function(){
  },
  mounted: function(){
    if(!this.project){
      this.$router.push('/');
      return;
    }

    this.fetchAll();
  },
  methods: {
    ...mapActions([
      'fetchParticipants',
    ]),
    fetchAll: function(){
      this.fetchPostures();
      this.fetchParticipants();
    },
    fetchPostures: function(){
      this.isLoading = true;
      this.axios.get(`/api/postures/${this.project.id}`).then(response => {
        this.postures = response.data.postures;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.isLoading = false;
      })
    },
    uploadPosture: function(){
      if(!this.selectedFile || !this.participantId){
        return;
      }
      
      if(this.isFileSizeExceeded) {
        this.selectedFile = null;
        alert(`${this.maxFileSize}MB以下のファイルのみ対応しています`)
        return;
      }

      const params = new FormData();
      params.append('file', this.selectedFile);
      params.append('posture_data_form', 
        JSON.stringify({
          name: this.postureName,
          projectId: this.project.id,
          participantId: this.participantId
        })
      );
      this.isUploading = true;

      this.axios.post(
        `/api/posture/${this.isVideo ? 'video' : 'image'}`,
        params,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
      ).then(() => {
        this.fetchAll();
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.isUploading = false;
      });
    },
    selectFiles: function(e){
      var files = Array.from(e.dataTransfer.files);
      files = files.filter(f => f.size <= this.maxFileSize*1000*1000 && (f.type.startsWith('image/') || f.type === 'video/mp4'));
      if(files.length == 0){
        return;
      }
      this.selectedFile = files[0];
      this.postureName = this.selectedFile.name.split('.').slice(0, -1).join('.');
    }
  }
}
</script>

<style lang="scss" scoped>

</style>