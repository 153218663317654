<template>
  <div 
    ref="container"
    class="chart-container"
    :style="{
      height: chartHeight + 'px',
    }"
    v-observe-visibility="isVisible => (isVisible ? resizeContainer() : false)"
  >
    <LineChart 
      ref="chart"
      :style="{
        height: chartHeight + 'px',
      }"
      hide-legends
    />
  </div>
</template>

<script>
import LineChart from '@components/parts/charts/LineChart.vue'

export default {
	components: {
		LineChart,
	},
	props: {
    target: {
      required: true,
    },
		leftTrajectories: {
      required: true,
    },
    rightTrajectories: {
      required: true,
    },
    selection: {
      required: true,
    },
		aspectRatio: {
      default: 1.92
    },
	},
	data: function() {
		return {
      chartWidth: 0,
		};
	},
	mounted() {
    this.resizeContainer();
    this.$refs.chart.dataCollection.datasets = this.createLineChartData();
	},
	watch: {
    selection: {
			handler(newVal){
				this.updateChartRange(newVal);
			},
			deep: true,
			immediate: true,
		},
  },
  computed: {
    chartHeight: function(){
      return parseInt(this.chartWidth/this.aspectRatio);
    },
    validLeftTrajectoires: function(){
      return this.leftTrajectories.filter(t => !t.isInvalid);
    },
    validRightTrajectoires: function(){
      return this.rightTrajectories.filter(t => !t.isInvalid);
    },
	},
	methods: {
    rerender: function(){
      this.$refs.chart.dataCollection.datasets = this.createLineChartData();
      this.updateChartRange(this.selection);
    },
    resizeContainer: function(){
      this.chartWidth = this.$refs.container?.clientWidth ?? 0;
    },
		updateChartRange: function(duration){
      let _duration = {
        from: duration?.from != null ? duration.from*10 : null,
        to: duration?.to != null ? duration.to*10 : null,
      }
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs.chart?.setRangeX(_duration);
        });
      })
    },
		createLineChartData: function(){
      let rtn = [];

      const defaultData = {
        fill: false,
        backgroundColor: 'hsla(0, 77%, 28%, 0.2)',
        borderColor: 'hsla(0, 77%, 28%, 0.2)',
        borderWidth: 2,
        pointRadius: 2,
        label: '',
      };

      let color = this.getLineChartColor(0);
      rtn.push({
        ...defaultData,
        label: `左 ${this.target.name}`,
        backgroundColor: color,
        borderColor: color,
        data: this.validLeftTrajectoires.map(t => ({
          x: t.positions[0].index*10,
          y: t.features[this.target.attr]
        })).filter(p => p.y != null).map(p => ({
          ...p,
          y: p.y*(this.target.scale ?? 1)
        })),
      });

      color = this.getLineChartColor(230);
      rtn.push({
        ...defaultData,
        label: `右 ${this.target.name}`,
        backgroundColor: color,
        borderColor: color,
        data: this.validRightTrajectoires.map(t => ({
          x: t.positions[0].index*10,
          y: t.features[this.target.attr]
        })).filter(p => p.y != null).map(p => ({
          ...p,
          y: p.y*(this.target.scale ?? 1)
        })),
      });

      return rtn;
    },
		getLineChartColor: function(hue){
      return `hsla(${hue}, 77%, 28%, 0.4)`;
    },
	}
}
</script>

<style lang="scss" scoped>
.chart-container{
  ::v-deep{
    canvas {
      height: 100% !important;
    }
  }
}
</style>