export function calcQuaternionMultiplication(p, q) {
  return [
    p[0]*q[0] - p[1]*q[1] - p[2]*q[2] - p[3]*q[3],
    p[0]*q[1] + p[1]*q[0] + p[2]*q[3] - p[3]*q[2],
    p[0]*q[2] - p[1]*q[3] + p[2]*q[0] + p[3]*q[1],
    p[0]*q[3] + p[1]*q[2] - p[2]*q[1] + p[3]*q[0]
  ];
}

export function calcQuaternionFromEulerAngles(roll, pitch, yaw) {
  const cosRoll = Math.cos(roll/2);
  const cosPitch = Math.cos(pitch/2);
  const cosYaw = Math.cos(yaw/2);

  const sinRoll = Math.sin(roll/2);
  const sinPitch = Math.sin(pitch/2);
  const sinYaw = Math.sin(yaw/2);

  return [
    cosRoll*cosPitch*cosYaw - sinRoll*sinPitch*sinYaw,
    sinRoll*cosPitch*cosYaw + cosRoll*sinPitch*sinYaw,
    cosRoll*sinPitch*cosYaw - sinRoll*cosPitch*sinYaw,
    cosRoll*cosPitch*sinYaw + sinRoll*sinPitch*cosYaw,
  ];
}


export function getRotationVectorFromQuaternion(q) {
  const x2 = q[1]*q[1]*2.0;
  const y2 = q[2]*q[2]*2.0;
  const z2 = q[3]*q[3]*2.0;
  const xy = q[1]*q[2]*2.0;
  const yz = q[2]*q[3]*2.0;
  const zx = q[3]*q[1]*2.0;
  const xw = q[1]*q[0]*2.0;
  const yw = q[2]*q[0]*2.0;
  const zw = q[3]*q[0]*2.0;

  return [
    1.0 - y2 - z2,
    xy + zw,
    zx - yw,
    0,
    xy - zw,
    1.0 - z2 - x2,
    yz + xw,
    0,
    zx + yw,
    yz - xw,
    1.0 - x2 - y2,
    0,
    0,
    0,
    0,
    1.0,
  ];
}