<template>
  <v-dialog
    ref="dialog"
    v-if="comment.filename"
    v-model="isDialogOpened"
    :width="width"
  >
    <template
      v-if="!isOpenerHidden"
      v-slot:activator="{ on, attrs }"
    >
      <v-btn 
        class="mb-3"
        v-if="isPdf"
        text
        :href="comment.filepath"
        target="_blank"
        color="primary"
      >
        <v-icon>mdi-file-pdf-box</v-icon>
        {{ comment.filename }}
      </v-btn>
      <v-img
        v-else
        class="mb-3"
        style="cursor: pointer;"
        :src="comment.filepath"
        max-height="160"
        max-width="160"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-card>
      <v-card-title 
        class="secondary font-weight-bold justify-start white--text"
      >
        {{ comment.filename }}
      </v-card-title>
      <v-card-text>
        <v-img
          :src="comment.filepath"
        />
      </v-card-text>
      <slot name="actions">
        <v-card-actions class="pb-5">
          <slot name="buttons">
            <v-spacer />
            <v-btn
              color="secondary lighten-5"
              class="mx-3"
              dark
              depressed
              @click="isDialogOpened = false"
            >
              <slot name="cancel">閉じる</slot>
            </v-btn>
            <v-btn  
              v-if="false"
              color="secondary"
              depressed
              class="mx-3"
              :disabled="isDisabled"
              @click="$emit('apply'); isDialogOpened = false;"
            >
              <slot name="apply">ダウンロード</slot>
            </v-btn>
            <v-spacer />
          </slot>
        </v-card-actions>
      </slot>
    </v-card>
  </v-dialog>
</template>


<script>
export default {
  components: {
  },
  props: {
    comment: {
      required: true,
    },
    width: {
      default: null,
    },
    disabled: {
      default: null,
    },
    dialogOnly: {
      default: null,
    },
    activatorClass: {
      default: null,
    },
    outlined: {
      default: null,
    },
    color: {
      default: null,
    },
    block: {
      default: null,
    },
    small: {
      default: null,
    },
  },
  data: function(){
    return {
      isDialogOpened: false,
    }
  },
  computed: {
    isPdf: function(){
      return this.comment.filename.toLowerCase().endsWith('pdf');
    },
    isOpenerHidden: function(){
      return this.dialogOnly != null;
    },
    isDisabled: function(){
      return this.disabled
    },
  },
  watch: {
    isDialogOpened: function(newVal){
      if(newVal){
        this.$emit('open');
      }else{
        this.$emit('close');
      }
    },
  },
  methods: {
    close: function(){
      this.isDialogOpened = false;
    },
    open: function(){
      this.isDialogOpened = true;
    }
  },
}
</script>