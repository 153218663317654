<template>
  <v-container fluid class="fill-height pt-0 pr-0">
    <template v-if="sway">
      <SwayTrajectoryGeometriesDefs
        v-if="swayTrajectory"
        :trajectory="swayTrajectory"
        :selection="selectedSwayDuration"
      />

      <SwayTrajectoryGeometriesDefs
        v-if="comparableSwayTrajectory"
        id="Comparable"
        :trajectory="comparableSwayTrajectory"
        :selection="selectedComparableSwayDuration"
      />

      <v-row class="px-3 mt-1" style="height: 100%; overflow: hidden;">
        <v-col
          v-if="isSideMenuOpened"
          cols="12"
          md="4"
          lg="3"
          class="pa-0 pr-3 d-flex flex-column"
        >
          <div class="d-flex align-center">
            <v-btn 
              icon
              large
              color="primary" 
              @click="fetchAll"
              class="mb-1"
            >
              <v-icon>mdi-reload</v-icon>
            </v-btn>
            <v-spacer />
            <v-btn
              v-if="false"
              depressed
              large
              color="primary" 
              @click="isSideMenuOpened = false"
            >
              <v-icon>mdi-cog</v-icon>
              <v-icon>mdi-arrow-collapse-left</v-icon>
            </v-btn>
          </div>
          <simplebar style="flex: 1; height: 0; overflow-x: hidden;">
            <v-card 
              class="pa-0 mt-2 elevation-0"
              outlined
            >
              <v-card-title 
                class="primary darken-3 white--text py-1"
              >
                ID: {{ sway.id }}
                <v-spacer/>
                <Dialog 
                  color="accent"
                  ref="swayReportSelectorDiablog"
                  small
                >
                  <v-icon>mdi-swap-horizontal</v-icon>
                  <template #title>重心動揺レポート切替</template>
                  <template #contents>
                    <SwayListTable
                      class="mt-3"
                      height="calc(100vh - 320px)"
                      :sway-list="swayList"
                      elevation="0"
                      selectable
                      :filter-params="{
                        isDeleted: false,
                      }"
                      :invisible-headers-values="[
                        'isDeleted',
                      ]"
                      @select="
                        $refs.swayReportSelectorDiablog.close(); 
                        selectSwayReport($event);
                      "
                    />
                  </template>
                  <template #actions>
                    <span></span>
                  </template>
                </Dialog>
                
              </v-card-title>
              <v-card-text>
                <v-container class="px-0 pb-0">
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        label="レポート名"
                        v-model="sway.name"
                        hide-details
                        @change="updateReportAttribute('name')"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-textarea
                        label="説明"
                        class="mt-3"
                        v-model="sway.description"
                        @change="updateReportAttribute('description')"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-select
                        label="有効/無効"
                        v-model="sway.isDeleted"
                        dense
                        hide-details
                        @change="updateReportAttribute('isDeleted')"
                        :items="[
                          {label: '有効', value: false},
                          {label: '無効', value: true}
                        ]"
                        item-text="label"
                        item-value="value"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        label="作成日時"
                        v-model="sway.createdTime"
                        class="mt-5"
                        dense
                        hide-details
                        readonly
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-btn
                    color="primary darken-2 my-2 py-1"
                    block
                    v-if="!isReportCommentsOpened"
                    @click="isReportCommentsOpened=true"
                  >
                    <v-icon>mdi-comment-text-multiple</v-icon>
                    <v-icon>mdi-unfold-more-horizontal</v-icon>
                  </v-btn>
                  <CommentsCard
                    class="pa-0 my-2 elevation-0"
                    :id="id"
                    target="sway"
                    outlined
                    v-if="isReportCommentsOpened"
                  >
                    <template #titleContainer>
                      <v-card-title 
                        class="primary darken-2 text-subtitle-1 white--text py-1 mb-3"
                        style="cursor: pointer;"
                        @click="isReportCommentsOpened=false"
                      >
                        <v-spacer></v-spacer>
                        <v-icon color="white">mdi-comment-text-multiple</v-icon>
                        <v-icon color="white">mdi-unfold-less-horizontal</v-icon>
                        <v-spacer></v-spacer>
                      </v-card-title>
                    </template>
                  </CommentsCard>
                  <v-row dense>
                    <v-col>
                      <v-btn
                        v-if="comparableId"
                        color="accent"
                        block
                        :to="`/sway/${id}`"
                      >
                        単一レポート
                      </v-btn>
                      <Dialog
                        v-else
                        color="accent"
                        block
                      >
                        レポート比較
                        <template #title>重心動揺比較レポート選択</template>
                        <template #contents>
                          <SwayListTable
                            class="mt-3"
                            height="calc(100vh - 320px)"
                            :sway-list="swayList"
                            elevation="0"
                            selectable
                            :filter-params="{
                              isDeleted: false,
                            }"
                            :invisible-headers-values="[
                              'isDeleted',
                            ]"
                            @select="selectComparableSwayReport($event)"
                          />
                        </template>
                        <template #actions>
                          <span></span>
                        </template>
                      </Dialog>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>

            <v-card 
              class="pa-0 mt-2 elevation-0"
              outlined
              v-if="comparableId && comparableSway"
            >
              <v-card-title 
                class="tertiary darken-3 white--text py-1"
              >
                ID: {{ comparableSway.id }}
                <v-spacer/>
                <Dialog 
                  color="accent"
                  ref="comparableSwayReportSelectorDiablog"
                  small
                >
                  <v-icon>mdi-swap-horizontal</v-icon>
                  <template #title>重心動揺レポート切替</template>
                  <template #contents>
                    <SwayListTable
                      class="mt-3"
                      height="calc(100vh - 320px)"
                      :sway-list="swayList"
                      elevation="0"
                      selectable
                      :filter-params="{
                        isDeleted: false,
                      }"
                      :invisible-headers-values="[
                        'isDeleted',
                      ]"
                      @select="
                        $refs.comparableSwayReportSelectorDiablog.close(); 
                        selectComparableSwayReport($event);
                      "
                    />
                  </template>
                  <template #actions>
                    <span></span>
                  </template>
                </Dialog>
                
              </v-card-title>
              <v-card-text>
                <v-container class="px-0 pb-0">
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        label="レポート名"
                        v-model="comparableSway.name"
                        hide-details
                        @change="updateComparableReportAttribute('name')"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-textarea
                        label="説明"
                        class="mt-3"
                        v-model="comparableSway.description"
                        @change="updateComparableReportAttribute('description')"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-select
                        label="有効/無効"
                        v-model="comparableSway.isDeleted"
                        dense
                        hide-details
                        @change="updateComparableReportAttribute('isDeleted')"
                        :items="[
                          {label: '有効', value: false},
                          {label: '無効', value: true}
                        ]"
                        item-text="label"
                        item-value="value"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        label="作成日時"
                        v-model="comparableSway.createdTime"
                        class="mt-5"
                        dense
                        hide-details
                        readonly
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-btn
                    color="tertiary darken-2 my-2 py-1"
                    block
                    v-if="!isComparableReportCommentsOpened"
                    @click="isComparableReportCommentsOpened=true"
                  >
                    <v-icon color="white">mdi-comment-text-multiple</v-icon>
                    <v-icon color="white">mdi-unfold-more-horizontal</v-icon>
                  </v-btn>
                  <CommentsCard
                    class="pa-0 my-2 elevation-0"
                    :id="comparableId"
                    target="sway"
                    outlined
                    v-if="isComparableReportCommentsOpened"
                  >
                    <template #titleContainer>
                      <v-card-title 
                        class="tertiary darken-2 text-subtitle-1 white--text py-1 mb-3"
                        style="cursor: pointer;"
                        @click="isComparableReportCommentsOpened=false"
                      >
                        <v-spacer></v-spacer>
                        <v-icon color="white">mdi-comment-text-multiple</v-icon>
                        <v-icon color="white">mdi-unfold-less-horizontal</v-icon>
                        <v-spacer></v-spacer>
                      </v-card-title>
                    </template>
                  </CommentsCard>
                  <v-row dense>
                    <v-col>
                      <v-btn
                        color="accent"
                        block
                        :to="`/sway/${comparableId}`"
                      >
                        単一レポート
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>

            <v-card>
              <v-card-text>
                <v-tabs 
                  v-model="currentSettingTabIndex" 
                >
                  <v-tab
                    v-for="tab in tabs"
                    :key="tab.key"
                  >{{ tab.label }}</v-tab>
                </v-tabs>

                <v-container class="px-0" v-if="currentSettingTab.key === 'karte'">
                  <v-row dense>
                    <v-col class="text-h6 d-flex align-center">
                      分析方向
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col class="text-h6 d-flex align-center">
                      <v-select
                        dense
                        hide-details
                        v-model="karteViewPoint"
                        :items="karteModeOptions"
                        item-text="label"
                        item-value="key"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>

                <v-container class="px-0" v-if="currentSettingTab.key === 'sway'">
                  <v-row dense>
                    <v-col class="text-h6 d-flex align-center">
                      軌道グラフ線透明度
                    </v-col>
                  </v-row>
                  
                  <v-row dense>
                    <v-col>
                      <v-slider
                        v-model="swayLineOpacity"
                        dense
                        hide-details
                        :min="0"
                        :max="1"
                        :step="0.01"
                        class="align-center"
                      >
                        <template v-slot:append>
                          <div class="d-flex align-center">
                            x
                            <v-text-field
                              v-model="swayLineOpacity"
                              dense
                              hide-details
                              :min="0"
                              :max="1"
                              :step="0.1"
                              class="mt-0 ml-1 pt-0"
                              type="number"
                              style="width: 48px"
                            ></v-text-field>
                          </div>
                        </template>
                      </v-slider>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col class="text-h6 d-flex align-center">
                      軌道グラフ領域透明度
                    </v-col>
                  </v-row>
                  
                  <v-row dense>
                    <v-col>
                      <v-slider
                        v-model="swayPolygonOpacity"
                        dense
                        hide-details
                        :min="0"
                        :max="1"
                        :step="0.01"
                        class="align-center"
                      >
                        <template v-slot:append>
                          <div class="d-flex align-center">
                            x
                            <v-text-field
                              v-model="swayPolygonOpacity"
                              dense
                              hide-details
                              :min="0"
                              :max="1"
                              :step="0.1"
                              class="mt-0 ml-1 pt-0"
                              type="number"
                              style="width: 48px"
                            ></v-text-field>
                          </div>
                        </template>
                      </v-slider>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col class="text-h6 d-flex align-center">
                      表示設定
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.swayReport.trajectory.position.coordinate"
                        label="重心位置軌道グラフ"
                        hide-details
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.swayReport.trajectory.position.velocity"
                        label="重心速度軌道グラフ"
                        hide-details
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.swayReport.trajectory.position.acceleration"
                        label="重心加速度軌道グラフ"
                        hide-details
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.swayReport.spectrum.position.coordinate"
                        label="重心位置周波数スペクトル"
                        hide-details
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.swayReport.spectrum.position.velocity"
                        label="重心速度周波数スペクトル"
                        hide-details
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.swayReport.spectrum.position.acceleration"
                        label="重心加速度周波数スペクトル"
                        hide-details
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.swayReport.features.position.table"
                        label="重心位置特徴量表"
                        hide-details
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.swayReport.trajectory.angle.coordinate"
                        label="姿勢角度軌道グラフ"
                        hide-details
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.swayReport.trajectory.angle.velocity"
                        label="姿勢角速度軌道グラフ"
                        hide-details
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.swayReport.trajectory.angle.acceleration"
                        label="姿勢角加速度軌道グラフ"
                        hide-details
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.swayReport.spectrum.angle.coordinate"
                        label="姿勢角度周波数スペクトル"
                        hide-details
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.swayReport.spectrum.angle.velocity"
                        label="姿勢角速度周波数スペクトル"
                        hide-details
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.swayReport.spectrum.angle.acceleration"
                        label="姿勢角加速度周波数スペクトル"
                        hide-details
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.swayReport.features.angle.table"
                        label="姿勢角度特徴量表"
                        hide-details
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.swayReport.sensor"
                        label="センサデータ"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </simplebar>
        </v-col>
        <v-col 
          v-if="sway.isAnalyzed && !sway.isFailed"
          cols="12" 
          :md="isSideMenuOpened ? 8 : null" 
          :lg="isSideMenuOpened ? 9 : null"
          class="d-flex flex-column pb-0"
        >
          <v-row justify="start" align="start" style="flex: initial;" v-if="!isSideMenuOpened">
            <v-btn
              depressed
              large
              color="primary" 
              class="mr-3" 
              @click="isSideMenuOpened = true"
            >
              <v-icon>mdi-cog</v-icon>
              <v-icon>mdi-arrow-collapse-right</v-icon>
            </v-btn>

            <v-btn 
              icon 
              large 
              color="primary" 
              class="mr-3" 
              @click="fetchAll"
            >
              <v-icon>mdi-reload</v-icon>
            </v-btn>
            <v-col cols="2">
              <div>
                <v-text-field
                  class="text-h6"
                  v-model="sway.name"
                  dense
                  label="名前"
                  hide-details
                  @change="updateSway"
                />
              </div>
            </v-col>
            <v-col cols="6">
              <div>
                <v-text-field
                  class="text-h6"
                  v-model="sway.description"
                  dense
                  label="説明"
                  hide-details
                  @change="updateSway"
                />
              </div>
            </v-col>
            <v-col class="ml-auto mr-2" justify="center" align="end" cols="2">
              <v-text-field
                class="text-h6"
                v-model="sway.createdTime"
                dense
                label="作成日時"
                hide-details
                readonly
                outlined
              />
            </v-col>
          </v-row>

          <v-row class="d-flex align-center pr-3" style="flex: initial;">
            <v-tabs 
              v-model="currentTabIndex" 
              class="mb-5"
              background-color="primary darken-2"
              dark
            >
              <v-tab
                v-for="tab in tabs"
                :key="tab.key"
              >{{ tab.label }}</v-tab>

              <v-spacer />
              <v-btn 
                v-if="!comparableId"
                color="accent"
                small
                class="my-auto mr-3"
                @click="downloadReport"
                v-tooltip.left="'xlsxファイルダウンロード'"
                :disabled="isDownloading"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </v-tabs>
          </v-row>

          <simplebar ref="scrollbar" style="flex: 1; height: 0; overflow-x: hidden;">
            <v-container 
              fluid 
              class="pa-0 pr-3"
              v-if="currentTab.key === 'sway'"
            >
              <v-row class="mt-0">
                <SwayTrajectoryGraphCard
                  id="positionCoordinate"
                  v-bind="graphCols"
                  :visible="visibility.swayReport.trajectory.position.coordinate"
                  :title="`重心位置${ comparableId ? ` - ID: ${id}` : '' }`"
                  :color="comparableId ? 'primary darken-1' : 'secondary'"
                  use="positionCoordinate"
                  :selection="selectedSwayDuration"
                  :trajectory="swayTrajectory.position.coordinate"
                  :polygon-data="selectedTrajectorysPolygon.position.coordinate"
                  :line-scale="100"
                  :line-opacity="swayLineOpacity"
                  :polygon-opacity="swayPolygonOpacity"
                />

                <SwayTrajectoryGraphCard
                  id="positionVelocity"
                  v-bind="graphCols"
                  :visible="visibility.swayReport.trajectory.position.velocity"
                  :title="`重心速度${ comparableId ? ` - ID: ${id}` : '' }`"
                  :color="comparableId ? 'primary darken-1' : 'secondary'"
                  use="positionVelocity"
                  :selection="selectedSwayDuration"
                  :trajectory="swayTrajectory.position.velocity"
                  :polygon-data="selectedTrajectorysPolygon.position.velocity"
                  :line-scale="10"
                  :line-opacity="swayLineOpacity"
                  :polygon-opacity="swayPolygonOpacity"
                />

                <SwayTrajectoryGraphCard
                  id="positionAcceleration"
                  v-bind="graphCols"
                  :visible="visibility.swayReport.trajectory.position.acceleration"
                  :title="`重心加速度${ comparableId ? ` - ID: ${id}` : '' }`"
                  :color="comparableId ? 'primary darken-1' : 'secondary'"
                  use="positionAcceleration"
                  :selection="selectedSwayDuration"
                  :trajectory="swayTrajectory.position.acceleration"
                  :polygon-data="selectedTrajectorysPolygon.position.acceleration"
                  :line-opacity="swayLineOpacity"
                  :polygon-opacity="swayPolygonOpacity"
                />

                <template v-if="comparableId && comparableSwayTrajectory">
                  <SwayTrajectoryGraphCard
                    id="positionCoordinateComparable"
                    v-bind="graphCols"
                    :visible="visibility.swayReport.trajectory.position.coordinate"
                    :title="`重心位置 - ID: ${comparableId}`"
                    color="tertiary darken-1"
                    use="positionCoordinateComparable"
                    :selection="selectedComparableSwayDuration"
                    :trajectory="comparableSwayTrajectory.position.coordinate"
                    :polygon-data="selectedComparableTrajectorysPolygon.position.coordinate"
                    :line-scale="100"
                    :line-opacity="swayLineOpacity"
                    :polygon-opacity="swayPolygonOpacity"
                  />

                  <SwayTrajectoryGraphCard
                    id="positionVelocityComparable"
                    v-bind="graphCols"
                    :visible="visibility.swayReport.trajectory.position.velocity"
                    :title="`重心速度 - ID: ${comparableId}`"
                    color="tertiary darken-1"
                    use="positionVelocityComparable"
                    :selection="selectedComparableSwayDuration"
                    :trajectory="comparableSwayTrajectory.position.velocity"
                    :polygon-data="selectedComparableTrajectorysPolygon.position.velocity"
                    :line-scale="10"
                    :line-opacity="swayLineOpacity"
                    :polygon-opacity="swayPolygonOpacity"
                  />

                  <SwayTrajectoryGraphCard
                    id="positionAccelerationComparable"
                    v-bind="graphCols"
                    :visible="visibility.swayReport.trajectory.position.acceleration"
                    :title="`重心加速度 - ID: ${comparableId}`"
                    color="tertiary darken-1"
                    use="positionAccelerationComparable"
                    :selection="selectedComparableSwayDuration"
                    :trajectory="comparableSwayTrajectory.position.acceleration"
                    :polygon-data="selectedComparableTrajectorysPolygon.position.acceleration"
                    :line-opacity="swayLineOpacity"
                    :polygon-opacity="swayPolygonOpacity"
                  />
                </template>

                <template v-if="swaySpectrums">
                  <SwaySpectrumChartCard
                    v-bind="graphCols"
                    :visible="visibility.swayReport.spectrum.position.coordinate"
                    :title="`重心位置周波数スペクトル${ comparableId ? ` - ID: ${id}` : '' }`"
                    :color="comparableId ? 'primary darken-1' : 'secondary'"
                    :trajectory="swaySpectrums.position.coordinate"
                  />

                  <SwaySpectrumChartCard
                    v-bind="graphCols"
                    :visible="visibility.swayReport.spectrum.position.velocity"
                    :title="`重心速度周波数スペクトル${ comparableId ? ` - ID: ${id}` : '' }`"
                    :color="comparableId ? 'primary darken-1' : 'secondary'"
                    :trajectory="swaySpectrums.position.velocity"
                  />

                  <SwaySpectrumChartCard
                    v-bind="graphCols"
                    :visible="visibility.swayReport.spectrum.position.acceleration"
                    :title="`重心加速度周波数スペクトル${ comparableId ? ` - ID: ${id}` : '' }`"
                    :color="comparableId ? 'primary darken-1' : 'secondary'"
                    :trajectory="swaySpectrums.position.acceleration"
                  />
                </template>

                <template v-if="comparableId && comparableSwayTrajectory && comparableSwaySpectrums">
                  <SwaySpectrumChartCard
                    v-bind="graphCols"
                    :visible="visibility.swayReport.spectrum.position.coordinate"
                    :title="`重心位置周波数スペクトル - ID: ${comparableId}`"
                    color="tertiary darken-1"
                    :trajectory="comparableSwaySpectrums.position.coordinate"
                  />

                  <SwaySpectrumChartCard
                    v-bind="graphCols"
                    :visible="visibility.swayReport.spectrum.position.velocity"
                    :title="`重心速度周波数スペクトル - ID: ${comparableId}`"
                    color="tertiary darken-1"
                    :trajectory="comparableSwaySpectrums.position.velocity"
                  />

                  <SwaySpectrumChartCard
                    v-bind="graphCols"
                    :visible="visibility.swayReport.spectrum.position.acceleration"
                    :title="`重心加速度周波数スペクトル - ID: ${comparableId}`"
                    color="tertiary darken-1"
                    :trajectory="comparableSwaySpectrums.position.acceleration"
                  />
                </template>

                <v-col 
                  v-bind="tableCols" 
                  v-if="visibility.swayReport.features.position.table"
                >
                  <v-row>
                    <v-chip
                      class="ml-3"
                      :color="comparableId ? 'primary darken-1' : 'secondary'"
                      outlined
                    >
                      重心位置特徴量{{ comparableId ? ` - ID: ${id}` : null }}
                    </v-chip>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1">
                      <SwayFeaturesTable
                        v-if="swaySpectrums"
                        :trajectory="swayTrajectory.position"
                        :selection="selectedSwayDuration"
                        :spectrum="swaySpectrums.position"
                        :polygon="selectedTrajectorysPolygon.position"
                        :aspect-ratio="null"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col 
                  v-bind="tableCols" 
                  v-if="visibility.swayReport.features.position.table && comparableId && comparableSwayTrajectory"
                >
                  <v-row>
                    <v-chip
                      class="ml-3"
                      color="tertiary darken-1"
                      outlined
                    >
                      重心位置特徴量 - ID: {{ comparableId }}
                    </v-chip>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1">
                      <SwayFeaturesTable
                        v-if="comparableSwaySpectrums"
                        color="tertiary darken-1 white--text"
                        :trajectory="comparableSwayTrajectory.position"
                        :selection="selectedComparableSwayDuration"
                        :spectrum="comparableSwaySpectrums.position"
                        :polygon="selectedComparableTrajectorysPolygon.position"
                        :aspect-ratio="null"
                      />
                    </v-col>
                  </v-row>
                </v-col>


                <SwayTrajectoryGraphCard
                  id="angleCoordinate"
                  v-bind="graphCols"
                  :visible="visibility.swayReport.trajectory.angle.coordinate"
                  :title="`姿勢角度${ comparableId ? ` - ID: ${id}` : '' }`"
                  :color="comparableId ? 'primary darken-1' : 'secondary'"
                  use="angleCoordinate"
                  :selection="selectedSwayDuration"
                  :trajectory="swayTrajectory.angle.coordinate"
                  :polygon-data="selectedTrajectorysPolygon.angle.coordinate"
                  :line-scale="0.1"
                  :line-opacity="swayLineOpacity"
                  :polygon-opacity="swayPolygonOpacity"
                />

                <SwayTrajectoryGraphCard
                  id="angleVelocity"
                  v-bind="graphCols"
                  :visible="visibility.swayReport.trajectory.angle.velocity"
                  :title="`姿勢角速度${ comparableId ? ` - ID: ${id}` : '' }`"
                  :color="comparableId ? 'primary darken-1' : 'secondary'"
                  use="angleVelocity"
                  :selection="selectedSwayDuration"
                  :trajectory="swayTrajectory.angle.velocity"
                  :polygon-data="selectedTrajectorysPolygon.angle.velocity"
                  :line-opacity="swayLineOpacity"
                  :polygon-opacity="swayPolygonOpacity"
                />

                <SwayTrajectoryGraphCard
                  id="angleAcceleration"
                  v-bind="graphCols"
                  :visible="visibility.swayReport.trajectory.angle.acceleration"
                  :title="`姿勢角加速度${ comparableId ? ` - ID: ${id}` : '' }`"
                  :color="comparableId ? 'primary darken-1' : 'secondary'"
                  use="angleAcceleration"
                  :trajectory="swayTrajectory.angle.acceleration"
                  :polygon-data="selectedTrajectorysPolygon.angle.acceleration"
                  :selection="selectedSwayDuration"
                  :line-opacity="swayLineOpacity"
                  :polygon-opacity="swayPolygonOpacity"
                />

                <template v-if="comparableId && comparableSwayTrajectory">
                  <SwayTrajectoryGraphCard
                    id="angleCoordinateComparable"
                    v-bind="graphCols"
                    :visible="visibility.swayReport.trajectory.angle.coordinate"
                    :title="`姿勢角度 - ID: ${comparableId}`"
                    color="tertiary darken-1"
                    use="angleCoordinateComparable"
                    :selection="selectedComparableSwayDuration"
                    :trajectory="comparableSwayTrajectory.angle.coordinate"
                    :polygon-data="selectedComparableTrajectorysPolygon.angle.coordinate"
                    :line-scale="0.1"
                    :line-opacity="swayLineOpacity"
                    :polygon-opacity="swayPolygonOpacity"
                  />

                  <SwayTrajectoryGraphCard
                    id="angleVelocityComparable"
                    v-bind="graphCols"
                    :visible="visibility.swayReport.trajectory.angle.velocity"
                    :title="`姿勢角速度 - ID: ${comparableId}`"
                    color="tertiary darken-1"
                    use="angleVelocityComparable"
                    :selection="selectedComparableSwayDuration"
                    :trajectory="comparableSwayTrajectory.angle.velocity"
                    :polygon-data="selectedComparableTrajectorysPolygon.angle.velocity"
                    :line-opacity="swayLineOpacity"
                    :polygon-opacity="swayPolygonOpacity"
                  />

                  <SwayTrajectoryGraphCard
                    id="angleAccelerationComparable"
                    v-bind="graphCols"
                    :visible="visibility.swayReport.trajectory.angle.acceleration"
                    :title="`姿勢角加速度 - ID: ${comparableId}`"
                    color="tertiary darken-1"
                    use="angleAccelerationComparable"
                    :selection="selectedComparableSwayDuration"
                    :trajectory="comparableSwayTrajectory.angle.acceleration"
                    :polygon-data="selectedComparableTrajectorysPolygon.angle.acceleration"
                    :line-opacity="swayLineOpacity"
                    :polygon-opacity="swayPolygonOpacity"
                  />
                </template>

                <template v-if="swaySpectrums">
                  <SwaySpectrumChartCard
                    v-bind="graphCols"
                    :visible="visibility.swayReport.spectrum.angle.coordinate"
                    :title="`姿勢角度${ comparableId ? ` - ID: ${id}` : '' }`"
                    :color="comparableId ? 'primary darken-1' : 'secondary'"
                    :trajectory="swaySpectrums.angle.coordinate"
                  />

                  <SwaySpectrumChartCard
                    v-bind="graphCols"
                    :visible="visibility.swayReport.spectrum.angle.velocity"
                    :title="`姿勢角速度${ comparableId ? ` - ID: ${id}` : '' }`"
                    :color="comparableId ? 'primary darken-1' : 'secondary'"
                    :trajectory="swaySpectrums.angle.velocity"
                  />

                  <SwaySpectrumChartCard
                    v-bind="graphCols"
                    :visible="visibility.swayReport.spectrum.angle.acceleration"
                    :title="`姿勢角加速度${ comparableId ? ` - ID: ${id}` : '' }`"
                    :color="comparableId ? 'primary darken-1' : 'secondary'"
                    :trajectory="swaySpectrums.angle.acceleration"
                  />
                </template>

                <template v-if="comparableId && comparableSwayTrajectory && comparableSwaySpectrums">
                  <SwaySpectrumChartCard
                    v-bind="graphCols"
                    :visible="visibility.swayReport.spectrum.angle.coordinate"
                    :title="`姿勢角度 - ID: ${comparableId}`"
                    color="tertiary darken-1"
                    :trajectory="comparableSwaySpectrums.angle.coordinate"
                  />

                  <SwaySpectrumChartCard
                    v-bind="graphCols"
                    :visible="visibility.swayReport.spectrum.angle.velocity"
                    :title="`姿勢角速度 - ID: ${comparableId}`"
                    color="tertiary darken-1"
                    :trajectory="comparableSwaySpectrums.angle.velocity"
                  />

                  <SwaySpectrumChartCard
                    v-bind="graphCols"
                    :visible="visibility.swayReport.spectrum.angle.acceleration"
                    :title="`姿勢角加速度 - ID: ${comparableId}`"
                    color="tertiary darken-1"
                    :trajectory="comparableSwaySpectrums.angle.acceleration"
                  />
                </template>

                <v-col 
                  v-bind="tableCols" 
                  v-if="visibility.swayReport.features.angle.table && currentTab.key === 'sway'"
                >
                  <v-row>
                    <v-chip
                      class="ml-3"
                      :color="comparableId ? 'primary darken-1' : 'secondary'"
                      outlined
                    >
                      姿勢角度特徴量{{ comparableId ? ` - ID: ${id}` : null }}
                    </v-chip>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1">
                      <SwayFeaturesTable
                        v-if="swaySpectrums"
                        :trajectory="swayTrajectory.angle"
                        :selection="selectedSwayDuration"
                        :spectrum="swaySpectrums.angle"
                        :polygon="selectedTrajectorysPolygon.angle"
                        :aspect-ratio="null"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col 
                  v-bind="tableCols" 
                  v-if="visibility.swayReport.features.angle.table && comparableId && comparableSwayTrajectory"
                >
                  <v-row>
                    <v-chip
                      class="ml-3"
                      color="tertiary darken-1"
                      outlined
                    >
                     姿勢角度特徴量 - ID: {{ comparableId }}
                    </v-chip>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1">
                      <SwayFeaturesTable
                        v-if="comparableSwaySpectrums"
                        color="tertiary darken-1 white--text"
                        :trajectory="comparableSwayTrajectory.angle"
                        :selection="selectedComparableSwayDuration"
                        :spectrum="comparableSwaySpectrums.angle"
                        :polygon="selectedComparableTrajectorysPolygon.angle"
                        :aspect-ratio="null"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col 
                  cols="12" 
                  v-show="visibility.swayReport.sensor"
                >
                  <v-chip
                    :color="comparableId ? 'primary darken-1' : 'secondary'"
                    outlined
                  >
                    センサデータ{{ comparableId ? ` - ID: ${id}` : null }}
                  </v-chip>
                  <RawDataChart
                    ref="leftLineChart"
                    :raw-data="swayRawData"
                    :selection="selectedSwayDuration"
                  />
                </v-col>

                <v-col 
                  v-if="comparableId && comparableSwayRawData"
                  cols="12" 
                  v-show="visibility.swayReport.sensor"
                >
                  <v-chip
                    color="tertiary darken-1"
                    outlined
                  >
                    センサデータ{{ ` - ID: ${comparableId}` }}
                  </v-chip>
                  <RawDataChart
                    ref="leftLineChart"
                    :raw-data="comparableSwayRawData"
                    :selection="selectedComparableSwayDuration"
                  />
                </v-col>

                <MapCard
                  v-if="sway.latitude != null && sway.longitude != null"
                  v-bind="cardCols" 
                  v-model="sway"
                  color="primary"
                  :title="`座標 - ID: ${id}`"
                />

                <MapCard
                  v-if="comparableId && comparableSway && comparableSway.latitude != null && comparableSway.longitude != null"
                  color="tertiary"
                  v-bind="cardCols" 
                  v-model="comparableSway"
                  :title="`座標 - ID: ${comparableId}`"
                />
              </v-row>
            </v-container>
              
            <div
              v-if="currentTab.key === 'karte'"
              class="py-5 secondary darken-4"
              style="position: relative;"
            >
              <v-btn 
                @click="printKarte"
                fixed
                right
                fab
                class="mr-3"
                color="accent"
                v-tooltip.left="'PDFダウンロード'"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>

              <div class="karte-mode-selector-container">
                <v-select
                  dense
                  hide-details
                  v-model="karteViewPoint"
                  :items="karteModeOptions"
                  item-text="label"
                  item-value="key"
                ></v-select>
              </div>

              <div ref="kartes">
                <div ref="karte" class="karte-container v-application">
                  <v-container 
                    fluid 
                    class="pa-3"
                  >
                    <KarteHeader />
                    <KarteSwayAccountInfo
                      :view="karteViewPoint"
                      :id="id"
                      :comparable-id="comparableId"
                      :sway="sway"
                      :comparable-sway="comparableSway"
                      :selected-sway-duration="selectedSwayDuration"
                      :selected-comparable-sway-duration="selectedComparableSwayDuration"
                      :sway-trajectory="swayTrajectory"
                      :comparable-sway-trajectory="comparableSwayTrajectory"
                    />

                    <KarteSwayGraphs
                      :view="karteViewPoint"
                      :id="id"
                      :comparable-id="comparableId"
                      :selected-sway-duration="selectedSwayDuration"
                      :selected-comparable-sway-duration="selectedComparableSwayDuration"
                      :sway-trajectory="swayTrajectory"
                      :comparable-sway-trajectory="comparableSwayTrajectory"
                      :sway-spectrums="swaySpectrums"
                      :comparable-sway-spectrums="comparableSwaySpectrums"
                      polygon-mode="octa"
                    />

                    <v-row dense>
                      <v-col>
                        <KarteSwayFeaturesTable
                          v-if="swaySpectrums"
                          :id="id"
                          :comparable-id="comparableId"
                          :aspect-ratio="null"
                          :trajectory="swayTrajectory.position"
                          :selection="selectedSwayDuration"
                          :spectrum="swaySpectrums.position"
                          :polygon="selectedTrajectorysPolygon.position"

                          :comparable-trajectory="comparableSwayTrajectory ? comparableSwayTrajectory.position : null"
                          :comparable-selection="selectedComparableSwayDuration ? selectedComparableSwayDuration : null"
                          :comparable-spectrum="comparableSwaySpectrums ? comparableSwaySpectrums.position : null"
                          :comparable-polygon="selectedComparableTrajectorysPolygon ? selectedComparableTrajectorysPolygon.position : null"
                          :only="karteViewPoint"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </div>

                <template v-if="comparableId">
                  <div ref="karteSingle" class="karte-container v-application">
                    <v-container 
                      fluid 
                      class="pa-3"
                    >
                      <KarteHeader />
                      <KarteSwayAccountInfo
                        :view="karteViewPoint"
                        :id="id"
                        :sway="sway"
                        :selected-sway-duration="selectedSwayDuration"
                        :sway-trajectory="swayTrajectory"
                      />

                      <KarteSwayGraphs
                        :view="karteViewPoint"
                        :id="`${id}-single`"
                        :selected-sway-duration="selectedSwayDuration"
                        :sway-trajectory="swayTrajectory"
                        :sway-spectrums="swaySpectrums"
                        polygon-mode="octa"
                      />

                      <v-row dense>
                        <v-col>
                          <KarteSwayFeaturesTable
                            v-if="swaySpectrums"
                            :id="id"
                            :aspect-ratio="null"
                            :trajectory="swayTrajectory.position"
                            :selection="selectedSwayDuration"
                            :spectrum="swaySpectrums.position"
                            :polygon="selectedTrajectorysPolygon.position"
                            :only="karteViewPoint"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>

                  <div ref="karteComparable" class="karte-container v-application">
                    <v-container 
                      fluid 
                      class="pa-3"
                    >
                      <KarteHeader />
                      <KarteSwayAccountInfo
                        color="comparable"
                        :view="karteViewPoint"
                        :id="comparableId"
                        :sway="comparableSway"
                        :selected-sway-duration="selectedComparableSwayDuration"
                        :sway-trajectory="comparableSwayTrajectory"
                      />

                      <KarteSwayGraphs
                        tag="Comparable"
                        :view="karteViewPoint"
                        :id="comparableId"
                        :selected-sway-duration="selectedComparableSwayDuration"
                        :sway-trajectory="comparableSwayTrajectory"
                        :sway-spectrums="comparableSwaySpectrums"
                        polygon-mode="octa"
                      />

                      <v-row dense>
                        <v-col>
                          <KarteSwayFeaturesTable
                            v-if="swaySpectrums"
                            comparable
                            :id="comparableId"
                            :aspect-ratio="null"
                            :trajectory="comparableSwayTrajectory.position"
                            :selection="selectedComparableSwayDuration"
                            :spectrum="comparableSwaySpectrums.position"
                            :polygon="selectedComparableTrajectorysPolygon.position"
                            :only="karteViewPoint"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                </template>
              </div>
            </div>
          </simplebar>

          <SwayPhaseGraph
            ref="swayPhaseGraph"
            class="pr-3 my-3"
            style="flex: initial;"
            :trajectory="swayTrajectory"
            :selection="selectedSwayDuration"
            @reload="calculateSwayFrequency"
          />

          <SwayPhaseGraph
            v-if="comparableId && comparableSway"
            ref="comparableSwayPhaseGraph"
            class="pr-3 mb-3"
            style="flex: initial;"
            color="tertiary darken-3"
            :trajectory="comparableSwayTrajectory"
            :selection="selectedComparableSwayDuration"
            @reload="calculateComparableSwayFrequency"
          />
        </v-col>
      </v-row>

      <v-alert v-if="!sway.isAnalyzed" class="mt-5" type="info" color="primary">
        分析中です。しばらくしてからリロードしてください。
      </v-alert>

      <v-alert v-if="sway.isFailed" class="mt-5" type="error">
        分析に失敗しました。
      </v-alert>
    </template>

    <v-snackbar 
      :value="isDownloading"
      :timeout="-1"
      color="primary darken-4"
    >
      <span>Downloading...</span>
      <v-progress-linear
        indeterminate 
        :height="24"
        color="primary lighten-5"
        class="my-2 ml-1"
      />
    </v-snackbar>

    <v-overlay :value="isLoading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import html2pdf from "html2pdf.js";
import { saveAs } from "file-saver";

import SwayTrajectoryGeometriesDefs from '@components/parts/graph/geometories/SwayTrajectoryGeometriesDefs.vue'

import SwayPhaseGraph from '@components/parts/graph/SwayPhaseGraph.vue'
import SwayListTable from "@components/parts/tables/SwayListTable.vue";

import SwayTrajectoryGraphCard from "@components/parts/cards/SwayTrajectoryGraphCard.vue";
import SwaySpectrumChartCard from "@components/parts/cards/SwaySpectrumChartCard.vue";
import SwayFeaturesTable from "@components/parts/tables/SwayFeaturesTable.vue";

import RawDataChart from '@components/parts/charts/RawDataChart.vue'
import Dialog from "@components/parts/dialogs/Dialog.vue";

import KarteHeader from "@components/parts/karte/KarteHeader.vue";
import KarteSwayAccountInfo from "@components/parts/karte/KarteSwayAccountInfo.vue";
import KarteSwayGraphs from "@components/parts/karte/KarteSwayGraphs.vue";
import KarteSwayFeaturesTable from "@components/parts/karte/KarteSwayFeaturesTable.vue";

import MapCard from "@components/parts/cards/MapCard.vue";
import CommentsCard from "@components/parts/cards/CommentsCard.vue";

import { 
  getTrajectoryPolygonData,
  getSwayGeometricFeatures,
  getSwayDirectionalFeatures,
} from '@js/utils/StatisticsUtil.js';

export default {
  components: {
    SwayTrajectoryGraphCard,
    SwaySpectrumChartCard,
    SwayTrajectoryGeometriesDefs,
    SwayPhaseGraph,
    RawDataChart,
    Dialog,
    SwayListTable,
    SwayFeaturesTable,

    KarteHeader,
    KarteSwayAccountInfo,
    KarteSwayGraphs,
    KarteSwayFeaturesTable,

    CommentsCard,
    MapCard,
  },
  props: {
    id: {
      required: true,
    },
    comparableId: {
      default: null,
    },
  },
  data: function(){
    return {
      isReportCommentsOpened: false,
      isComparableReportCommentsOpened: false,

      isSwayPerspective: true,
      isComparableSwayPerspective: true,

      swayLineOpacity: 0.25,
      swayPolygonOpacity: 0.35,

      isSideMenuOpened: true,
      currentTabIndex: 0,
      currentSettingTabIndex: 0,
      karteModeOptions: [
        {key: 'top', label: '左右・前後'},
        {key: 'side', label: '前後・上下'},
        {key: 'front', label: '左右・上下'}
      ],
      tabs: [
        {label: '重心動揺', key: 'sway'},
        {label: '印刷用', key: 'karte'},
      ],
      graphCols: {
        cols: 12,
        sm: 6,
        lg: 4
      },
      tableCols: {
        cols: 12,
      },
      cardCols: {
        cols: 12,
        sm: 6,
        lg: 6
      },
      
      selectedSwayDuration: {
        from: 0,
        to: 0,
      },
      selectedComparableSwayDuration: {
        from: 0,
        to: 0,
      },

      swaySpectrums: null,
      comparableSwaySpectrums: null,
      
      karteViewPoint: 'top', // top | side | front
      kartePolygonMode: 'tetra', // tetra | octa

      isDownloading: false,
    }
  },
  computed: {
    ...mapState([
      'user',
      'project',
      'rootPath',
      'visibility',

      'sway',
      'swayTrajectory',
      'swayRawData',

      'comparableSway',
      'comparableSwayTrajectory',
      'comparableSwayRawData',

      'swayList',
      'loadingCount',
    ]),
    selectedTrajectorysPolygon: function(){
      return getTrajectoryPolygonData(this.swayTrajectory, this.selectedSwayDuration);
    },
    selectedComparableTrajectorysPolygon: function(){
      return getTrajectoryPolygonData(this.comparableSwayTrajectory, this.selectedComparableSwayDuration);
    },
    positionTrajectory: function(){
      return this.swayTrajectory;
    },


    isLoading: function(){
      return this.loadingCount > 0;
    },
    currentTab: function(){
      return this.tabs[this.currentTabIndex];
    },
    currentSettingTab: function(){
      return this.tabs[this.currentSettingTabIndex];
    },
  },
  watch: {
    id: function(){
      this.fetchAll();
    },
    comparableId: function(){
      this.fetchAll();
    },
    currentTab: function(newVal){
      this.currentSettingTabIndex = this.tabs.findIndex(t => t.key === newVal.key);

      this.$refs.scrollbar.scrollElement.scrollTop = 0;
    },
    swayTrajectory: {
      handler(newVal){
        if(!newVal){
          return;
        }

        this.selectedSwayDuration = {
          from: 0,
          to: newVal.angle.coordinate.x.length - 1,
        }

        this.$nextTick(() => {
          this.$refs.swayPhaseGraph?.updateCurrentSelection();
        });

        this.calculateSwayFrequency();
      },
      immediate: true,
    },
    comparableSwayTrajectory: {
      handler(newVal){
        if(!newVal){
          return;
        }

        this.selectedComparableSwayDuration = {
          from: 0,
          to: newVal.angle.coordinate.x.length - 1,
        }

        this.$nextTick(() => {
          this.$refs.comparableSwayPhaseGraph?.updateCurrentSelection();
        });

        this.calculateComparableSwayFrequency();
      },
      immediate: true,
    },
    project: function(newVal, oldVal){
      if(newVal?.id != oldVal?.id){
        this.$router.push('/sway')
      }
    },
  },
  destroyed: function(){
  },
  mounted: function(){
    this.fetchAll();
  },
  beforeDestroy: function(){
  },
  methods: {
    ...mapActions([
      'fetchSwayDetail',
      'fetchComparableSwayDetail',
    ]),
    fetchAll: function(){
      if(this.sway?.id != this.id){
        this.fetchSwayDetail(this.id);
      }

      if(this.comparableId != null && this.comparableSway?.id != this.comparableId){
        this.fetchComparableSwayDetail(this.comparableId);
      }
    },
    updateSway: function(){
      this.axios.put('/api/sway', this.sway).then(() => {
      }).catch(error => {
        console.log(error);
      })
    },
    refreshSwayTrajectoryGraphCamera: function(){
      this.$refs.swayTrajectoryGraph?.refreshCamera();
    },
    
    refreshComparableSwayTrajectoryGraphCamera: function(){
      this.$refs.comparableSwayTrajectoryGraph?.refreshCamera();
    },

    updateReportAttribute: function(key){
      this.axios.put(`/api/sway/${key}`, {
        ...this.sway,
      }).then(() => {

      }).catch(error => {
        console.log(error);
        alert('重心動揺レポート更新に失敗しました');
      })
    },
    updateComparableReportAttribute: function(key){
      this.axios.put(`/api/sway/${key}`, {
        ...this.comparableSway,
      }).then(() => {

      }).catch(error => {
        console.log(error);
        alert('重心動揺レポート更新に失敗しました');
      })
    },
    selectSwayReport: function(sway){
      if(this.id == sway.id){
        return;
      }
      this.$router.push(this.comparableId ? `/sway/${sway.id}/${this.comparableId}` : `/sway/${sway.id}`);
    },
    selectComparableSwayReport: function(sway){
      if(this.comparableId == sway.id){
        return;
      }
      this.$router.push(`/sway/${this.id}/${sway.id}`);
    },
    calculateSwayFrequency: function(){
      this.axios.post(`/api/sway/frequency/${parseInt(this.selectedSwayDuration.from)}/${parseInt(this.selectedSwayDuration.to)}`, {
        trajectory: this.swayTrajectory,
      }).then(response => {
        this.$set(this, 'swaySpectrums', response.data);
      })
    },
    calculateComparableSwayFrequency: function(){
      this.axios.post(`/api/sway/frequency/${parseInt(this.selectedComparableSwayDuration.from)}/${parseInt(this.selectedComparableSwayDuration.to)}`, {
        trajectory: this.comparableSwayTrajectory,
      }).then(response => {
        this.$set(this, 'comparableSwaySpectrums', response.data);
      })
    },
    printKarte: async function(){
      this.isDownloading = true;
      html2pdf().set({
        image: { type: 'jpeg', quality: 1 },
        filename: `重心動揺分析レポート-${this.sway.name}.pdf`,
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      }).from(this.comparableId ? this.$refs.kartes : this.$refs.karte).save().then(() => {
        this.isDownloading = false;
      });
    },
    downloadReport: async function(){
      this.isDownloading = true;
      let selectedSpectrum = null;
      await this.axios.post(`/api/sway/frequency/${parseInt(this.selectedSwayDuration.from)}/${parseInt(this.selectedSwayDuration.to)}`, {
        trajectory: this.swayTrajectory,
      }).then(response => {
        selectedSpectrum = response.data;
      })

      let fullTo = this.swayTrajectory.angle.coordinate.x.length - 1;
      let fullSpectrum = null;
      await this.axios.post(`/api/sway/frequency/${0}/${fullTo}`, {
        trajectory: this.swayTrajectory,
      }).then(response => {
        fullSpectrum = response.data;
      })

      let fullTrajectoryPolygon = getTrajectoryPolygonData(this.swayTrajectory, {from: 0, to: fullTo});

      let features = {
        full: {
          position: {
            geometric: getSwayGeometricFeatures(
              this.swayTrajectory.position,
              fullTrajectoryPolygon.position,
              {from: 0, to: fullTo}
            ),
            directional: getSwayDirectionalFeatures(
              this.swayTrajectory.position,
              this.swaySpectrums.position,
              {from: 0, to: fullTo}
            )
          },
          angle: {
            geometric: getSwayGeometricFeatures(
              this.swayTrajectory.angle,
              fullTrajectoryPolygon.angle,
              {from: 0, to: fullTo}
            ),
            directional: getSwayDirectionalFeatures(
              this.swayTrajectory.angle,
              this.swaySpectrums.angle,
              {from: 0, to: fullTo}
            )
          },
        },
        selected: {
          position: {
            geometric: getSwayGeometricFeatures(
              this.swayTrajectory.position,
              this.selectedTrajectorysPolygon.position,
              this.selectedSwayDuration
            ),
            directional: getSwayDirectionalFeatures(
              this.swayTrajectory.position,
              this.swaySpectrums.position,
              this.selectedSwayDuration
            )
          },
          angle: {
            geometric: getSwayGeometricFeatures(
              this.swayTrajectory.angle,
              this.selectedTrajectorysPolygon.angle,
              this.selectedSwayDuration
            ),
            directional: getSwayDirectionalFeatures(
              this.swayTrajectory.angle,
              this.swaySpectrums.angle,
              this.selectedSwayDuration
            )
          },
        }
      }

      this.axios.post(
        `/api/download/sway/${this.id}`,
        {
          trajectory: this.swayTrajectory,
          selection: this.selectedSwayDuration,
          spectrums: {
            full: fullSpectrum,
            selected: selectedSpectrum,
          },
          features: features,
        },
        {
          responseType: "blob"
        },
      ).then(response => {
        const blob = new Blob([response.data], {
          type: response.data.type
        });
        let fileName = `【重心動揺レポート】${this.sway.name}.xlsx`;
        saveAs(blob, fileName);
      }).finally(() => {
        this.isDownloading = false;
      });
    },
  },
}
</script>

<style lang="scss" scoped>
</style>