import MarkdownIt from 'markdown-it';
import katex from 'katex';

const createContainerOptions = (a) => {
  const pattern = new RegExp(`^${a}$`);
  return [
    a,
    {
      render: (tokens, idx) => {
        const m = tokens[idx].info.trim().match(pattern);
        if (m && tokens[idx].nesting === 1) {
          return `<div class="v-alert md-container v-alert--dense v-alert--text ${a}--text">\n`;
        }
        return `</div>\n`;
      }
    }
  ];
};


function createRenderer(config = {}) {
	const renderer = new MarkdownIt({
			...config.markdownIt ?? {
        linkify: true,
        breaks: true,
      }
		})
		.use(require('markdown-it-texmath'), {
			engine: katex,
			...config.texmath ?? {}
		})
		.use(require('markdown-it-mermaid-plugin'))
		.use(require('markdown-it-highlightjs'), {
			...config.highlightjs ?? {}
		})
		.use(require('markdown-it-code-copy'), {
			...config.codeCopy ?? {}
		})
    .use(require('markdown-it-emoji'))
    .use(require('markdown-it-container'), ...createContainerOptions("success"))
    .use(require('markdown-it-container'), ...createContainerOptions("info"))
    .use(require('markdown-it-container'), ...createContainerOptions("warning"))
    .use(require('markdown-it-container'), ...createContainerOptions("error"))
    .use(require('markdown-it-task-checkbox'))
    ;
	return renderer;
}

export default createRenderer;
