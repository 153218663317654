export function getSplittedTrunkTrajectories(trajectory) {
  if(!trajectory){
    return [];
  }

  let rtn = [];
  for(let i=0, iMax=trajectory.left.heelStrikeIndexes.length; i<iMax-1; i++){
    let _from = trajectory.left.heelStrikeIndexes[i];
    let _to = trajectory.left.heelStrikeIndexes[i + 1];

    _from = Math.max(150, _from);
    _to = Math.min(trajectory.trunk.x.length - 150, _to)

    let _trajectory = {positions: []};
    for(let j=_from; j<_to; j++){
      _trajectory.positions.push({
        index: j,
        x: trajectory.trunk.y[j - 150],
        y: trajectory.trunk.x[j - 150],
        z: trajectory.trunk.z[j - 150],
      })
    }
    if(_from < _to){
      rtn.push(_trajectory);
    }
  }
  return rtn;
}
  