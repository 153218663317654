<template>
  <v-row dense>
    <v-col :cols="comparableId ? 3 : 4">
      <v-row dense>
        <v-col class="text-center text-caption">
          {{ comparableId ? `ID: ${id} ` : '' }}足首全軌道
        </v-col>
      </v-row>
      <AnkleTrajectoryGraph
        id="karteSide"
        class="mb-2"
        :gait-id="id"
        grid-mode="side"
        :left-trajectories="leftTrajectories"
        :right-trajectories="rightTrajectories"
        :selection="selectedGaitDuration"
        :vertical-offset="ankleVerticalOffset"
        :horizontal-offset="ankleHorizontalOffset"
        :perspective="false"
        :initial-roll="0"
        :initial-pitch="0"
        :initial-yaw="0"
        :scale-y="ankleVerticalScale"
        :initial-camera-position="1.0"
        :grid="true"
        :aspect-ratio="karteAnkleGraphAspectRatio"
        dragging-mode="translation"
      />

      <AnkleTrajectoryGraph
        id="karteTop"
        :gait-id="id"
        grid-mode="top"
        class="mb-2"
        :left-trajectories="leftTrajectories"
        :right-trajectories="rightTrajectories"
        :selection="selectedGaitDuration"
        :vertical-offset="ankleVerticalOffset"
        :horizontal-offset="ankleHorizontalOffset"
        :perspective="false"
        :initial-roll="90"
        :initial-pitch="0"
        :initial-yaw="0"
        :scale-y="0"
        :initial-camera-position="1.0"
        :grid="true"
        :aspect-ratio="karteAnkleGraphAspectRatio"
        dragging-mode="translation"
      />


      <AnkleTrajectoryGraph
        id="karteFront"
        :gait-id="id"
        grid-mode="front"
        :left-trajectories="leftTrajectories"
        :right-trajectories="rightTrajectories"
        :selection="selectedGaitDuration"
        :vertical-offset="ankleVerticalOffset"
        :horizontal-offset="ankleHorizontalOffset"
        :perspective="false"
        :initial-roll="0"
        :initial-pitch="90"
        :initial-yaw="0"
        :scale-x="0"
        :initial-camera-position="1.0"
        :grid="true"
        :aspect-ratio="karteAnkleGraphAspectRatio"
        dragging-mode="translation"
      />
      
      <template v-if="comparableId">
        <v-row dense>
          <v-col class="mt-1 text-center text-caption">
            ID: {{ id }} 腰首全軌道
          </v-col>
        </v-row>
        <TrunkTrajectoryGraph
          id="karteTrunk"
          :trajectory="gaitTrajectory"
          :selection="selectedGaitDuration"
          :initial-roll="0"
          :initial-pitch="0"
          :initial-yaw="0"
          :initial-camera-position="0.25"
          :scale-x="3"
          :scale-y="3"
          :scale-z="0"
          :perspective="false"
          :grid="true"
          dragging-mode="translation"
          :aspect-ratio="karteTrunkGraphAspectRatio"
        />
      </template>
    </v-col>

    <v-col v-if="comparableId" cols="3">
      <v-row dense>
        <v-col class="text-center text-caption">
          ID: {{comparableId}} 足首全軌道
        </v-col>
      </v-row>
      <AnkleTrajectoryGraph
        id="karteComparableSide"
        class="mb-2"
        :gait-id="comparableId"
        grid-mode="side"
        :left-trajectories="comparableLeftTrajectories"
        :right-trajectories="comparableRightTrajectories"
        :selection="selectedComparableGaitDuration"
        :vertical-offset="comparableAnkleVerticalOffset"
        :horizontal-offset="comparableAnkleHorizontalOffset"
        :perspective="false"
        :initial-roll="0"
        :initial-pitch="0"
        :initial-yaw="0"
        :scale-y="ankleVerticalScale"
        :initial-camera-position="1.0"
        :grid="true"
        :aspect-ratio="karteAnkleGraphAspectRatio"
        dragging-mode="translation"
      />

      <AnkleTrajectoryGraph
        id="karteComparableTop"
        :gait-id="comparableId"
        grid-mode="top"
        class="mb-2"
        :left-trajectories="comparableLeftTrajectories"
        :right-trajectories="comparableRightTrajectories"
        :selection="selectedComparableGaitDuration"
        :vertical-offset="comparableAnkleVerticalOffset"
        :horizontal-offset="comparableAnkleHorizontalOffset"
        :perspective="false"
        :initial-roll="90"
        :initial-pitch="0"
        :initial-yaw="0"
        :scale-y="0"
        :initial-camera-position="1.0"
        :grid="true"
        :aspect-ratio="karteAnkleGraphAspectRatio"
        dragging-mode="translation"
      />


      <AnkleTrajectoryGraph
        id="karteComparableFront"
        :gait-id="comparableId"
        grid-mode="front"
        :left-trajectories="comparableLeftTrajectories"
        :right-trajectories="comparableRightTrajectories"
        :selection="selectedComparableGaitDuration"
        :vertical-offset="comparableAnkleVerticalOffset"
        :horizontal-offset="comparableAnkleHorizontalOffset"
        :perspective="false"
        :initial-roll="0"
        :initial-pitch="90"
        :initial-yaw="0"
        :scale-x="0"
        :initial-camera-position="1.0"
        :grid="true"
        :aspect-ratio="karteAnkleGraphAspectRatio"
        dragging-mode="translation"
      />

      <v-row dense>
        <v-col class="mt-1 text-center text-caption">
          ID: {{ comparableId }} 腰首全軌道
        </v-col>
      </v-row>
      <TrunkTrajectoryGraph
        id="karteComparableTrunk"
        :trajectory="comparableGaitTrajectory"
        :selection="selectedComparableGaitDuration"
        :initial-roll="0"
        :initial-pitch="0"
        :initial-yaw="0"
        :initial-camera-position="0.25"
        :scale-x="3"
        :scale-y="3"
        :scale-z="0"
        :perspective="false"
        :grid="true"
        dragging-mode="translation"
        :aspect-ratio="karteTrunkGraphAspectRatio"
      />
    </v-col>

    <v-col :cols="comparableId ? 3 : 4">
      <v-row dense>
        <v-col class="text-center text-caption">
          {{ comparableId ? `ID: ${id} ` : '' }}足首平均軌道
        </v-col>
      </v-row>
      <AnkleTrajectoryGraph
        id="karteAveragedSide"
        :gait-id="id"
        grid-mode="side"
        class="mb-2"
        :left-trajectories="leftTrajectories"
        :right-trajectories="rightTrajectories"
        :selection="selectedGaitDuration"
        :vertical-offset="ankleVerticalOffset"
        :horizontal-offset="ankleHorizontalOffset"
        :perspective="false"
        :initial-roll="0"
        :initial-pitch="0"
        :initial-yaw="0"
        :scale-y="ankleVerticalScale"
        :initial-camera-position="1.0"
        :grid="true"
        :aspect-ratio="karteAnkleGraphAspectRatio"
        dragging-mode="translation"
        averaged
      />

      <AnkleTrajectoryGraph
        id="karteAveragedTop"
        :gait-id="id"
        grid-mode="top"
        class="mb-2"
        :left-trajectories="leftTrajectories"
        :right-trajectories="rightTrajectories"
        :selection="selectedGaitDuration"
        :vertical-offset="ankleVerticalOffset"
        :horizontal-offset="ankleHorizontalOffset"
        :perspective="false"
        :initial-roll="90"
        :initial-pitch="0"
        :initial-yaw="0"
        :scale-y="0"
        :initial-camera-position="1.0"
        :grid="true"
        :aspect-ratio="karteAnkleGraphAspectRatio"
        dragging-mode="translation"
        averaged
      />

      <AnkleTrajectoryGraph
        id="karteAveragedFront"
        :gait-id="id"
        grid-mode="front"
        :left-trajectories="leftTrajectories"
        :right-trajectories="rightTrajectories"
        :selection="selectedGaitDuration"
        :vertical-offset="ankleVerticalOffset"
        :horizontal-offset="ankleHorizontalOffset"
        :perspective="false"
        :initial-roll="0"
        :initial-pitch="90"
        :initial-yaw="0"
        :scale-x="0"
        :initial-camera-position="1.0"
        :grid="true"
        :aspect-ratio="karteAnkleGraphAspectRatio"
        dragging-mode="translation"
        averaged
      />

      <template v-if="comparableId">
        <v-row dense>
          <v-col class="mt-1 text-center text-caption">
            ID: {{ id }} 腰平均軌道
          </v-col>
        </v-row>
        <TrunkTrajectoryGraph
          id="karteAveragedTrunk"
          :gaitId="id"
          :trajectory="gaitTrajectory"
          :selection="selectedGaitDuration"
          :initial-roll="0"
          :initial-pitch="0"
          :initial-yaw="0"
          :initial-camera-position="0.25"
          :scale-x="3"
          :scale-y="3"
          :scale-z="0"
          :perspective="false"
          :grid="true"
          dragging-mode="translation"
          :aspect-ratio="karteTrunkGraphAspectRatio"
          averaged
        />
      </template>
    </v-col>

    <v-col v-if="comparableId" cols="3">
      <v-row dense>
        <v-col class="text-center text-caption">
          ID: {{comparableId}} 足首平均軌道
        </v-col>
      </v-row>
      <AnkleTrajectoryGraph
        id="karteAveragedComparableSide"
        class="mb-2"
        :gait-id="comparableId"
        grid-mode="side"
        :left-trajectories="comparableLeftTrajectories"
        :right-trajectories="comparableRightTrajectories"
        :selection="selectedComparableGaitDuration"
        :vertical-offset="comparableAnkleVerticalOffset"
        :horizontal-offset="comparableAnkleHorizontalOffset"
        :perspective="false"
        :initial-roll="0"
        :initial-pitch="0"
        :initial-yaw="0"
        :scale-y="ankleVerticalScale"
        :initial-camera-position="1.0"
        :grid="true"
        :aspect-ratio="karteAnkleGraphAspectRatio"
        dragging-mode="translation"
        averaged
        comparable
      />

      <AnkleTrajectoryGraph
        id="karteAveragedComparableTop"
        :gait-id="comparableId"
        grid-mode="top"
        class="mb-2"
        :left-trajectories="comparableLeftTrajectories"
        :right-trajectories="comparableRightTrajectories"
        :selection="selectedComparableGaitDuration"
        :vertical-offset="comparableAnkleVerticalOffset"
        :horizontal-offset="comparableAnkleHorizontalOffset"
        :perspective="false"
        :initial-roll="90"
        :initial-pitch="0"
        :initial-yaw="0"
        :scale-y="0"
        :initial-camera-position="1.0"
        :grid="true"
        :aspect-ratio="karteAnkleGraphAspectRatio"
        dragging-mode="translation"
        averaged
        comparable
      />


      <AnkleTrajectoryGraph
        id="karteAveragedComparableFront"
        :gait-id="comparableId"
        grid-mode="front"
        :left-trajectories="comparableLeftTrajectories"
        :right-trajectories="comparableRightTrajectories"
        :selection="selectedComparableGaitDuration"
        :vertical-offset="comparableAnkleVerticalOffset"
        :horizontal-offset="comparableAnkleHorizontalOffset"
        :perspective="false"
        :initial-roll="0"
        :initial-pitch="90"
        :initial-yaw="0"
        :scale-x="0"
        :initial-camera-position="1.0"
        :grid="true"
        :aspect-ratio="karteAnkleGraphAspectRatio"
        dragging-mode="translation"
        averaged
        comparable
      />

      <v-row dense>
        <v-col class="mt-1 text-center text-caption">
          ID: {{ comparableId }} 腰平均全軌道
        </v-col>
      </v-row>
      <TrunkTrajectoryGraph
        id="karteAveragedComparableTrunk"
        :gaitId="comparableId"
        :trajectory="comparableGaitTrajectory"
        :selection="selectedComparableGaitDuration"
        :initial-roll="0"
        :initial-pitch="0"
        :initial-yaw="0"
        :initial-camera-position="0.25"
        :scale-x="3"
        :scale-y="3"
        :scale-z="0"
        :perspective="false"
        :grid="true"
        dragging-mode="translation"
        :aspect-ratio="karteTrunkGraphAspectRatio"
        averaged
        comparable
      />
    </v-col>

    <v-col v-if="!comparableId" cols="4">
      <v-row dense>
        <v-col class="text-center text-caption">
          腰首全軌道
        </v-col>
      </v-row>
      <TrunkTrajectoryGraph
        id="karteTrunk"
        :trajectory="gaitTrajectory"
        :selection="selectedGaitDuration"
        :initial-roll="0"
        :initial-pitch="0"
        :initial-yaw="0"
        :initial-camera-position="0.25"
        :scale-x="3"
        :scale-y="3"
        :scale-z="0"
        :perspective="false"
        :grid="true"
        dragging-mode="translation"
        :aspect-ratio="karteTrunkGraphAspectRatio"
      />
      <v-row dense>
        <v-col style="margin-top: 1px;" class="text-center text-caption">
          腰平均軌道
        </v-col>
      </v-row>
      <TrunkTrajectoryGraph
        id="karteAveragedTrunk"
        :gaitId="id"
        :trajectory="gaitTrajectory"
        :selection="selectedGaitDuration"
        :initial-roll="0"
        :initial-pitch="0"
        :initial-yaw="0"
        :initial-camera-position="0.25"
        :scale-x="3"
        :scale-y="3"
        :scale-z="0"
        :perspective="false"
        :grid="true"
        dragging-mode="translation"
        :aspect-ratio="karteTrunkGraphAspectRatio"
        averaged
      />
    </v-col>
  </v-row>
</template>

<script>
import AnkleTrajectoryGraph from '@components/parts/graph/AnkleTrajectoryGraph.vue'
import TrunkTrajectoryGraph from '@components/parts/graph/TrunkTrajectoryGraph.vue'

import { mapState } from "vuex";

export default {
  components: {
    AnkleTrajectoryGraph,
    TrunkTrajectoryGraph,
  },
  props: {
    id: {
      required: true,
    },
    comparableId: {
      default: null,
    },
    selectedGaitDuration: {
    },
    selectedComparableGaitDuration: {
    },
    gaitTrajectory: {
    },
    comparableGaitTrajectory: {
    },
    ankleVerticalScale: {
      default: 2,
    },
  },
  data: function(){
    return {
    }
  },
  computed: {
    ...mapState([
    ]),
    
    ankleAveragedHeight: function(){
      return this.gaitTrajectory?.statistics?.averagedHeight ?? 0;
    },
    ankleAveragedStride: function(){
      return this.gaitTrajectory?.statistics?.averagedStride ?? 0;
    },
    isAnkleTrajectoriesDataGenerating: function(){
      return this.gaitTrajectoryDataGeneratingCount > 0;
    },
    ankleVerticalOffset: function(){
      return this.ankleAveragedHeight/2;
    },
    ankleHorizontalOffset: function(){
      return this.ankleAveragedStride/2;
    },

    comparableAnkleAveragedHeight: function(){
      return this.comparableGaitTrajectory?.statistics?.averagedHeight ?? 0;
    },
    comparableAnkleAveragedStride: function(){
      return this.comparableGaitTrajectory?.statistics?.averagedStride ?? 0;
    },
    comparableAnkleVerticalOffset: function(){
      return this.comparableAnkleAveragedHeight/2;
    },
    comparableAnkleHorizontalOffset: function(){
      return this.comparableAnkleAveragedStride/2;
    },

    karteAnkleGraphAspectRatio: function(){
      return this.comparableId ? 1.6 : 1.2;
    },
    karteTrunkGraphAspectRatio: function(){
      return this.comparableId ? 1.5 : 0.8053;
    },

    leftTrajectories: function(){
      return this.gaitTrajectory?.splitted?.left ?? [];
    },
    rightTrajectories: function(){
      return this.gaitTrajectory?.splitted?.right ?? [];
    },
    comparableLeftTrajectories: function(){
      return this.comparableGaitTrajectory?.splitted?.left ?? [];
    },
    comparableRightTrajectories: function(){
      return this.comparableGaitTrajectory?.splitted?.right ?? [];
    },
    selectedDuration: function(){
      let indexFrom = this.selectedGaitDuration?.from ?? 0;
      let indexTo = this.selectedGaitDuration?.to ?? 0;

      return (indexTo - indexFrom)*0.01;
    },
    selectedComparableDuration: function(){
      let indexFrom = this.selectedComparableGaitDuration?.from ?? 0;
      let indexTo = this.selectedComparableGaitDuration?.to ?? 0;

      return (indexTo - indexFrom)*0.01;
    },
    selectedLeftTrajectories: function(){
      let indexFrom = this.selectedGaitDuration?.from ?? 0;
      let indexTo = this.selectedGaitDuration?.to ?? 0;
      return this.leftTrajectories.filter(t => indexFrom <= t.positions[0].index && t.positions.slice(-1)[0].index < indexTo);
    },
    selectedRightTrajectories: function(){
      let indexFrom = this.selectedGaitDuration?.from ?? 0;
      let indexTo = this.selectedGaitDuration?.to ?? 0;
      return this.rightTrajectories.filter(t => indexFrom <= t.positions[0].index && t.positions.slice(-1)[0].index < indexTo);
    },
    selectedComparableLeftTrajectories: function(){
      let indexFrom = this.selectedComparableGaitDuration?.from ?? 0;
      let indexTo = this.selectedComparableGaitDuration?.to ?? 0;
      return this.comparableLeftTrajectories.filter(t => indexFrom <= t.positions[0].index && t.positions.slice(-1)[0].index < indexTo);
    },
    selectedComparableRightTrajectories: function(){
      let indexFrom = this.selectedComparableGaitDuration?.from ?? 0;
      let indexTo = this.selectedComparableGaitDuration?.to ?? 0;
      return this.comparableRightTrajectories.filter(t => indexFrom <= t.positions[0].index && t.positions.slice(-1)[0].index < indexTo);
    },
  }
}
</script>