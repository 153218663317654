var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"dark":"","id":"app"}},[_c('v-navigation-drawer',{attrs:{"app":"","color":"secondary","dark":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.user)?_c('v-list',{attrs:{"nav":""}},[_vm._l((_vm.validMenus.filter(function (m) { return m.bottom; })),function(menu){return [_c('v-tooltip',{key:menu.title,attrs:{"color":"primary darken-4","bottom":"","disabled":!_vm.isMenuDisabled(menu)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({class:{
                'grey--text text--darken-2': _vm.isMenuDisabled(menu)
              },attrs:{"to":_vm.isMenuDisabled(menu) ? null : menu.url}},'v-list-item',attrs,false),on),[(menu.icon)?_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":_vm.isMenuDisabled(menu)? 'grey darken-2' : null}},[_vm._v(" "+_vm._s(menu.icon)+" ")])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(menu.title)+" ")])],1)],1)]}}],null,true)},[_vm._v(" ヘッダーメニューでプロジェクトを選択してください ")])]})],2):_vm._e()]},proxy:true}]),model:{value:(_vm.isSideMenuOpened),callback:function ($$v) {_vm.isSideMenuOpened=$$v},expression:"isSideMenuOpened"}},[_c('v-list-item',[_c('v-list-item-title'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.isSideMenuOpened=false}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1)],1),_c('v-divider'),(_vm.user)?_c('v-list',{attrs:{"nav":""}},[_vm._l((_vm.validMenus.filter(function (m) { return !m.bottom; })),function(menu){return [_c('v-tooltip',{key:menu.title,attrs:{"color":"primary darken-4","bottom":"","disabled":!_vm.isMenuDisabled(menu)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({class:{
                'grey--text text--darken-2': _vm.isMenuDisabled(menu)
              },attrs:{"to":_vm.isMenuDisabled(menu) ? null : menu.url}},'v-list-item',attrs,false),on),[(menu.icon)?_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":_vm.isMenuDisabled(menu)? 'grey darken-2' : null}},[_vm._v(_vm._s(menu.icon))])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(menu.title)+" ")])],1)],1)]}}],null,true)},[_vm._v(" ヘッダーメニューでプロジェクトを選択してください ")])]})],2):_vm._e()],1),_c('v-app-bar',{attrs:{"app":"","color":"primary","dark":"","dense":""}},[_c('v-app-bar-nav-icon',{attrs:{"disabled":!_vm.user},on:{"click":function($event){$event.stopPropagation();_vm.isSideMenuOpened = !_vm.isSideMenuOpened}}}),(!_vm.$vuetify.breakpoint.mobile)?_c('v-toolbar-title',{staticClass:"pl-1"},[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")]):_vm._e(),(_vm.isLoggedIn)?_c('v-select',{staticClass:"text-no-wrap primary--text",class:{
        'ml-5': !_vm.$vuetify.breakpoint.mobile,
        'ml-1 mr-3': _vm.$vuetify.breakpoint.mobile
      },style:({
        'max-width': _vm.$vuetify.breakpoint.mobile ? 'calc(100vw - 200px)' : '20vw'
      }),attrs:{"background-color":"primary lighten-1","placeholder":"プロジェクトを選択してください","dense":"","outlined":"","hide-details":"","items":_vm.user.projects,"item-text":"name","item-value":"id"},model:{value:(_vm.currentProjectId),callback:function ($$v) {_vm.currentProjectId=$$v},expression:"currentProjectId"}}):_vm._e(),_c('v-spacer'),(_vm.user)?_c('span',{staticClass:"mr-5"},[_vm._v(" "+_vm._s(_vm.user.name)+" ")]):_vm._e(),(!!_vm.user)?_c('v-btn',{attrs:{"outlined":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.logout.apply(null, arguments)}}},[_vm._v(" ログアウト ")]):_vm._e()],1),_c('v-main',[(_vm.user)?_c('router-view',{ref:"page"}):_c('Login')],1),(false)?_c('v-footer',{attrs:{"app":""}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }