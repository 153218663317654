<template>
  <div>
    <v-textarea
      :dense="dense"
      ref="textarea"
      v-if="isEditMode"
      :outlined="isOutlined"
      :label="label"
      hide-details
      v-model="innerValue"
      @change="$emit('change', $event)"
      @focus.stop="isFocused=true"
      @blur.prevent.stop="setEditMode(false)"
    />
    <div v-if="!isEditMode && label && label.length > 0" class="text-caption">
      {{ label }}
    </div>

    <div 
      v-if="isPreviewCaptionShown" 
      class="text-caption mt-3"
    >
      Preview
    </div>

    <div
      v-if="isCompiledShown"
      class="md"
      :class="{
        editable: isEditable && !isEditMode,
        outlined: isOutlined,
      }"
      v-html="compiled"
      @dblclick="setEditMode(true)"
      v-tooltip="isEditable ? 'ダブルクリックで編集' : null"
    >
    </div>
    <div
      v-if="hint"
      class="text-caption ml-3"     
    >
      {{ hint }}
    </div>
  </div>
</template>

<script>
import createRenderer from "@js/utils/MarkdownUtil.js";

export default {
  props: {
    value: {
      required: true,
    },
    label: {
    },
    readonly: {
      default: null,
    },
    dense: {
      default: false,
    },
    hint: {
      default: null,
    },
    outlined: {
      default: null,
    }
  },
  data: function(){
    return {
      markdownRenderer: createRenderer(),
      recomputingCount: 0,
      text: null,
      isFocused: false,
    }
  },
  mounted: function(){
    this.text = this.value;
    if(!this.text?.length){
      this.isFocused = true;
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal){
        this.text = newVal;
      },
      immediate: true,
    },
  },
  computed: {
    isPreviewCaptionShown: function(){
      return this.isEditMode && this.text && this.compliedTextLength > 0
    },
    isCompiledShown: function(){
      return !this.isEditable || (this.text && this.compliedTextLength > 0)
    },
    isEditable: function(){
      return this.readonly == null;
    },
    isOutlined: function(){
      return this.outlined != null;
    },
    isEditMode: {
      get(){
        if(!this.isEditable){
          return false;
        }
        
        if(this.isEditable){
          if(!this.text || this.compliedTextLength == 0){
            return true;
          }
        }
        return this.isFocused;
      },
      set(newVal){
        this.isFocused = newVal;
      }
    },
    compiled: function(){
      let rtn = this.markdownRenderer.render(this.text ?? "");
      return rtn;
    },
    compliedTextLength: function(){
      const domParser = new DOMParser();
      let doc = domParser.parseFromString(this.compiled, "text/html");
      let rtn = doc.getElementsByTagName("body")[0]?.innerText?.trim()?.length ?? 0;
      return rtn;
    },
    innerValue: {
      get(){
        return this.value;
      },
      set(newVal){
        this.$emit('input', newVal);
        this.text = newVal;
      }
    },
  },
  methods: {
    setEditMode: function(val){
      if(!this.isEditable){
        return;
      }

      if(!val && !this.text?.length){
        return;
      }
      this.isFocused = val;
      if(this.isFocused){
        this.$nextTick(() => {
          this.$refs.textarea.focus();
        });
      }
    }
  }
}
</script>


<style lang="scss" scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/KaTeX/0.11.1/katex.min.css";
@import "../../../node_modules/highlight.js/styles/monokai.css";

.editable{
  cursor: pointer !important;
  &:hover{
    background: #eee;
  }
}

.md{
  &.outlined{
    padding: 8px 12px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 4px;
  }
}

.md ::v-deep{
  line-height: 1.5 !important;
  p {
    margin-bottom: 0 !important;
  }
  .md-container{
    > p{
      margin-bottom: 0;
    }
  }
  code {
    font-weight: initial;
    background-color: #2f2f2f;
    color: rgba(255, 255, 255, 0.9);
    font-size: 95%;
    display: inline-block;
    padding: 1px 0.5em 1px 0.5em;
    margin: 2px;
    margin-bottom: 14px;
  }
  blockquote {
    border-left: 0.25rem solid lightgrey;
    padding-left: 0.5rem;
    margin-left: 0.5rem;
  }

  pre code {
    display: block;
    padding: 0.5em 0.8em 0.5em 0.8em;
  }

  code:before,
  code:after {
    content: initial;
  }

  p {
    margin-bottom: 0;
  }

  table {
    margin: 16px 0;
    color: #303030;
    width: auto;
    display: block;
    overflow-x: auto;
    border-collapse: collapse;

    th,
    td {
      padding: 10px;
      border: 1px solid #dbdbdb;
    }

    th {
      background-color: #f0f0f0;
      border-bottom: solid 2px #bfbfbf;
    }
  }

  a {
    color: currentColor !important;
  }
}
</style>