<template>
  <VglDefs>
    <template v-slot:[`angleCoordinate${id}`]>
      <SwayTrajectoryGeometry
        :trajectory="trajectory.angle.coordinate"
        :selection="selection"
      />
    </template>
    <template v-slot:[`angleVelocity${id}`]>
      <SwayTrajectoryGeometry
        :trajectory="trajectory.angle.velocity"
        :selection="selection"
      />
    </template>
    <template v-slot:[`angleAcceleration${id}`]>
      <SwayTrajectoryGeometry
        :trajectory="trajectory.angle.acceleration"
        :selection="selection"
      />
    </template>

    <template v-slot:[`positionCoordinate${id}`]>
      <SwayTrajectoryGeometry
        :trajectory="trajectory.position.coordinate"
        :selection="selection"
      />
    </template>
    <template v-slot:[`positionVelocity${id}`]>
      <SwayTrajectoryGeometry
        :trajectory="trajectory.position.velocity"
        :selection="selection"
      />
    </template>
    <template v-slot:[`positionAcceleration${id}`]>
      <SwayTrajectoryGeometry
        :trajectory="trajectory.position.acceleration"
        :selection="selection"
      />
    </template>
  </VglDefs>
</template>

<script>
import SwayTrajectoryGeometry from '@components/parts/graph/geometories/SwayTrajectoryGeometry.vue'

import { 
  VglDefs,
} from 'vue-gl';

export default {
  components: {
    VglDefs,
    SwayTrajectoryGeometry,
  },
  props: {
    id: {
      default: '',
    },
    trajectory: {
      required: true,
    },
    selection: {
    },
  },
  computed: {
  },
  mounted: function(){
  },
}
</script>