<template>
  <div class="d-flex align-center">
    <v-select
      class="my-2 mr-2"
      v-model="selectedSettingId"
      :items="settings"
      label="除外軌道設定"
      hide-details
      outlined
      dense
      item-text="name"
      item-value="id"
      :loading="isLoading"
      ref="selector"
    >
      <template #prepend-item>
        <Dialog
          :color="color"
          block
          text
          width="640"
          @open="$nextTick(() => $refs.gaitSettingForm.validate())"
          @apply="createNewSetting"
          :disabled="!isNewSettingValid"
        >
          新規作成
          <template #title>
            ID: {{ gaitId }} 除外軌道設定新規作成
          </template>

          <template #contents>
            <v-container class="pt-5">
              <v-form
                @submit.prevent 
                ref="gaitSettingForm" 
                autocomplete="off" 
                lazy-validation
              >
                <v-row class="mt-3" dense>
                  <v-col>
                    <v-text-field
                      dense
                      outlined
                      label="設定名"
                      v-model="newSetting.name"
                      :rules="[required]"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-textarea
                      dense
                      outlined
                      label="説明"
                      v-model="newSetting.description"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-select
                      dense
                      :items="defaultModeOptions"
                      item-text="label"
                      item-value="key"
                      outlined
                      label="初期設定"
                      v-model="newSetting.default"
                      :rules="[required]"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </template>

          <template #apply>
            作成
          </template>
        </Dialog>
        <v-divider class="my-1"></v-divider>
      </template>
      <template #item="{ item, on, attrs }">
        <v-list-item
          v-bind="attrs"
          v-on="on"
          dense
          :color="color"
        >
          <v-list-item-content class="mr-3">
            <v-list-item-title class="text-subtitle-1 d-flex align-center">
              <span>{{ item.name }}</span>

              <v-tooltip
                v-if="item.description && item.description.length > 0"              
                color="primary darken-4`"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-1" 
                    color="secondary"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information
                  </v-icon>
                </template>
                <div style="white-space:pre-wrap;" v-text="item.description"></div>
              </v-tooltip>
            </v-list-item-title>
            <v-list-item-subtitle class="text-caption d-flex align-center">
              <span>作成者：{{ item.userName ? item.userName : '自動作成' }}</span>
              <v-spacer/>
              <span>作成日時：{{ item.createdDatetime }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <Dialog
            :color="color"
            icon
            @apply="updateSetting(item)"
            :openable="item.userName && !item.isLocked"
            :tooltip="getEditTooltip(item)"
            width="640"
            @open="$nextTick(() => $refs.gaitSettingEditForm.validate())"
          >
            <v-icon>mdi-pencil</v-icon>

            <template #title>
              除外軌道設定更新
            </template>

            <template #contents>
              <v-container class="pt-5">
                <v-form 
                  @submit.prevent
                  ref="gaitSettingEditForm" 
                  autocomplete="off" 
                  lazy-validation
                >
                  <v-row class="mt-3" dense>
                    <v-col>
                      <v-text-field
                        dense
                        outlined
                        label="設定名"
                        v-model="item.name"
                        :rules="[required]"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-textarea
                        dense
                        outlined
                        label="説明"
                        v-model="item.description"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </template>

            <template #apply>
              更新
            </template>
          </Dialog>
          <span v-tooltip="user.id != item.userId ? '作成者以外ロック切替不可' : null">
            <v-btn 
              icon 
              :color="color"
              :disabled="user.id != item.userId"
              @click.stop="switchLock(item)"
            >
              <v-icon v-if="item.isLocked">mdi-lock</v-icon>
              <v-icon v-else>mdi-lock-open-variant</v-icon>
            </v-btn>
          </span>
          <Dialog
            :color="color"
            icon
            width="480px"
            @apply="deleteSetting(item)"
            :openable="item.userName && !item.isLocked"
            :tooltip="getDeletionTooltip(item)"
          >
            <v-icon>mdi-delete</v-icon>
            <template #title>
              <div>
                除外軌道設定削除
              </div>
            </template>
            <template #contents>
              <v-container class="text-h6 mt-3">
                設定「{{ item.name }}」を削除しますか？
              </v-container>
            </template>
            <template #apply>
              削除
            </template>
          </Dialog>
        </v-list-item>
      </template>
    </v-select>

    <Dialog
      v-if="selectedSetting"
      :color="color"
      icon
      width="480px"
      @apply="saveSetting"
      :tooltip="'保存'"
      @open="initSaveSetting"
      :disabled="!savedSetting.name || savedSetting.name.length == 0"
    >
      <v-icon>mdi-content-save</v-icon>
      <template #title>
        除外軌道設定保存
      </template>
      <template #contents>
        <v-container class="pt-1">
          <v-radio-group 
            v-model="writingMode" 
            row
            dense
          >
            <v-radio
              label="上書保存"
              value="overwrite"
              :disabled="selectedSetting.isLocked"
            ></v-radio>
            <v-radio
              label="新規保存"
              value="new"
            ></v-radio>
          </v-radio-group>
          <v-alert
            type="info"
            dense 
            color="primary"
            v-if="selectedSetting.isLocked"
          >
            ロックされている設定は新規保存のみ
          </v-alert>

          <v-form 
            v-if="writingMode === 'new'"
            @submit.prevent
            ref="newGaitSettingSavingForm" 
            autocomplete="off" 
            lazy-validation
          >
            <v-row class="mt-3" dense>
              <v-col>
                <v-text-field
                  dense
                  outlined
                  label="設定名"
                  v-model="savedSetting.name"
                  :rules="[required]"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-textarea
                  dense
                  outlined
                  label="説明"
                  v-model="savedSetting.description"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </template>
      <template #apply>
        保存
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Dialog from "@components/parts/dialogs/Dialog.vue";

export default {
  components: {
    Dialog,
  },
  props: {
    value: {
      required: true,
    },
    gait: {
    },
    color: {
      default: 'primary',
    },
    leftTrajectories: {
      default: () => []
    },
    rightTrajectories: {
      default: () => []
    },
  },
  data: function(){
    return {
      isLoading: false,
      settings: [],
      newSetting: {
        name: '',
        description: '',
        default: 'auto',
      },
      defaultModeOptions: [
        {key: 'nothing', label: '除外無し'},
        {key: 'auto', label: '自動'},
      ],
      isNewSettingValid: false,
      required: value => !!value || "※必須",
      selectedSettingId: null,
      writingMode: null,
      savedSetting: {},
    }
  },
  computed: {
    ...mapState([
      'user',
    ]),
    selectedSetting: function(){
      return this.settings.find(s => s.id == this.selectedSettingId);
    },
    getDeletionTooltip: function(){
      return item => {
        if(!item.userName){
          return '自動作成は削除できません';
        }

        if(item.isLocked){
          return 'ロックされています';
        }

        return null;
      }
    },
    getEditTooltip: function(){
      return item => {
        if(!item.userName){
          return '自動作成は編集できません';
        }

        if(item.isLocked){
          return 'ロックされています';
        }

        return null;
      }
    },
    setting: {
      get() {
        return this.value;
      },
      set(newVal) {
        if (this.value !== newVal){
          this.$emit('input', newVal);
        }
      }
    },
    gaitId: function(){
      return this.gait?.id;
    },
  },
  watch: {
    gaitId: {
      handler(newVal){
        if(!newVal){
          this.settings = [];
          this.setting = null;
          return;
        }

        this.fetchSettings();
      },
      immediate: true,
    },
    newSetting: {
      handler(){
        this.isNewSettingValid = this.$refs.gaitSettingForm?.validate();
      },
      deep: true,
      immediate: true,
    },
    selectedSettingId: function(newVal){
      if(!newVal){
        this.setting = null;
        return;
      }
      
      this.axios.get(`/api/gait/trajectories_setting/${this.gaitId}/${newVal}`).then(response => {
        this.leftTrajectories.forEach((t, i) => {
          this.$set(t, 'isInvalid', response.data.setting.left.some(_i => _i - 1 == i));
        });

        this.rightTrajectories.forEach((t, i) => {
          this.$set(t, 'isInvalid', response.data.setting.right.some(_i => _i - 1 == i));
        });

        this.$emit('update-invalid');

        this.$forceUpdate();
      }).catch(error => {
        console.log(error);
        alert('除外軌道設定データ取得に失敗しました');
      })
    },
  },
  methods: {
    createNewSetting: function(){      
      this.$refs.selector.blur();
      this.isLoading = true;
      this.axios.post(`/api/gait/trajectories_setting/${this.gaitId}`, this.newSetting).then(response => {
        const setting = response.data.setting;
        this.settings.unshift(setting);
        this.selectedSettingId = setting.id;
      }).catch(error => {
        console.log(error);
        alert('除外軌道設定の新規作成に失敗しました');
      }).finally(() => {
        this.isLoading = false;
      })
    },
    fetchSettings: function(){
      this.isLoading = true;
      this.axios.get(`/api/gait/trajectories_settings/${this.gaitId}`).then(response => {
        this.settings = response.data.settings;
        if(this.settings.length > 0){
          this.selectedSettingId = this.settings[0].id;
        }
      }).catch(error => {
        console.log(error);
        alert('除外軌道設定一覧の取得に失敗しました');
      }).finally(() => {
        this.isLoading = false;
      })
    },
    switchLock: function(setting){
      this.isLoading = true;
      setting.isLocked = !setting.isLocked;
      this.axios.put(`/api/gait/trajectories_setting/${this.gaitId}/${setting.id}/locked`, setting).then(() => {

      }).catch(error => {
        console.log(error);
        alert('除外軌道設定ロック切替に失敗しました');
        setting.isLocked = !setting.isLocked;
      }).finally(() => {
        this.isLoading = false;
      })
    },
    updateSetting: function(setting){
      this.isLoading = true;
      this.axios.put(`/api/gait/trajectories_setting/${this.gaitId}/${setting.id}`, setting).then(() => {

      }).catch(error => {
        console.log(error);
        alert('除外軌道設定更新に失敗しました');
      }).finally(() => {
        this.isLoading = false;
      })
    },
    deleteSetting: function(setting){
      this.axios.delete(`/api/gait/trajectories_setting/${this.gaitId}/${setting.id}`).then(() => {
        this.settings = this.settings.filter(s => s.id != setting.id);

        if(this.selectedSettingId == setting.id){
          this.selectedSettingId = this.settings.length > 0 ? this.settings[0].id : null;
        }
      }).catch(error => {
        console.log(error);
        alert('除外軌道設定削除に失敗しました');
      })
    },
    saveSetting: function(){
      let setting = {
        left: [],
        right: [],
      }
      this.leftTrajectories.forEach((t, i) => {
        if(t.isInvalid){
          setting.left.push(i + 1);
        }
      });

      this.rightTrajectories.forEach((t, i) => {
        if(t.isInvalid){
          setting.right.push(i + 1);
        }
      });

      this.isLoading = true;
      if(this.writingMode === 'new'){
        this.axios.post(`/api/gait/trajectories_setting/${this.gaitId}`, {
          ...this.savedSetting,
          ...setting
        }).then(response => {
          const setting = response.data.setting;
          this.settings.unshift(setting);
          this.selectedSettingId = setting.id;
        }).catch(error => {
          console.log(error);
          alert('除外軌道設定の新規保存に失敗しました');
        }).finally(() => {
          this.isLoading = false;
        })
      }else{
        this.axios.put(`/api/gait/trajectories_setting/${this.gaitId}/${this.selectedSetting.id}/setting`, {
          ...this.selectedSetting,
          ...setting
        }).then(() => {
        }).catch(error => {
          console.log(error);
          alert('除外軌道設定の上書保存に失敗しました');
        }).finally(() => {
          this.isLoading = false;
        })
      }
    },
    initSaveSetting: function(){
      this.writingMode = this.selectedSetting.isLocked ? 'new' : 'overwrite';
      this.savedSetting = {
        name: this.selectedSetting.name,
        description: null,
      }
    },
  },
}
</script>