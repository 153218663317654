<template>
  <v-simple-table 
    ref="table"
    dense 
    fixed-header
    :height="`${tableHeight}px`"
    v-resize="resizeContainer"
  >
    <thead>
      <tr>
        <th :class="color"></th>
        <th 
          v-for="header in headers"
          class="text-center"
          :class="color"
          :key="`header${header}`"
        >
          {{ header }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="feature in features"
        :key="`feature-${feature.name}`"
      >
        <td>{{ feature.name }}</td>
        <td 
          v-for="(val, i) in feature.vals"
          :key="`${feature.name}-${i}`"
          class="text-center"
          :rowspan="i > 2 ? 2 : 1"
        >
          {{ val != null ? val.toFixed(2) : val }}
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import {
  getAnkleFeatures,
} from '@js/utils/StatisticsUtil.js';

export default {
  props: {
    leftTrajectories: {
      required: true,
    },
    rightTrajectories: {
      required: true,
    },
    selection: {
    },
    aspectRatio: {
      default: 1.92
    },
    color: {
      default: 'primary darken-4 white--text'
    }
  },
  data: function(){
    return {
      tableWidth: 0,
      headers: [
        '平均',
        'CV',
        'SD',

        'GA',
        '左/右',
        'SI',
        'SA'
      ],
    }
  },
  computed: {
    tableHeight: function(){
      return parseInt(this.tableWidth/this.aspectRatio);
    },
    validLeftTrajectoires: function(){
      let _from = this.selection?.from ?? 0;
      let _to = this.selection?.to ?? Number.MAX_SAFE_INTEGER;

      return this.leftTrajectories.filter(t => !t.isInvalid && _from <= t.positions[0].index && t.positions.slice(-1)[0].index < _to);
    },
    validRightTrajectoires: function(){
      let _from = this.selection?.from ?? 0;
      let _to = this.selection?.to ?? Number.MAX_SAFE_INTEGER;

      return this.rightTrajectories.filter(t => !t.isInvalid && _from <= t.positions[0].index && t.positions.slice(-1)[0].index < _to);
    },

    features: function(){
      return getAnkleFeatures(
        this.validLeftTrajectoires,
        this.validRightTrajectoires,
      );
    },
  },
  watch: {
    selection: {
      handler(){
        this.$forceUpdate();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted: function(){
    this.$nextTick(() => {
      this.resizeContainer();
    })
  },
  methods:{
    resizeContainer: function(){
      this.$nextTick(() => {
        this.tableWidth = this.$refs.table?.$el?.clientWidth ?? 0;
      });
    },
  },
}
</script>

<style lang="scss" scoped>
tr:hover {
  background-color: transparent !important;
}
</style>