<template>
  <v-row dense>
    <v-col class="mt-3" cols="12">
      <v-row dense>
        <v-col 
          cols="3" 
          class="karte-cell header"
          :class="{
            neutral: comparableId,
            comparable: isComparableColor,
          }"
        ></v-col>
        <v-col
          v-for="coordinate in coordinates"
          class="karte-cell header text-center text-caption"
          :class="{
            neutral: comparableId,
            comparable: isComparableColor,
          }"
          :key="`coordinate${coordinate.key}`"
        >
          {{ coordinate.label }}
        </v-col>
      </v-row>

      <v-row dense v-if="comparableId && comparableGeometricFeatures">
        <v-col 
          cols="3" 
          class="karte-cell header neutral"
          style="border-top: none;"
        ></v-col>
        <template v-for="coordinate in coordinates">
          <v-col
            class="karte-cell header text-center text-caption"
            :key="`coordinate${coordinate.key}-${id}`"
          >
            ID: {{ id }}
          </v-col>
          <v-col
            class="karte-cell comparable header text-center text-caption"
            :key="`coordinate${coordinate.key}-comparable${comparableId}`"
          >
            ID: {{ comparableId }}
          </v-col>
          <v-col
            class="karte-cell neutral header text-center text-caption"
            :key="`coordinate${coordinate.key}-ratio-${id}-${comparableId}`"
          >
            ID: {{ id }}/{{ comparableId }}
          </v-col>
        </template>
      </v-row>

      <v-row
        dense
        v-for="(feature, featureIndex) in geometricFeatures"
        :key="`geometricFeature${feature.name}`"
      >
        <v-col 
          cols="3" 
          class="karte-cell text-start text-caption"
          :class="{
            comparable: isComparableColor,
          }"
        >
          {{ feature.name}}
        </v-col>
        <template v-for="(val, i) in feature.vals">
          <v-col
            :key="`${feature.name}-${i}`"
            class="karte-cell text-center text-caption"
          >
            {{ val != null ? val.toExponential(2) : null }}
          </v-col>

          <template v-if="comparableGeometricFeatures">
            <v-col
              :key="`${feature.name}-comparable${i}`"
              class="karte-cell text-center text-caption"
            >
              {{ comparableGeometricFeatures[featureIndex].vals[i] != null ? comparableGeometricFeatures[featureIndex].vals[i].toExponential(2) : null }}
            </v-col>

            <v-col
              :key="`${feature.name}-ratio-${i}`"
              class="karte-cell text-center text-caption"
            >
              {{ comparableGeometricFeatures[featureIndex].vals[i] != null && val != null ? (val/comparableGeometricFeatures[featureIndex].vals[i]).toExponential(2) : null }}
            </v-col>
          </template>
        </template>
      </v-row>
    </v-col>

    <v-col class="mt-3" cols="12" v-if="!comparableId">
      <v-row dense>
        <v-col 
          cols="3" 
          class="karte-cell header"
          :class="{
            neutral: comparableId,
            comparable: isComparableColor,
          }"
        ></v-col>
        <v-col
          v-for="coordinate in coordinates"
          class="karte-cell header text-center text-caption"
          :class="{
            comparable: isComparableColor,
          }"
          :key="`coordinate${coordinate.key}`"
        >
          {{ coordinate.label }}
        </v-col>
      </v-row>

      <v-row dense>
        <v-col 
          cols="3" 
          class="karte-cell header"
          :class="{
            neutral: comparableId,
            comparable: isComparableColor,
          }"
          style="border-top: none;"
        ></v-col>
        <template v-for="coordinate in coordinates">
          <v-col
            v-for="directionType in visibleDirectionTypes"
            class="karte-cell header text-center text-caption"
            :class="{
              comparable: isComparableColor,
            }"
            :key="`coordinate${coordinate.key}-directionType${directionType.key}`"
          >
            {{ directionType.label }}
          </v-col>
        </template>
      </v-row>

      <v-row
        dense
        v-for="feature in directionalFeatures"
        :key="`geometricFeature${feature.name}`"
      >
        <v-col cols="3" class="karte-cell text-start text-caption">
          {{ feature.name}}
        </v-col>
        <v-col
          v-for="(val, i) in feature.vals"
          :key="`${feature.name}-${i}`"
          class="karte-cell text-center text-caption"
        >
          {{ val != null ? val.toExponential(2) : null }}
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import SwayFeaturesTable from "@components/parts/tables/SwayFeaturesTable.vue";
import { 
  getSwayGeometricFeatures,
} from '@js/utils/StatisticsUtil.js';


export default {
  mixins: [
    SwayFeaturesTable,
  ],
  props: {
    id: {
    },
    comparable: {
    },
    comparableId: {
    },
    comparableTrajectory: {
    },
    comparableSelection: {
    },
    comparableSpectrum: {
    },
    comparablePolygon: {
    },
  },
  data: function(){
    return {
    }
  },
  computed: {
    isComparableColor: function(){
      return this.comparable != null;
    },
    comparableGeometricFeatures: function(){
      if(!this.comparableTrajectory || !this.comparablePolygon || !this.comparableSelection){
        return null;
      }

      return getSwayGeometricFeatures(
        this.comparableTrajectory, 
        this.comparablePolygon, 
        this.comparableSelection,
        this.coordinates,
        this.visibleViewTypes
      );
    },
  },
  watch: {
  },
  mounted: function(){
  },
  methods:{
  },
}
</script>

<style lang="scss" scoped>
tr:hover {
  background-color: transparent !important;
}
</style>