<template>
  <v-col v-show="visible" v-bind="$attrs">
    <v-row v-if="noMenu == null">
      <v-chip
        class="ml-3"
        :color="color"
        outlined
      >
        {{ title }}
      </v-chip>

      <v-btn 
        @click="refreshCamera" 
        icon 
        :color="color"
        class="ml-2"
        v-tooltip="'視点初期化'"
      >
        <v-icon>mdi-eye-refresh</v-icon>
      </v-btn>
      <v-btn 
        @click="viewPoint = 'side'" 
        icon 
        :color="viewPoint === 'side' ? 'white' : color"
        active
        class="ml-2"
        :class="{
          [color]: viewPoint === 'side'
        }"
        v-tooltip="'前後・上下'"
      >
        <v-icon>mdi-axis-x-arrow</v-icon>
      </v-btn>

      <v-btn 
        @click="viewPoint = 'front'" 
        icon 
        :color="viewPoint === 'front' ? 'white' : color"
        class="ml-2"
        :class="{
          [color]: viewPoint === 'front'
        }"
        v-tooltip="'左右・上下'"
      >
        <v-icon>mdi-axis-y-arrow</v-icon>
      </v-btn>

      <v-btn 
        @click="viewPoint = 'top'" 
        icon
        :color="viewPoint === 'top' ? 'white' : color"
        class="ml-2"
        :class="{
          [color]: viewPoint === 'top'
        }"
        v-tooltip="'左右・前後'"
      >
        <v-icon>mdi-axis-z-arrow</v-icon>
      </v-btn>


      <v-btn
        @click="setNextPolygonMode"
        icon
        :color="color"
        class="ml-2"
        v-tooltip="`領域描画切替（4点・8点・なし）`"
      >
        <v-icon 
          v-if="polygonMode === 'tetra'"
          style="transform: rotate(45deg);"
        >
          mdi-square-outline
        </v-icon>
        <v-icon 
          v-else-if="polygonMode === 'octa'"
          style="transform: rotate(22.5deg);"
        >
          mdi-octagon-outline
        </v-icon>
        <v-icon v-else>mdi-close-outline</v-icon>
      </v-btn>

      <v-btn 
        @click="isGridVisible = !isGridVisible" 
        icon 
        :color="color" 
        class="ml-2"
      >
        <v-icon>{{ isGridVisible ? 'mdi-grid' : 'mdi-grid-off' }}</v-icon>
      </v-btn>

      <v-btn 
        @click="zoomInGraph" 
        icon 
        :color="color" 
        class="ml-2"
      >
        <v-icon>mdi-magnify-plus</v-icon>
      </v-btn>
      
      <v-btn 
        @click="zoomOutGraph" 
        icon 
        :color="color" 
        class="ml-2"
      >
        <v-icon>mdi-magnify-minus</v-icon>
      </v-btn>
    </v-row>
    <v-row>
      <v-col class="pt-1">
        <SwayTrajectoryGraph
          :id="id"
          ref="graph"
          :line-scale="lineScale"
          :use="use"
          :selection="selection"
          dragging-mode="translation"
          :trajectory="trajectory"
          :grid-mode="viewPoint"
          v-bind="{
            ...graphScale,
            ...initialViewAngle,
          }"
          :grid="isGridVisible"
          :vertical-offset="verticalOffset"
          :horizontal-offset="horizontalOffset"
          :polygon-data="polygonData"

          :polygon-mode="polygonMode"
          :aspect-ratio="aspectRatio"

          :line-opacity="lineOpacity"
          :polygon-opacity="polygonOpacity"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import SwayTrajectoryGraph from '@components/parts/graph/SwayTrajectoryGraph.vue'

export default {
  components: {
    SwayTrajectoryGraph,
  },
  props: {
    id: {},
    visible: {
      default: true,
    },
    trajectory: {
      required: true,
    },
    lineScale: {
      default: 1,
    },
    color: {
      default: 'secondary'
    },
    title: {
    },
    use: {
      required: true,
    },
    selection: {
      required: true,
    },
    polygonData: {
    },
    noMenu: {
      default: null,
    },
    aspectRatio: {
      default: 1
    },
    initialViewPoint: {
      default: null,
    },
    lineOpacity: {
      default: 0.25,
    },
    polygonOpacity: {
      default: 0.35,
    },
  },
  data: function(){
    return {
      isGridVisible: true,
      viewPoint: 'top', // top | side | front
      polygonMode: 'tetra',
    }
  },
  watch: {
    initialViewPoint: {
      handler(newVal){
        if(!newVal){
          return;
        }
        this.viewPoint = newVal;
      },
      immediate: true,
    }
  },
  computed: {
    verticalOffset: function(){
      switch(this.viewPoint){
        case 'top':
          return -this.lineScale*this.trajectory.features.average.z;
        case 'front':
          return -this.lineScale*this.trajectory.features.average.y;
        case 'side':
          return -this.lineScale*this.trajectory.features.average.y;
      }
      return 0;
    },
    horizontalOffset: function(){
      switch(this.viewPoint){
        case 'top':
          return -this.lineScale*this.trajectory.features.average.x;
        case 'front':
          return this.lineScale*this.trajectory.features.average.z;
        case 'side':
          return -this.lineScale*this.trajectory.features.average.x;
      }
      return 0;
    },
    graphScale: function(){
      let x = 1;
      let y = 1;
      let z = 1;
      switch(this.viewPoint){
        case 'top':
          y = 0;
          break;
        case 'front':
          x = 0;
          break;
        case 'side':
          z = 0;
          break;
      }
      return {
        scaleX: x,
        scaleY: y,
        scaleZ: z,
      }
    },
    initialViewAngle: function(){
      let roll = 0;
      let pitch = 0;
      let yaw = 0;
      switch(this.viewPoint){
        case 'top':
          roll = 90;
          break;
        case 'front':
          pitch = 90;
          break;          
      }

      return {
        initialRoll: roll,
        initialPitch: pitch,
        initialYaw: yaw,
      }
    },
  },
  methods: {
    refreshCamera: function(){
      if(!this.$refs.graph){
        return;
      }
      this.$refs.graph.refreshCamera();
    },
    setNextPolygonMode: function(){
      switch(this.polygonMode){
        case 'tetra':
          this.polygonMode = 'octa';
          break;
        case 'octa':
          this.polygonMode = 'none';
          break;
        default:
        this.polygonMode = 'tetra';
      }
    },
    zoomInGraph: function(){
      this.$refs.graph?.zoomIn();
    },
    zoomOutGraph: function(){
      this.$refs.graph?.zoomOut();
    },
  }

}
</script>