<template>
  <v-col v-show="visible" v-bind="$attrs">
    <v-row v-if="noMenu == null">
      <v-chip
        class="ml-3"
        :color="color"
        outlined
      >
        {{ title }}
      </v-chip>

      <v-btn 
        @click="refreshCamera" 
        icon 
        :color="color"
        class="ml-2"
        v-tooltip="'視点初期化'"
      >
        <v-icon>mdi-eye-refresh</v-icon>
      </v-btn>

      <template v-if="plane == null">
        <v-btn 
          @click="setCameraAngle(0, 0, 0)" 
          icon 
          :color="color"
          active
          class="ml-2"
          v-tooltip="'側面'"
        >
          <v-icon>mdi-axis-x-arrow</v-icon>
        </v-btn>

        <v-btn 
          @click="setCameraAngle(0, 90, 0)" 
          icon 
          :color="color"
          class="ml-2"
          v-tooltip="'背面'"
        >
          <v-icon>mdi-axis-y-arrow</v-icon>
        </v-btn>

        <v-btn 
          @click="setCameraAngle(90, 0, 0)" 
          icon
          :color="color"
          class="ml-2"
          v-tooltip="'上面'"
        >
          <v-icon>mdi-axis-z-arrow</v-icon>
        </v-btn>

        <v-btn 
          @click="isPerspective=!isPerspective" 
          icon 
          :color="color"
          class="ml-2"
          v-tooltip="'透視投影/平行投影'"
        >
          <svg v-if="isPerspective" style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M20,15.5C20,15.88 19.79,16.21 19.47,16.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L4.53,16.38C4.21,16.21 4,15.88 4,15.5L3,7.5C3,7.12 3.21,6.79 3.53,6.62L11.43,4.18C11.59,4.06 11.79,4 12,4C12.21,4 12.41,4.06 12.57,4.18L20.47,6.62C20.79,6.79 21,7.12 21,7.5L20,16.5M12,6L6.04,7.5L12,10.85L17.96,7.5M6,14.91L11,19.29V12.58L5,9.21M18,14.91L19,9.21L13,12.58V19.25Z" />
          </svg>
          <v-icon v-else>mdi-cube-outline</v-icon>
        </v-btn>

      </template>
      
      <v-btn 
        @click="isGridVisible = !isGridVisible" 
        icon 
        :color="color" 
        class="ml-2"
        v-tooltip="gridCaption"
      >
        <v-icon>{{ isGridVisible ? 'mdi-grid' : 'mdi-grid-off' }}</v-icon>
      </v-btn>

      <v-btn 
        @click="zoomInGraph" 
        icon 
        :color="color" 
        class="ml-2"
      >
        <v-icon>mdi-magnify-plus</v-icon>
      </v-btn>
      
      <v-btn 
        @click="zoomOutGraph" 
        icon 
        :color="color" 
        class="ml-2"
      >
        <v-icon>mdi-magnify-minus</v-icon>
      </v-btn>

      <v-btn 
        v-if="averaged == null"
        @click="isEditing = !isEditing" 
        icon 
        :color="isEditing ? 'white' : color" 
        class="ml-2"
        :class="{
          [color]: isEditing,
        }"
        v-tooltip="'除外軌道設定（軌道をクリックで切替）'"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </v-row>
    <v-row>
      <v-col class="pt-1">
        <AnkleTrajectoryGraph
          ref="graph"
          :id="id"
          :gait-id="gait.id"
          :loading="loading"
          :left-trajectories="leftTrajectories"
          :right-trajectories="rightTrajectories"
          :selection="selection"
          :vertical-offset="verticalOffset"
          :horizontal-offset="horizontalOffset"
          :scale-x="scaleX"
          :scale-y="scaleY"
          :scale-z="scaleZ"
          :perspective="isPerspective"
          :grid="isGridVisible"
          :line-opacity="lineOpacity"

          :initial-roll="initialRoll"
          :initial-pitch="initialPitch"
          :initial-yaw="initialYaw"
          :dragging-mode="draggingMode"
          :grid-mode="gridMode"
          :initial-camera-position="initialCameraPosition"
          :max-camera-position="maxCameraPosition"
          :min-camera-position="minCameraPosition"
          :aspect-ratio="aspectRatio"
          :averaged="averaged"

          :editing="isEditing"
          @update-invalid="$emit('update-invalid')"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import AnkleTrajectoryGraph from '@components/parts/graph/AnkleTrajectoryGraph.vue'

export default {
  components: {
    AnkleTrajectoryGraph,
  },
  props: {
    id: {},
    gait: {
      required: true,
    },
    loading: {
      default: false,
    },
    visible: {
      default: true,
    },
    color: {
      default: 'secondary'
    },
    title: {
    },
    leftTrajectories: {
      default: () => [],
    },
    rightTrajectories: {
      default: () => [],
    },
    verticalOffset: {
      default: 0,
    },
    horizontalOffset: {
      default: 0,
    },
    scaleX: {
      default: 1,
    },
    scaleY: {
      default: 1,
    },
    scaleZ: {
      default: 1,
    },

    plane: {
      defaut: null,
    },
    
    selection: {
      default: null,
    },
    noMenu: {
      default: null,
    },
    perspective: {
      default: null,
    },
    lineOpacity: {
      default: 0.65,
    },
    gridCaption: {
      default: '1目盛 - 10cm',
    },


    initialRoll: {
      default: 25,
    },
    initialPitch: {
      default: 45,
    },
    initialYaw: {
      default: 0,
    },
    draggingMode: {
      default: 'rotation',
    },

    gridMode: {
      default: 'all', // top | side | front | all
    },
    initialCameraPosition: {
      default: 1.5,
    },
    maxCameraPosition: {
      default: 3.0,
    },
    minCameraPosition: {
      default: 0.5,
    },
    aspectRatio: {
      default: 1.92
    },
    averaged: {
      default: null,
    },
  },
  data: function(){
    return {
      isEditing: false,
      isPerspective: false,
      isGridVisible: true,
    }
  },
  watch: {
    perspective: {
      handler(newVal){
        this.isPerspective = newVal != null;
      },
      immediate: true,
    },
  },
  computed: {
  },
  methods: {
    refreshCamera: function(){
      if(!this.$refs.graph){
        return;
      }
      this.$refs.graph.refreshCamera();
    },
    zoomInGraph: function(){
      this.$refs.graph?.zoomIn();
    },
    zoomOutGraph: function(){
      this.$refs.graph?.zoomOut();
    },
    setCameraAngle: function(roll, pitch, yaw){
      this.$refs.graph?.setCameraAngle(roll, pitch, yaw);
    },
  }

}
</script>