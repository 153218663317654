<template>
  <v-simple-table
    ref="table"
    :height="aspectRatio ? `${tableHeight}px` : null"
    v-resize="resizeContainer"
    dense
  >
    <SwayFeaturesTableBody
      :only="only"
      :color="color"
      :coordinates="coordinates"
      :view-types="visibleViewTypes"
      :direction-types="visibleDirectionTypes"
      :geometric-features="geometricFeatures"
      :directional-features="directionalFeatures"
    />
  </v-simple-table>
</template>

<script>
import { 
  getSwayGeometricFeatures,
  getSwayDirectionalFeatures,
} from '@js/utils/StatisticsUtil.js';

import SwayFeaturesTableBody from "@components/parts/tables/SwayFeaturesTableBody.vue";

export default {
  components: {
    SwayFeaturesTableBody,
  },
  props: {
    trajectory: {
      required: true,
    },
    selection: {
    },
    spectrum: {
    },
    polygon: {
    },
    aspectRatio: {
      default: 3.84
    },
    color: {
      default: 'primary darken-4 white--text'
    },
    only: {
      default: null,
    },
  },
  data: function(){
    return {
      tableWidth: 0,
      coordinates: [
        {key: 'coordinate', label: '位置'},
        {key: 'velocity', label: '速度'},
        {key: 'acceleration', label: '角速度'},
      ],
      viewTypes: [
        {key: 'side', label: '前後・上下'},
        {key: 'front', label: '左右・上下'},
        {key: 'top', label: '左右・前後'},
      ],
      directionTypes: [
        {key: 'x', label: '左右'},
        {key: 'y', label: '上下'},
        {key: 'z', label: '前後'},
      ],
    }
  },
  computed: {
    visibleViewTypes: function(){
      if(!this.only){
        return this.viewTypes;
      }

      return this.viewTypes.filter(v => v.key === this.only);
    },
    visibleDirectionTypes: function(){
      if(!this.only){
        return this.directionTypes;
      }

      switch(this.only){
        case 'top':
          return [
            {key: 'x', label: '左右'},
            {key: 'z', label: '前後'},
          ];
        case 'side':
          return [
            {key: 'y', label: '上下'},
            {key: 'z', label: '前後'},
          ];
        case 'front':
          return [
            {key: 'x', label: '左右'},
            {key: 'y', label: '上下'},
          ];
      }

      return this.directionType
    },
    tableHeight: function(){
      return this.aspectRatio ? parseInt(this.tableWidth/this.aspectRatio) : 0;
    },
    directionalFeatures: function(){
      return getSwayDirectionalFeatures(
        this.trajectory,
        this.spectrum,
        this.selection,
        this.coordinates,
        this.visibleDirectionTypes
      );
    },
    geometricFeatures: function(){
      return getSwayGeometricFeatures(
        this.trajectory, 
        this.polygon, 
        this.selection,
        this.coordinates,
        this.visibleViewTypes
      );
    },
  },
  watch: {
    selection: {
      handler(){
        this.$forceUpdate();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted: function(){
    this.$nextTick(() => {
      this.resizeContainer();
    })
  },
  methods:{
    resizeContainer: function(){
      this.$nextTick(() => {
        this.tableWidth = this.$refs.table?.$el?.clientWidth ?? 0;
      });
    },
  },
}
</script>

<style lang="scss" scoped>
tr:hover {
  background-color: transparent !important;
}
</style>