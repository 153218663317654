<template>
  <v-container fluid class="fill-height pt-0 pr-0">
    <template v-if="gait">
      <VglDefs>
        <template v-slot:[`averagedLeftTrajectory-${gait.id}`]>
          <AnkleTrajectoryGeometry
            :trajectory="averagedLeftTrajectory"
            :horizontal-offset="ankleHorizontalOffset"
            :vertical-offset="ankleVerticalOffset"
          />
        </template>

        <template v-slot:[`averagedRightTrajectory-${gait.id}`]>
          <AnkleTrajectoryGeometry
            :trajectory="averagedRightTrajectory"
            :horizontal-offset="ankleHorizontalOffset"
            :vertical-offset="ankleVerticalOffset"
          />
        </template>

        <template v-slot:[`averagedTrunkTrajectory-${gait.id}`]>
          <AnkleTrajectoryGeometry
            :trajectory="averagedTrunkTrajectory"
            :horizontal-offset="0"
            :vertical-offset="0"
          />
        </template>

        <template v-if="comparableGait" v-slot:[`averagedComparableLeftTrajectory-${comparableGait.id}`]>
          <AnkleTrajectoryGeometry
            :trajectory="comaparbleAveragedLeftTrajectory"
            :horizontal-offset="comparableAnkleHorizontalOffset"
            :vertical-offset="comparableAnkleVerticalOffset"
          />
        </template>

        <template v-if="comparableGait" v-slot:[`averagedComparableRightTrajectory-${comparableGait.id}`]>
          <AnkleTrajectoryGeometry
            :trajectory="comaparbleAveragedRightTrajectory"
            :horizontal-offset="comparableAnkleHorizontalOffset"
            :vertical-offset="comparableAnkleVerticalOffset"
          />
        </template>

        <template v-if="comparableGait" v-slot:[`averagedComparableTrunkTrajectory-${comparableGait.id}`]>
          <AnkleTrajectoryGeometry
            :trajectory="comaparbleAveragedTrunkTrajectory"
            :horizontal-offset="0"
            :vertical-offset="0"
          />
        </template>


        


        <template 
          v-for="(trajectory, i) in leftTrajectories" 
          v-slot:[`leftTrajectory${i}-${gait.id}`]
        >
          <AnkleTrajectoryGeometry
            :trajectory="trajectory"
            :horizontal-offset="ankleHorizontalOffset"
            :vertical-offset="ankleVerticalOffset"
            :key="`leftTrajectory${i}`"
            :selection="selectedGaitDuration"
          />
        </template>

        <template 
          v-for="(trajectory, i) in rightTrajectories" 
          v-slot:[`rightTrajectory${i}-${gait.id}`]
        >
          <AnkleTrajectoryGeometry
            :trajectory="trajectory"
            :horizontal-offset="ankleHorizontalOffset"
            :vertical-offset="ankleVerticalOffset"
            :key="`rightTrajectory${i}`"
            :selection="selectedGaitDuration"
          />
        </template>
      </VglDefs>

      <VglDefs v-if="comparableGait">
        <template 
          v-for="(trajectory, i) in comparableLeftTrajectories" 
          v-slot:[`leftTrajectory${i}-${comparableGait.id}`]
        >
          <AnkleTrajectoryGeometry
            :trajectory="trajectory"
            :horizontal-offset="comparableAnkleHorizontalOffset"
            :vertical-offset="comparableAnkleVerticalOffset"
            :key="`leftTrajectory${i}`"
            :selection="selectedComparableGaitDuration"
          />
        </template>

        <template 
          v-for="(trajectory, i) in comparableRightTrajectories" 
          v-slot:[`rightTrajectory${i}-${comparableGait.id}`]
        >
          <AnkleTrajectoryGeometry
            :trajectory="trajectory"
            :horizontal-offset="comparableAnkleHorizontalOffset"
            :vertical-offset="comparableAnkleVerticalOffset"
            :key="`rightTrajectory${i}`"
            :selection="selectedComparableGaitDuration"
          />
        </template>
      </VglDefs>

      <v-row class="px-3 mt-1" style="height: 100%; overflow: hidden;">
        <v-col
          v-if="isSideMenuOpened"
          cols="12"
          md="4"
          lg="3"
          class="pa-0 pr-3 d-flex flex-column"
        >
          <div class="d-flex align-center">
            <v-btn 
              icon
              large
              color="primary" 
              @click="fetchAll"
              class="mb-1"
            >
              <v-icon>mdi-reload</v-icon>
            </v-btn>
            <v-spacer />
            <v-btn
              v-if="false"
              depressed
              large
              color="primary" 
              @click="isSideMenuOpened = false"
            >
              <v-icon>mdi-cog</v-icon>
              <v-icon>mdi-arrow-collapse-left</v-icon>
            </v-btn>
          </div>
          <simplebar style="flex: 1; height: 0; overflow-x: hidden;">
            <v-card 
              class="pa-0 mt-2 elevation-0"
              outlined
            >
              <v-card-title 
                class="primary darken-3 white--text py-1"
              >
                ID: {{ gait.id }}
                <v-spacer/>
                <Dialog 
                  color="accent"
                  ref="gaitReportSelectorDiablog"
                  small
                >
                  <v-icon>mdi-swap-horizontal</v-icon>
                  <template #title>歩行分析レポート切替</template>
                  <template #contents>
                    <GaitsTable
                      class="mt-3"
                      height="calc(100vh - 320px)"
                      :gaits="gaits"
                      elevation="0"
                      selectable
                      :filter-params="{
                        isDeleted: false,
                      }"
                      :invisible-headers-values="[
                        'isDeleted',
                      ]"
                      @select="
                        $refs.gaitReportSelectorDiablog.close(); 
                        selectGaitReport($event);
                      "
                    />
                  </template>
                  <template #actions>
                    <span></span>
                  </template>
                </Dialog>
                
              </v-card-title>
              <v-card-text>
                <v-container class="px-0 pb-0">
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        label="レポート名"
                        v-model="gait.name"
                        hide-details
                        @change="updateReportAttribute('name')"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-textarea
                        label="説明"
                        class="mt-3"
                        v-model="gait.description"
                        @change="updateReportAttribute('description')"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-select
                        label="有効/無効"
                        v-model="gait.isDeleted"
                        dense
                        hide-details
                        @change="updateReportAttribute('isDeleted')"
                        :items="[
                          {label: '有効', value: false},
                          {label: '無効', value: true}
                        ]"
                        item-text="label"
                        item-value="value"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        label="作成日時"
                        v-model="gait.createdTime"
                        class="mt-5"
                        dense
                        hide-details
                        readonly
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="6">
                      <v-text-field
                        label="緯度"
                        v-model="gait.latitude"
                        class="mt-5"
                        dense
                        hide-details
                        readonly
                        outlined
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        label="経度"
                        v-model="gait.longitude"
                        class="mt-5"
                        dense
                        hide-details
                        readonly
                        outlined
                      />
                    </v-col>
                  </v-row>
                  
                  <v-row dense>
                    <v-col>
                      <TrajectoriesSettingSelector
                        v-model="gaitTrajectoriesSetting"
                        :gait="gait"
                        :left-trajectories="leftTrajectories"
                        :right-trajectories="rightTrajectories"
                        @update-invalid="rerenderAnkleFeaturesCharts"
                      />
                    </v-col>
                  </v-row>
                  <v-btn
                    color="primary darken-2 my-2 py-1"
                    block
                    v-if="!isReportCommentsOpened"
                    @click="isReportCommentsOpened=true"
                  >
                    <v-icon>mdi-comment-text-multiple</v-icon>
                    <v-icon>mdi-unfold-more-horizontal</v-icon>
                  </v-btn>
                  <CommentsCard
                    class="pa-0 my-2 elevation-0"
                    :id="id"
                    target="gait"
                    outlined
                    v-if="isReportCommentsOpened"
                  >
                    <template #titleContainer>
                      <v-card-title 
                        class="primary darken-2 text-subtitle-1 white--text py-1 mb-3"
                        style="cursor: pointer;"
                        @click="isReportCommentsOpened=false"
                      >
                        <v-spacer></v-spacer>
                        <v-icon color="white">mdi-comment-text-multiple</v-icon>
                        <v-icon color="white">mdi-unfold-less-horizontal</v-icon>
                        <v-spacer></v-spacer>
                      </v-card-title>
                    </template>
                  </CommentsCard>
                  <v-row dense>
                    <v-col>
                      <v-btn
                        v-if="comparableId"
                        color="accent"
                        block
                        :to="`/gait/${id}`"
                      >
                        単一レポート
                      </v-btn>
                      <Dialog
                        v-else
                        color="accent"
                        block
                      >
                        レポート比較
                        <template #title>歩行分析比較レポート選択</template>
                        <template #contents>
                          <GaitsTable
                            class="mt-3"
                            height="calc(100vh - 320px)"
                            :gaits="gaits"
                            elevation="0"
                            selectable
                            :filter-params="{
                              isDeleted: false,
                            }"
                            :invisible-headers-values="[
                              'isDeleted',
                            ]"
                            @select="selectComparableGaitReport($event)"
                          />
                        </template>
                        <template #actions>
                          <span></span>
                        </template>
                      </Dialog>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>

            <v-card 
              class="pa-0 mt-2 elevation-0"
              outlined
              v-if="comparableId && comparableGait"
            >
              <v-card-title 
                class="tertiary darken-3 white--text py-1"
              >
                ID: {{ comparableGait.id }}
                <v-spacer/>
                <Dialog 
                  color="accent"
                  ref="comparableGaitReportSelectorDiablog"
                  small
                >
                  <v-icon>mdi-swap-horizontal</v-icon>
                  <template #title>歩行分析レポート切替</template>
                  <template #contents>
                    <GaitsTable
                      class="mt-3"
                      height="calc(100vh - 320px)"
                      :gaits="gaits"
                      elevation="0"
                      selectable
                      :filter-params="{
                        isDeleted: false,
                      }"
                      :invisible-headers-values="[
                        'isDeleted',
                      ]"
                      @select="
                        $refs.comparableGaitReportSelectorDiablog.close(); 
                        selectComparableGaitReport($event);
                      "
                    />
                  </template>
                  <template #actions>
                    <span></span>
                  </template>
                </Dialog>
                
              </v-card-title>
              <v-card-text>
                <v-container class="px-0 pb-0">
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        label="レポート名"
                        v-model="comparableGait.name"
                        hide-details
                        @change="updateComparableReportAttribute('name')"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-textarea
                        label="説明"
                        class="mt-3"
                        v-model="comparableGait.description"
                        @change="updateComparableReportAttribute('description')"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-select
                        label="有効/無効"
                        v-model="comparableGait.isDeleted"
                        dense
                        hide-details
                        @change="updateComparableReportAttribute('isDeleted')"
                        :items="[
                          {label: '有効', value: false},
                          {label: '無効', value: true}
                        ]"
                        item-text="label"
                        item-value="value"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        label="作成日時"
                        v-model="comparableGait.createdTime"
                        class="mt-5"
                        dense
                        hide-details
                        readonly
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="6">
                      <v-text-field
                        label="緯度"
                        v-model="comparableGait.latitude"
                        class="mt-5"
                        dense
                        hide-details
                        readonly
                        outlined
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        label="経度"
                        v-model="comparableGait.longitude"
                        class="mt-5"
                        dense
                        hide-details
                        readonly
                        outlined
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <TrajectoriesSettingSelector
                        v-model="comparableGaitTrajectoriesSetting"
                        color="tertiary"
                        :gait="comparableGait"
                        :left-trajectories="comparableLeftTrajectories"
                        :right-trajectories="comparableRightTrajectories"
                        @update-invalid="rerenderComparableAnkleFeaturesCharts"
                      />
                    </v-col>
                  </v-row>

                  <v-btn
                    color="tertiary darken-2 my-2 py-1"
                    block
                    v-if="!isComparableReportCommentsOpened"
                    @click="isComparableReportCommentsOpened=true"
                  >
                    <v-icon color="white">mdi-comment-text-multiple</v-icon>
                    <v-icon color="white">mdi-unfold-more-horizontal</v-icon>
                  </v-btn>
                  <CommentsCard
                    class="pa-0 my-2 elevation-0"
                    :id="comparableId"
                    target="gait"
                    outlined
                    v-if="isComparableReportCommentsOpened"
                  >
                    <template #titleContainer>
                      <v-card-title 
                        class="tertiary darken-2 text-subtitle-1 white--text py-1 mb-3"
                        style="cursor: pointer;"
                        @click="isComparableReportCommentsOpened=false"
                      >
                        <v-spacer></v-spacer>
                        <v-icon color="white">mdi-comment-text-multiple</v-icon>
                        <v-icon color="white">mdi-unfold-less-horizontal</v-icon>
                        <v-spacer></v-spacer>
                      </v-card-title>
                    </template>
                  </CommentsCard>

                  <v-row dense>
                    <v-col>
                      <v-btn
                        color="accent"
                        block
                        :to="`/gait/${comparableId}`"
                      >
                        単一レポート
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>

            <v-card>
              <v-card-text>
                <v-tabs 
                  v-model="currentSettingTabIndex" 
                >
                  <v-tab
                    v-for="tab in tabs"
                    :key="tab.key"
                  >{{ tab.label }}</v-tab>
                </v-tabs>

                <v-container class="px-0" v-if="currentSettingTab.key === 'karte'">
                  <v-row dense>
                    <v-col class="text-h6 d-flex align-center">
                      表示モード
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-select
                        dense
                        hide-details
                        v-model="karteMode"
                        :items="karteModeOptions"
                        item-text="label"
                        item-value="key"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>

                <v-container class="px-0" v-if="currentSettingTab.key === 'trunk'">
                  <v-row dense>
                    <v-col class="text-h6 d-flex align-center">
                      軌道グラフ透明度
                    </v-col>
                  </v-row>
                  
                  <v-row dense>
                    <v-col>
                      <v-slider
                        v-model="trunkTrajectoryLineOpacity"
                        dense
                        hide-details
                        :min="0"
                        :max="1"
                        :step="0.05"
                        class="align-center"
                      >
                        <template v-slot:append>
                          <div class="d-flex align-center">
                            <v-text-field
                              v-model="trunkTrajectoryLineOpacity"
                              dense
                              hide-details
                              :min="0"
                              :max="1"
                              :step="0.1"
                              class="mt-0 ml-1 pt-0"
                              type="number"
                              style="width: 48px"
                            ></v-text-field>
                          </div>
                        </template>
                      </v-slider>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col class="text-h6">
                      表示切替
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.gaitReport.trunk.graph"
                        label="腰軌道グラフ"
                        hide-details
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.gaitReport.trunk.features.table"
                        label="特徴量表"
                        hide-details
                      />
                    </v-col>
                  </v-row>

                  <v-row dense v-for="target in trunkFeatureTargets" :key="`trunk-feature-${target.attr}`">
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.gaitReport.trunk.features.graph[target.attr]"
                        :label="`特徴量グラフ ${target.name}`"
                        hide-details
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.gaitReport.trunk.sensor"
                        label="腰センサデータ"
                        hide-details
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.gaitReport.trunk.freezingIndexes[400]"
                        label="すくみ指数 4秒Hann窓"
                        hide-details
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.gaitReport.trunk.freezingIndexes[600]"
                        label="すくみ指数 6秒窓"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-container>

                <v-container class="px-0" v-if="currentSettingTab.key === 'ankle'">
                  <v-row dense>
                    <v-col class="text-h6 d-flex align-center">
                      軌道グラフ透明度
                    </v-col>
                  </v-row>
                  
                  <v-row dense>
                    <v-col>
                      <v-slider
                        v-model="ankleTrajectoryLineOpacity"
                        dense
                        hide-details
                        :min="0"
                        :max="1"
                        :step="0.05"
                        class="align-center"
                      >
                        <template v-slot:append>
                          <div class="d-flex align-center">
                            <v-text-field
                              v-model="ankleTrajectoryLineOpacity"
                              dense
                              hide-details
                              :min="0"
                              :max="1"
                              :step="0.1"
                              class="mt-0 ml-1 pt-0"
                              type="number"
                              style="width: 48px"
                            ></v-text-field>
                          </div>
                        </template>
                      </v-slider>
                    </v-col>
                  </v-row>


                  <v-row dense>
                    <v-col class="text-h6 d-flex align-center" v-tooltip="'歩行軌道の垂直方向は比較し易さの観点から倍率をかけることをお勧めしております'">
                      垂直方向拡大率
                      <v-icon class="ml-2">mdi-information</v-icon>
                    </v-col>
                  </v-row>
                  
                  <v-row dense>
                    <v-col>
                      <v-slider
                        v-model="ankleVerticalScale"
                        dense
                        hide-details
                        :min="1"
                        :max="5"
                        :step="0.1"
                        class="align-center"
                      >
                        <template v-slot:append>
                          <div class="d-flex align-center">
                            x
                            <v-text-field
                              v-model="ankleVerticalScale"
                              dense
                              hide-details
                              :min="1"
                              :max="5"
                              class="mt-0 ml-1 pt-0"
                              type="number"
                              style="width: 48px"
                            ></v-text-field>
                          </div>
                        </template>
                      </v-slider>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col class="text-h6">
                      表示切替
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.gaitReport.ankle.all"
                        label="自由視点軌道グラフ"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-switch
                        dense 
                        v-model="visibility.gaitReport.ankle.top"
                        label="上面軌道グラフ"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-switch
                        dense 
                        v-model="visibility.gaitReport.ankle.front"
                        label="正面軌道グラフ"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.gaitReport.ankle.side"
                        label="側面軌道グラフ"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-switch stride
                        dense
                        v-model="visibility.gaitReport.ankle.features.table"
                        label="特徴量表"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                  <v-row 
                    dense 
                    v-for="target in ankleFeatureTargets"
                    :key="`visiblity-${target.attr}`"
                  >
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.gaitReport.ankle.features.graph[target.attr]"
                        :label="`特徴量グラフ ${target.name}`"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.gaitReport.ankle.continuous.left"
                        label="左足首連続軌道グラフ"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.gaitReport.ankle.continuous.right"
                        label="右足首連続軌道グラフ"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.gaitReport.ankle.sensor.left"
                        label="左足首センサデータ"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.gaitReport.ankle.sensor.right"
                        label="右足首センサデータ"
                        hide-details
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.gaitReport.ankle.freezingIndexes.left['400']"
                        label="左足首すくみ指数 4秒Hann窓"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.gaitReport.ankle.freezingIndexes.left['600']"
                        label="左足首すくみ指数 6秒窓"
                        hide-details
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.gaitReport.ankle.freezingIndexes.right['400']"
                        label="右足首すくみ指数 4秒Hann窓"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-switch 
                        dense
                        v-model="visibility.gaitReport.ankle.freezingIndexes.right['600']"
                        label="右足首すくみ指数 6秒窓"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </simplebar>
        </v-col>
        <v-col 
          v-if="gait.isAnalyzed && !gait.isFailed"
          cols="12" 
          :md="isSideMenuOpened ? 8 : null" 
          :lg="isSideMenuOpened ? 9 : null"
          class="d-flex flex-column pb-0"
        >
          <v-row justify="start" align="start" style="flex: initial;" v-if="!isSideMenuOpened">
            <v-btn
              depressed
              large
              color="primary" 
              class="mr-3" 
              @click="isSideMenuOpened = true"
            >
              <v-icon>mdi-cog</v-icon>
              <v-icon>mdi-arrow-collapse-right</v-icon>
            </v-btn>

            <v-btn 
              icon 
              large 
              color="primary" 
              class="mr-3" 
              @click="fetchAll"
            >
              <v-icon>mdi-reload</v-icon>
            </v-btn>
            <v-col cols="2">
              <div>
                <v-text-field
                  class="text-h6"
                  v-model="gait.name"
                  dense
                  label="名前"
                  hide-details
                  @change="updateGait"
                />
              </div>
            </v-col>
            <v-col cols="6">
              <div>
                <v-text-field
                  class="text-h6"
                  v-model="gait.description"
                  dense
                  label="説明"
                  hide-details
                  @change="updateGait"
                />
              </div>
            </v-col>
            <v-col class="ml-auto mr-2" justify="center" align="end" cols="2">
              <v-text-field
                class="text-h6"
                v-model="gait.createdTime"
                dense
                label="作成日時"
                hide-details
                readonly
                outlined
              />
            </v-col>
          </v-row>

          <v-row class="d-flex align-center pr-3" style="flex: initial;">
            <v-tabs 
              v-model="currentTabIndex" 
              class="mb-5"
              background-color="primary darken-2"
              dark
            >
              <v-tab
                v-for="tab in tabs"
                :key="tab.key"
              >{{ tab.label }}</v-tab>

              <v-spacer />
              <v-btn 
                v-if="!comparableId"
                color="accent"
                small
                class="my-auto mr-3"
                @click="downloadReport"
                v-tooltip.left="'xlsxファイルダウンロード'"
                :disabled="isDownloading"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </v-tabs>
          </v-row>

          <simplebar ref="scrollbar" style="flex: 1; height: 0; overflow-x: hidden; margin-left: -12px; margin-top: -8px;">
            <v-container 
              fluid 
              class="pt-3 pr-5"
              v-if="currentTab.key === 'ankle'"
            >
              <v-row class="mt-0">
                <GaitTrajectoryGraphCard
                  :title="`自由視点${comparableId ? ` - ID: ${id}` : `` }`"
                  id="3D"
                  color="primary"
                  v-bind="graphCols"
                  :visible="visibility.gaitReport.ankle.all"
                  :gait="gait"
                  :loading="isAnkleTrajectoriesDataGenerating"
                  :left-trajectories="leftTrajectories"
                  :right-trajectories="rightTrajectories"
                  :selection="selectedGaitDuration"
                  :vertical-offset="ankleVerticalOffset"
                  :horizontal-offset="ankleHorizontalOffset"
                  :scale-y="ankleVerticalScale"
                  :perspective="true"
                  :line-opacity="ankleTrajectoryLineOpacity"

                  @update-invalid="rerenderAnkleFeaturesCharts"
                />

                <GaitTrajectoryGraphCard
                  v-if="comparableGait"
                  id="comparable3D"
                  :title="`自由視点 - ID: ${comparableId}`"
                  color="tertiary"
                  v-bind="graphCols"
                  :visible="visibility.gaitReport.ankle.all && comparableId"
                  :gait="comparableGait"
                  :loading="isComparableAnkleTrajectoriesDataGenerating"
                  :left-trajectories="comparableLeftTrajectories"
                  :right-trajectories="comparableRightTrajectories"
                  :selection="selectedComparableGaitDuration"
                  :vertical-offset="comparableAnkleVerticalOffset"
                  :horizontal-offset="comparableAnkleHorizontalOffset"
                  :scale-y="ankleVerticalScale"
                  :perspective="true"
                  :line-opacity="ankleTrajectoryLineOpacity"
                  @update-invalid="rerenderComparableAnkleFeaturesCharts"
                />

                <GaitTrajectoryGraphCard
                  :visible="visibility.gaitReport.ankle.top"
                  :title="`上面${comparableId ? ` - ID: ${id}` : `` }`"
                  v-bind="graphCols"
                  id="top"
                  :gait="gait"
                  grid-mode="top"
                  color="primary"
                  plane
                  :loading="isAnkleTrajectoriesDataGenerating"
                  :left-trajectories="leftTrajectories"
                  :right-trajectories="rightTrajectories"
                  :selection="selectedGaitDuration"
                  :vertical-offset="ankleVerticalOffset"
                  :horizontal-offset="ankleHorizontalOffset"
                  :initial-roll="90"
                  :initial-pitch="0"
                  :initial-yaw="0"
                  :scale-y="0.01"
                  :initial-camera-position="1.0"
                  :line-opacity="ankleTrajectoryLineOpacity"
                  dragging-mode="translation"

                  @update-invalid="rerenderAnkleFeaturesCharts"
                />

                <GaitTrajectoryGraphCard
                  v-if="comparableGait"
                  :visible="visibility.gaitReport.ankle.top && comparableId"
                  :title="`上面 - ID: ${comparableId}`"
                  v-bind="graphCols"
                  id="comparableTop"
                  :gait="comparableGait"
                  grid-mode="top"
                  color="tertiary"
                  plane
                  :loading="isComparableAnkleTrajectoriesDataGenerating"
                  :left-trajectories="comparableLeftTrajectories"
                  :right-trajectories="comparableRightTrajectories"
                  :selection="selectedComparableGaitDuration"
                  :vertical-offset="comparableAnkleVerticalOffset"
                  :horizontal-offset="comparableAnkleHorizontalOffset"
                  :initial-roll="90"
                  :initial-pitch="0"
                  :initial-yaw="0"
                  :scale-y="0.01"
                  :initial-camera-position="1.0"
                  :line-opacity="ankleTrajectoryLineOpacity"
                  dragging-mode="translation"
                  @update-invalid="rerenderComparableAnkleFeaturesCharts"
                />

                <GaitTrajectoryGraphCard
                  :visible="visibility.gaitReport.ankle.front"
                  :title="`背面${comparableId ? ` - ID: ${id}` : `` }`"
                  v-bind="graphCols"
                  id="front"
                  :gait="gait"
                  grid-mode="front"
                  color="primary"
                  plane
                  :loading="isAnkleTrajectoriesDataGenerating"
                  :left-trajectories="leftTrajectories"
                  :right-trajectories="rightTrajectories"
                  :selection="selectedGaitDuration"
                  :vertical-offset="ankleVerticalOffset"
                  :horizontal-offset="ankleHorizontalOffset"
                  :initial-roll="0"
                  :initial-pitch="90"
                  :initial-yaw="0"
                  :scale-x="0.01"
                  :scale-y="ankleVerticalScale"
                  :initial-camera-position="1.0"
                  :min-camera-position="0.25"
                  :max-camera-position="1.5"
                  :line-opacity="ankleTrajectoryLineOpacity"
                  dragging-mode="translation"

                  @update-invalid="rerenderAnkleFeaturesCharts"
                />
                

                <GaitTrajectoryGraphCard
                  v-if="comparableGait"
                  :visible="visibility.gaitReport.ankle.front && comparableId"
                  :title="`背面 - ID: ${comparableId}`"
                  v-bind="graphCols"
                  id="comparableFront"
                  :gait="comparableGait"
                  grid-mode="front"
                  color="tertiary"
                  plane
                  :loading="isComparableAnkleTrajectoriesDataGenerating"
                  :left-trajectories="comparableLeftTrajectories"
                  :right-trajectories="comparableRightTrajectories"
                  :selection="selectedComparableGaitDuration"
                  :vertical-offset="comparableAnkleVerticalOffset"
                  :horizontal-offset="comparableAnkleHorizontalOffset"
                  :initial-roll="0"
                  :initial-pitch="90"
                  :initial-yaw="0"
                  :scale-x="0.01"
                  :scale-y="ankleVerticalScale"
                  :initial-camera-position="1.0"
                  :min-camera-position="0.25"
                  :max-camera-position="1.5"
                  :line-opacity="ankleTrajectoryLineOpacity"
                  dragging-mode="translation"
                  @update-invalid="rerenderComparableAnkleFeaturesCharts"
                />

                <GaitTrajectoryGraphCard
                  :visible="visibility.gaitReport.ankle.side"
                  :title="`側面${comparableId ? ` - ID: ${id}` : `` }`"
                  v-bind="graphCols"
                  id="side"
                  :gait="gait"
                  grid-mode="side"
                  color="primary"
                  plane
                  :loading="isAnkleTrajectoriesDataGenerating"
                  :left-trajectories="leftTrajectories"
                  :right-trajectories="rightTrajectories"
                  :selection="selectedGaitDuration"
                  :vertical-offset="ankleVerticalOffset"
                  :horizontal-offset="ankleHorizontalOffset"
                  :initial-roll="0"
                  :initial-pitch="0"
                  :initial-yaw="0"
                  :scale-y="ankleVerticalScale"
                  :scale-z="0.01"
                  :initial-camera-position="1.0"
                  :line-opacity="ankleTrajectoryLineOpacity"
                  dragging-mode="translation"

                  @update-invalid="rerenderAnkleFeaturesCharts"
                />

                <GaitTrajectoryGraphCard
                  v-if="comparableGait"
                  :visible="visibility.gaitReport.ankle.side && comparableId"
                  :title="`側面 - ID: ${comparableId}`"
                  v-bind="graphCols"
                  id="comparableSide"
                  :gait="comparableGait"
                  grid-mode="side"
                  color="tertiary"
                  plane
                  :loading="isComparableAnkleTrajectoriesDataGenerating"
                  :left-trajectories="comparableLeftTrajectories"
                  :right-trajectories="comparableRightTrajectories"
                  :selection="selectedComparableGaitDuration"
                  :vertical-offset="comparableAnkleVerticalOffset"
                  :horizontal-offset="comparableAnkleHorizontalOffset"
                  :initial-roll="0"
                  :initial-pitch="0"
                  :initial-yaw="0"
                  :scale-y="ankleVerticalScale"
                  :scale-z="0.01"
                  :initial-camera-position="1.0"
                  :line-opacity="ankleTrajectoryLineOpacity"
                  dragging-mode="translation"
                  @update-invalid="rerenderComparableAnkleFeaturesCharts"
                />

                <v-col 
                  v-bind="graphCols" 
                  v-if="visibility.gaitReport.ankle.features.table && currentTab.key === 'ankle'"
                >
                  <v-row class="d-flex align-center">
                    <v-chip
                      class="ml-3 my-0"
                      :color="comparableId ? 'primary darken-1' : 'secondary'"
                      outlined
                    >
                      特徴量{{ comparableId ? ` - ID: ${id}` : null }}
                    </v-chip>

                    <v-spacer/>

                    <v-btn 
                      fab 
                      x-small 
                      color="accent" 
                      class="mr-3" 
                      depressed
                      v-tooltip="'現在の選択範囲の特徴量を登録<br/>※計測参加者詳細 「時系列グラフ」タブの表示データ'"
                      @click="uploadFeatures"
                    >
                      <v-icon>mdi-upload</v-icon>
                    </v-btn>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1">
                      <AnkleFeaturesTable
                        ref="ankleFeaturesTable"
                        :leftTrajectories="leftTrajectories"
                        :rightTrajectories="rightTrajectories"
                        :selection="selectedGaitDuration"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col 
                  v-bind="graphCols" 
                  v-if="comparableGait && comparableId && visibility.gaitReport.ankle.features.table && currentTab.key === 'ankle'"
                >
                  <v-row class="d-flex align-center">
                    <v-chip
                      class="ml-3 my-0"
                      color="tertiary darken-1"
                      outlined
                    >
                      特徴量{{ ` - ID: ${comparableId}` }}
                    </v-chip>

                    <v-spacer/>

                    <v-btn 
                      fab 
                      x-small 
                      color="accent" 
                      class="mr-3" 
                      depressed
                      v-tooltip="'現在の選択範囲の特徴量を登録<br/>※計測参加者詳細 「時系列グラフ」タブの表示データ'"
                      @click="uploadComparableFeatures"
                    >
                      <v-icon>mdi-upload</v-icon>
                    </v-btn>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1">
                      <AnkleFeaturesTable
                        ref="comparableAnkleFeaturesTable"
                        :leftTrajectories="comparableLeftTrajectories"
                        :rightTrajectories="comparableRightTrajectories"
                        :selection="selectedComparableGaitDuration"
                        color="tertiary darken-4 white--text"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                
                <template v-for="target in ankleFeatureTargets">
                  <v-col 
                    :key="`ankleFeatureGraph${target.attr}`"
                    v-bind="graphCols" 
                    v-if="visibility.gaitReport.ankle.features.graph[target.attr] && currentTab.key === 'ankle'"
                  >
                    <v-row class="d-flex align-center mb-1">
                      <v-chip
                        class="ml-3 my-0"
                        :color="comparableId ? 'primary darken-1' : 'secondary'"
                        outlined
                      >
                        {{ target.name }} {{ comparableId ? ` - ID: ${id}` : null }}
                      </v-chip>

                      <v-chip 
                        label
                        color="red darken-4"
                        dark
                        class="ml-3"
                      >
                        左
                      </v-chip>

                      <v-chip 
                        label
                        color="blue darken-4"
                        dark
                        class="ml-3"
                      >
                        右
                      </v-chip>
                    </v-row>
                    <v-row>
                      <v-col class="pt-1">
                        <AnkleFeaturesChart
                          ref="ankleFeaturesCharts"
                          :target="target"
                          :leftTrajectories="leftTrajectories"
                          :rightTrajectories="rightTrajectories"
                          :selection="selectedGaitDuration"
                        />
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col 
                    :key="`comparableAnkleFeatureGraph${target.attr}`"
                    v-bind="graphCols" 
                    v-if="comparableId && comparableGait && visibility.gaitReport.ankle.features.graph && currentTab.key === 'ankle'"
                  >
                    <v-row class="d-flex align-center mb-1">
                      <v-chip
                        class="ml-3 my-0"
                        color="tertiary darken-1"
                        outlined
                      >
                        {{ target.name }} {{ ` - ID: ${comparableId}` }}
                      </v-chip>

                      <v-chip 
                        label
                        color="red darken-4"
                        dark
                        class="ml-3"
                      >
                        左
                      </v-chip>

                      <v-chip 
                        label
                        color="blue darken-4"
                        dark
                        class="ml-3"
                      >
                        右
                      </v-chip>
                    </v-row>
                    <v-row>
                      <v-col class="pt-1">
                        <AnkleFeaturesChart
                          ref="comparableAnkleFeaturesCharts"
                          :target="target"
                          :leftTrajectories="comparableLeftTrajectories"
                          :rightTrajectories="comparableRightTrajectories"
                          :selection="selectedComparableGaitDuration"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </template>

                <v-col 
                  cols="12"
                  v-show="visibility.gaitReport.ankle.continuous.left && !comparableId"
                >
                  <v-row class="d-flex align-center">
                    <v-chip
                      class="ml-3 my-0"
                      color="red"
                      outlined
                    >
                      左足首連続軌道
                    </v-chip>
                    <v-btn 
                      @click="$refs.leftContinuousAnkleTrajectorySide ? $refs.leftContinuousAnkleTrajectorySide.refreshCamera() : null" 
                      icon 
                      color="primary" 
                      class="ml-2"
                      v-tooltip="'視点初期化'"
                    >
                      <v-icon>mdi-eye-refresh</v-icon>
                    </v-btn>
                    <v-btn 
                      @click="isGridForLeftAnkleContinuousVisible = !isGridForLeftAnkleContinuousVisible" 
                      icon 
                      color="primary" 
                      class="ml-2"
                      v-tooltip="'1目盛 - 1m'"
                    >
                      <v-icon>{{ isGridForLeftAnkleContinuousVisible ? 'mdi-grid' : 'mdi-grid-off' }}</v-icon>
                    </v-btn>

                    <v-btn 
                      @click="isLeftAnkleCnotinuousGraphRotatable = !isLeftAnkleCnotinuousGraphRotatable" 
                      icon 
                      color="primary" 
                      class="ml-2"
                      v-tooltip="'移動/回転'"
                    >
                      <v-icon>{{ isLeftAnkleCnotinuousGraphRotatable ? 'mdi-rotate-orbit' : 'mdi-cursor-move' }}</v-icon>
                    </v-btn>

                    <v-btn 
                      @click="zoomInGraph('leftContinuousAnkleTrajectorySide')" 
                      icon 
                      color="primary" 
                      class="ml-2"
                    >
                      <v-icon>mdi-magnify-plus</v-icon>
                    </v-btn>
                    
                    <v-btn 
                      @click="zoomOutGraph('leftContinuousAnkleTrajectorySide')" 
                      icon 
                      color="primary" 
                      class="ml-2"
                    >
                      <v-icon>mdi-magnify-minus</v-icon>
                    </v-btn>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1">
                      <ContinuousAnkleTrajectoryGraph
                        id="left"
                        ref="leftContinuousAnkleTrajectorySide"
                        :trajectory="gaitTrajectory.left"
                        :selection="selectedGaitDuration"
                        :perspective="false"
                        color="#CC4444"
                        :initial-roll="90"
                        :initial-pitch="0"
                        :initial-yaw="0"
                        :initial-camera-position="'auto'"
                        :scale="0.1"
                        :scale-y="ankleVerticalScale"
                        :grid="isGridForLeftAnkleContinuousVisible"
                        :dragging-mode="isLeftAnkleCnotinuousGraphRotatable ? 'rotation' : 'translation'"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col 
                  cols="12"
                  v-show="visibility.gaitReport.ankle.continuous.right && !comparableId"
                >
                  <v-row class="d-flex align-center">
                    <v-chip
                      class="ml-3"
                      color="blue"
                      outlined
                    >
                      右足首連続軌道
                    </v-chip>
                    <v-btn 
                      @click="$refs.rightContinuousAnkleTrajectorySide ? $refs.rightContinuousAnkleTrajectorySide.refreshCamera() : null" 
                      icon 
                      color="primary" 
                      class="ml-2"
                      v-tooltip="'視点初期化'"
                    >
                      <v-icon>mdi-eye-refresh</v-icon>
                    </v-btn>
                    <v-btn 
                      @click="isGridForRightAnkleContinuousVisible = !isGridForRightAnkleContinuousVisible" 
                      icon 
                      color="primary" 
                      class="ml-2"
                      v-tooltip="'1目盛 - 1m'"
                    >
                      <v-icon>{{ isGridForRightAnkleContinuousVisible ? 'mdi-grid' : 'mdi-grid-off' }}</v-icon>
                    </v-btn>

                    <v-btn 
                      @click="isRightAnkleCnotinuousGraphRotatable = !isRightAnkleCnotinuousGraphRotatable" 
                      icon 
                      color="primary" 
                      class="ml-2"
                      v-tooltip="'移動/回転'"
                    >
                      <v-icon>{{ isRightAnkleCnotinuousGraphRotatable ? 'mdi-rotate-orbit' : 'mdi-cursor-move' }}</v-icon>
                    </v-btn>

                    <v-btn 
                      @click="zoomInGraph('rightContinuousAnkleTrajectorySide')" 
                      icon 
                      color="primary" 
                      class="ml-2"
                    >
                      <v-icon>mdi-magnify-plus</v-icon>
                    </v-btn>
                    
                    <v-btn 
                      @click="zoomOutGraph('rightContinuousAnkleTrajectorySide')" 
                      icon 
                      color="primary" 
                      class="ml-2"
                    >
                      <v-icon>mdi-magnify-minus</v-icon>
                    </v-btn>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1">
                      <ContinuousAnkleTrajectoryGraph
                        id="right"
                        ref="rightContinuousAnkleTrajectorySide"
                        :trajectory="gaitTrajectory.right"
                        :selection="selectedGaitDuration"
                        :perspective="false"
                        color="#4444CC"
                        :initial-roll="90"
                        :initial-pitch="0"
                        :initial-yaw="0"
                        :initial-camera-position="'auto'"
                        :scale="0.1"
                        :scale-y="ankleVerticalScale"
                        :grid="isGridForRightAnkleContinuousVisible"
                        :dragging-mode="isRightAnkleCnotinuousGraphRotatable ? 'rotation' : 'translation'"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col 
                  cols="12" 
                  v-show="visibility.gaitReport.ankle.sensor.left"
                >
                  <v-row class="d-flex align-center ml-1">
                    <v-chip
                      :color="comparableId ? 'primary darken-1' : 'red'"
                      outlined
                    >
                      左足首センサデータ{{ comparableId ? ` - ID: ${id}` : null }}
                    </v-chip>

                    <v-btn 
                      icon 
                      color="primary" 
                      class="ml-2"
                      @click="downloadRawData(gaitRawData.left, `ID_${id}_${reportDatetimeString}left.csv`)"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </v-row>
                  <RawDataChart
                    ref="leftLineChart"
                    :raw-data="gaitRawData.left"
                    :selection="selectedGaitDuration"
                  />
                </v-col>
                <v-col 
                  v-if="comparableId && comparableGaitRawData"
                  cols="12" 
                  v-show="visibility.gaitReport.ankle.sensor.left"
                >
                  <v-row  class="d-flex align-center ml-1">
                    <v-chip
                      color="tertiary darken-1"
                      outlined
                    >
                      左足首センサデータ{{ ` - ID: ${comparableId}` }}
                    </v-chip>

                    <v-btn 
                      icon 
                      color="tertiary" 
                      class="ml-2"
                      @click="downloadRawData(comparableGaitRawData.left, `ID_${comparableId}_${comparableReportDatetimeString}left.csv`)"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </v-row>
                  <RawDataChart
                    ref="leftLineChart"
                    :raw-data="comparableGaitRawData.left"
                    :selection="selectedComparableGaitDuration"
                  />
                </v-col>
                <v-col 
                  cols="12"
                  v-show="visibility.gaitReport.ankle.sensor.right"
                >
                  <v-row  class="d-flex align-center ml-1">
                    <v-chip
                      :color="comparableId ? 'primary darken-1' : 'blue'"
                      outlined
                    >
                      右足首センサデータ{{ comparableId ? ` - ID: ${id}` : null }}
                    </v-chip>

                    <v-btn 
                      icon 
                      color="primary" 
                      class="ml-2"
                      @click="downloadRawData(gaitRawData.right, `ID_${id}_${reportDatetimeString}right.csv`)"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </v-row>
                  <RawDataChart
                    ref="rightLineChart"
                    :raw-data="gaitRawData.right"
                    :selection="selectedGaitDuration"
                  />
                </v-col>
                <v-col 
                  v-if="comparableId && comparableGaitRawData"
                  cols="12"
                  v-show="visibility.gaitReport.ankle.sensor.right"
                >
                  <v-row  class="d-flex align-center ml-1">
                    <v-chip
                      color="tertiary darken-1"
                      outlined
                    >
                      右足首センサデータ{{ ` - ID: ${comparableId}` }}
                    </v-chip>

                    <v-btn 
                      icon 
                      color="tertiary" 
                      class="ml-2"
                      @click="downloadRawData(comparableGaitRawData.right, `ID_${comparableId}_${comparableReportDatetimeString}right.csv`)"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </v-row>
                  <RawDataChart
                    ref="rightLineChart"
                    :raw-data="comparableGaitRawData.right"
                    :selection="selectedComparableGaitDuration"
                  />
                </v-col>

                <v-col 
                  cols="12" 
                  v-show="visibility.gaitReport.ankle.freezingIndexes.left['400']"
                >
                  <v-row class="d-flex align-center ml-1">
                    <v-chip
                      :color="comparableId ? 'primary darken-1' : 'red'"
                      outlined
                    >
                      左足首すくみ指数 4秒Hann窓{{ comparableId ? ` - ID: ${id}` : null }}
                    </v-chip>

                    <TooltipGaitPhase />
                  </v-row>
                  <FreezingIndexesChart
                    v-if="gaitFreezingIndexes"
                    :trajectory="gaitTrajectory"
                    :freezing-indexes="gaitFreezingIndexes.left"
                    :height="240"
                    :window-size="400"
                    :selection="selectedGaitDuration"
                  />
                </v-col>

                <v-col 
                  cols="12" 
                  v-if="comparableId && comparableGaitFreezingIndexes"
                  v-show="visibility.gaitReport.ankle.freezingIndexes.left['400']"
                >
                  <v-row class="d-flex align-center ml-1">
                    <v-chip
                      color="tertiary darken-1"
                      outlined
                    >
                      左足首すくみ指数 4秒Hann窓{{ ` - ID: ${comparableId}` }}
                    </v-chip>

                    <TooltipGaitPhase />
                  </v-row>
                  <FreezingIndexesChart
                    :trajectory="comparableGaitTrajectory"
                    :freezing-indexes="comparableGaitFreezingIndexes.left"
                    :height="240"
                    :window-size="400"
                    :selection="selectedComparableGaitDuration"
                  />
                </v-col>

                <v-col 
                  cols="12"
                  v-show="visibility.gaitReport.ankle.freezingIndexes.left['600']"
                >
                  <v-row class="d-flex align-center ml-1">
                    <v-chip
                      :color="comparableId ? 'primary darken-1' : 'red'"
                      outlined
                    >
                      左足首すくみ指数 6秒窓{{ comparableId ? ` - ID: ${id}` : null }}
                    </v-chip>

                    <TooltipGaitPhase />
                  </v-row>
                  <FreezingIndexesChart
                    v-if="gaitFreezingIndexes"
                    :trajectory="gaitTrajectory"
                    :freezing-indexes="gaitFreezingIndexes.left"
                    :height="240"
                    :window-size="600"
                    :selection="selectedGaitDuration"
                  />
                </v-col>

                <v-col 
                  cols="12"
                  v-if="comparableId && comparableGaitFreezingIndexes"
                  v-show="visibility.gaitReport.ankle.freezingIndexes.left['600']"
                >
                  <v-row class="d-flex align-center ml-1">
                    <v-chip
                      color="tertiary darken-1"
                      outlined
                    >
                      左足首すくみ指数 6秒窓{{ ` - ID: ${comparableId}` }}
                    </v-chip>

                    <TooltipGaitPhase />
                  </v-row>
                  <FreezingIndexesChart
                    :trajectory="comparableGaitTrajectory"
                    :freezing-indexes="comparableGaitFreezingIndexes.left"
                    :height="240"
                    :window-size="600"
                    :selection="selectedComparableGaitDuration"
                  />
                </v-col>


                <v-col 
                  cols="12"
                  v-show="visibility.gaitReport.ankle.freezingIndexes.right['400']"
                >
                  <v-row class="d-flex align-center ml-1">
                    <v-chip
                      :color="comparableId ? 'primary darken-1' : 'blue'"
                      outlined
                    >
                      右足首すくみ指数 4秒Hann窓{{ comparableId ? ` - ID: ${id}` : null }}
                    </v-chip>

                    <TooltipGaitPhase />
                  </v-row>
                  <FreezingIndexesChart
                    v-if="gaitFreezingIndexes"
                    :trajectory="gaitTrajectory"
                    :freezing-indexes="gaitFreezingIndexes.right"
                    :height="240"
                    :window-size="400"
                    :selection="selectedGaitDuration"
                  />
                </v-col>

                <v-col 
                  cols="12"
                  v-if="comparableId && comparableGaitFreezingIndexes"
                  v-show="visibility.gaitReport.ankle.freezingIndexes.right['400']"
                >
                  <v-row class="d-flex align-center ml-1">
                    <v-chip
                      color="tertiary darken-1"
                      outlined
                    >
                      右足首すくみ指数 4秒Hann窓{{ ` - ID: ${comparableId}` }}
                    </v-chip>

                    <TooltipGaitPhase />
                  </v-row>
                  <FreezingIndexesChart
                    :trajectory="comparableGaitTrajectory"
                    :freezing-indexes="comparableGaitFreezingIndexes.right"
                    :height="240"
                    :window-size="400"
                    :selection="selectedComparableGaitDuration"
                  />
                </v-col>

                <v-col 
                  cols="12" 
                  v-show="visibility.gaitReport.ankle.freezingIndexes.right['600']"
                >
                  <v-row class="d-flex align-center ml-1">
                    <v-chip
                      :color="comparableId ? 'primary darken-1' : 'blue'"
                      outlined
                    >
                      右足首すくみ指数 6秒窓{{ comparableId ? ` - ID: ${id}` : null }}
                    </v-chip>

                    <TooltipGaitPhase />
                  </v-row>
                  <FreezingIndexesChart
                    v-if="gaitFreezingIndexes"
                    :trajectory="gaitTrajectory"
                    :freezing-indexes="gaitFreezingIndexes.right"
                    :height="240"
                    :window-size="600"
                    :selection="selectedGaitDuration"
                  />
                </v-col>

                <v-col 
                  cols="12" 
                  v-if="comparableId && comparableGaitFreezingIndexes"
                  v-show="visibility.gaitReport.ankle.freezingIndexes.right['600']"
                >
                  <v-row class="d-flex align-center ml-1">
                    <v-chip
                      color="tertiary darken-1"
                      outlined
                    >
                      右足首すくみ指数 6秒窓{{ ` - ID: ${comparableId}` }}
                    </v-chip>

                    <TooltipGaitPhase />
                  </v-row>
                  <FreezingIndexesChart
                    :trajectory="comparableGaitTrajectory"
                    :freezing-indexes="comparableGaitFreezingIndexes.right"
                    :height="240"
                    :window-size="600"
                    :selection="selectedComparableGaitDuration"
                  />
                </v-col>

                <MapCard
                  v-if="gait.latitude != null && gait.longitude != null"
                  v-bind="graphCols" 
                  v-model="gait"
                  color="primary"
                  :title="`座標 - ID: ${id}`"
                />

                <MapCard
                  v-if="comparableId && comparableGait && comparableGait.latitude != null && comparableGait.longitude != null"
                  color="tertiary"
                  v-bind="graphCols" 
                  v-model="gait"
                  :title="`座標 - ID: ${comparableId}`"
                />
              </v-row>
            </v-container>

            <div
              v-if="currentTab.key === 'karte'"
              class="py-5 secondary darken-4"
              style="position: relative;"
            >
              <v-btn 
                @click="printKarte"
                fixed
                right
                fab
                class="mr-3"
                color="accent"
                v-tooltip.left="'PDFダウンロード'"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>

              <div class="karte-mode-selector-container">
                <v-select
                  dense
                  hide-details
                  v-model="karteMode"
                  :items="karteModeOptions"
                  item-text="label"
                  item-value="key"
                ></v-select>
              </div>
              <div ref="karte" class="karte-container v-application">
                <v-container 
                  fluid 
                  class="pa-3"
                >
                  <KarteHeader />
                  <KarteGaitAccountInfo
                    :id="id"
                    :comparable-id="comparableId"
                    :gait="gait"
                    :comparable-gait="comparableGait"
                    :selected-gait-duration="selectedGaitDuration"
                    :selected-comparable-gait-duration="selectedComparableGaitDuration"
                    :gait-trajectory="gaitTrajectory"
                    :comparable-gait-trajectory="comparableGaitTrajectory"
                  />
                  <KarteGaitFeaturesTable
                    v-if="karteMode === 'standard'"
                    :id="id"
                    :comparable-id="comparableId"
                    :selected-gait-duration="selectedGaitDuration"
                    :selected-comparable-gait-duration="selectedComparableGaitDuration"
                    :gait-trajectory="gaitTrajectory"
                    :comparable-gait-trajectory="comparableGaitTrajectory"
                  />
                  <KarteGaitStandardGraphs
                    v-if="karteMode === 'standard'"
                    :ankle-vertical-scale="ankleVerticalScale"
                    :id="id"
                    :comparable-id="comparableId"
                    :selected-gait-duration="selectedGaitDuration"
                    :selected-comparable-gait-duration="selectedComparableGaitDuration"
                    :gait-trajectory="gaitTrajectory"
                    :comparable-gait-trajectory="comparableGaitTrajectory"
                  />

                  <v-row dense v-if="karteMode === 'continuous'">
                    <v-col :cols="comparableId ? 6 : 12">
                      <v-row dense>
                        <v-col style="margin-top: 1px;" class="text-center text-caption">
                          {{ comparableId ? `ID: ${id} ` : '' }}左足連続軌道
                        </v-col>
                      </v-row>
                      <ContinuousAnkleTrajectoryGraph
                        id="karteLeft"
                        :trajectory="gaitTrajectory.left"
                        :selection="selectedGaitDuration"
                        :perspective="false"
                        color="#CC4444"
                        :initial-roll="90"
                        :initial-pitch="0"
                        :initial-yaw="0"
                        :initial-camera-position="'auto'"
                        :scale="0.1"
                        :scale-y="ankleVerticalScale"
                        :aspect-ratio="comparableId ? 3 : 4"
                        :grid="true"
                        :dragging-mode="'translation'"
                      />
                      <v-row dense>
                        <v-col style="margin-top: 1px;" class="mt-1 text-center text-caption">
                          {{ comparableId ? `ID: ${id} ` : '' }}右足連続軌道
                        </v-col>
                      </v-row>
                      <ContinuousAnkleTrajectoryGraph
                        id="karteRight"
                        :trajectory="gaitTrajectory.right"
                        :selection="selectedGaitDuration"
                        :perspective="false"
                        color="#4444CC"
                        :initial-roll="90"
                        :initial-pitch="0"
                        :initial-yaw="0"
                        :initial-camera-position="'auto'"
                        :scale="0.1"
                        :scale-y="ankleVerticalScale"
                        :aspect-ratio="comparableId ? 3 : 4"
                        :grid="true"
                        :dragging-mode="'translation'"
                      />

                      <v-row dense>
                        <v-col style="margin-top: 1px;" class="mt-1 text-center text-caption">
                          {{ comparableId ? `ID: ${id} ` : '' }}左足すくみ指数
                        </v-col>
                      </v-row>
                      <FreezingIndexesChart
                        v-if="gaitFreezingIndexes"
                        :trajectory="gaitTrajectory"
                        :freezing-indexes="gaitFreezingIndexes.left"
                        :height="210"
                        :window-size="400"
                        :selection="selectedGaitDuration"
                      />

                      <v-row dense>
                        <v-col style="margin-top: 1px;" class="mt-1 text-center text-caption">
                          {{ comparableId ? `ID: ${id} ` : '' }}右足すくみ指数
                        </v-col>
                      </v-row>
                      <FreezingIndexesChart
                        v-if="gaitFreezingIndexes"
                        :trajectory="gaitTrajectory"
                        :freezing-indexes="gaitFreezingIndexes.right"
                        :height="210"
                        :window-size="400"
                        :selection="selectedGaitDuration"
                      />
                    </v-col>

                    <v-col 
                      v-if="comparableId && comparableGaitTrajectory" 
                      :cols="comparableId ? 6 : 12"
                    >
                      <v-row dense>
                        <v-col style="margin-top: 1px;" class="text-center text-caption">
                          ID: {{ comparableId }} 左足連続軌道
                        </v-col>
                      </v-row>
                      <ContinuousAnkleTrajectoryGraph
                        id="karteComparableLeft"
                        :trajectory="comparableGaitTrajectory.left"
                        :selection="selectedComparableGaitDuration"
                        :perspective="false"
                        color="#CC4444"
                        :initial-roll="90"
                        :initial-pitch="0"
                        :initial-yaw="0"
                        :initial-camera-position="'auto'"
                        :scale="0.1"
                        :scale-y="ankleVerticalScale"
                        :aspect-ratio="comparableId ? 3 : 4"
                        :grid="true"
                        :dragging-mode="'translation'"
                      />
                      <v-row dense>
                        <v-col style="margin-top: 1px;" class="mt-1 text-center text-caption">
                          ID: {{ comparableId }} 右足連続軌道
                        </v-col>
                      </v-row>
                      <ContinuousAnkleTrajectoryGraph
                        id="karteComparableRight"
                        :trajectory="comparableGaitTrajectory.right"
                        :selection="selectedComparableGaitDuration"
                        :perspective="false"
                        color="#4444CC"
                        :initial-roll="90"
                        :initial-pitch="0"
                        :initial-yaw="0"
                        :initial-camera-position="'auto'"
                        :scale="0.1"
                        :scale-y="ankleVerticalScale"
                        :aspect-ratio="comparableId ? 3 : 4"
                        :grid="true"
                        :dragging-mode="'translation'"
                      />

                      <v-row dense>
                        <v-col style="margin-top: 1px;" class="mt-1 text-center text-caption">
                          ID: {{ comparableId }} 左足すくみ指数
                        </v-col>
                      </v-row>
                      <FreezingIndexesChart
                        v-if="comparableGaitFreezingIndexes"
                        :trajectory="comparableGaitTrajectory"
                        :freezing-indexes="comparableGaitFreezingIndexes.left"
                        :height="210"
                        :window-size="400"
                        :selection="selectedComparableGaitDuration"
                      />

                      <v-row dense>
                        <v-col style="margin-top: 1px;" class="mt-1 text-center text-caption">
                          ID: {{ comparableId }} 右足すくみ指数
                        </v-col>
                      </v-row>
                      <FreezingIndexesChart
                        v-if="comparableGaitFreezingIndexes"
                        :trajectory="comparableGaitTrajectory"
                        :freezing-indexes="comparableGaitFreezingIndexes.right"
                        :height="210"
                        :window-size="400"
                        :selection="selectedComparableGaitDuration"
                      />
                    </v-col>
                  </v-row>
                  
                  <v-row class="mx-0" v-if="karteMode === 'standard'">
                    <div class="text-caption">
                      【足首軌道】赤線：左足、青線：右足。上図：矢状面、中図：水平面、下図：前額面。1目盛：10cm
                    </div>
                    <div class="text-caption">
                      【腰軌道】赤線：両脚支持期（右前）、黄線：片脚支持期（右）、青線：両脚支持期（左前）、緑線：片脚支持期。1目盛：1cm
                    </div>
                  </v-row>
                  <v-row dense>
                    <v-col class="mt-4">
                      <GaitPhaseGraph
                        ref="karteGaitPhaseGraph"
                        :trajectory="gaitTrajectory"
                        :selection="selectedGaitDuration"
                        icon-hidden
                      />
                    </v-col>
                  </v-row>
                  <v-row dense v-if="comparableId && comparableGaitTrajectory">
                    <v-col class="mt-0">
                      <GaitPhaseGraph
                        ref="karteComparableGaitPhaseGraph"
                        color="tertiary darken-3"
                        :trajectory="comparableGaitTrajectory"
                        :selection="selectedComparableGaitDuration"
                        icon-hidden
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </div>

            <v-container 
              fluid 
              class="pt-3 pr-5"
              v-if="currentTab.key === 'trunk'"
            >
              <v-row class="mt-0">
                <v-col 
                  v-bind="graphCols" 
                  v-show="visibility.gaitReport.trunk.graph"
                >
                  <v-row class="d-flex align-center">
                    <v-chip
                      class="ml-3"
                      :color="comparableId ? 'primary darken-1' : 'secondary'"
                      outlined
                    >
                      腰軌道{{ comparableId ? ` - ID: ${id}` : null }}
                    </v-chip>
                    <v-btn 
                      @click="refreshTrunkTrajectoryGraphCamera" 
                      icon 
                      color="primary" 
                      class="ml-2"
                      v-tooltip="'視点初期化'"
                    >
                      <v-icon>mdi-eye-refresh</v-icon>
                    </v-btn>

                    <v-btn 
                      @click="isGridForTrunkVisible = !isGridForTrunkVisible" 
                      icon 
                      color="primary" 
                      class="ml-2"
                      v-tooltip="'1目盛 - 1cm'"
                    >
                      <v-icon>{{ isGridForTrunkVisible ? 'mdi-grid' : 'mdi-grid-off' }}</v-icon>
                    </v-btn>

                    <v-btn 
                      @click="isTrunkGraphRotatable = !isTrunkGraphRotatable" 
                      icon 
                      color="primary" 
                      class="ml-2"
                      v-tooltip="'移動/回転'"
                    >
                      <v-icon>{{ isTrunkGraphRotatable ? 'mdi-rotate-orbit' : 'mdi-cursor-move' }}</v-icon>
                    </v-btn>

                    <v-btn 
                      @click="zoomInGraph('trunkTrajectoryGraph')" 
                      icon 
                      color="primary" 
                      class="ml-2"
                    >
                      <v-icon>mdi-magnify-plus</v-icon>
                    </v-btn>
                    
                    <v-btn 
                      @click="zoomOutGraph('trunkTrajectoryGraph')" 
                      icon 
                      color="primary" 
                      class="ml-2"
                    >
                      <v-icon>mdi-magnify-minus</v-icon>
                    </v-btn>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1">
                      <TrunkTrajectoryGraph
                        ref="trunkTrajectoryGraph"
                        :trajectory="gaitTrajectory"
                        :selection="selectedGaitDuration"
                        :initial-roll="0"
                        :initial-pitch="0"
                        :initial-yaw="0"
                        :initial-camera-position="0.25"
                        :scale-x="3"
                        :scale-y="3"
                        :scale-z="3"
                        :perspective="false"
                        :grid="isGridForTrunkVisible"
                        :dragging-mode="isTrunkGraphRotatable ? 'rotation' : 'translation'"
                        :line-opacity="trunkTrajectoryLineOpacity"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col 
                  v-bind="graphCols" 
                  v-if="visibility.gaitReport.trunk.features.table && currentTab.key === 'trunk'"
                >
                  <v-row class="d-flex align-center">
                    <v-chip
                      class="ml-3"
                      :color="comparableId ? 'primary darken-1' : 'secondary'"
                      outlined
                    >
                      特徴量{{ comparableId ? ` - ID: ${id}` : null }}
                    </v-chip>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1">
                      <TrunkFeaturesTable
                        :trajectories="leftTrajectories"
                        :selection="selectedGaitDuration"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col 
                  v-if="comparableGait"
                  v-bind="graphCols" 
                  v-show="visibility.gaitReport.trunk.graph && comparableId"
                >
                  <v-row class="d-flex align-center">
                    <v-chip
                      class="ml-3"
                      color="tertiary darken-1"
                      outlined
                    >
                      腰軌道{{ ` - ID: ${comparableId}` }}
                    </v-chip>
                    <v-btn 
                      @click="refreshComparableTrunkTrajectoryGraphCamera" 
                      icon 
                      color="tertiary" 
                      class="ml-2"
                      v-tooltip="'視点初期化'"
                    >
                      <v-icon>mdi-eye-refresh</v-icon>
                    </v-btn>

                    <v-btn 
                      @click="isGridForComparableTrunkVisible = !isGridForComparableTrunkVisible" 
                      icon 
                      color="tertiary" 
                      class="ml-2"
                      v-tooltip="'1目盛 - 1cm'"
                    >
                      <v-icon>{{ isGridForComparableTrunkVisible ? 'mdi-grid' : 'mdi-grid-off' }}</v-icon>
                    </v-btn>

                    <v-btn 
                      @click="isComparableTrunkGraphRotatable = !isComparableTrunkGraphRotatable" 
                      icon 
                      color="tertiary" 
                      class="ml-2"
                      v-tooltip="'移動/回転'"
                    >
                      <v-icon>{{ isComparableTrunkGraphRotatable ? 'mdi-rotate-orbit' : 'mdi-cursor-move' }}</v-icon>
                    </v-btn>

                    <v-btn 
                      @click="zoomInGraph('comparableTrunkTrajectoryGraph')" 
                      icon 
                      color="tertiary" 
                      class="ml-2"
                    >
                      <v-icon>mdi-magnify-plus</v-icon>
                    </v-btn>
                    
                    <v-btn 
                      @click="zoomOutGraph('comparableTrunkTrajectoryGraph')" 
                      icon 
                      color="tertiary" 
                      class="ml-2"
                    >
                      <v-icon>mdi-magnify-minus</v-icon>
                    </v-btn>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1">
                      <TrunkTrajectoryGraph
                        ref="comparableTrunkTrajectoryGraph"
                        id="comparableTrunk"
                        :trajectory="comparableGaitTrajectory"
                        :selection="selectedComparableGaitDuration"
                        :initial-roll="0"
                        :initial-pitch="0"
                        :initial-yaw="0"
                        :initial-camera-position="0.25"
                        :scale-x="3"
                        :scale-y="3"
                        :scale-z="3"
                        :perspective="false"
                        :grid="isGridForComparableTrunkVisible"
                        :dragging-mode="isComparableTrunkGraphRotatable ? 'rotation' : 'translation'"
                        :line-opacity="trunkTrajectoryLineOpacity"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col 
                  v-bind="graphCols" 
                  v-if="comparableGait && comparableId && visibility.gaitReport.trunk.features.table && currentTab.key === 'trunk'"
                >
                  <v-row class="d-flex align-center">
                    <v-chip
                      class="ml-3"
                      color="tertiary darken-1"
                      outlined
                    >
                      特徴量{{ ` - ID: ${comparableId}` }}
                    </v-chip>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1">
                      <TrunkFeaturesTable
                        :trajectories="comparableLeftTrajectories"
                        :selection="selectedComparableGaitDuration"
                        color="tertiary darken-4 white--text"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <template v-for="target in trunkFeatureTargets">
                  <v-col 
                    :key="`trunkFeatureGraph${target.attr}`"
                    v-bind="graphCols" 
                    v-if="visibility.gaitReport.trunk.features.graph[target.attr] && currentTab.key === 'trunk'"
                  >
                    <v-row class="d-flex align-center my-1">
                      <v-chip
                        class="ml-3 my-0"
                        :color="comparableId ? 'primary darken-1' : 'secondary'"
                        outlined
                      >
                        {{ target.name }} {{ comparableId ? ` - ID: ${id}` : null }}
                      </v-chip>

                      <v-chip 
                        label
                        color="red darken-4"
                        dark
                        class="ml-3"
                      >
                        左側
                      </v-chip>

                      <v-chip 
                        label
                        color="blue darken-4"
                        dark
                        class="ml-3"
                      >
                        右側
                      </v-chip>

                      <v-chip 
                        label
                        color="grey darken-1"
                        dark
                        class="ml-3"
                      >
                        両側
                      </v-chip>
                    </v-row>
                    <v-row>
                      <v-col class="pt-1">
                        <TrunkFeaturesChart
                          :target="target"
                          :trajectories="leftTrajectories"
                          :selection="selectedGaitDuration"
                        />
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col 
                    :key="`comparableTrunkFeatureGraph${target.attr}`"
                    v-bind="graphCols" 
                    v-if="comparableId && comparableGait && visibility.gaitReport.trunk.features.graph && currentTab.key === 'trunk'"
                  >
                    <v-row class="d-flex align-center mb-1">
                      <v-chip
                        class="ml-3"
                        color="tertiary darken-1"
                        outlined
                      >
                        {{ target.name }} {{ ` - ID: ${comparableId}` }}
                      </v-chip>

                      <v-chip 
                        label
                        color="red darken-4"
                        dark
                        class="ml-3"
                      >
                        左側
                      </v-chip>

                      <v-chip 
                        label
                        color="blue darken-4"
                        dark
                        class="ml-3"
                      >
                        右側
                      </v-chip>

                      <v-chip 
                        label
                        color="grey darken-1"
                        dark
                        class="ml-3"
                      >
                        両側
                      </v-chip>
                    </v-row>
                    <v-row>
                      <v-col class="pt-1">
                        <TrunkFeaturesChart
                          :target="target"
                          :trajectories="leftTrajectories"
                          :selection="selectedComparableGaitDuration"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </template>

                <v-col 
                  cols="12" 
                  v-show="visibility.gaitReport.trunk.sensor"
                >
                  <v-row class="d-flex align-center ml-1">
                    <v-chip
                      :color="comparableId ? 'primary darken-1' : 'secondary'"
                      outlined
                    >
                      腰センサデータ{{ comparableId ? ` - ID: ${id}` : null }}
                    </v-chip>

                    <v-btn 
                      icon 
                      color="primary" 
                      class="ml-2"
                      @click="downloadRawData(gaitRawData.trunk, `ID_${id}_${reportDatetimeString}trunk.csv`)"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </v-row>
                  <RawDataChart
                    v-if="currentTab.key === 'trunk'"
                    :raw-data="gaitRawData.trunk"
                    :selection="selectedGaitDuration"
                  />
                </v-col>

                <v-col 
                  v-if="comparableId && comparableGaitRawData"
                  cols="12" 
                  v-show="visibility.gaitReport.trunk.sensor"
                >
                  <v-row class="d-flex align-center ml-1">
                    <v-chip
                      color="tertiary darken-1"
                      outlined
                    >
                      腰センサデータ{{ ` - ID: ${comparableId}` }}
                    </v-chip>

                    <v-btn 
                      icon 
                      color="tertiary" 
                      class="ml-2"
                      @click="downloadRawData(comparableGaitRawData.trunk, `ID_${comparableId}_${comparableReportDatetimeString}trunk.csv`)"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </v-row>
                  <RawDataChart
                    v-if="currentTab.key === 'trunk'"
                    :raw-data="comparableGaitRawData.trunk"
                    :selection="selectedComparableGaitDuration"
                  />
                </v-col>

                <v-col 
                  cols="12" 
                  v-show="visibility.gaitReport.trunk.freezingIndexes['400']"
                >
                  <v-row class="d-flex align-center ml-1">
                    <v-chip
                      :color="comparableId ? 'primary darken-1' : 'secondary'"
                      outlined
                    >
                      腰すくみ指数 4秒Hann窓{{ comparableId ? ` - ID: ${id}` : null }}
                    </v-chip>

                    <TooltipGaitPhase />
                  </v-row>
                  <FreezingIndexesChart
                    v-if="gaitFreezingIndexes && currentTab.key === 'trunk'"
                    :trajectory="gaitTrajectory"
                    :freezing-indexes="gaitFreezingIndexes.trunk"
                    :height="240"
                    :window-size="400"
                    :selection="selectedGaitDuration"
                  />
                </v-col>

                <v-col 
                  v-if="comparableId && comparableGaitFreezingIndexes"
                  cols="12" 
                  v-show="visibility.gaitReport.trunk.freezingIndexes['400']"
                >
                  <v-row class="d-flex align-center ml-1">
                    <v-chip
                      color="tertiary darken-1"
                      outlined
                    >
                      腰すくみ指数 4秒Hann窓{{ ` - ID: ${comparableId}` }}
                    </v-chip>

                    <TooltipGaitPhase />
                  </v-row>
                  <FreezingIndexesChart
                    v-if="comparableGaitFreezingIndexes && currentTab.key === 'trunk'"
                    :trajectory="comparableGaitTrajectory"
                    :freezing-indexes="comparableGaitFreezingIndexes.trunk"
                    :height="240"
                    :window-size="400"
                    :selection="selectedComparableGaitDuration"
                  />
                </v-col>

                <v-col 
                  cols="12" 
                  v-show="visibility.gaitReport.trunk.freezingIndexes['600']"
                >
                  <v-row class="d-flex align-center ml-1">
                    <v-chip
                      :color="comparableId ? 'primary darken-1' : 'secondary'"
                      outlined
                    >
                      腰すくみ指数 6秒窓{{ comparableId ? ` - ID: ${id}` : null }}
                    </v-chip>

                    <TooltipGaitPhase />
                  </v-row>
                  <FreezingIndexesChart
                    v-if="gaitFreezingIndexes && currentTab.key === 'trunk'"
                    :trajectory="gaitTrajectory"
                    :freezing-indexes="gaitFreezingIndexes.trunk"
                    :height="240"
                    :window-size="600"
                    :selection="selectedGaitDuration"
                  />
                </v-col>

                <v-col 
                  v-if="comparableId && comparableGaitFreezingIndexes"
                  cols="12" 
                  v-show="visibility.gaitReport.trunk.freezingIndexes['600']"
                >
                  <v-row class="d-flex align-center ml-1">
                    <v-chip
                      color="tertiary darken-1"
                      outlined
                    >
                      腰すくみ指数 6秒窓{{ ` - ID: ${comparableId}` }}
                    </v-chip>

                    <TooltipGaitPhase />
                  </v-row>
                  <FreezingIndexesChart
                    v-if="comparableGaitFreezingIndexes && currentTab.key === 'trunk'"
                    :trajectory="comparableGaitTrajectory"
                    :freezing-indexes="comparableGaitFreezingIndexes.trunk"
                    :height="240"
                    :window-size="600"
                    :selection="selectedComparableGaitDuration"
                  />
                </v-col>

                <MapCard
                  v-if="gait.latitude != null && gait.longitude != null"
                  v-bind="graphCols" 
                  v-model="gait"
                  color="primary"
                  :title="`座標 - ID: ${id}`"
                />

                <MapCard
                  v-if="comparableId && comparableGait && comparableGait.latitude != null && comparableGait.longitude != null"
                  color="tertiary"
                  v-bind="graphCols" 
                  v-model="gait"
                  :title="`座標 - ID: ${comparableId}`"
                />
              </v-row>
            </v-container>
          </simplebar>

          <GaitPhaseGraph
            v-if="currentTab.key !== 'karte'"
            ref="gaitPhaseGraph"
            class="pr-3 my-3"
            style="flex: initial;"
            :trajectory="gaitTrajectory"
            :selection="selectedGaitDuration"
          />

          <GaitPhaseGraph
            v-if="currentTab.key !== 'karte' && comparableId && comparableGait"
            ref="comparableGaitPhaseGraph"
            class="pr-3 mb-3"
            style="flex: initial;"
            color="tertiary darken-3"
            :trajectory="comparableGaitTrajectory"
            :selection="selectedComparableGaitDuration"
          />
        </v-col>
      </v-row>

      <v-alert v-if="!gait.isAnalyzed" class="mt-5" type="info" color="primary">
        分析中です。しばらくしてからリロードしてください。
      </v-alert>

      <v-alert v-if="gait.isFailed" class="mt-5" type="error">
        分析に失敗しました。
      </v-alert>
    </template>

    <v-snackbar 
      :value="isDownloading"
      :timeout="-1"
      color="primary darken-4"
    >
      <span>Downloading...</span>
      <v-progress-linear
        indeterminate 
        :height="24"
        color="primary lighten-5"
        class="my-2 ml-1"
      />
    </v-snackbar>

    <v-overlay :value="isLoading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { 
  getAveragedTrajectory,
  getTrunkFeatures,
  getAnkleFeatures,
} from '@js/utils/StatisticsUtil.js';

import { getSplittedTrunkTrajectories } from '@js/utils/TrajectoryUtil.js';

import html2pdf from "html2pdf.js";
import { saveAs } from "file-saver";

import { mapActions, mapState } from "vuex";
import { VglDefs } from 'vue-gl';

import AnkleTrajectoryGeometry from '@components/parts/graph/geometories/AnkleTrajectoryGeometry.vue'

import ContinuousAnkleTrajectoryGraph from '@components/parts/graph/ContinuousAnkleTrajectoryGraph.vue'
import TrunkTrajectoryGraph from '@components/parts/graph/TrunkTrajectoryGraph.vue'
import GaitPhaseGraph from '@components/parts/graph/GaitPhaseGraph.vue'
import GaitsTable from "@components/parts/tables/GaitsTable.vue";

import TrunkFeaturesTable from "@components/parts/tables/TrunkFeaturesTable.vue";
import AnkleFeaturesTable from "@components/parts/tables/AnkleFeaturesTable.vue";

import FreezingIndexesChart from '@components/parts/charts/FreezingIndexesChart.vue'
import RawDataChart from '@components/parts/charts/RawDataChart.vue'
import AnkleFeaturesChart from '@components/parts/charts/AnkleFeaturesChart.vue'
import TrunkFeaturesChart from '@components/parts/charts/TrunkFeaturesChart.vue'
import Dialog from "@components/parts/dialogs/Dialog.vue";

import KarteHeader from "@components/parts/karte/KarteHeader.vue";
import KarteGaitAccountInfo from "@components/parts/karte/KarteGaitAccountInfo.vue";
import KarteGaitFeaturesTable from "@components/parts/karte/KarteGaitFeaturesTable.vue";
import KarteGaitStandardGraphs from "@components/parts/karte/KarteGaitStandardGraphs.vue";

import TooltipGaitPhase from "@components/parts/tooltips/TooltipGaitPhase.vue";

import GaitTrajectoryGraphCard from "@components/parts/cards/GaitTrajectoryGraphCard.vue";
import MapCard from "@components/parts/cards/MapCard.vue";
import CommentsCard from "@components/parts/cards/CommentsCard.vue";
import TrajectoriesSettingSelector from "@components/inputs/TrajectoriesSettingSelector.vue";

import moment from "moment";

export default {
  components: {
    AnkleTrajectoryGeometry,
    VglDefs,

    ContinuousAnkleTrajectoryGraph,
    TrunkTrajectoryGraph,
    GaitPhaseGraph,
    RawDataChart,
    AnkleFeaturesChart,
    TrunkFeaturesChart,
    FreezingIndexesChart,
    Dialog,
    GaitsTable,
    AnkleFeaturesTable,
    TrunkFeaturesTable,

    KarteHeader,
    KarteGaitAccountInfo,
    KarteGaitFeaturesTable,
    KarteGaitStandardGraphs,

    TooltipGaitPhase,

    MapCard,
    GaitTrajectoryGraphCard,
    CommentsCard,
    TrajectoriesSettingSelector,
  },
  props: {
    id: {
      required: true,
    },
    comparableId: {
      default: null,
    },
  },
  data: function(){
    return {
      gaitTrajectoriesSetting: null,
      comparableGaitTrajectoriesSetting: null,

      isTrunkGraphRotatable: false,
      isComparableTrunkGraphRotatable: false,

      isReportCommentsOpened: false,
      isComparableReportCommentsOpened: false,
      ankleTrajectoryLineOpacity: 0.65,
      trunkTrajectoryLineOpacity: 0.65,
      karteMode: 'standard',
      karteModeOptions: [
        {label: "標準", key: 'standard'},
        {label: "すくみ指数＋連続軌道", key: 'continuous'},
      ],

      ankleFeatureTargets: [
        {name: 'ストライド長さ', attr: 'stride', unit: 'm'},
        {name: 'ストライド周期', attr: 'strideDuration', unit: 'sec'},
        {name: '歩行速度', attr: 'velocity', unit: 'm/sec'},
        {name: '持ち上げの高さ', attr: 'height', scale: 100, unit: 'cm'},
        {name: 'ステップ時間', attr: 'stepDuration', unit: 'sec'},
        {name: '足振り強度', attr: 'swingStrength', unit: 'dps'},
        {name: '遊脚期', attr: 'swingPhaseDuration', unit: 'sec'},
        {name: '立脚期', attr: 'stancePhaseDuration', unit: 'sec'},
        {name: '片脚支持期', attr: 'singleStancePhaseDuration', unit: 'sec'},
      ],
      trunkFeatureTargets: [
        {name: '横揺れの大きさ', attr: 'trunkSwingWidth', leftAttr: 'trunkSwingLeftWidth', rightAttr: 'trunkSwingRightWidth', scale: 100, unit: 'cm'},
        {name: '持ち上げの高さ', attr: 'trunkSwingHeight', leftAttr: 'trunkSwingLeftHeight', rightAttr: 'trunkSwingRightHeight', scale: 100, unit: 'cm'},
      ],

      ankleVerticalScale: 2,
      
      isTrunkPerspective: false,
      isSideMenuOpened: true,
      currentTabIndex: 0,
      currentSettingTabIndex: 0,
      tabs: [
        {label: '足首軌道', key: 'ankle'},
        {label: '腰軌道', key: 'trunk'},
        {label: '印刷用', key: 'karte'},
      ],
      graphCols: {
        cols: 12,
        sm: 6,
        lg: 6
      },

      isGridForLeftAnkleContinuousVisible: true,
      isGridForRightAnkleContinuousVisible: true,
      isGridForTrunkVisible: true,
      isLeftAnkleCnotinuousGraphRotatable: false,
      isRightAnkleCnotinuousGraphRotatable: false,

      isGridForComparableLeftAnkleContinuousVisible: true,
      isGridForComparableRightAnkleContinuousVisible: true,
      isGridForComparableTrunkVisible: true,      
      isComparableLeftAnkleCnotinuousGraphRotatable: false,
      isComparableRightAnkleCnotinuousGraphRotatable: false,

      selectedGaitDuration: {
        from: 0,
        to: 0,
      },
      selectedComparableGaitDuration: {
        from: 0,
        to: 0,
      },
      isDownloading: false,
    }
  },
  computed: {
    ...mapState([
      'user',
      'project',
      'rootPath',
      'visibility',

      'gait',
      'gaitTrajectory',
      'gaitRawData',
      'gaitTrajectoryDataGeneratingCount',
      'gaitFreezingIndexes',

      'comparableGait',
      'comparableGaitTrajectory',
      'comparableGaitRawData',
      'comparableGaitTrajectoryDataGeneratingCount',
      'comparableGaitFreezingIndexes',

      'gaits',
      'loadingCount',
    ]),
    reportDatetimeString: function(){
      return moment(this.gait?.createdTime).format("YYYYMMDDhhmmss");
    },
    comparableReportDatetimeString: function(){
      return moment(this.comparableGait?.createdTime).format("YYYYMMDDhhmmss");
    },
    averagedLeftTrajectory: function(){
      return getAveragedTrajectory(this.selectedLeftTrajectories);
    },
    averagedRightTrajectory: function(){
      return getAveragedTrajectory(this.selectedRightTrajectories);
    },
    averagedTrunkTrajectory: function(){
      return getAveragedTrajectory(this.selectedTrunkTrajectories);
    },
    comaparbleAveragedLeftTrajectory: function(){
      return getAveragedTrajectory(this.selectedComparableLeftTrajectories);
    },
    comaparbleAveragedRightTrajectory: function(){
      return getAveragedTrajectory(this.selectedComparableRightTrajectories);
    },
    comaparbleAveragedTrunkTrajectory: function(){
      return getAveragedTrajectory(this.selectedComparableTrunkTrajectories);
    },
    selectedLeftTrajectories: function(){
      let indexFrom = this.selectedGaitDuration?.from ?? 0;
      let indexTo = this.selectedGaitDuration?.to ?? 0;
      return this.leftTrajectories.filter(t => !t.isInvalid && indexFrom <= t.positions[0].index && t.positions.slice(-1)[0].index < indexTo);
    },
    selectedRightTrajectories: function(){
      let indexFrom = this.selectedGaitDuration?.from ?? 0;
      let indexTo = this.selectedGaitDuration?.to ?? 0;
      return this.rightTrajectories.filter(t => !t.isInvalid && indexFrom <= t.positions[0].index && t.positions.slice(-1)[0].index < indexTo);
    },
    selectedTrunkTrajectories: function(){
      let indexFrom = this.selectedGaitDuration?.from ?? 0;
      let indexTo = this.selectedGaitDuration?.to ?? 0;
      return this.splittedTrunkTrajectories.filter(t => indexFrom <= t.positions[0].index && t.positions.slice(-1)[0].index < indexTo);
    },
    selectedComparableLeftTrajectories: function(){
      let indexFrom = this.selectedComparableGaitDuration?.from ?? 0;
      let indexTo = this.selectedComparableGaitDuration?.to ?? 0;
      return this.comparableLeftTrajectories.filter(t => !t.isInvalid && indexFrom <= t.positions[0].index && t.positions.slice(-1)[0].index < indexTo);
    },
    selectedComparableRightTrajectories: function(){
      let indexFrom = this.selectedComparableGaitDuration?.from ?? 0;
      let indexTo = this.selectedComparableGaitDuration?.to ?? 0;
      return this.comparableRightTrajectories.filter(t => !t.isInvalid && indexFrom <= t.positions[0].index && t.positions.slice(-1)[0].index < indexTo);
    },
    selectedComparableTrunkTrajectories: function(){
      let indexFrom = this.selectedComparableGaitDuration?.from ?? 0;
      let indexTo = this.selectedComparableGaitDuration?.to ?? 0;
      return this.splittedComparableTrunkTrajectories.filter(t => indexFrom <= t.positions[0].index && t.positions.slice(-1)[0].index < indexTo);
    },
    splittedTrunkTrajectories: function(){
      return getSplittedTrunkTrajectories(this.gaitTrajectory);
    },
    splittedComparableTrunkTrajectories: function(){
      return getSplittedTrunkTrajectories(this.comparableGaitTrajectory);
    },
    leftTrajectories: function(){
      console.log(this.gaitTrajectory?.splitted?.left);
      return this.gaitTrajectory?.splitted?.left ?? [];
    },
    rightTrajectories: function(){
      return this.gaitTrajectory?.splitted?.right ?? [];
    },
    ankleAveragedHeight: function(){
      return this.gaitTrajectory?.statistics?.averagedHeight ?? 0;
    },
    ankleAveragedStride: function(){
      return this.gaitTrajectory?.statistics?.averagedStride ?? 0;
    },
    isAnkleTrajectoriesDataGenerating: function(){
      return this.gaitTrajectoryDataGeneratingCount > 0;
    },
    ankleVerticalOffset: function(){
      return this.ankleAveragedHeight/2;
    },
    ankleHorizontalOffset: function(){
      return this.ankleAveragedStride/2;
    },


    comparableLeftTrajectories: function(){
      return this.comparableGaitTrajectory?.splitted?.left ?? [];
    },
    comparableRightTrajectories: function(){
      return this.comparableGaitTrajectory?.splitted?.right ?? [];
    },
    comparableAnkleAveragedHeight: function(){
      return this.comparableGaitTrajectory?.statistics?.averagedHeight ?? 0;
    },
    comparableAnkleAveragedStride: function(){
      return this.comparableGaitTrajectory?.statistics?.averagedStride ?? 0;
    },
    isComparableAnkleTrajectoriesDataGenerating: function(){
      return this.comparableGaitTrajectoryDataGeneratingCount > 0;
    },
    comparableAnkleVerticalOffset: function(){
      return this.comparableAnkleAveragedHeight/2;
    },
    comparableAnkleHorizontalOffset: function(){
      return this.comparableAnkleAveragedStride/2;
    },


    isLoading: function(){
      return this.loadingCount > 0;
    },
    currentTab: function(){
      return this.tabs[this.currentTabIndex];
    },
    currentSettingTab: function(){
      return this.tabs[this.currentSettingTabIndex];
    },
  },
  watch: {
    id: function(){
      this.fetchAll();
    },
    comparableId: function(){
      this.currentTabIndex = 0;
      this.fetchAll();
    },
    currentTab: function(newVal){
      this.currentSettingTabIndex = this.tabs.findIndex(t => t.key === newVal.key);

      this.$refs.scrollbar.scrollElement.scrollTop = 0;
      this.$nextTick(() => {
        this.$refs.gaitPhaseGraph?.updateCurrentSelection();
        this.$refs.comparableGaitPhaseGraph?.updateCurrentSelection();

        this.$refs.karteGaitPhaseGraph?.updateCurrentSelection();
        this.$refs.karteComparableGaitPhaseGraph?.updateCurrentSelection();
      })
    },
    gaitTrajectory: {
      handler(newVal){
        if(!newVal){
          return;
        }


        this.selectedGaitDuration = {
          from: 0,
          to: newVal.left.x.length - 1,
        }

        this.$nextTick(() => {
          this.$refs.gaitPhaseGraph?.updateCurrentSelection();
        });
      },
      immediate: true,
    },
    comparableGaitTrajectory: {
      handler(newVal){
        if(!newVal){
          return;
        }

        this.selectedComparableGaitDuration = {
          from: 0,
          to: newVal.left.x.length - 1,
        }

        this.$nextTick(() => {
          this.$refs.comparableGaitPhaseGraph?.updateCurrentSelection();
        });
      },
      immediate: true,
    },
    project: function(newVal, oldVal){
      if(newVal?.id != oldVal?.id){
        this.$router.push('/gaits')
      }
    },
  },
  destroyed: function(){
  },
  mounted: function(){
    this.fetchAll();
  },
  beforeDestroy: function(){
  },
  methods: {
    ...mapActions([
      'fetchGaitDetail',
      'fetchComparableGaitDetail',
      'incrementLoadingCount',
      'decrementLoadingCount',
    ]),
    rerenderAnkleFeaturesCharts: function(){
      this.$refs.ankleFeaturesCharts.forEach(c => {
        c.rerender();
      });
    },
    rerenderComparableAnkleFeaturesCharts: function(){
      this.$refs.comparableAnkleFeaturesCharts.forEach(c => {
        c.rerender();
      });
    },
    fetchAll: function(){
      if(this.gait?.id != this.id){
        this.fetchGaitDetail(this.id);
      }

      if(this.comparableId != null && this.comparableGait?.id != this.comparableId){
        this.fetchComparableGaitDetail(this.comparableId);
      }
    },
    updateGait: function(){
      this.axios.put('/api/gait', this.gait).then(() => {
      }).catch(error => {
        console.log(error);
      })
    },
    refreshTrunkTrajectoryGraphCamera: function(){
      this.$refs.trunkTrajectoryGraph?.refreshCamera();
    },
    setTrunkTrajectoryGraphCameraAngle: function(roll, pitch, yaw){
      this.$refs.trunkTrajectoryGraph?.setCameraAngle(roll, pitch, yaw);
    },
    
    refreshComparableTrunkTrajectoryGraphCamera: function(){
      this.$refs.comparableAnkleTrajectoryGraph?.refreshCamera();
    },
    setComparableTrunkTrajectoryGraphCameraAngle: function(roll, pitch, yaw){
      this.$refs.comparableTrunkTrajectoryGraph?.setCameraAngle(roll, pitch, yaw);
    },

    updateReportAttribute: function(key){
      this.axios.put(`/api/gait/${key}`, {
        ...this.gait,
      }).then(() => {

      }).catch(error => {
        console.log(error);
        alert('歩行レポート更新に失敗しました');
      })
    },
    updateComparableReportAttribute: function(key){
      this.axios.put(`/api/gait/${key}`, {
        ...this.comparableGait,
      }).then(() => {

      }).catch(error => {
        console.log(error);
        alert('歩行レポート更新に失敗しました');
      })
    },
    selectGaitReport: function(gait){
      if(this.id == gait.id){
        return;
      }
      this.$router.push(this.comparableId ? `/gait/${gait.id}/${this.comparableId}` : `/gait/${gait.id}`);
    },
    selectComparableGaitReport: function(gait){
      if(this.comparableId == gait.id){
        return;
      }
      this.$router.push(`/gait/${this.id}/${gait.id}`);
    },
    printKarte: async function(){
      this.isDownloading = true;
      html2pdf().set({
        image: { type: 'jpeg', quality: 1 },
        filename: `歩行分析レポート-${this.gait.name}.pdf`,
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      }).from(this.$refs.karte).save().then(() => {
        this.isDownloading = false;
      });
    },
    uploadFeatures: function(){
      this.incrementLoadingCount();
      let selection = this.selectedGaitDuration;
      this.axios.post(`/api/gait_features/${this.id}`, {
        gaitFeatures: [
          ...getAnkleFeatures(
            this.selectedLeftTrajectories,
            this.selectedRightTrajectories,
          ),
          ...getTrunkFeatures(this.selectedLeftTrajectories),
        ],
        selectionFrom: selection.from,
        selectionTo: selection.to,
      }).then(() => {
      }).catch(error => {
        console.log(error);
        alert('歩行特徴量登録に失敗しました');
      }).finally(() => {
        this.decrementLoadingCount();
      });
    },
    uploadComparableFeatures: function(){
      this.incrementLoadingCount();
      let selection = this.selectedComparableGaitDuration;
      this.axios.post(`/api/gait_features/${this.comparableId}`, {
        gaitFeatures: [
          ...getAnkleFeatures(
            this.selectedComparableLeftTrajectories,
            this.selectedComparableRightTrajectories,
          ),
          ...getTrunkFeatures(this.selectedComparableLeftTrajectories),
        ],
        selectionFrom: selection.from,
        selectionTo: selection.to,
      }).then(() => {
      }).catch(error => {
        console.log(error);
        alert('歩行特徴量登録に失敗しました');
      }).finally(() => {
        this.decrementLoadingCount();
      });
    },
    downloadRawData: function(data, filename){
      const csv = data.map(row => row.join(',')).join('\n');
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blob = new Blob([bom, csv], { type: "text/csv" });

      if (window.navigator.msSaveBlob) {
          window.navigator.msSaveBlob(blob, filename);
      } else {
          const url = (window.URL || window.webkitURL).createObjectURL(blob);
          const download = document.createElement("a");
          download.href = url;
          download.download = filename;
          download.click();
          (window.URL || window.webkitURL).revokeObjectURL(url);
      }
    },
    downloadReport: function(){
      this.isDownloading = true;
      this.axios.post(
        `/api/download/gait/${this.id}`,
        {
          trajectory: this.gaitTrajectory,
          selection: this.selectedGaitDuration,
          freezingIndexes: this.gaitFreezingIndexes,
          features: {
            full: {
              ankle: getAnkleFeatures(
                this.leftTrajectories.filter(t => !t.isInvalid),
                this.rightTrajectories.filter(t => !t.isInvalid),
              ),
              trunk: getTrunkFeatures(this.leftTrajectories),
            },
            selected: {
              ankle: getAnkleFeatures(this.selectedLeftTrajectories, this.selectedRightTrajectories),
              trunk: getTrunkFeatures(this.selectedLeftTrajectories),
            }
          },
        },
        {
          responseType: "blob"
        },
      ).then(response => {
        const blob = new Blob([response.data], {
          type: response.data.type
        });
        let fileName = `【歩行分析レポート】${this.gait.name}.xlsx`;
        saveAs(blob, fileName);
      }).finally(() => {
        this.isDownloading = false;
      });
    },
    zoomInGraph: function(ref){
      this.$refs[ref]?.zoomIn();
    },
    zoomOutGraph: function(ref){
      this.$refs[ref]?.zoomOut();
    },
  },
}
</script>

<style lang="scss" scoped>
</style>