<template>
  <v-container fluid>
    <v-row dense v-if="project">
      <v-col cols="6" md="4" lg="3">
        <v-btn 
          icon
          large
          color="primary" 
          @click="fetchAll"
          class="mb-0"
        >
          <v-icon>mdi-reload</v-icon>
        </v-btn>
        <v-card 
          class="pa-0 elevation-0 mr-3"
          outlined
        >
          <v-card-title 
            class="primary darken-3 white--text py-1"
          >
            ID: {{ project.id }}
          </v-card-title>
          <v-card-text>
            <v-container class="px-0">
              <v-row dense>
                <v-col>
                  <v-text-field
                    label="プロジェクト名"
                    v-model="project.name"
                    hide-details
                    @change="updateProjectAttribute('name')"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <MarkdownEditor
                    label="説明"
                    class="mt-3"
                    v-model="project.description"
                    outlined
                    @change="updateProjectAttribute('description')"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    label="最大ユーザ数"
                    v-model="project.maxUserNumber"
                    hide-details
                    @change="updateProjectAttribute('maxUserNumber')"
                    type="number"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-select
                    label="有効/無効"
                    v-model="project.isDeleted"
                    hide-details
                    @change="updateProjectAttribute('isDeleted')"
                    :items="[
                      {label: '有効', value: false},
                      {label: '無効', value: true}
                    ]"
                    item-text="label"
                    item-value="value"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" md="8" lg="9">
        <v-row dense>
          <v-col cols="7">
            <div class="d-flex align-center">
              <v-chip 
                outlined
                color="primary"
              >
                プロジェクト内アカウント
              </v-chip>
            </div>
            <UsersTable
              class="mt-3"
              height="calc(100vh - 196px)"
              :loading="loadingCount > 0"
              :users="usersInProject"
              :roles="roles"
              :filter-params="filterParamsForUsersInProject"
              :invisible-headers-values="invisibleUserHeadersValues"
              :additional-headers="[{
                text: '管理者',
                value: 'isManager',
                width: 100,
                align: 'center',
                sortable: true,
              }]"
            >
              <template #itemPrepend="{ props }">
                <v-btn
                  icon
                  color="primary"
                  small
                  class="mr-1"
                  @click="removeFromProject(props.item)"
                >
                  <v-icon>mdi-account-minus-outline</v-icon>
                </v-btn>
              </template>
              <template #item.isManager="{ props }">
                <div
                  class="d-flex align-center justify-center"
                >
                  <v-switch
                    v-model="props.item.affiliation.isManager"
                    @change="updateProjectManager(props.item)"
                  />
                </div>
              </template>
            </UsersTable>
          </v-col>
          <v-col cols="5">
            <div class="d-flex align-center">
              <v-chip 
                outlined
                color="secondary"
              >
                他アカウント
              </v-chip>              
            </div>
            <UsersTable
              class="mt-3"
              height="calc(100vh - 196px)"
              :loading="loadingCount > 0"
              :users="otherUsers"
              :roles="roles"
              :filter-params="filterParamsForOtherUsers"
              :invisible-headers-values="invisibleUserHeadersValues"
            >
              <template #itemPrepend="{ props }">
                <v-btn
                  icon
                  color="primary"
                  small
                  class="mr-1"
                  @click="addToProject(props.item)"
                >
                  <v-icon>mdi-account-plus-outline</v-icon>
                </v-btn>
              </template>
            </UsersTable>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-overlay
      absolute
      :value="loadingCount > 0"
      opacity="0.5"
    >
      <v-progress-circular
        indeterminate
        size="32"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import MarkdownEditor from "@components/inputs/MarkdownEditor.vue";

import UsersTable from "@components/parts/tables/UsersTable.vue";

export default {
  components: {
    MarkdownEditor,
    UsersTable,
  },
  props: {
    id: {
      required: true,
    }
  },
  data: function(){
    return {
      loadingCount: 0,
      project: [],

      roles: [],
      users: [],
      affiliations: [],
      filterParamsForUsersInProject: {
        'isDeleted': [0],
      },
      filterParamsForOtherUsers: {
        'isDeleted': [0],
      },

      invisibleUserHeadersValues: [
        'isDeleted',
        'sensors',
        'isProMode',
        'isKarteEnable',
        'isMagEnable',
        'isAbnormalTrajectorySettingEnable',
        'isSwayModeEnable',
        'isContinuousTrajectoryEnable',
        'isContinuousTrajectorySeparated',
        'isPostureEnable',
        'isAssistEnable',
        'is2DAccEnable',
        'isFreezingIdexesOutputEnable',
        'isRawDataOutputEnable',
        'isReportOutputEnable',
      ],
    }
  },
  computed: {
    ...mapState([
      'user',
    ]),
    usersInProject: function(){
      return this.users.map(u => ({
        ...u, 
        affiliation: this.affiliations.find(a => a.userId == u.id)})
      ).filter(u => u.affiliation);
    },
    otherUsers: function(){
      return this.users.filter(u => !this.affiliations.some(a => a.userId == u.id));
    },
  },
  watch: {
  },
  destroyed: function(){
  },
  mounted: function(){
    if(!this.user?.role?.isAdmin){
      this.$router.push('/');
      return;
    }

    this.fetchAll();
  },
  methods: {
    fetchAll: function(){
      this.fetchUsers();
      this.fetchRoles();
      this.fetchProject();
    },
    fetchUsers: function(){
      this.loadingCount++;
      this.axios.get(`/api/users`).then(response => {
        this.users = response.data.users;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.loadingCount--;
      })
    },
    fetchRoles: function(){
      this.loadingCount++;
      this.axios.get(`/api/roles`).then(response => {
        this.roles = response.data.roles;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.loadingCount--;
      })
    },
    fetchProject: function(){
      this.loadingCount++;
      this.axios.get(`/api/project/${this.id}`).then(response => {
        this.project = response.data.project;
        this.affiliations = response.data.affiliations;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.loadingCount--;
      })
    },
    updateProjectAttribute: function(key){
      if(!this.project.maxUserNumber || this.project.maxUserNumber < 0){
        this.project.maxUserNumber = 0;
      }
      this.axios.put(`/api/project/${key}`, this.project).then(() => {

      }).catch(error => {
        alert('プロジェクト更新に失敗しました');
        console.log(error);
      });
    },
    removeFromProject: function(user){
      this.loadingCount++;
      this.axios.delete(`/api/user_project_affiliation/${user.id}/${this.id}`).then(() => {
        this.fetchProject();
      }).catch(error => {
        console.log(error);
        alert("プロジェクトからの削除に失敗しました");
      }).finally(() => {
        this.loadingCount--;
      })
    },
    addToProject: function(user){
      if(this.project.maxUserNumber <= this.usersInProject.length){
        alert("プロジェクトの最大ユーザ数に達しています");
        return;
      }
      

      this.loadingCount++;
      this.axios.post(`/api/user_project_affiliation/${user.id}/${this.id}`).then(() => {
        this.fetchProject();
      }).catch(error => {
        console.log(error);
        alert("プロジェクトへの追加に失敗しました");
      }).finally(() => {
        this.loadingCount--;
      })
    },
    updateProjectManager: function(user){
      this.axios.put(
        `/api/user_project_affiliation`,
        user.affiliation
      ).then(() => {

      }).catch(error => {
        console.log(error);
        alert("プロジェクト内アカウント情報の更新に失敗しました");
        this.fetchAll();
      }).finally(() => {
      })
    },
  }
}
</script>

<style lang="scss" scoped>

</style>