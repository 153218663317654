import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#174E9E', //colors.indigo.darken4,
        secondary: colors.blueGrey.darken4,
        tertiary: "#9B1717",
        info: '#E6E8ED',
        success: '#518ADB',
        accent: '#DC9543',//colors.orange.darken4,
      },
    },
    options: { customProperties: true },
  },
});
