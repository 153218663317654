<template>
  <v-container fluid class="pa-0">
    <EyeCatch />

    <v-row v-if="isVerifyingMailSent">
      <v-col cols="12" sm="6" lg="4" class="mx-auto">
        <v-alert
          prominent
          type="success"
          class="ma-5"
        >
          ログイン用メールが{{ accountId }}へ送信されました。<br/>
          メール内のリンクよりログインを完了してください。
        </v-alert>
      </v-col>
    </v-row>
    <template v-else>
      <v-row align="center" justify="center" class="pt-5" dense>
        <v-col md="3">
          <v-text-field dense class="mt-5" v-model="accountId" label="メールアドレス" />
        </v-col>
      </v-row>
      <v-row align="center" justify="center" dense>
        <v-col md="3">
          <v-text-field dense @keydown.enter="login" type="password" v-model="password" label="パスワード" />
        </v-col>
      </v-row>
      <v-row align="center" justify="center" dense>
        <v-btn 
          outlined
          color="primary"
          text 
          @click.stop="login" 
          :disabled="(!password || password.length == 0) || (!accountId || accountId.length == 0)"
        >ログイン</v-btn>
      </v-row>

      <v-row v-if="isVerificationFailed">
        <v-col cols="12" sm="6" lg="4" class="mx-auto">
          <v-alert
            prominent
            type="error"
            class="ma-5"
          >
            アカウント認証に失敗しました。<br/>
            URLが不正もしくは期限切りの可能性があります。<br/>
            再度上記よりログインを行ってください。
          </v-alert>
        </v-col>
      </v-row>
    </template>

    <v-overlay :value="isLoading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import EyeCatch from '@parts/EyeCatch.vue';

export default {
  name: 'App',
  components: {
    EyeCatch,
  },
  data: () => ({
    accountId: null,
    password: null,
    isLoading: false,
    isVerifyingMailSent: false,
    isVerificationFailed: false,
  }),
  computed: {
    ...mapState([
    ]),
  },
  watch:{
  },
  mounted() {
    if(this.$route.meta?.verification){
      const userId = this.$route.params.userId;
      const verificationKey = this.$route.params.verificationKey;
      this.verify(userId, verificationKey);
    }
  },
  methods: {
    ...mapActions([
      'setUser',
    ]),
    verify: function(userId, verificationKey){
      this.isLoading = true;
      this.axios.get(`/api/verify_account/${userId}/${verificationKey}`).then(response => {
        if(response.data.result === 'ok'){
          this.setUser({
            ...response.data.user,
            role: response.data.role,
          })

          this.$cookies.set('token', response.data.access_token);
          this.axios.defaults.headers.common['Authorization'] = "Bearer " + response.data.access_token;
          this.isVerificationFailed = false;
          this.$router.push('/')
        }else{
          this.isVerificationFailed = true;
        }
      }).catch(error => {
        console.log(error);
        alert('アカウント認証に失敗しました');
      }).finally(() => {
        this.isLoading = false;
      })
    },
    login: function(){
      this.isLoading = true;
      this.axios.post(`/api/verify_account`, {
        mailAddress: this.accountId,
        password: this.password,
      }).then(response => {
        if(response.data.result === 'not_found'){
          alert('該当アカウントが存在しません');
          return;
        }
        if(response.data.result === 'duplicated'){
          alert('duplicatedアカウントが複数存在しています');
          return;
        }
        if(response.data.result === 'deleted'){
          alert('該当アカウントは削除されています');
          return;
        }
        if(response.data.result === 'incorrect'){
          alert('パスワードが正しくありません');
          return;
        }
        
        if(response.data.result === 'ok'){
          this.isVerifyingMailSent = true;
        }
      }).catch(error => {
        console.log(error);
        alert('ログインに失敗しました');
      }).finally(() => {
        this.isLoading = false;
      });
    },
  }
};


</script>