<template>
  <v-container fluid class="fill-height pt-0 pr-0">
    <template v-if="gaitGroup">
      <v-row class="px-3 mt-1" style="height: 100%; overflow: hidden;">
        <v-col
          v-if="isSideMenuOpened"
          cols="12"
          md="4"
          lg="3"
          class="pa-0 pr-3 d-flex flex-column"
        >
          <div class="d-flex align-center">
            <v-btn 
              icon
              small
              color="primary" 
              @click="fetchAll"
              class="mb-1"
            >
              <v-icon>mdi-reload</v-icon>
            </v-btn>
            <v-spacer />
            <v-btn
              v-if="false"
              depressed
              small
              color="primary" 
              @click="isSideMenuOpened = false"
            >
              <v-icon>mdi-cog</v-icon>
              <v-icon>mdi-arrow-collapse-left</v-icon>
            </v-btn>
          </div>

          <simplebar style="flex: 1; height: 0; overflow-x: hidden;">
            <v-card 
              class="pa-0 mt-2 elevation-0"
              outlined
            >
              <v-card-title 
                class="primary darken-3 white--text py-1"
              >
                ID: {{ gaitGroup.id }}
                <v-spacer/>
                <Dialog 
                  color="accent"
                  ref="groupSelectorDiablog"
                  small
                >
                  <v-icon>mdi-swap-horizontal</v-icon>
                  <template #title>歩行グループ切替</template>
                  <template #contents>
                    <GaitGroupsTable
                      class="mt-3"
                      height="calc(100vh - 320px)"
                      :groups="gaitGroups"
                      elevation="0"
                      selectable
                      :filter-params="{
                        isDeleted: false,
                      }"
                      :invisible-headers-values="[
                        'isDeleted',
                      ]"
                      @select="selectGaitGroup($event);"
                    />
                  </template>
                  <template #actions>
                    <span></span>
                  </template>
                </Dialog>
                
              </v-card-title>
              <v-card-text>
                <v-container class="px-0 pb-0">
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        label="グループ名"
                        v-model="gaitGroup.name"
                        hide-details
                        @change="updateGroupAttribute('name')"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-textarea
                        label="説明"
                        class="mt-3"
                        v-model="gaitGroup.description"
                        @change="updateGroupAttribute('description')"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-select
                        label="有効/無効"
                        v-model="gaitGroup.isDeleted"
                        dense
                        hide-details
                        @change="updateGroupAttribute('isDeleted')"
                        :items="[
                          {label: '有効', value: false},
                          {label: '無効', value: true}
                        ]"
                        item-text="label"
                        item-value="value"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        label="作成日時"
                        v-model="gaitGroup.createdDatetime"
                        class="mt-5"
                        dense
                        hide-details
                        readonly
                        outlined
                      />
                    </v-col>
                  </v-row>

                  <v-btn
                    color="primary darken-2 my-2 py-1"
                    block
                    v-if="!isGroupCommentsOpened"
                    @click="isGroupCommentsOpened=true"
                  >
                    <v-icon>mdi-comment-text-multiple</v-icon>
                    <v-icon>mdi-unfold-more-horizontal</v-icon>
                  </v-btn>
                  <CommentsCard
                    class="pa-0 my-2 elevation-0"
                    :id="id"
                    target="gait_group"
                    outlined
                    v-if="isGroupCommentsOpened"
                  >
                    <template #titleContainer>
                      <v-card-title 
                        class="primary darken-2 text-subtitle-1 white--text py-1 mb-3"
                        style="cursor: pointer;"
                        @click="isGroupCommentsOpened=false"
                      >
                        <v-spacer></v-spacer>
                        <v-icon color="white">mdi-comment-text-multiple</v-icon>
                        <v-icon color="white">mdi-unfold-less-horizontal</v-icon>
                        <v-spacer></v-spacer>
                      </v-card-title>
                    </template>
                  </CommentsCard>
                  <v-row dense>
                    <v-col>
                      <v-btn
                        v-if="comparableId"
                        color="accent"
                        block
                        :to="`/gait_group/${id}`"
                      >
                        単一グループ
                      </v-btn>
                      <Dialog
                        v-else
                        color="accent"
                        block
                      >
                        グループ比較
                        <template #title>比較グループ選択</template>
                        <template #contents>
                          <GaitGroupsTable
                            class="mt-3"
                            height="calc(100vh - 320px)"
                            :groups="gaitGroups"
                            elevation="0"
                            selectable
                            :filter-params="{
                              isDeleted: false,
                            }"
                            :invisible-headers-values="[
                              'isDeleted',
                            ]"
                            @select="selectComparableGaitGroup($event)"
                          />
                        </template>
                        <template #actions>
                          <span></span>
                        </template>
                      </Dialog>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>

            <v-card 
              class="pa-0 mt-2 elevation-0"
              outlined
              v-if="comparableId && comparableGaitGroup"
            >
              <v-card-title 
                class="tertiary darken-3 white--text py-1"
              >
                ID: {{ comparableGaitGroup.id }}
                <v-spacer/>
                <Dialog 
                  color="accent"
                  ref="comparableGroupSelectorDiablog"
                  small
                >
                  <v-icon>mdi-swap-horizontal</v-icon>
                  <template #title>歩行グループ切替</template>
                  <template #contents>
                    <GaitGroupsTable
                      class="mt-3"
                      height="calc(100vh - 320px)"
                      :groups="gaitGroups"
                      elevation="0"
                      selectable
                      :filter-params="{
                        isDeleted: false,
                      }"
                      :invisible-headers-values="[
                        'isDeleted',
                      ]"
                      @select="
                        selectComparableGaitGroup($event);
                      "
                    />
                  </template>
                  <template #actions>
                    <span></span>
                  </template>
                </Dialog>
                
              </v-card-title>
              <v-card-text>
                <v-container class="px-0 pb-0">
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        label="グループ名"
                        v-model="comparableGaitGroup.name"
                        hide-details
                        @change="updateComparableGroupAttribute('name')"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-textarea
                        label="説明"
                        class="mt-3"
                        v-model="comparableGaitGroup.description"
                        @change="updateComparableGroupAttribute('description')"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-select
                        label="有効/無効"
                        v-model="comparableGaitGroup.isDeleted"
                        dense
                        hide-details
                        @change="updateComparableGroupAttribute('isDeleted')"
                        :items="[
                          {label: '有効', value: false},
                          {label: '無効', value: true}
                        ]"
                        item-text="label"
                        item-value="value"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        label="作成日時"
                        v-model="comparableGaitGroup.createdDatetime"
                        class="mt-5"
                        dense
                        hide-details
                        readonly
                        outlined
                      />
                    </v-col>
                  </v-row>

                  <v-btn
                    color="tertiary darken-2 my-2 py-1"
                    block
                    v-if="!isComparableGroupCommentsOpened"
                    @click="isComparableGroupCommentsOpened=true"
                  >
                    <v-icon color="white">mdi-comment-text-multiple</v-icon>
                    <v-icon color="white">mdi-unfold-more-horizontal</v-icon>
                  </v-btn>
                  <CommentsCard
                    class="pa-0 my-2 elevation-0"
                    :id="comparableId"
                    target="gait_group"
                    outlined
                    v-if="isComparableGroupCommentsOpened"
                  >
                    <template #titleContainer>
                      <v-card-title 
                        class="tertiary darken-2 text-subtitle-1 white--text py-1 mb-3"
                        style="cursor: pointer;"
                        @click="isGroupCommentsOpened=false"
                      >
                        <v-spacer></v-spacer>
                        <v-icon color="white">mdi-comment-text-multiple</v-icon>
                        <v-icon color="white">mdi-unfold-less-horizontal</v-icon>
                        <v-spacer></v-spacer>
                      </v-card-title>
                    </template>
                  </CommentsCard>
                  <v-row dense>
                    <v-col>
                      <v-btn
                        color="accent"
                        block
                        :to="`/gait_group/${comparableId}`"
                      >
                        単一グループ
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </simplebar>
        </v-col>

        <v-col 
          cols="12" 
          :md="isSideMenuOpened ? 8 : null" 
          :lg="isSideMenuOpened ? 9 : null"
          class="d-flex flex-column pb-0"
        >
          <v-row 
            v-if="!isSideMenuOpened"
            justify="start" 
            align="start" 
            style="flex: initial;" 
          >
            <v-btn
              depressed
              large
              color="primary" 
              class="mr-3" 
              @click="isSideMenuOpened = true"
            >
              <v-icon>mdi-cog</v-icon>
              <v-icon>mdi-arrow-collapse-right</v-icon>
            </v-btn>

            <v-btn 
              icon 
              large 
              color="primary" 
              class="mr-3" 
              @click="fetchAll"
            >
              <v-icon>mdi-reload</v-icon>
            </v-btn>
            <v-col cols="2">
              <div>
                <v-text-field
                  class="text-h6"
                  v-model="gaitGroup.name"
                  dense
                  label="名前"
                  hide-details
                  @change="updateGroupAttribute('name')"
                />
              </div>
            </v-col>
            <v-col cols="6">
              <div>
                <v-text-field
                  class="text-h6"
                  v-model="gaitGroup.description"
                  dense
                  label="説明"
                  hide-details
                  @change="updateGroupAttribute('description')"
                />
              </div>
            </v-col>
            <v-col class="ml-auto mr-2" justify="center" align="end" cols="2">
              <v-text-field
                class="text-h6"
                v-model="gaitGroup.createdDatetime"
                dense
                label="作成日時"
                hide-details
                readonly
                outlined
              />
            </v-col>
          </v-row>

          <v-row class="d-flex align-center pr-3" style="flex: initial;">
            <v-tabs 
              v-model="currentTabIndex" 
              class="mb-5"
              background-color="primary darken-2"
              dark
            >
              <v-tab
                v-for="tab in tabs"
                :key="tab.key"
              >{{ tab.label }}</v-tab>
            </v-tabs>
          </v-row>

          <simplebar ref="scrollbar" style="flex: 1; height: 0; overflow-x: hidden; margin-left: -12px; margin-top: -8px;">
            <v-container 
              fluid 
              class="pt-3 pr-5"
              v-if="currentTabKey === 'setting'"
            >
              <v-row>
                <v-col cols="6">
                  <div class="d-flex align-center mb-3">
                    <v-chip
                      outlined
                      class="mr-5"
                      color="primary"
                    >グループ内歩行一覧</v-chip>

                    <v-spacer/>
                    <v-tooltip 
                      top
                      color="secondary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="secondary"
                          v-bind="attrs"
                          v-on="on"
                          @click="removeFilteredFromGroup"
                        >一括除去</v-btn>
                      </template>
                      フィルタ条件に合致する分析を全てグループから外します
                    </v-tooltip>
                  </div>
                  <GaitsTable
                    :gaits="gaitsInGroup"
                    ref="gaitsInGroup"
                  >
                    <template #itemPrepend="{ props }">
                      <v-btn
                        icon
                        color="primary"
                        small
                        class="mr-1"
                        @click="removeFromGroup(props.item)"
                      >
                        <v-icon>mdi-note-minus</v-icon>
                      </v-btn>
                    </template>
                  </GaitsTable>
                </v-col>
                <v-col cols="6">
                  <div class="d-flex align-center mb-3">
                    <v-chip
                      outlined
                      class="mr-5"
                      color="primary"
                    >その他</v-chip>

                    <v-spacer/>
                    <v-tooltip 
                      top
                      color="secondary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="secondary"
                          v-bind="attrs"
                          v-on="on"
                          @click="addFilteredToGroup"
                        >一括追加</v-btn>
                      </template>
                      フィルタ条件に合致する分析を全てグループに追加します
                    </v-tooltip>
                  </div>
                  <GaitsTable
                    ref="gaitsNotInGroup"
                    :gaits="gaitsNotInGroup"
                  >
                    <template #itemPrepend="{ props }">
                      <v-btn
                        icon
                        color="primary"
                        small
                        class="mr-1"
                        @click="addToGroup(props.item)"
                      >
                        <v-icon>mdi-note-plus</v-icon>
                      </v-btn>
                    </template>
                  </GaitsTable>
                </v-col>
              </v-row>
            </v-container>
            <GaitGroupReport
              v-if="currentTabKey === 'detail'"
              fluid 
              class="pt-3 pr-5"
              :group-id="id"
              :comparable-group-id="comparableId"
              :gait-ids="gaitIds"
              :comparable-gait-ids="comparableGaitIds"
            >
            </GaitGroupReport>
          </simplebar>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import GaitGroupReport from "@components/pages/GaitGroupReport.vue";
import GaitGroupsTable from "@components/parts/tables/GaitGroupsTable.vue";
import GaitsTable from "@components/parts/tables/GaitsTable.vue";
import CommentsCard from "@components/parts/cards/CommentsCard.vue";
import Dialog from "@components/parts/dialogs/Dialog.vue";

export default {
  components: {
    GaitGroupReport,
    GaitGroupsTable,
    GaitsTable,
    CommentsCard,
    Dialog,
  },
  props: {
    id: {
      required: true,
    },
    comparableId: {
      default: null,
    },
  },
  data: function(){
    return {
      isSideMenuOpened: true,
      isGroupCommentsOpened: false,
      isComparableGroupCommentsOpened: false,
      currentTabIndex: 0,
      tabs: [
        {label: '設定', key: 'setting'},
        {label: '詳細', key: 'detail'},
      ]
    }
  },
  computed: {
    ...mapState([
      'gaitGroup',
      'comparableGaitGroup',
      'gaitGroups',
      'gaits',

      'loadingCount',
    ]),
    isLoading: function(){
      return this.loadingCount > 0;
    },
    currentTab: function(){
      return this.tabs[this.currentTabIndex];
    },
    currentTabKey: function(){
      return this.currentTab?.key;
    },
    gaitIds: function(){
      return this.gaitGroup?.gaitIds ?? [];
    },
    comparableGaitIds: function(){
      return this.comparableGaitGroup?.gaitIds ?? [];
    },
    gaitsInGroup: function(){
      return this.gaits.filter(g => this.gaitIds.includes(g.id));
    },
    gaitsNotInGroup: function(){
      return this.gaits.filter(g => !this.gaitIds.includes(g.id));
    },
  },
  watch: {
    id: function(){
      this.fetchAll();
    },
    comparableId: function(){
      this.fetchAll();
    },
  },
  mounted: function(){
    this.fetchAll();
  },
  methods: {
    ...mapActions([
      'fetchGaitGroup',
      'fetchGaits',
      'fetchComparableGaitGroup',
      'fetchGaitGroups',
    ]),
    fetchAll: function(){
      this.fetchGaits();
      this.fetchGaitGroups();

      if(this.group?.id != this.id){
        this.fetchGaitGroup(this.id);
      }

      if(this.comparableId != null && this.comparableGaitGroup?.id != this.comparableId){
        this.fetchComparableGaitGroup(this.comparableId);
      }
    },
    selectGaitGroup: function(group){
      this.$refs.groupSelectorDiablog?.close();
      this.$refs.comparableGroupSelectorDiablog?.close();
      if(this.id == group.id){
        return;
      }
      this.$router.push(
        this.comparableId 
          ? `/gait_group/${group.id}/${this.comparableId}` 
          : `/gait_group/${group.id}`
        );
    },
    selectComparableGaitGroup: function(group){
      this.$refs.groupSelectorDiablog?.close();
      this.$refs.comparableGroupSelectorDiablog?.close();
      if(this.comparableId == group.id){
        return;
      }
      this.$router.push(`/gait_group/${this.id}/${group.id}`);
    },
    updateGroupAttribute: function(key){
      this.axios.put(`/api/gait_group/${key}`, this.gaitGroup).then(() => {
      }).catch(error => {
        console.log(error);
        alert('歩行グループ更新に失敗しました');
      })
    },
    updateComparableGroupAttribute: function(key){
      this.axios.put(`/api/gait_group/${key}`, this.comparableGaitGroup).then(() => {
      }).catch(error => {
        console.log(error);
        alert('歩行グループ更新に失敗しました');
      })
    },
    fetchGaitGroupAfflications: function(){
      this.axios.get(`/api/gait_group_affiliations/${this.gaitGroup.id}`).then(response => {
        this.$set(
          this.gaitGroup, 'gaitIds', response.data.affiliations.map(a => a.gaitId)
        );
      }).catch(error => {
        alert("歩行グループ情報取得に失敗しました");
        console.log(error);
      });
    },
    removeFromGroup: function(gait){
      this.axios.delete(`/api/gait_group_affiliation/${this.gaitGroup.id}/${gait.id}`).then(() => {
        this.fetchGaitGroupAfflications();
      }).catch(error => {
        alert("歩行グループ更新に失敗しました");
        console.log(error);
      });
    },
    addToGroup: function(gait){
      this.axios.post(`/api/gait_group_affiliation/${this.gaitGroup.id}/${gait.id}`).then(() => {
        this.fetchGaitGroupAfflications();
      }).catch(error => {
        alert("歩行グループ更新に失敗しました");
        console.log(error);
      })
    },
    removeFilteredFromGroup: function(){
      const gaits = this.$refs.gaitsInGroup?.getFilteredItems() ?? [];
      gaits.forEach(g => this.removeFromGroup(g));
    },
    addFilteredToGroup: function(){
      const gaits = this.$refs.gaitsNotInGroup?.getFilteredItems() ?? [];
      gaits.forEach(g => this.addToGroup(g));
    },
  },
}
</script>