<template>
  <v-tooltip
    color="primary darken-4"
    v-bind="$attrs"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        class="ml-3"
        v-bind="attrs"
        v-on="on"
        color="grey"
      >mdi-information</v-icon>
    </template>

    <div class="text-h6 mt-2">背景色の意味</div>

    <v-img 
      max-width="480px"
      class="ma-3" 
      contain 
      src="@/assets/images/gait_phase.png"
    />
  </v-tooltip>
</template>


<script>
export default {
  props: {
  }
}
</script>