var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center"},[(_vm.iconHidden == null)?_c('div',{staticClass:"d-flex align-center mr-3"},[_c('v-btn',{attrs:{"color":"accent","disabled":_vm.hasFFT,"height":"40"},on:{"click":function($event){_vm.$emit('reload'); _vm.hasFFT=true}}},[_vm._v(" 周波数 "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-reload")])],1)],1):_vm._e(),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: "ctrl+wheelまたはマウスオーバしてctrl+「+」/ctrl+「-」で選択範囲の拡大/縮小<br/>ドラッグまたはshift+wheelで選択範囲の移動<br/>領域両端部分ドラッグで選択開始/終了点調整<br/>",
      delay: {
        show: 2000,
        hide: 0
      },
    }),expression:"{\n      content: `ctrl+wheelまたはマウスオーバしてctrl+「+」/ctrl+「-」で選択範囲の拡大/縮小<br/>ドラッグまたはshift+wheelで選択範囲の移動<br/>領域両端部分ドラッグで選択開始/終了点調整<br/>`,\n      delay: {\n        show: 2000,\n        hide: 0\n      },\n    }"}],ref:"phaseGraphContainer",staticClass:"lighten-5 phase-graph-container",staticStyle:{"height":"48px"},on:{"click":function($event){if(!$event.shiftKey){ return null; }return _vm.startShift.apply(null, arguments)},"wheel":[function($event){if(!$event.ctrlKey){ return null; }$event.preventDefault();return _vm.scaleSelection.apply(null, arguments)},function($event){if(!$event.shiftKey){ return null; }$event.preventDefault();return _vm.moveSelection.apply(null, arguments)}],"mousemove":_vm.adjustSelection,"mouseenter":_vm.setKeyEventListeners,"mouseleave":_vm.removeKeyEventListeners}},[(_vm.currentSelection)?_c('div',{staticClass:"selected-area",class:_vm.color,style:({
        left: ((100*_vm.currentSelection.from/_vm.duration) + "%"),
        width: ((100*(_vm.currentSelection.to - _vm.currentSelection.from)/_vm.duration) + "%"),
      }),on:{"mousedown":function($event){return _vm.grabSelection($event)}}},[_c('div',{staticClass:"grip start",on:{"mousedown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }$event.stopPropagation();return _vm.grabSelection($event, 'start')}}}),_c('div',{staticClass:"grip end",on:{"mousedown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }$event.stopPropagation();return _vm.grabSelection($event, 'end')}}})]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }