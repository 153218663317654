import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import vuetify from './plugins/vuetify'
//import './registerServiceWorker'
import { createRouter } from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
import VueObserveVisibility from 'vue-observe-visibility'
import VueResizeObserver from "vue-resize-observer";
import VueHtmlToPaper from 'vue-html-to-paper';

import simplebar from "simplebar-vue";
import 'simplebar/dist/simplebar.min.css';

import VTooltip from 'v-tooltip'

import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

Vue.config.productionTip = false
Vue.use(Vuex);
Vue.use(VueCookies)
Vue.use(VueObserveVisibility)
Vue.use(VueResizeObserver)
Vue.use(VTooltip)
Vue.$cookies.config('365y')

const myAxios = axios.create();
if(process.env.NODE_ENV === 'localhost'){
  myAxios.defaults.baseURL = "http://localhost";
}
Vue.use(VueAxios, myAxios);
Vue.use(
  VueHtmlToPaper, 
  {
    specs: [
      'fullscreen=yes',
      'titlebar=no',
      'scrollbars=no'
    ],
    autoClose: true,
  }
);

Vue.component('Simplebar', simplebar);

const store = new Vuex.Store({
  state: {
    user: null,
    menus: [
      { title: 'ホーム', icon: 'mdi-home', url: '/'},
      { title: '計測参加者管理', icon: 'mdi-card-account-details', url: '/participants', project: true,},
      { title: '歩行分析一覧', icon: 'mdi-walk', url: '/gaits', project: true,},
      { title: '重心動揺一覧', icon: 'mdi-human', url: '/sway', project: true,},      
      { title: '姿勢分析一覧', icon: 'mdi-yoga', url: '/postures', project: true,},
      { title: 'メンバー管理', icon: 'mdi-account-group', url: '/members', project: true, validation: (user, project) => user.role.isAdmin || project?.affiliation?.isManager, },

      { title: '顧客管理', icon: 'mdi-handshake-outline', url: '/clients', validation: (user) => user.role.isAdmin, bottom: true,},
      { title: 'APK管理', icon: 'mdi-android', url: '/apks', validation: (user) => user.role.isAdmin, bottom: true,},
      { title: 'プロジェクト管理', icon: 'mdi-clipboard-text-outline', url: '/projects', validation: (user) => user.role.isAdmin, bottom: true,},
      { title: 'アカウント管理', icon: 'mdi-card-account-details', url: '/users', validation: (user) => user.role.isAdmin, bottom: true,},
    ],
    rootPath: process.env.NODE_ENV === 'localhost' ? 'http://localhost' : '',
    project: null,
    projects: [],
    participants: [],
    authorizedUsersMap: {},
    loadingCount: 0,


    gaits: [],
    gait: null,
    gaitGroup: null,
    comparableGaitGroup: null,
    gaitTrajectory: null,
    gaitRawData: null,
    gaitTrajectoryDataGeneratingCount: 0,
    gaitFreezingIndexes: null,
    comparableGait: null,
    comparableGaitTrajectory: null,
    comparableGaitRawData: null,
    comparableGaitTrajectoryDataGeneratingCount: 0,
    comparableGaitFreezingIndexes: null,
    gaitGroups: [],


    swayList: [],
    sway: null,
    swayGroup: null,
    comparableSwayGroup: null,
    swayTrajectory: null,
    swayRawData: null,
    comparableSway: null,
    comparableSwayTrajectory: null,
    comparableSwayRawData: null,
    swayGroups: [],


    visibility: {
      gaitReport: {
        ankle: {
          all: true,
          top: true,
          front: true,
          side: true,
          continuous: {
            left: true,
            right: true,
          },
          features: {
            table: true,
            graph: {
              stride: true,
              strideDuration: true,
              velocity: true,
              height: true,
              stepDuration: true,
              swingStrength: true,
              swingPhaseDuration: true,
              stancePhaseDuration: true,
              singleStancePhaseDuration: true,
            },
          },
          sensor: {
            left: true,
            right: true,
          },
          freezingIndexes: {
            left: {
              400: true,
              600: true,
            },
            right: {
              400: true,
              600: true,
            },
          },
        },
        trunk: {
          graph: true,
          sensor: true,
          features: {
            table: true,
            graph: {
              trunkSwingWidth: true,
              trunkSwingHeight: true,
            },
          },
          freezingIndexes: {
            400: true,
            600: true,
          },
        },
      },
      swayReport: {
        trajectory: {
          position: {
            coordinate: true,
            velocity: true,
            acceleration: true,
          },
          angle: {
            coordinate: true,
            velocity: true,
            acceleration: true,
          },
        },
        spectrum: {
          position: {
            coordinate: true,
            velocity: true,
            acceleration: true,
          },
          angle: {
            coordinate: true,
            velocity: true,
            acceleration: true,
          },
        },
        features: {
          position: {
            table: true,
          },
          angle: {
            table: true,
          },
        },
        sensor: true,
      },
    },

    movingAverageWidth: 50,

    applicationFunctions: [
      {
        key: 'isProMode',
        name: 'プロモード',
      },
      {
        key: 'isKarteEnable',
        name: 'カルテ',
      },
      {
        key: 'isMagEnable',
        name: '地磁気',
      },
      {
        key: 'isAbnormalTrajectorySettingEnable',
        name: '異常軌道設定',
      },
      {
        key: 'isSwayModeEnable',
        name: '重心動揺',
      },
      {
        key: 'isSwayTimerEnable',
        name: '重心動揺タイマー',
      },

      {
        key: 'isContinuousTrajectoryEnable',
        name: '連続軌道',
      },
      {
        key: 'isContinuousTrajectorySeparated',
        name: '連続軌道分離',
      },
      {
        key: 'isPostureEnable',
        name: '姿勢計測',
      },
      {
        key: 'isAssistEnable',
        name: 'アシスト',
      },
      {
        key: 'isPlaneAccEnable',
        name: '加速度平面表示',
      },

      {
        key: 'isFreezingIdexesOutputEnable',
        name: 'すくみ指数xls',
      },
      {
        key: 'isRawDataOutputEnable',
        name: 'センサデータxls',
      },
      {
        key: 'isReportOutputEnable',
        name: 'レポート出力',
      },
      {
        key: 'isCustomConsentFormEnable',
        name: 'カスタム同意書',
      },
    ],
    

    isGraphVisible: 
      true, //本番用
//      false, //開発用
  },
  getters: {
    validMenus(state) {
      let user = state.user;
      if(!user || !user.role){
        return [];
      }
      
      return state.menus.filter(m => {
        if(!m.validation){
          return true;
        }

        return m.validation(user, state.project);
      });
    },
  },
  mutations: {
    setUser(state, val) {
      state.user = val;
    },
    setProject(state, val) {
      state.project = val;
    },
    setProjects(state, val) {
      state.projects = val;
    },
    fetchGaitDetail(state, gaitId) {
      state.loadingCount++;
      Vue.axios.get(`/api/gait/${gaitId}`).then(response => {
        this.commit('setGait', response.data.gait);
        this.commit('setGaitTrajectory', response.data.trajectory);
        this.commit('setGaitRawData', response.data.rawData);
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        state.loadingCount--;
      })

      state.loadingCount++;
      Vue.axios.get(`/api/gait/freezing_indexes/${gaitId}`).then(response => {
        state.gaitFreezingIndexes = response.data;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        state.loadingCount--;
      })
    },
    fetchGaitGroup(state, groupId) {
      state.loadingCount++;
      Vue.axios.get(`/api/gait_group/${groupId}`).then(response => {
        state.loadingCount++;
        const group = response.data.group;
        Vue.axios.get(`/api/gait_group_affiliations/${groupId}`).then(response => {
          this.commit('setGaitGroup', {
            ...group,
            gaitIds: response.data.affiliations.map(a => a.gaitId),
          });
        }).catch(error => {
          console.log(error);
        }).finally(() => {
          state.loadingCount--;
        });
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        state.loadingCount--;
      })
    },
    fetchComparableGaitGroup(state, groupId) {
      state.loadingCount++;
      Vue.axios.get(`/api/gait_group/${groupId}`).then(response => {
        state.loadingCount++;
        const group = response.data.group;
        Vue.axios.get(`/api/gait_group_affiliations/${groupId}`).then(response => {
          this.commit('setComparableGaitGroup', {
            ...group,
            gaitIds: response.data.affiliations.map(a => a.gaitId),
          });
        }).catch(error => {
          console.log(error);
        }).finally(() => {
          state.loadingCount--;
        });
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        state.loadingCount--;
      })
    },
    fetchSwayGroup(state, groupId) {
      state.loadingCount++;
      Vue.axios.get(`/api/sway_group/${groupId}`).then(response => {
        state.loadingCount++;
        const group = response.data.group;
        Vue.axios.get(`/api/sway_group_affiliations/${groupId}`).then(response => {
          this.commit('setSwayGroup', {
            ...group,
            swayIds: response.data.affiliations.map(a => a.swayId),
          });
        }).catch(error => {
          console.log(error);
        }).finally(() => {
          state.loadingCount--;
        });
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        state.loadingCount--;
      })
    },
    fetchComparableSwayGroup(state, groupId) {
      state.loadingCount++;
      Vue.axios.get(`/api/sway_group/${groupId}`).then(response => {
        const group = response.data.group;
        Vue.axios.get(`/api/sway_group_affiliations/${groupId}`).then(response => {
          this.commit('setComparableSwayGroup', {
            ...group,
            swayIds: response.data.affiliations.map(a => a.swayId),
          });
        }).catch(error => {
          console.log(error);
        }).finally(() => {
          state.loadingCount--;
        });
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        state.loadingCount--;
      })
    },
    fetchSwayDetail(state, swayId) {
      state.loadingCount++;
      Vue.axios.get(`/api/sway/${swayId}`).then(response => {
        this.commit('setSway', response.data.sway);
        this.commit('setSwayTrajectory', response.data.trajectory);
        this.commit('setSwayRawData', response.data.rawData);
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        state.loadingCount--;
      })
    },
    fetchComparableGaitDetail(state, gaitId) {
      state.loadingCount++;
      Vue.axios.get(`/api/gait/${gaitId}`).then(response => {
        this.commit('setComparableGait', response.data.gait);
        this.commit('setComparableGaitTrajectory', response.data.trajectory);
        this.commit('setComparableGaitRawData', response.data.rawData);
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        state.loadingCount--;
      })

      state.loadingCount++;
      Vue.axios.get(`/api/gait/freezing_indexes/${gaitId}`).then(response => {
        state.comparableGaitFreezingIndexes = response.data;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        state.loadingCount--;
      })
    },
    fetchComparableSwayDetail(state, swayId) {
      state.loadingCount++;
      Vue.axios.get(`/api/sway/${swayId}`).then(response => {
        this.commit('setComparableSway', response.data.sway);
        this.commit('setComparableSwayTrajectory', response.data.trajectory);
        this.commit('setComparableSwayRawData', response.data.rawData);
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        state.loadingCount--;
      })
    },

    setGait(state, val) {
      state.gait = val;
    },
    setGaitGroup(state, val) {
      state.gaitGroup = val;
    },
    setComparableGaitGroup(state, val) {
      state.comparableGaitGroup = val;
    },
    setSwayGroup(state, val) {
      state.swayGroup = val;
    },
    setComparableSwayGroup(state, val) {
      state.comparableSwayGroup = val;
    },
    setGaitTrajectory(state, val) {
      state.gaitTrajectory = val;
    },
    setGaitRawData(state, val) {
      state.gaitRawData = val;
    },

    setSway(state, val) {
      state.sway = val;
    },
    setSwayTrajectory(state, val) {
      state.swayTrajectory = val;
    },
    setSwayRawData(state, val) {
      state.swayRawData = val;
    },

    setComparableGait(state, val) {
      state.comparableGait = val;
    },
    setComparableGaitTrajectory(state, val) {
      state.comparableGaitTrajectory = val;
    },
    setComparableGaitRawData(state, val) {
      state.comparableGaitRawData = val;
    },

    setComparableSway(state, val) {
      state.comparableSway = val;
    },
    setComparableSwayTrajectory(state, val) {
      state.comparableSwayTrajectory = val;
    },
    setComparableSwayRawData(state, val) {
      state.comparableSwayRawData = val;
    },

    fetchParticipants(state){
      if(!state.project){
        return;
      }
      
      state.loadingCount++;
      Vue.axios.get(`/api/participants/${state.project.id}`).then(response => {
        state.participants = response.data.participants;

        let authorizedUsersMap = response.data.authorizedUsersMap;
        state.participants.forEach(p => {
          if(!(p.id in authorizedUsersMap)){
            authorizedUsersMap[p.id] = [];
          }
        });
        state.authorizedUsersMap = authorizedUsersMap;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        state.loadingCount--;
      });
    },
    fetchGaits(state){
      if(!state.project){
        return;
      }
      
      state.loadingCount++;
      Vue.axios.get(`/api/gaits/${state.project.id}`).then(response => {
        state.gaits = response.data.gaits.map(g => ({
          ...g,
          status: g.isAnalyzed ? (g.isFailed ? 'failed' : 'analyzed') : 'analyzing',
        }));
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        state.loadingCount--;
      });
    },
    fetchGaitGroups(state){
      if(!state.project){
        return;
      }
      
      state.loadingCount++;
      Vue.axios.get(`/api/gait_groups/${state.project.id}`).then(response => {
        state.gaitGroups = response.data.groups;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        state.loadingCount--;
      });
    },
    fetchSwayGroups(state){
      if(!state.project){
        return;
      }
      
      state.loadingCount++;
      Vue.axios.get(`/api/sway_groups/${state.project.id}`).then(response => {
        state.swayGroups = response.data.groups;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        state.loadingCount--;
      });
    },
    fetchSwayList(state){
      if(!state.project){
        return;
      }
      
      state.loadingCount++;
      Vue.axios.get(`/api/sway_list/${state.project.id}`).then(response => {
        state.swayList = response.data.swayList.map(s => ({
          ...s,
          status: s.isAnalyzed ? (s.isFailed ? 'failed' : 'analyzed') : 'analyzing',
        }));
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        state.loadingCount--;
      });
    },
    incrementLoadingCount(state){
      state.loadingCount++;
    },
    decrementLoadingCount(state){
      state.loadingCount--;
    },
  },
  actions: {
    setUser({ commit }, val) {
      commit('setUser', val);
    },
    setProject({ commit }, val) {
      commit('setProject', val);
    },
    setProjects({ commit }, val) {
      commit('setProjects', val);
    },


    setGait({ commit }, val) {
      commit('setGait', val);
    },
    setGaitTrajectory({ commit }, val) {
      commit('setGaitTrajectory', val);
    },
    setGaitRawData({ commit }, val) {
      commit('setGaitRawData', val);
    },

    setComparableGait({ commit }, val) {
      commit('setComparableGait', val);
    },
    setComparableGaitTrajectory({ commit }, val) {
      commit('setComparableGaitTrajectory', val);
    },
    setComparableGaitRawData({ commit }, val) {
      commit('setComparableGaitRawData', val);
    },

    fetchParticipants({ commit }){
      commit('fetchParticipants');
    },
    fetchGaits({ commit }){
      commit('fetchGaits');
    },
    fetchGaitGroups({ commit }){
      commit('fetchGaitGroups');
    },
    fetchSwayList({ commit }){
      commit('fetchSwayList');
    },
    fetchGaitDetail({ commit }, gaitId){
      commit('fetchGaitDetail', gaitId);
    },
    fetchGaitGroup({ commit }, gaitId){
      commit('fetchGaitGroup', gaitId);
    },
    fetchComparableGaitGroup({ commit }, gaitId){
      commit('fetchComparableGaitGroup', gaitId);
    },
    fetchComparableGaitDetail({ commit }, gaitId){
      commit('fetchComparableGaitDetail', gaitId);
    },

    fetchSwayDetail({ commit }, swayId){
      commit('fetchSwayDetail', swayId);
    },
    fetchComparableSwayDetail({ commit }, swayId){
      commit('fetchComparableSwayDetail', swayId);
    },

    fetchSwayGroups({ commit }){
      commit('fetchSwayGroups');
    },
    fetchSwayGroup({ commit }, swayId){
      commit('fetchSwayGroup', swayId);
    },
    fetchComparableSwayGroup({ commit }, swayId){
      commit('fetchComparableSwayGroup', swayId);
    },

    incrementLoadingCount({ commit }){
      commit('incrementLoadingCount');
    },
    decrementLoadingCount({ commit }){
      commit('decrementLoadingCount');
    },
  },
});

const router = createRouter();

new Vue({
  vuetify,
  router,
  render: h => h(App),
  store
}).$mount('#app')
