<template>
  <v-container>
    <v-row class="px-0 mt-1" style="height: 100%; overflow: hidden;">
      <FeaturesScatterChartCard
        title="各レポート特徴量散布図"
        cols="12"
        :aspect-ratio="3.84"
        :features="scatterChartFeatures"
      />

      <v-col cols="12">
        <v-row class="d-flex align-center">
          <v-chip
            class="ml-3 my-0"
            :color="comparableGroupId ? 'primary darken-1' : 'secondary'"
            outlined
          >
            重心位置特徴量{{ comparableGroupId ? ` - ID: ${groupId}` : null }}
          </v-chip>
        </v-row>
        <v-row>
          <v-col class="pt-1">
            <v-simple-table
              ref="table"
              :height="aspectRatio ? `${tableHeight}px` : null"
              v-resize="resizeContainer"
              dense
            >
              <SwayFeaturesTableBody
                :loading="isLoading"
                :coordinates="coordinates"
                :direction-types="directionTypes"
                :view-types="viewTypes"
                :geometric-features="positionGeometricFeatures"
                :directional-features="positionDirectionalFeatures"
              />
            </v-simple-table>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" v-if="comparableGroupId">
        <v-row class="d-flex align-center">
          <v-chip
            class="ml-3 my-0"
            color="tertiary darken-1"
            outlined
          >
            重心位置特徴量 - ID: {{ comparableGroupId }}
          </v-chip>
        </v-row>
        <v-row>
          <v-col class="pt-1">
            <v-simple-table
              ref="table"
              :height="aspectRatio ? `${tableHeight}px` : null"
              dense
            >
              <SwayFeaturesTableBody
                :loading="isLoading"
                color="tertiary darken-1 white--text"
                :coordinates="coordinates"
                :direction-types="directionTypes"
                :view-types="viewTypes"
                :geometric-features="comparablePositionGeometricFeatures"
                :directional-features="comparablePositionDirectionalFeatures"
              />
            </v-simple-table>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-row class="d-flex align-center">
          <v-chip
            class="ml-3 my-0"
            :color="comparableGroupId ? 'primary darken-1' : 'secondary'"
            outlined
          >
            姿勢角度特徴量{{ comparableGroupId ? ` - ID: ${groupId}` : null }}
          </v-chip>
        </v-row>
        <v-row>
          <v-col class="pt-1">
            <v-simple-table
              ref="table"
              :height="aspectRatio ? `${tableHeight}px` : null"
              dense
            >
              <SwayFeaturesTableBody
                :loading="isLoading"
                :coordinates="coordinates"
                :direction-types="directionTypes"
                :view-types="viewTypes"
                :geometric-features="angleGeometricFeatures"
                :directional-features="angleDirectionalFeatures"
              />
            </v-simple-table>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" v-if="comparableGroupId">
        <v-row class="d-flex align-center">
          <v-chip
            class="ml-3 my-0"
            color="tertiary darken-1"
            outlined
          >
            姿勢角度特徴量 - ID: {{ comparableGroupId }}
          </v-chip>
        </v-row>
        <v-row>
          <v-col class="pt-1">
            <v-simple-table
              ref="table"
              :height="aspectRatio ? `${tableHeight}px` : null"
              dense
            >
              <SwayFeaturesTableBody
                :loading="isLoading"
                color="tertiary darken-1 white--text"
                :coordinates="coordinates"
                :direction-types="directionTypes"
                :view-types="viewTypes"
                :geometric-features="comparableAngleGeometricFeatures"
                :directional-features="comparableAngleDirectionalFeatures"
              />
            </v-simple-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { 
  getTrajectoryPolygonData,
  getSwayGeometricFeatures,
  getSwayDirectionalFeatures,
} from '@js/utils/StatisticsUtil.js';

import SwayFeaturesTableBody from "@components/parts/tables/SwayFeaturesTableBody.vue";
import FeaturesScatterChartCard from "@components/parts/cards/FeaturesScatterChartCard.vue";

export default {
  components: {
    SwayFeaturesTableBody,
    FeaturesScatterChartCard,
  },
  props: {
    groupId: {
      default: 0,
    },
    comparableGroupId: {
      default: null,
    },
    swayIds: {
      default: () => [],
    },
    comparableSwayIds: {
      default: () => [],
    },
    aspectRatio: {
      default: 3.84
    },
  },
  data: function(){
    return {
      swayMap: {},
      graphCols: {
        cols: 12,
        sm: 6,
        lg: 6
      },
      coordinates: [
        {key: 'coordinate', label: '位置'},
        {key: 'velocity', label: '速度'},
        {key: 'acceleration', label: '角速度'},
      ],
      directionTypes: [
        {key: 'x', label: '左右'},
        {key: 'y', label: '上下'},
        {key: 'z', label: '前後'},
      ],
      viewTypes: [
        {key: 'side', label: '前後・上下'},
        {key: 'front', label: '左右・上下'},
        {key: 'top', label: '左右・前後'},
      ],
      featuresKeys: [
        'position', 
        'angle',
      ]
    }
  },
  computed: {
    ...mapState([
      'loadingCount',
    ]),
    isLoading: function(){
      return this.loadingCount > 0;
    },
    tableHeight: function(){
      return this.aspectRatio ? parseInt(this.tableWidth/this.aspectRatio) : 0;
    },
    sways: function(){
      return this.getSwayList(
        this.swayIds.map(id => this.swayMap[id]).filter(s => !!s)
      );
    },
    comparableSways: function(){
      return this.getSwayList(
        this.comparableSwayIds.map(id => this.swayMap[id]).filter(s => !!s)
      );
    },
    averagedSwayFeatures: function(){
      return this.getAveragedSwayFeatures(this.sways);
    },
    positionGeometricFeatures: function(){
      return this.getAveragedFeatures(this.averagedSwayFeatures?.position?.geometric ?? []);
    },
    positionDirectionalFeatures: function(){
      return this.getAveragedFeatures(this.averagedSwayFeatures?.position?.directional ?? []);
    },
    angleGeometricFeatures: function(){
      return this.getAveragedFeatures(this.averagedSwayFeatures?.angle?.geometric ?? []);
    },
    angleDirectionalFeatures: function(){
      return this.getAveragedFeatures(this.averagedSwayFeatures?.angle?.directional ?? []);
    },
    averagedComparableSwayFeatures: function(){
      return this.getAveragedSwayFeatures(this.comparableSways);
    },
    comparablePositionGeometricFeatures: function(){
      return this.getAveragedFeatures(this.averagedComparableSwayFeatures?.position?.geometric ?? []);
    },
    comparablePositionDirectionalFeatures: function(){
      return this.getAveragedFeatures(this.averagedComparableSwayFeatures?.position?.directional ?? []);
    },
    comparableAngleGeometricFeatures: function(){
      return this.getAveragedFeatures(this.averagedComparableSwayFeatures?.angle?.geometric ?? []);
    },
    comparableAngleDirectionalFeatures: function(){
      return this.getAveragedFeatures(this.averagedComparableSwayFeatures?.angle?.directional ?? []);
    },
    scatterChartFeatures: function(){
      let rtn = [];
      const MODES = [
        {key: 'position', label: '重心位置'},
        {key: 'angle', label: '姿勢角度'},
      ]

      MODES.forEach(mode => {
        if(!(mode.key in this.averagedSwayFeatures)){
          return;          
        }

        this.averagedSwayFeatures[mode.key].geometric.forEach((f, fi) => {
          const name = f.name;
          this.coordinates.forEach((c, ci) => {
            this.viewTypes.forEach((v, vi) => {
              const i =this.viewTypes.length*ci + vi;
              let valMap = {
                [this.groupId]: f.reportVals.map(vals => vals[i]),
              };
              if(this.comparableGroupId != null && this.comparableGroupId != this.groupId){
                valMap[this.comparableGroupId] = this.averagedComparableSwayFeatures[mode.key]?.geometric[fi]?.reportVals?.map(vals => vals[i]) ?? [];
              }
              rtn.push({
                name: `${mode.label} ${c.label} ${v.label} ${name}`,
                valMap: valMap,
              })
            });
          });
        });
        
        this.averagedSwayFeatures[mode.key].directional.forEach((f, fi) => {
          const name = f.name;
          this.coordinates.forEach((c, ci) => {
            this.directionTypes.forEach((v, vi) => {
              const i =this.directionTypes.length*ci + vi;
              let valMap = {
                [this.groupId]: f.reportVals.map(vals => vals[i]),
              };
              if(this.comparableGroupId != null && this.comparableGroupId != this.groupId){
                valMap[this.comparableGroupId] = this.averagedComparableSwayFeatures[mode.key]?.directional[fi]?.reportVals?.map(vals => vals[i]) ?? [];
              }
              rtn.push({
                name: `${mode.label} ${c.label} ${v.label} ${name}`,
                valMap: valMap,
              })
            });
          });
        });
      });
      return rtn;
    },
  },
  watch: {
    swayIds: {
      handler: function(newVal){
        this.fetchSwayDetails(newVal);
      },
      immediate: true,
      deep: true,
    },
    comparableSwayIds: {
      handler: function(newVal){
        this.fetchSwayDetails(newVal);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapActions([
      'incrementLoadingCount',
      'decrementLoadingCount',
    ]),
    resizeContainer: function(){
      this.$nextTick(() => {
        this.tableWidth = this.$refs.table?.$el?.clientWidth ?? 0;
      });
    },
    getAveragedSwayFeatures: function(sways){
      let rtn = {};
      sways.forEach(s => {
        for(let key in s.features){
          if(!(key in rtn)){
            rtn[key] = {
            };
          }

          for(let type in s.features[key]){
            if(!(type in rtn[key])){
              rtn[key][type] = s.features[key][type].map(f => ({
                name: f.name,
                reportVals: [],
              }));
            }

            s.features[key][type].forEach((f, i) => {
              rtn[key][type][i].reportVals.push(f.vals);
            })
          }
        }
      });
      return rtn;
    },
    getAveragedFeatures: function(features){
      return features.map(f => {
        let vals = [];
        const c = f.reportVals.length;
        f.reportVals.forEach(_vals => {
          _vals.forEach((v, i) => {
            if(vals.length <= i){
              vals.push(0);
            }
            vals[i] += v/c;
          });
        });

        return {
          name: f.name,
          vals: vals,
        }
      });
    },
    getSwayList: function(sways){
      return sways.map(s => {
        let features = {};
        this.featuresKeys.forEach(key=> {
          features[key] = {
            directional: getSwayDirectionalFeatures(
              s.trajectory[key],
              s.spectrums[key],
              null,
              this.coordinates,
              this.directionTypes
            ),
            geometric: getSwayGeometricFeatures(
              s.trajectory[key],
              s.polygon[key],
              null,
              this.coordinates,
              this.viewTypes
            ),
          }
        });
        return {
          ...s,
          features: features,
        };
      })
    },
    fetchSwayDetails: function(ids){
      ids.forEach(id => {
        this.fetchSwayDetail(id);
      });
    },
    fetchSwayDetail: function(id){
      if(id in this.swayMap){
        return;
      }

      this.incrementLoadingCount();
      this.axios.get(`/api/sway/${id}`).then(response => {
        const sway = response.data.sway;
        const trajectory = response.data.trajectory;
        this.incrementLoadingCount();
        this.axios.post(`/api/sway/frequency/0/${Number.MAX_SAFE_INTEGER}`, {
          trajectory: trajectory,
        }).then(response => {
          this.$set(this.swayMap, id, {
            sway: sway,
            trajectory: trajectory,
            spectrums: response.data,
            polygon: getTrajectoryPolygonData(trajectory),
          });
        }).finally(() => {
          this.decrementLoadingCount();
        })
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.decrementLoadingCount();
      })
    },
  },
}
</script>