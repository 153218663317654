<template>
  <v-col v-show="visible" v-bind="$attrs">
    <v-row v-if="noMenu == null">
      <v-chip
        class="ml-3"
        :color="color"
        outlined
      >
        {{ title }}
      </v-chip>
    </v-row>
    <v-row :dense="dense != null">
      <v-col class="pt-0 mt-0">
        <div 
          ref="chartContainer"
          class="chart-container"
          :style="{
            height: chartHeight + 'px',
          }"
          v-observe-visibility="isVisible => (isVisible ? resizeContainer() : false)"
        >
          <LineChart 
            ref="chart"
            :style="{
              height: chartHeight + 'px',
            }"
            :hide-legends="hideLegends"
          />
        </div>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import LineChart from '@components/parts/charts/LineChart.vue'

export default {
  components: {
    LineChart,
  },
  props: {
    id: {},
    visible: {
      default: true,
    },
    trajectory: {
      required: true,
    },
    color: {
      default: 'secondary'
    },
    title: {
    },
    aspectRatio: {
      default: 1.92
    },
    noMenu: {
      default: null,
    },
    hideLegends: {
			default: null,
		},
    only: {
      default: null,
    },
    dense: {
      default: null,
    }
  },
  data: function(){
    return {
      chartWidth: 0,
    }
  },
  watch: {
    trajectory: {
			handler(){
        this.$nextTick(() => {
          this.updateChart();
        });
			},
			deep: true,
			immediate: true,
		},
    only: function(){
      this.updateChart();
    },
    aspectRatio: function(){
      this.$nextTick(() => {
        this.updateChart();
      });
    }
  },
  mounted() {
    this.resizeContainer();
	},
  computed: {
    chartHeight: function(){
      return parseInt(this.chartWidth/this.aspectRatio);
    },
  },
  methods: {
    resizeContainer: function(){
      this.chartWidth = this.$refs.chartContainer?.clientWidth ?? 0;
    },
    updateChart: function(){
      if(!this.$refs.chart){
        return;
      }

      this.$refs.chart.dataCollection.datasets = this.createLineChartData();
      this.$refs.chart.setRangeX({
        from: 0,
        to: 10,
      })
      this.$forceUpdate();
    },
    createLineChartData: function(){
      let rtn = [];

      const defaultData = {
        fill: false,
        backgroundColor: 'hsla(0, 77%, 28%, 0.2)',
        borderColor: 'hsla(0, 77%, 28%, 0.2)',
        borderWidth: 2,
        label: '',
      };

      let axes = [
        {key: 'x', label: '左右'},
        {key: 'y', label: '上下'},
        {key: 'z', label: '前後'},
      ]

      if(this.only){
        switch(this.only){
          case 'top':
            axes = [
              {key: 'x', label: '左右'},
              {key: 'z', label: '前後'},
            ];
            break;
          case 'side':
            axes = [
              {key: 'y', label: '上下'},
              {key: 'z', label: '前後'},
            ];
            break;
          case 'front':
            axes = [
              {key: 'x', label: '左右'},
              {key: 'y', label: '上下'},
            ];
            break;
        }
      }

      let windowWidth = this.trajectory.x.length;

      axes.forEach((axis, i) => {
        const color = this.getLineChartColor(i*120);
        rtn.push({
          ...defaultData,
          label: axis.label,
          backgroundColor: color,
          borderColor: color,
          data: this.trajectory[axis.key].map((val, index) => ({
            x: index/(windowWidth*0.01),
            y: val
          })),
        });
      })

      return rtn;
    },
    getLineChartColor: function(hue){
      return `hsla(${hue}, 77%, 28%, 0.4)`;
    },
  },
}
</script>

<style lang="scss" scoped>
.chart-container{
  ::v-deep{
    canvas {
      height: 100% !important;
    }
  }
}
</style>