<template>
  <v-container fluid>
    <v-row justify="center" dense>
      <v-btn 
        absolute 
        left 
        icon 
        large 
        color="primary" 
        class="mr-3" 
        @click="fetchAll"
      >
        <v-icon>mdi-reload</v-icon>
      </v-btn>

      <Dialog
        ref="projectDeletionDialog"
        width="480px"
        @apply="switchProjectDeleted"
        dialog-only
        outlined
      >
        <template #title>
          プロジェクト{{ target.isDeleted ? '有効化' : '無効化' }}
        </template>

        <template #contents>
          <v-container dense>
            <v-row dense align="center">
              <v-col dense>
                {{ target.isDeleted ? '以下のプロジェクトを有効にしますか？' : '以下のプロジェクトを無効にしますか？' }}
              </v-col>
            </v-row>
            <v-row dense align="center">
              <v-col dense class="text-h5 pl-5">
                {{ target.name }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <MarkdownEditor
                  v-model="target.description"
                  label="説明"
                  outlined
                  readonly
                />
              </v-col>
            </v-row>
          </v-container>
        </template>
        
        <template #apply>
          {{ target.isDeleted ? '有効化' : '無効化' }}
        </template>
      </Dialog>


      <Dialog
        ref="newProjectDialog"
        width="480px"
        outlined
        @open="initNewProject"
        :disabled="!isNewProjectValid"
        @apply="createNewProject"
      >
        <template>
          <v-icon class="mr-2">mdi-clipboard-text-outline</v-icon>
          プロジェクト追加
        </template>

        <template #title>
          プロジェクト追加
        </template>

        <template #contents>
          <v-container dense>
            <v-form 
              ref="newProjectForm" 
              @submit.prevent
              autocomplete="off" 
              lazy-validation
            >
              <v-row dense align="center">
                <v-col>
                  <v-text-field 
                    dense
                    class="mt-5" 
                    autocomplete="new-name"
                    v-model="newProjectData.name" 
                    label="名前"
                    :rules="[required]"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <MarkdownEditor
                    label="説明"
                    v-model="newProjectData.description"
                    outlined
                  />
                </v-col>
              </v-row>
              <v-row dense align="center">
                <v-col>
                  <v-text-field 
                    dense
                    class="mt-5" 
                    autocomplete="max-user-number"
                    v-model="newProjectData.maxUserNumber" 
                    label="最大ユーザ数"
                    type="number"
                    :rules="[required]"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </template>

        <template #apply>追加</template>
      </Dialog>
    </v-row>
    <v-row dense>
      <v-col>
        <v-data-table
          fixed-header
          class="mt-3 elevation-3"
          height="calc(100vh - 196px)"
          :loading="isLoading"
          :items="filteredProjects"
          :headers="headers"
          :item-class="rowClasses"
          :custom-sort="customSort"
        >
          <template v-slot:body.prepend>
            <tr>
              <td 
                v-for="header in headers" 
                :key="header.value"
              >
                <template v-if="header.value === 'id'">
                  <div class="d-flex">
                    <v-text-field
                      v-model="filterParams['id']"
                      dense
                      hide-details
                      class="ml-1"
                    />
                  </div>
                </template>
                <template v-else-if="header.value === 'isDeleted'">
                  <div class="d-flex">
                    <v-select
                      v-model="filterParams['isDeleted']"
                      dense
                      hide-details
                      class="text-no-wrap mr-1"
                      :items="[
                        {label: '', value: null}, 
                        {label: '有効', value: false},
                        {label: '無効', value: true},
                      ]"
                      item-text="label"
                      item-value="value"
                    ></v-select>
                  </div>
                </template>
                <v-select
                  v-else-if="booleanHeaders.some(h => h === header.value)"
                  v-model="filterParams[header.value]"
                  dense
                  hide-details
                  class="text-no-wrap"
                  :items="[{label: '', value: null}, {label: 'ON', value: true}, {label: 'OFF', value: false}]"
                  item-text="label"
                  item-value="value"
                ></v-select>
                <v-text-field
                  v-else-if="header.filterable"
                  dense
                  hide-details
                  v-model="filterParams[header.value]"
                  :hint="header.text"
                  single-line
                ></v-text-field>
              </td>
            </tr>
          </template>
          <template v-slot:item.id="props">
            <div class="d-flex align-center">
              <v-btn 
                icon 
                small 
                color="accent" 
                class="mr-auto"
                :to="`/project/${props.item.id}`"
              >
                <v-icon>mdi-arrow-top-right-bold-box-outline</v-icon>
              </v-btn>
              {{ props.item.id }}
            </div>
          </template>

          <template v-slot:item.isDeleted="props">
            <v-btn 
              @click="openProjectDeleteModal(props.item)" 
              icon 
              small 
              color="primary" 
              class="mr-auto"
            >
              <v-icon>{{ props.item.isDeleted ? 'mdi-delete-off' : 'mdi-delete' }}</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.name="props">
            {{ props.item.name }}
            <v-tooltip 
              right
              v-if="props.item.description && props.item.description.length > 0"
              color="secondary darken-4"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon 
                  class="ml-2"
                  color="secondary"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-comment-text-outline
                </v-icon>
              </template>
              <MarkdownEditor
                v-model="props.item.description"
                readonly
              />
            </v-tooltip>
          </template>

          <template v-slot:item.users="props">
            {{ props.item.users.length }}
            <v-tooltip 
              right
              v-if="props.item.users.length > 0"
              color="secondary darken-4"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon 
                  class="ml-2"
                  color="secondary"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-account-group
                </v-icon>
              </template>
              <div  
                v-for="_user in props.item.users"
                :key="_user.id"
              >
                {{ _user.name }}
              </div>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

import MarkdownEditor from "@components/inputs/MarkdownEditor.vue";
import Dialog from "@components/parts/dialogs/Dialog.vue";

export default {
  components: {
    MarkdownEditor,
    Dialog,
  },
  data: function(){
    return {
      loadingCount: 0,
      filterParams: {},
      users: [],
      projects: [],
      affiliations: [],
      headers: [
        {
          text: 'ID', 
          value: 'id',
          width: 120,
          sortable: true,
          align: 'end',
          filterable: true,
          filter: value => !this.filterParams['id']?.length || String(value).indexOf(this.filterParams['id']) >= 0,
        },
        {
          text: 'プロジェクト名', 
          value: 'name',
          sortable: true,
          filterable: true,
          filter: value => !this.filterParams['name']?.length || value?.indexOf(this.filterParams['name']) >= 0,
        },
        {
          text: 'ユーザ数', 
          value: 'users',
          sortable: true,
          align: 'end',
          width: 130,
        },
        {
          text: '最大ユーザ数', 
          value: 'maxUserNumber',
          sortable: true,
          align: 'end',
          width: 130,
        },
        {
          text: '更新日時', 
          value: 'updatedTime',
          sortable: true,
          align: 'end',
          width: 120,
        },
        {
          text: '作成日時', 
          value: 'createdTime',
          sortable: true,
          align: 'end',
          width: 120,
        },
        {
          text: '有効/無効', 
          value: 'isDeleted',
          width: 120,
          sortable: true,
          filterable: true,
          align: 'center',
          filter: value => this.filterParams['isDeleted'] == null || value == this.filterParams['isDeleted'],
        },
      ],
      booleanHeaders: [],
      required: value => !!value || "※必須",
      newProjectData: {},
      isNewProjectValid: false,
      target: {},
    }
  },
  computed: {
    ...mapState([
      'user',
    ]),
    projectsWithUsers: function(){
      return this.projects.map(p => ({
        ...p,
        users: this.affiliations.filter(
          a => a.projectId == p.id
        ).map(
          a => this.users.find(u => u.id == a.userId)
        )
      }));
    },
    filteredProjects: function(){
      let rtn = [...this.projectsWithUsers];
      if(this.filterParams['isDeleted'] != null){
        rtn = rtn.filter(p => p.isDeleted == this.filterParams['isDeleted']);
      }
      return rtn;
    },
    rowClasses: function(){
      return item => item.isDeleted ? 'blue-grey lighten-4' : null;
    },
    isLoading: function(){
      return this.loadingCount > 0;
    },
  },
  watch: {
    newProjectData: {
      handler: function(){
        this.$nextTick(() => {
          this.isNewProjectValid = this.$refs.newProjectForm?.validate();
        });
      },
      deep: true,
    },
  },
  destroyed: function(){
  },
  mounted: function(){
    if(!this.user?.role?.isAdmin){
      this.$router.push('/');
      return;
    }

    this.fetchAll();
  },
  methods: {
    customSort(items, index, isDesc) {
      let isAsc = !isDesc[0];
      let target = index[0];
      if(!target){
        return items;
      }
      items.sort((a, b) => {
        if (target === "users") {
          return isAsc 
            ? a.users.length - b.users.length
            : b.users.length - a.users.length;
        } else {
          if (isAsc) {
            return a[target] < b[target] ? -1 : 1;
          } else {
            return b[target] < a[target] ? -1 : 1;
          }
        }
      });
      return items;
    },
    initNewProject: function(){
      this.newProjectData = {
        name: null,
        description: "",
        maxUserNumber: 10,
      };
      this.$nextTick(() => {
        this.isNewProjectValid = this.$refs.newProjectForm.validate();
      });
    },
    fetchAll: function(){
      this.fetchProjects();
      this.fetchUsers();
    },
    fetchUsers: function(){
      this.loadingCount++;
      this.axios.get(`/api/users`).then(response => {
        this.users = response.data.users;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.loadingCount--;
      })
    },
    fetchProjects: function(){
      this.loadingCount++;
      this.axios.get(`/api/projects`).then(response => {
        this.projects = response.data.projects;
        this.affiliations = response.data.affiliations;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.loadingCount--;
      })
    },
    createNewProject: function(){
      this.axios.post(`/api/project`, this.newProjectData).then(() => {
        this.fetchProjects();
      }).catch(error => {
        console.log(error);
        alert('プロジェクト作成に失敗しました');
      });
    },
    openProjectDeleteModal: function(project){
      this.target = project;
      this.$refs.projectDeletionDialog.open();
    },
    switchProjectDeleted: function(){
      let newVal = !this.target.isDeleted;
      this.axios.put(`/api/project/isDeleted`, {
        ...this.target,
        isDeleted: newVal
      }).then(response => {
        if(response.data.result === 'not_found'){
          alert('該当のプロジェクトが存在しません');
          return;
        }

        this.target.isDeleted = newVal;
      }).catch(error => {
        console.log(error);
        alert(newVal ? 'プロジェクト無効化に失敗しました' : 'プロジェクト有効化に失敗しました');
      }).finally(() => {
        this.$refs.projectDeletionDialog.close();
      });
    },
  }
}
</script>

<style lang="scss" scoped>

</style>