<template>
  <v-app dark id="app">
    <v-navigation-drawer
      app 
      color="secondary"
      dark
      v-model="isSideMenuOpened"
    >
      <v-list-item>
        <v-list-item-title></v-list-item-title>
        <v-btn icon @click.stop="isSideMenuOpened=false">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>
      <v-divider />
      <v-list nav v-if="user">
        <template v-for="menu in validMenus.filter(m => !m.bottom)">
          <v-tooltip
            color="primary darken-4"
            bottom
            :key="menu.title"
            :disabled="!isMenuDisabled(menu)"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item 
                v-bind="attrs"
                v-on="on"
                :to="isMenuDisabled(menu) ? null : menu.url"
                :class="{
                  'grey--text text--darken-2': isMenuDisabled(menu)
                }"
              >
                <v-list-item-icon v-if="menu.icon">
                  <v-icon
                    :color="isMenuDisabled(menu)? 'grey darken-2' : null"
                  >{{ menu.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ menu.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            ヘッダーメニューでプロジェクトを選択してください
          </v-tooltip>
        </template>
      </v-list>
      <template v-slot:append>
        <v-list nav v-if="user">
        <template v-for="menu in validMenus.filter(m => m.bottom)">
          <v-tooltip
            color="primary darken-4"
            bottom
            :key="menu.title"
            :disabled="!isMenuDisabled(menu)"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item 
                v-bind="attrs"
                v-on="on"
                :to="isMenuDisabled(menu) ? null : menu.url"
                :class="{
                  'grey--text text--darken-2': isMenuDisabled(menu)
                }"
              >
                <v-list-item-icon v-if="menu.icon">
                  <v-icon :color="isMenuDisabled(menu)? 'grey darken-2' : null">
                    {{ menu.icon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ menu.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            ヘッダーメニューでプロジェクトを選択してください
          </v-tooltip>
        </template>
      </v-list>
      </template>
    </v-navigation-drawer>

    <v-app-bar
      app
      color="primary"
      dark
      dense
    >
      <v-app-bar-nav-icon 
        :disabled="!user"
        @click.stop="isSideMenuOpened = !isSideMenuOpened"
      />
      <v-toolbar-title 
        v-if="!$vuetify.breakpoint.mobile"
        class="pl-1"
      >
        {{ pageTitle }}
      </v-toolbar-title>

      <v-select
        v-if="isLoggedIn"
        v-model="currentProjectId"
        background-color="primary lighten-1"
        placeholder="プロジェクトを選択してください"
        dense
        outlined
        hide-details
        :items="user.projects"
        class="text-no-wrap primary--text"
        :class="{
          'ml-5': !$vuetify.breakpoint.mobile,
          'ml-1 mr-3': $vuetify.breakpoint.mobile
        }"
        :style="{
          'max-width': $vuetify.breakpoint.mobile ? 'calc(100vw - 200px)' : '20vw'
        }"
        item-text="name"
        item-value="id"
      />

      <v-spacer></v-spacer>

      <span 
        v-if="user"
        class="mr-5"
      >
        {{ user.name }}
      </span>

      <v-btn outlined v-if="!!user" text @click.stop="logout">
        ログアウト
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view ref="page" v-if="user"/>
      <Login v-else/>
    </v-main>

    <v-footer v-if="false" app>
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Login from '@pages/Login.vue';

export default {
  name: 'App',
  components: {
    Login
  },
  data: function(){
    return {
      isSideMenuOpened: false,
      pageTitle: '',
      siteName: 'WM GAIT CHECKER Web',
    }
  },
  computed: {
    ...mapState([
      'user',
      'project',
      'projects',
    ]),
    ...mapGetters([
      'validMenus',
    ]),
    isLoggedIn: function(){
      return !!this.user;
    },
    isMenuDisabled: function(){
      return (menu) => {
        if(menu.project && !this.project){
          return true;
        }
        return false;
      }
    },
    currentProjectId: {
      get(){
        return this.project?.id;
      },
      set(newVal){
        this.setProject(this.user?.projects?.find(p => p.id == newVal))
      }
    }
  },
  watch:{
    '$route':{
      handler: function(to) {
        if(to?.meta?.title){
          this.pageTitle = to.meta.title;
        } else {
          this.pageTitle = this.siteName
        }
      },
      immediate: true,
    },
    user: {
      handler: function(newVal){
        if(!newVal){
          this.pageTitle = this.siteName;
        }else{
          this.pageTitle = this.$route?.meta?.title ?? this.siteName;

        }
      },
      immediate: true,
    },
    isLoggedIn: function(newVal){
      if(newVal){
        this.fetchLoggedInUser();
      }else{
        this.setProject(null);
      }
    },
    currentProjectId: function(newVal){
      if(newVal){
        localStorage.setItem('projectId', newVal);
        this.fetchGaits();
        this.fetchSwayList();
        this.fetchParticipants();
      }else{
        localStorage.removeItem('projectId');
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    let accessToken = this.$cookies.get('token');
    if(accessToken){
      this.axios.defaults.headers.common['Authorization'] = "Bearer " + accessToken;
      this.fetchLoggedInUser();
    }

    
  },
  methods: {
    ...mapActions([
      'setUser',
      'setProject',
      'fetchGaits',
      'fetchSwayList',
      'fetchParticipants',
    ]),
    handleResize: function(){
    },
    logout: function(){
      this.setUser(null);
      this.$cookies.remove('token');
    },
    fetchLoggedInUser: function(){
      this.axios.get('/api/user').then(response => {
        this.setUser({
          ...response.data.user,
          role: response.data.role,
          projects: response.data.projects
        });

        let _projectId = localStorage.getItem('projectId');
        if(_projectId){
          this.currentProjectId = parseInt(_projectId);
        }

        if(this.user.projects.length == 1){
          this.setProject(this.user.projects[0]);
        }
      }).catch(error => {
        console.log(error);
        this.$cookies.remove('token');
        this.setProject(null);
      });
    },
  }
};
</script>

<style lang="scss">
@import "@style/global.scss";
</style>