<script>
import {Line, mixins} from 'vue-chartjs'

export default {
	extends: Line,
	mixins: [mixins.reactiveData],
	props: {
		hideLegends: {
			default: null,
		},
		hideXLabels: {
			default: null,
		}
	},
	data: function() {
		return {
			dataCollection: {
				labels: [],
				datasets: []
			},
			options: {
				animation: {
					duration: 0,
				},
				scales: {
					yAxes: [{
						stacked: false,
						beginAtZero: false,
						gridLines: {
							drawBorder: true,
							display: true
						},
						ticks: {
							beginAtZero: false,
							display: true
						}
					}],
					xAxes: [{
						type: 'linear',
						gridLines: {
							display: false
						},
						ticks: {
							display: this.hideXLabels == null,
						}
					}],
				},
				legend: {
					display: this.hideLegends == null,
					position: 'top'
				},
				elements: {
					line: {
						tension: 0,
					},
					point:{
						radius: 0,
					},
				},
				responsive: true,
				maintainAspectRatio: false,
			},
		};
	},
	mounted() {
		this.chartData = this.dataCollection;
	},
	watch: {
	},
	methods: {
		updateChart: function(options){
			const newChartData = Object.assign({}, this.dataCollection);
			this.chartData = newChartData;

			if(options){
				this.setRange(options.range)
			}
		},
		setRange: function(option){
			if(!option){
				return;
			}
			this.setRangeX(option.x);
		},
		setRangeX: function({from, to}){
			if(from != null){
				this.options.scales.xAxes[0].ticks = {
					...this.options.scales.xAxes[0].ticks,
					min: from,
				}
			}

			if(to != null){
				this.options.scales.xAxes[0].ticks = {
					...this.options.scales.xAxes[0].ticks,
					max: to,
				}
			}

			this.rerender();
		},
		rerender: function(){
			if(this.$data._chart){
				this.$data._chart.options = this.options;
				this.$data._chart.update();
			}
		},
	}
}
</script>