<template>
  <v-data-table
    @click:row="selectGait"
    class="gait-table"
    fixed-header
    :height="height"
    :loading="loading"
    :items="filteredItems"
    :headers="visibleHeaders"
    :item-class="rowClasses"
    :page.sync="_currentPage"
    :items-per-page.sync="_itemsPerPage"
    :sort-by="_sortedBy"
    :sort-desc="_sortedDesc"
    @update:sort-by="_sortedBy=$event"
    @update:sort-desc="_sortedDesc=$event"
    :class="`elevation-${elevation}`"
    :footer-props="{
      'items-per-page-options' : [15, 30, 50, 100, -1]
    }"
  >
    <template v-slot:body.prepend>
      <tr>
        <td v-for="header in visibleHeaders" :key="header.value">
          <v-select
            v-if="header.value === 'isDeleted'"
            v-model="filterParams['isDeleted']"
            dense
            hide-details
            class="text-no-wrap mr-1"
            :items="[
              {label: '', value: null}, 
              {label: '有効', value: false},
              {label: '無効', value: true}, 
            ]"
            item-text="label"
            item-value="value"
          ></v-select>
          <v-text-field
            v-else-if="header.filterable"
            dense
            hide-details
            v-model="filterParams[header.value]"
            :hint="header.text"
            single-line
          ></v-text-field>
        </td>
      </tr>
    </template>
    <template v-slot:item.id="props">
      <slot name="itemPrepend" :props="props">
        <v-btn 
          v-if="!isSelectable" 
          icon 
          small 
          color="accent" 
          class="mr-3" 
          :to="`/gait_group/${props.item.id}`"
        >
          <v-icon>mdi-arrow-top-right-bold-box-outline</v-icon>
        </v-btn>
      </slot>
      {{ props.item.id }}
    </template>
    <template v-slot:item.isDeleted="props">
      <Dialog
        outlined
        width="480px"
        @apply="switchDeleted(props.item)"
      >
        <template>
          <v-icon color="primary">
            {{ props.item.isDeleted ? 'mdi-delete-off' : 'mdi-delete' }}
          </v-icon>
        </template>
        <template #title>
          グループ{{ props.item.isDeleted ? '有効化' : '無効化' }}
        </template>
        <template #contents>
          <v-container dense>
            <v-row dense align="center">
              <v-col dense>
                {{ props.item.isDeleted ? '以下のグループを有効にしますか？' : '以下のグループを削除いたしますか？' }}
              </v-col>
            </v-row>
            <v-row dense align="center">
              <v-col dense class="text-h5 pl-5">
                {{ props.item.mailAddress }}
              </v-col>
            </v-row>
            <v-row dense align="center">
              <v-col dense class="text-h5 pl-5">
                {{ props.item.name }}
              </v-col>
            </v-row>
          </v-container>
        </template>
      </Dialog>
    </template>

    <template
      v-for="header in additionalHeaders"
      v-slot:[`item.${header.value}`]="props"
    >
      <slot
        :name="`item.${header.value}`" 
        :props="props"
      >
      </slot>
    </template>
  </v-data-table>
</template>

<script>
import Dialog from "@components/parts/dialogs/Dialog.vue";

export default {
  components: {
    Dialog,
  },
  props: {
    groups: {
      default: () => [],
    },
    additionalHeaders: {
      default: () => [],
    },
    height: {
      default: null,
    },
    loading: {
      default: false,
    },
    invisibleHeadersValues: {
      default: () => []
    },
    filterParams: {
      default: () => ({})
    },
    itemsPerPage: {
      default: 0,
    },
    currentPage: {
      default: 0,
    },
    sortedBy: {
      default: 'id'
    },
    sortedDesc: {
      default: true,
    },
    elevation: {
      default: 3,
    },
    selectable: {
      default: null,
    }
  },
  data: function(){
    return {
      headers: [
        {
          text: 'ID', 
          value: 'id',
          width: 100,
          sortable: true,
          filterable: true,
          filter: value => !this.filterParams['id']?.length || String(value).indexOf(this.filterParams['id']) >= 0,
        },
        {
          text: '名前', 
          value: 'name',
          width: 160,
          sortable: true,
          filterable: true,
          filter: value => !this.filterParams['name']?.length || value?.indexOf(this.filterParams['name']) >= 0,
        },
        {
          text: '説明', 
          value: 'description',
          sortable: true,
          filterable: true,
          filter: value => !this.filterParams['description']?.length || value?.indexOf(this.filterParams['description']) >= 0,
        },
        {
          text: '作成日', 
          value: 'createdDatetime',
          width: 120,
          align: 'right',
          sortable: true,
          filterable: true,
          filter: value => !this.filterParams['createdDatetime']?.length || value?.indexOf(this.filterParams['createdDatetime']) >= 0,
        },
        {
          text: '作成者', 
          value: 'userName',
          width: 160,
          sortable: true,
          filterable: true,
          filter: value => !this.filterParams['userName']?.length || value?.indexOf(this.filterParams['userName']) >= 0,
        },

        {
          text: '有効/無効', 
          value: 'isDeleted',
          width: 120,
          sortable: true,
          filterable: true,
          align: 'center',
          filter: value => this.filterParams['isDeleted'] == null || value == this.filterParams['isDeleted'],
        },
      ],
    }
  },
  computed: {
    isSelectable: function(){
      return this.selectable != null;
    },
    filteredItems: function(){
      let rtn = [...this.groups];
      if(this.filterParams['isDeleted'] != null){
        rtn = rtn.filter(u => u.isDeleted == this.filterParams['isDeleted']);
      }
      return rtn;
    },
    visibleHeaders: function(){
      let rtn = [...this.headers, ...this.additionalHeaders];
      return rtn.filter(h => !this.invisibleHeadersValues.some(v => v === h.value));
    },
    rowClasses: function(){
      return item => {
        let rtn = [];
        if(item.isDeleted){
          rtn.push('blue-grey lighten-4');
        }

        if(this.isSelectable){
          rtn.push('selectable');
        }
        return rtn.join(" ");
      };
    },
    _itemsPerPage: {
      get(){
        return this.itemsPerPage;
      },
      set(newVal){
        this.$emit('update:itemsPerPage', newVal)
      }
    },
    _currentPage: {
      get(){
        return this.currentPage;
      },
      set(newVal){
        this.$emit('update:currentPage', newVal)
      }
    },
    _sortedBy: {
      get(){
        return this.sortedBy;
      },
      set(newVal){
        this.$emit('update:sortedBy', newVal)
      }
    },
    _sortedDesc: {
      get(){
        return this.sortedDesc;
      },
      set(newVal){
        this.$emit('update:sortedDesc', newVal)
      }
    },
  },
  methods: {
    switchDeleted: function(item){
      this.axios.put(`/api/gait_group/isDeleted`, {
        ...item,
        isDeleted: !item.isDeleted
      }).then(() => {
        item.isDeleted = !item.isDeleted;
      }).catch(error => {
        console.log(error);
        alert('歩行グループ更新に失敗しました');
      })
    },
    selectGait: function(gait){
      if(!this.isSelectable){
        return;
      }

      this.$emit('select', gait);
    }
  },
}
</script>

<style lang="scss" scoped>
.gait-table{
  ::v-deep{
    .selectable{
      cursor: pointer;
    }
  }
}
</style>