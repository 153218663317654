<template>
  <v-data-table
    fixed-header
    :height="height"
    :loading="loading"
    :items="filteredItems"
    :headers="visibleHeaders"
    :item-class="rowClasses"
    :page.sync="_currentPage"
    :items-per-page.sync="_itemsPerPage"
    :sort-by="_sortedBy"
    :sort-desc="_sortedDesc"
    @update:sort-by="_sortedBy=$event"
    @update:sort-desc="_sortedDesc=$event"
    class="elevation-3"
    :footer-props="{
      'items-per-page-options' : [15, 30, 50, 100, -1]
    }"
  >
    <template v-slot:body.prepend>
      <tr>
        <td v-for="header in visibleHeaders" :key="header.value">
          <v-select
            v-if="header.value === 'isVideo'"
            v-model="filterParams['isVideo']"
            dense
            hide-details
            class="text-no-wrap"
            :items="[{label: '', value: null}, {label: 'ビデオ', value: true}, {label: '画像', value: false}]"
            item-text="label"
            item-value="value"
          ></v-select>
          <v-select
            v-else-if="header.value === 'isAnalyzed'"
            v-model="filterParams['isAnalyzed']"
            dense
            hide-details
            class="text-no-wrap"
            :items="[{label: '', value: null}, {label: '完了', value: true}, {label: '分析中', value: false}]"
            item-text="label"
            item-value="value"
          ></v-select>
          <v-select
            v-else-if="header.value === 'isDeleted'"
            v-model="filterParams['isDeleted']"
            dense
            hide-details
            class="text-no-wrap mr-1"
            :items="[
              {label: '', value: null}, 
              {label: '有効', value: false},
              {label: '無効', value: true}, 
            ]"
            item-text="label"
            item-value="value"
          ></v-select>
          <v-text-field
            v-else-if="header.filterable"
            dense
            hide-details
            v-model="filterParams[header.value]"
            :hint="header.text"
            single-line
          ></v-text-field>
        </td>
      </tr>
    </template>
    <template v-slot:item.id="props">
      <v-btn icon small color="accent" class="mr-3" :to="`/posture/${props.item.id}`">
        <v-icon>mdi-arrow-top-right-bold-box-outline</v-icon>
      </v-btn>
      {{ props.item.id }}
    </template>
    <template v-slot:item.isVideo="props">
      {{ props.item.isVideo ? 'ビデオ' : '画像' }}
    </template>
    <template v-slot:item.isAnalyzed="props">
      {{ props.item.isAnalyzed ? '完了' : '分析中' }}
    </template>
    <template v-slot:item.isDeleted="props">
      <Dialog
        width="480px"
        @apply="switchDeleted(props.item)"
      >
        <template>
          <v-icon color="primary">
            {{ props.item.isDeleted ? 'mdi-delete-off' : 'mdi-delete' }}
          </v-icon>
        </template>
        <template #title>
          レポート{{ props.item.isDeleted ? '有効化' : '無効化' }}
        </template>
        <template #contents>
          <v-container dense>
            <v-row dense align="center">
              <v-col dense>
                {{ props.item.isDeleted ? '以下のレポートを有効にしますか？' : '以下のレポートを削除いたしますか？' }}
              </v-col>
            </v-row>
            <v-row dense align="center">
              <v-col dense class="text-h5 pl-5">
                {{ props.item.mailAddress }}
              </v-col>
            </v-row>
            <v-row dense align="center">
              <v-col dense class="text-h5 pl-5">
                {{ props.item.name }}
              </v-col>
            </v-row>
          </v-container>
        </template>
      </Dialog>
    </template>

    <template
      v-for="header in additionalHeaders"
      v-slot:[`item.${header.value}`]="props"
    >
      <slot
        :name="`item.${header.value}`" 
        :props="props"
      >
      </slot>
    </template>
  </v-data-table>
</template>

<script>
import Dialog from "@components/parts/dialogs/Dialog.vue";

export default {
  components: {
    Dialog,
  },
  props: {
    postures: {
      default: () => [],
    },
    additionalHeaders: {
      default: () => [],
    },
    height: {
      default: null,
    },
    loading: {
      default: false,
    },
    invisibleHeadersValues: {
      default: () => []
    },
    filterParams: {
      default: () => ({})
    },
    itemsPerPage: {
      default: 0,
    },
    currentPage: {
      default: 0,
    },
    sortedBy: {
      default: 'id'
    },
    sortedDesc: {
      default: true,
    },
  },
  data: function(){
    return {
      headers: [
        {
          text: 'ID', 
          value: 'id',
          width: 100,
          sortable: true,
          filterable: true,
          filter: value => !this.filterParams['id']?.length || String(value).indexOf(this.filterParams['id']) >= 0,
        },
        {
          text: '名前', 
          value: 'name',
          width: 320,
          sortable: true,
          filterable: true,
          filter: value => !this.filterParams['name']?.length || value?.indexOf(this.filterParams['name']) >= 0,
        },
        {
          text: '説明', 
          value: 'description',
          sortable: true,
          filterable: true,
          filter: value => !this.filterParams['description']?.length || value?.indexOf(this.filterParams['description']) >= 0,
        },
        {
          text: '計測参加者', 
          value: 'participantName',
          width: 240,
          sortable: true,
          filterable: true,
          filter: value => !this.filterParams['participantName']?.length || value?.indexOf(this.filterParams['participantName']) >= 0,
        },
        {
          text: '種別', 
          value: 'isVideo',
          width: 120,
          align: 'center',
          sortable: true,
          filterable: true,
          filter: value => this.filterParams['isVideo'] == null || value == this.filterParams['isVideo'],
        },
        {
          text: 'ステータス', 
          value: 'isAnalyzed',
          width: 120,
          align: 'center',
          sortable: true,
          filterable: true,
          filter: value => this.filterParams['isAnalyzed'] == null || value == this.filterParams['isAnalyzed'],
        },
        {
          text: '作成日', 
          value: 'createdTime',
          width: 120,
          align: 'right',
          sortable: true,
          filterable: true,
          filter: value => !this.filterParams['createdTime']?.length || value?.indexOf(this.filterParams['createdTime']) >= 0,
        },
        {
          text: '作成者', 
          value: 'userName',
          width: 240,
          sortable: true,
          filterable: true,
          filter: value => !this.filterParams['userName']?.length || value?.indexOf(this.filterParams['userName']) >= 0,
        },
        {
          text: '有効/無効', 
          value: 'isDeleted',
          width: 120,
          sortable: true,
          filterable: true,
          align: 'center',
          filter: value => this.filterParams['isDeleted'] == null || value == this.filterParams['isDeleted'],
        },
      ],
    }
  },
  computed: {
    filteredItems: function(){
      let rtn = [...this.postures];
      if(this.filterParams['isDeleted'] != null){
        rtn = rtn.filter(u => u.isDeleted == this.filterParams['isDeleted']);
      }
      return rtn;
    },
    visibleHeaders: function(){
      let rtn = [...this.headers, ...this.additionalHeaders];
      return rtn.filter(h => !this.invisibleHeadersValues.some(v => v === h.value));
    },
    rowClasses: function(){
      return item => {
        if(item.isDeleted){
          return 'blue-grey lighten-4';
        }
        if(!item.isAnalyzed){
          return 'grey lighten-4';
        }
        return null;
      };
    },
    _itemsPerPage: {
      get(){
        return this.itemsPerPage;
      },
      set(newVal){
        this.$emit('update:itemsPerPage', newVal)
      }
    },
    _currentPage: {
      get(){
        return this.currentPage;
      },
      set(newVal){
        this.$emit('update:currentPage', newVal)
      }
    },
    _sortedBy: {
      get(){
        return this.sortedBy;
      },
      set(newVal){
        this.$emit('update:sortedBy', newVal)
      }
    },
    _sortedDesc: {
      get(){
        return this.sortedDesc;
      },
      set(newVal){
        this.$emit('update:sortedDesc', newVal)
      }
    },
  },
  methods: {
    switchDeleted: function(item){
      this.axios.put(`/api/posture/isDeleted`, {
        ...item,
        isDeleted: !item.isDeleted
      }).then(() => {
        item.isDeleted = !item.isDeleted;
      }).catch(error => {
        console.log(error);
        alert('姿勢レポート更新に失敗しました');
      })
    }
  },
}
</script>