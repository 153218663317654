var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center","dense":""}},[_c('v-btn',{staticClass:"mr-3",attrs:{"absolute":"","left":"","icon":"","large":"","color":"primary"},on:{"click":_vm.fetchAll}},[_c('v-icon',[_vm._v("mdi-reload")])],1),_c('v-tooltip',{attrs:{"color":"primary darken-4","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"outlined":"","disabled":""}},[_vm._v(" メンバー追加 ")])],1)]}}])},[_vm._v(" メンバーの追加はシステム管理者にお問い合わせください ")])],1),_c('UsersTable',{staticClass:"mt-5",attrs:{"height":"calc(100vh - 196px)","loading":_vm.loadingCount > 0,"users":_vm.validUsers,"filter-params":_vm.filterParams,"invisible-headers-values":_vm.invisibleUserHeadersValues,"additional-headers":[{
      text: '管理者',
      value: 'isManager',
      width: 100,
      align: 'center',
      sortable: true,
    }]},scopedSlots:_vm._u([{key:"item.isManager",fn:function(ref){
    var props = ref.props;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-switch',{staticClass:"ma-auto pa-0",attrs:{"dense":"","hide-details":""},on:{"change":function($event){return _vm.updateProjectManager(props.item)}},model:{value:(props.item.affiliation.isManager),callback:function ($$v) {_vm.$set(props.item.affiliation, "isManager", $$v)},expression:"props.item.affiliation.isManager"}})],1)]}}])}),_c('v-overlay',{attrs:{"absolute":"","value":_vm.loadingCount > 0,"opacity":"0.5"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"32"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }