<template>
  <v-container fluid class="fill-height pt-0 pr-0">
    <v-row 
      v-if="participant" 
      class="px-3 mt-1" 
      style="height: 100%; overflow: hidden;"
    >
      <v-col 
        cols="6" 
        md="4" 
        lg="3"
        class="pa-0 pr-3 d-flex flex-column"
      >
        <div class="d-flex align-center">
          <v-btn 
            class="mb-1"
            icon
            large
            color="primary" 
            @click="fetchAll"
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </div>
        <simplebar style="flex: 1; height: 0; overflow-x: hidden;">
          <v-card 
            class="pa-0 mt-2 elevation-0"
            outlined
          >
            <v-card-title 
              class="secondary white--text py-1"
            >
              ID: {{ participant.id }}
            </v-card-title>
            <v-card-text>
              <v-container class="px-0 pt-5">
                <v-row>
                  <v-col>
                    <v-text-field
                      label="名前"
                      v-model="participant.name"
                      hide-details
                      outlined
                      @change="updateParticipantAttribute('name')"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <MarkdownEditor
                      class="mt-1"
                      label="説明"
                      v-model="participant.description"
                      outlined
                      @change="updateParticipantAttribute('description')"
                    />
                  </v-col>
                </v-row>
                <v-row >
                  <v-col>
                    <v-select
                      label="プロジェクト内公開"
                      v-model="participant.isSharedByProject"
                      hide-details
                      @change="updateParticipantAttribute('isSharedByProject')"
                      :items="[
                        {label: '公開', value: true},
                        {label: '非公開', value: false}
                      ]"
                      outlined
                      item-text="label"
                      item-value="value"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      label="有効/無効"
                      v-model="participant.isDeleted"
                      hide-details
                      @change="updateParticipantAttribute('isDeleted')"
                      :items="[
                        {label: '有効', value: false},
                        {label: '無効', value: true}
                      ]"
                      outlined
                      item-text="label"
                      item-value="value"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>

          <CommentsCard
            class="pa-0 mt-2 elevation-0"
            outlined
            :id="id"
            target="participant"
          />
        </simplebar>
      </v-col>
      <v-col cols="6" md="8" lg="9">
        <v-row class="d-flex align-center pr-3" style="flex: initial;">
          <v-tabs 
            v-model="currentTabIndex" 
            class="mb-5"
            background-color="primary darken-2"
            dark
          >
            <v-tab
              v-for="tab in tabs"
              :key="tab.key"
            >{{ tab.label }}</v-tab>
          </v-tabs>
        </v-row>

        <v-row dense>
          <v-col>
            <GaitsTable
              v-if="currentTab.key === 'gait'"
              height="calc(100vh - 196px)"
              :gaits="gaits"
            />

            <SwayListTable
              v-if="currentTab.key === 'sway'"
              height="calc(100vh - 196px)"
              :sway-list="swayList"
            />

            <v-row dense v-if="currentTab.key === 'time_series'">
              <GaitFeatureTimeChartCard
                cols="6"
                class="mt-3"
                v-for="(feature, index) in gaitFeaturesChartData"
                :key="`features${index}`"
                :title="feature.title"
                :features="feature.features"
              />
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-overlay
      absolute
      :value="loadingCount > 0"
      opacity="0.5"
    >
      <v-progress-circular
        indeterminate
        size="32"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

import MarkdownEditor from "@components/inputs/MarkdownEditor.vue";

import GaitFeatureTimeChartCard from "@components/parts/cards/GaitFeatureTimeChartCard.vue";
import CommentsCard from "@components/parts/cards/CommentsCard.vue";
import GaitsTable from "@components/parts/tables/GaitsTable.vue";
import SwayListTable from "@components/parts/tables/SwayListTable.vue";

export default {
  components: {
    MarkdownEditor,
    GaitsTable,
    SwayListTable,
    GaitFeatureTimeChartCard,
    CommentsCard,
  },
  props: {
    id: {
      required: true,
    }
  },
  data: function(){
    return {
      loadingCount: 0,
      participant: null,
      filterParams: {},
      gaits: [],
      swayList: [],
      gaitFeatures: [],
      currentTabIndex: 0,
      tabs: [
        {label: '歩行分析', key: 'gait'},
        {label: '重心動揺', key: 'sway'},
        {label: '時系列グラフ', key: 'time_series'},
      ],
      headers: [
        {
          text: 'ID', 
          value: 'id',
          width: 120,
          sortable: true,
          align: 'end',
          filterable: true,
          filter: value => !this.filterParams['id']?.length || String(value).indexOf(this.filterParams['id']) >= 0,
        },
        {
          text: '名前', 
          value: 'name',
          sortable: true,
          filterable: true,
          filter: value => !this.filterParams['name']?.length || value?.indexOf(this.filterParams['name']) >= 0,
        },
        {
          text: '更新日時', 
          value: 'updatedTime',
          sortable: true,
          align: 'end',
          width: 120,
        },
        {
          text: '作成日時', 
          value: 'createdTime',
          sortable: true,
          align: 'end',
          width: 120,
        },
        {
          text: '有効/無効', 
          value: 'isDeleted',
          width: 120,
          sortable: true,
          filterable: true,
          align: 'center',
          filter: value => this.filterParams['isDeleted'] == null || value == this.filterParams['isDeleted'],
        },
      ],
      required: value => !!value || "※必須",
    };
  },
  computed: {
    ...mapState([
      'user',
      'project',
    ]),
    currentTab: function(){
      return this.tabs[this.currentTabIndex];
    },
    rowClasses: function(){
      return item => item.isDeleted ? 'blue-grey lighten-4' : null;
    },
    isLoading: function(){
      return this.loadingCount > 0;
    },
    gaitFeaturesChartData: function(){
      let rtn = [];

      let ankleTargetNames = [
        'ストライド長さ (m)',
        'ストライド周期 (sec)',
        '歩行速度 (m/sec)',
        'ステップ時間 (sec)',
        '足振り強度 (dps)',
        '遊脚期 (sec)',
        '立脚期 (sec)',
        '片脚支持期 (sec)',
      ]

      ankleTargetNames.forEach(name => {
        for(let i=0; i<2; i++){
          rtn.push({
            title: i == 0 ? name : `${name.split(' ')[0]} CV`,
            features: [
              {name: '左', vals: this.getFeatureValues(this.gaitFeatures, `左 ${name}`, i)},
              {name: '右', vals: this.getFeatureValues(this.gaitFeatures, `右 ${name}`, i)},
            ]
          })
        }
      })


      let trunkTargetNames = [
        '横揺れの大きさ (cm)',
        '持ち上げの高さ (cm)',
      ]

      trunkTargetNames.forEach(name => {
        for(let i=0; i<2; i++){
          rtn.push({
            title: i == 0 ? name : `${name.split(' ')[0]} CV`,
            features: [
              {name: '左側', vals: this.getFeatureValues(this.gaitFeatures, `左側 ${name}`, i)},
              {name: '右側', vals: this.getFeatureValues(this.gaitFeatures, `右側 ${name}`, i)},
              {name: '両側', vals: this.getFeatureValues(this.gaitFeatures, `${name}`, i)},
            ]
          })
        }
      })

      return rtn;
    },
  },
  watch: {
    project: {
      handler: function(newVal){
        if(newVal){
          this.$router.push('/participants');
        }
      },
    },
    id: {
      handler: function(){
        this.fetchAll();
      },
      immediate: true,
    },
  },
  destroyed: function(){
  },
  mounted: function(){
    if(!this.project){
      this.$router.push('/');
      return;
    }

    this.fetchAll();
  },
  methods: {
    customSort(items, index, isDesc) {
      let isAsc = !isDesc[0];
      let target = index[0];
      if(!target){
        return items;
      }
      items.sort((a, b) => {
        if (target === "users") {
          return isAsc 
            ? a.users.length - b.users.length
            : b.users.length - a.users.length;
        } else {
          if (isAsc) {
            return a[target] < b[target] ? -1 : 1;
          } else {
            return b[target] < a[target] ? -1 : 1;
          }
        }
      });
      return items;
    },
    fetchAll: function(){
      this.fetchParticipant();
      this.fetchGaits();
      this.fetchSwayList();
      this.fetchGaitFeatures();
    },
    fetchParticipant: function(){
      this.loadingCount++;
      this.axios.get(`/api/participant/${this.id}`).then(response => {
        this.participant = response.data.participant;
      }).catch(error => {
        console.log(error);
        alert('計測参加者情報の取得に失敗しました');
      }).finally(() => {
        this.loadingCount--;
      })
    },
    fetchGaits: function(){
      this.loadingCount++;
      this.axios.get(`/api/gaits/${this.project.id}/${this.id}`).then(response => {
        this.gaits = response.data.gaits;
      }).catch(error => {
        console.log(error);
        alert('歩行分析一覧の取得に失敗しました');
      }).finally(() => {
        this.loadingCount--;
      })
    },
    fetchSwayList: function(){
      this.loadingCount++;
      this.axios.get(`/api/sway_list/${this.project.id}/${this.id}`).then(response => {
          this.swayList = response.data.swayList;
      }).catch(error => {
        console.log(error);
        alert('重心動揺一覧の取得に失敗しました');
      }).finally(() => {
        this.loadingCount--;
      })
    },
    updateParticipantAttribute: function(key){
      this.axios.put(`/api/participant/${key}`, this.participant).then(() => {

      }).catch(error => {
        alert('計測参加者情報の更新に失敗しました');
        console.log(error);
      });
    },
    fetchGaitFeatures: function(){
      this.loadingCount++;
      this.axios.get(`/api/gait_features/participant/${this.id}`).then(response => {
          this.gaitFeatures = response.data.features;
      }).catch(error => {
        console.log(error);
        alert('歩行分析特徴量一覧の取得に失敗しました');
      }).finally(() => {
        this.loadingCount--;
      })
    },
    getFeatureValues: function(gaitFeatures, targetName, statisticsIndex){
      return gaitFeatures.filter(f => f.featuresData.some(d => d.name === targetName)).map(f => ({
        time: f.createdTime,
        value: f.featuresData.find(d => d.name === targetName).vals[statisticsIndex]
      }))
    },
  }
}
</script>

<style lang="scss" scoped>

</style>