import { render, staticRenderFns } from "./GaitsTable.vue?vue&type=template&id=8e6f5e3e&scoped=true&"
import script from "./GaitsTable.vue?vue&type=script&lang=js&"
export * from "./GaitsTable.vue?vue&type=script&lang=js&"
import style0 from "./GaitsTable.vue?vue&type=style&index=0&id=8e6f5e3e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e6f5e3e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCol,VContainer,VDataTable,VIcon,VRow,VSelect,VTextField})
