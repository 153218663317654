<template>
  <div 
    ref="container"
    class="chart-container"
    :style="{
      height: chartHeight + 'px',
    }"
  >
    <LineChart 
      v-observe-visibility="isVisible => (isVisible ? resizeContainer() : false)"
      ref="chart"
      hide-legends
      :style="{
        height: chartHeight + 'px',
      }"
    />
  </div>
</template>

<script>
import LineChart from '@components/parts/charts/LineChart.vue'

export default {
	components: {
		LineChart,
	},
	props: {
    target: {
      required: true,
    },
		trajectories: {
      required: true,
    },
    selection: {
      required: true,
    },
		aspectRatio: {
      default: 1.92
    },
	},
	data: function() {
		return {
      chartWidth: 240,
		};
	},
	mounted() {
    this.resizeContainer();
    this.$refs.chart.dataCollection.datasets = this.createLineChartData();
	},
	watch: {
    selection: {
			handler(newVal){
				this.updateChartRange(newVal);
			},
			deep: true,
			immediate: true,
		},
  },
  computed: {
    chartHeight: function(){
      return parseInt(this.chartWidth/this.aspectRatio);
    },
    validTrajectoires: function(){
      return this.trajectories;
    },
	},
	methods: {
    resizeContainer: function(){
      this.chartWidth = this.$refs.container?.clientWidth ?? 0;
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
		updateChartRange: function(duration){
      let _duration = {
        from: duration?.from != null ? duration.from*10 : null,
        to: duration?.to != null ? duration.to*10 : null,
      }
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs.chart?.setRangeX(_duration);
        });
      })
    },
		createLineChartData: function(){
      let rtn = [];

      const defaultData = {
        fill: false,
        backgroundColor: 'hsla(0, 0%, 28%, 0.4)',
        borderColor: 'hsla(0, 0%, 28%, 0.4)',
        borderWidth: 2,
        pointRadius: 2,
        label: '',
      };

      let color = this.getLineChartColor(0);
      rtn.push({
        ...defaultData,
        label: `左側 ${this.target.name}`,
        backgroundColor: color,
        borderColor: color,
        data: this.validTrajectoires.map(t => ({
          x: t.positions[0].index*10,
          y: t.features[this.target.leftAttr]
        })).filter(p => p.y != null).map(p => ({
          ...p,
          y: p.y*(this.target.scale ?? 1)
        })),
      });

      color = this.getLineChartColor(230);
      rtn.push({
        ...defaultData,
        label: `右側 ${this.target.name}`,
        backgroundColor: color,
        borderColor: color,
        data: this.validTrajectoires.map(t => ({
          x: t.positions[0].index*10,
          y: t.features[this.target.rightAttr]
        })).filter(p => p.y != null).map(p => ({
          ...p,
          y: p.y*(this.target.scale ?? 1)
        })),
      });

      rtn.push({
        ...defaultData,
        label: `${this.target.name}`,
        data: this.validTrajectoires.map(t => ({
          x: t.positions[0].index*10,
          y: t.features[this.target.attr]
        })).filter(p => p.y != null).map(p => ({
          ...p,
          y: p.y*(this.target.scale ?? 1)
        })),
      });

      return rtn;
    },
		getLineChartColor: function(hue){
      return `hsla(${hue}, 77%, 28%, 0.4)`;
    },
	}
}
</script>

<style lang="scss" scoped>
.chart-container{
  ::v-deep{
    canvas {
      height: 100% !important;
    }
  }
}
</style>