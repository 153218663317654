<template>
  <v-container>
    <VglDefs>
      <template v-slot:[`averagedLeftTrajectory-${groupId}`]>
        <AnkleTrajectoryGeometry
          :trajectory="averagedLeftTrajectory"
          :horizontal-offset="ankleHorizontalOffset"
          :vertical-offset="ankleVerticalOffset"
        />
      </template>

      <template v-slot:[`averagedRightTrajectory-${groupId}`]>
        <AnkleTrajectoryGeometry
          :trajectory="averagedRightTrajectory"
          :horizontal-offset="ankleHorizontalOffset"
          :vertical-offset="ankleVerticalOffset"
        />
      </template>

      <template 
        v-for="(trajectory, i) in leftTrajectories" 
        v-slot:[`leftTrajectory${i}-${groupId}`]
      >
        <AnkleTrajectoryGeometry
          :trajectory="trajectory"
          :horizontal-offset="ankleHorizontalOffset"
          :vertical-offset="ankleVerticalOffset"
          :key="`leftTrajectory${i}`"
        />
      </template>

      <template 
        v-for="(trajectory, i) in rightTrajectories" 
        v-slot:[`rightTrajectory${i}-${groupId}`]
      >
        <AnkleTrajectoryGeometry
          :trajectory="trajectory"
          :horizontal-offset="ankleHorizontalOffset"
          :vertical-offset="ankleVerticalOffset"
          :key="`rightTrajectory${i}`"
        />
      </template>
    </VglDefs>

    <VglDefs v-if="comparableGroupId && comparableGroupId != groupId">
      <template v-slot:[`averagedLeftTrajectory-${comparableGroupId}`]>
        <AnkleTrajectoryGeometry
          :trajectory="averagedComparableLeftTrajectory"
          :horizontal-offset="comparableAnkleHorizontalOffset"
          :vertical-offset="comparableAnkleVerticalOffset"
        />
      </template>

      <template v-slot:[`averagedRightTrajectory-${comparableGroupId}`]>
        <AnkleTrajectoryGeometry
          :trajectory="averagedComparableRightTrajectory"
          :horizontal-offset="comparableAnkleHorizontalOffset"
          :vertical-offset="comparableAnkleVerticalOffset"
        />
      </template>

      <template 
        v-for="(trajectory, i) in comparableLeftTrajectories" 
        v-slot:[`leftTrajectory${i}-${comparableGroupId}`]
      >
        <AnkleTrajectoryGeometry
          :trajectory="trajectory"
          :horizontal-offset="comparableAnkleHorizontalOffset"
          :vertical-offset="comparableAnkleVerticalOffset"
          :key="`comparableLeftTrajectory${i}`"
        />
      </template>

      <template 
        v-for="(trajectory, i) in comparableRightTrajectories" 
        v-slot:[`rightTrajectory${i}-${comparableGroupId}`]
      >
        <AnkleTrajectoryGeometry
          :trajectory="trajectory"
          :horizontal-offset="comparableAnkleHorizontalOffset"
          :vertical-offset="comparableAnkleVerticalOffset"
          :key="`comparableRightTrajectory${i}`"
        />
      </template>
    </VglDefs>

    <v-row class="px-0 mt-1" style="height: 100%; overflow: hidden;">
      <FeaturesScatterChartCard
        title="各レポート特徴量散布図"
        v-bind="comparableGroupId ? {cols: 12} : graphCols"
        :aspect-ratio="comparableGroupId ? 3.84 : 1.92"
        :features="scatterChartFeatures"
      />

      <GaitTrajectoryGraphCard
        :title="`各レポート平均${comparableGroupId ? ` - ID: ${groupId}`: ''}`"
        v-bind="graphCols"
        :color="color"
        :id="`${groupId}-3D`"
        :gait="{id: groupId}"
        :loading="isLoading"
        :left-trajectories="leftTrajectories"
        :right-trajectories="rightTrajectories"
        :vertical-offset="ankleVerticalOffset"
        :horizontal-offset="ankleHorizontalOffset"
        :scale-y="ankleVerticalScale"
        :perspective="true"
        :line-opacity="ankleTrajectoryLineOpacity"
      />

      <GaitTrajectoryGraphCard
        v-if="comparableGroupId"
        :title="`各レポート平均 - ID: ${comparableGroupId}`"
        v-bind="graphCols"
        color="tertiary"
        :id="`${comparableGroupId}-comparable3D`"
        :gait="{id: comparableGroupId}"
        :loading="isLoading"
        :left-trajectories="comparableLeftTrajectories"
        :right-trajectories="comparableRightTrajectories"
        :vertical-offset="comparableAnkleVerticalOffset"
        :horizontal-offset="comparableAnkleHorizontalOffset"
        :scale-y="ankleVerticalScale"
        :perspective="true"
        :line-opacity="ankleTrajectoryLineOpacity"
      />

      <GaitTrajectoryGraphCard
        :title="`グループ平均${comparableGroupId ? ` - ID: ${groupId}`: ''}`"
        v-bind="graphCols"
        :color="color"
        :id="`${groupId}-averaged3D`"
        :gait="{id: groupId}"
        :loading="isLoading"
        :left-trajectories="leftTrajectories"
        :right-trajectories="rightTrajectories"
        :vertical-offset="ankleVerticalOffset"
        :horizontal-offset="ankleHorizontalOffset"
        :scale-y="ankleVerticalScale"
        :perspective="true"
        averaged
        :line-opacity="ankleTrajectoryLineOpacity"
      />

      <GaitTrajectoryGraphCard
        v-if="comparableGroupId"
        :title="`グループ平均 - ID: ${comparableGroupId}`"
        v-bind="graphCols"
        color="tertiary"
        :id="`${comparableGroupId}-comparableAveraged3D`"
        :gait="{id: comparableGroupId}"
        :loading="isLoading"
        :left-trajectories="comparableLeftTrajectories"
        :right-trajectories="comparableRightTrajectories"
        :vertical-offset="comparableAnkleVerticalOffset"
        :horizontal-offset="comparableAnkleHorizontalOffset"
        :scale-y="ankleVerticalScale"
        :perspective="true"
        averaged
        :line-opacity="ankleTrajectoryLineOpacity"
      />

      <v-col v-bind="graphCols">
        <v-row class="d-flex align-center">
          <v-chip
            class="ml-3 my-0"
            :color="comparableGroupId ? 'primary darken-1' : 'secondary'"
            outlined
          >
            特徴量{{ comparableGroupId ? ` - ID: ${groupId}` : null }}
          </v-chip>
        </v-row>
        <v-row>
          <v-col class="pt-1">
            <AnkleFeaturesTable
              ref="ankleFeaturesTable"
              :left-trajectories="leftTrajectories"
              :right-trajectories="rightTrajectories"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col v-if="comparableGroupId" v-bind="graphCols">
        <v-row class="d-flex align-center">
          <v-chip
            class="ml-3 my-0"
            color="tertiary darken-1"
            outlined
          >
            特徴量 - ID: {{ comparableGroupId }}
          </v-chip>
        </v-row>
        <v-row>
          <v-col class="pt-1">
            <AnkleFeaturesTable
              :left-trajectories="comparableLeftTrajectories"
              :right-trajectories="comparableRightTrajectories"
              color="tertiary darken-4 white--text"
            />
          </v-col>
        </v-row>
      </v-col>
      <TrunkTrajectoryGraphCard
        :title="`各レポート平均${comparableGroupId ? ` - ID: ${groupId}`: ''}`"
        v-bind="graphCols"
        :color="color"
        :id="`trunk${groupId}`"
        :gait-id="{id: groupId}"
        :loading="isLoading"
        :trajectory="{trunk: trunkTrajectories}"
        :initial-roll="0"
        :initial-pitch="0"
        :initial-yaw="0"
        :initial-camera-position="0.25"
        :scale-x="3"
        :scale-y="3"
        :scale-z="0"
        :perspective="false"
        dragging-mode="translation"
        :line-opacity="trunkTrajectoryLineOpacity"
      />

      <TrunkTrajectoryGraphCard
        v-if="comparableGroupId"
        :title="`各レポート平均 - ID: ${comparableGroupId}`"
        v-bind="graphCols"
        color="tertiary"
        :id="`trunkComparable${comparableGroupId}`"
        :gait-id="{id: comparableGroupId}"
        :loading="isLoading"
        :trajectory="{trunk: comparableTrunkTrajectories}"
        :initial-roll="0"
        :initial-pitch="0"
        :initial-yaw="0"
        :initial-camera-position="0.25"
        :scale-x="3"
        :scale-y="3"
        :scale-z="0"
        :perspective="false"
        dragging-mode="translation"
        :line-opacity="trunkTrajectoryLineOpacity"
        graph-color="#CC4444"
      />

      <TrunkTrajectoryGraphCard
        :title="`グループ平均${comparableGroupId ? ` - ID: ${groupId}`: ''}`"
        v-bind="graphCols"
        :color="color"
        :id="`averagedTrunk${groupId}`"
        :gait-id="{id: groupId}"
        :loading="isLoading"
        :trajectory="{trunk: averagedTrunkTrajectories}"
        :initial-roll="0"
        :initial-pitch="0"
        :initial-yaw="0"
        :initial-camera-position="0.25"
        :scale-x="3"
        :scale-y="3"
        :scale-z="0"
        :perspective="false"
        dragging-mode="translation"
        :line-opacity="trunkTrajectoryLineOpacity"
      />

      <TrunkTrajectoryGraphCard
        v-if="comparableGroupId"
        :title="`グループ平均 - ID: ${comparableGroupId}`"
        v-bind="graphCols"
        color="tertiary"
        :id="`averagedComparableTrunk${comparableGroupId}`"
        :gait-id="{id: comparableGroupId}"
        :loading="isLoading"
        :trajectory="{trunk: averagedComparableTrunkTrajectories}"
        :initial-roll="0"
        :initial-pitch="0"
        :initial-yaw="0"
        :initial-camera-position="0.25"
        :scale-x="3"
        :scale-y="3"
        :scale-z="0"
        :perspective="false"
        dragging-mode="translation"
        :line-opacity="trunkTrajectoryLineOpacity"
        graph-color="#CC4444"
      />

      <v-col v-bind="graphCols">
        <v-row class="d-flex align-center">
          <v-chip
            class="ml-3"
            :color="comparableGroupId ? 'primary darken-1' : 'secondary'"
            outlined
          >
            腰軌道特徴量{{ comparableGroupId ? ` - ID: ${groupId}` : null }}
          </v-chip>
        </v-row>
        <v-row>
          <v-col class="pt-1">
            <TrunkFeaturesTable
              :trajectories="leftTrajectories"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col v-bind="graphCols" v-if="comparableGroupId">
        <v-row class="d-flex align-center">
          <v-chip
            class="ml-3"
            color="tertiary"
            outlined
          >
            腰軌道特徴量 - ID: {{ comparableGroupId }}
          </v-chip>
        </v-row>
        <v-row>
          <v-col class="pt-1">
            <TrunkFeaturesTable
              color="tertiary darken-4 white--text"
              :trajectories="comparableLeftTrajectories"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { 
  average,
  getAveragedTrajectory,
  getAnkleFeatures,
} from '@js/utils/StatisticsUtil.js';
import { getSplittedTrunkTrajectories } from '@js/utils/TrajectoryUtil.js';

import { VglDefs } from 'vue-gl';

import { mapActions, mapState } from 'vuex';

import AnkleTrajectoryGeometry from '@components/parts/graph/geometories/AnkleTrajectoryGeometry.vue'
import GaitTrajectoryGraphCard from "@components/parts/cards/GaitTrajectoryGraphCard.vue";
import TrunkTrajectoryGraphCard from "@components/parts/cards/TrunkTrajectoryGraphCard.vue";
import FeaturesScatterChartCard from "@components/parts/cards/FeaturesScatterChartCard.vue";
import AnkleFeaturesTable from "@components/parts/tables/AnkleFeaturesTable.vue";
import TrunkFeaturesTable from "@components/parts/tables/TrunkFeaturesTable.vue";

export default {
  props: {
    groupId: {
      default: 0,
    },
    comparableGroupId: {
      default: null,
    },
    gaitIds: {
      default: () => [],
    },
    comparableGaitIds: {
      default: () => [],
    },
    color: {
      default: 'primary',
    },
    ankleVerticalScale: {
      default: 2,
    },
    ankleTrajectoryLineOpacity: {
      default: 1.0,
    },
    trunkTrajectoryLineOpacity: {
      default: 1.0,
    },
  },
  components: {
    VglDefs,
    AnkleTrajectoryGeometry,
    GaitTrajectoryGraphCard,
    AnkleFeaturesTable,
    TrunkFeaturesTable,
    TrunkTrajectoryGraphCard,
    FeaturesScatterChartCard,
  },
  data: function(){
    return {
      gaitMap: {},
      graphCols: {
        cols: 12,
        sm: 6,
        lg: 6
      },
    }
  },
  mounted: function(){
  },
  computed: {
    ...mapState([
      'loadingCount',
    ]),
    isLoading: function(){
      return this.loadingCount > 0;
    },
    gaits: function(){
      return this.gaitIds.map(id => this.gaitMap[id])?.filter(g => !!g);
    },
    comparableGaits: function(){
      return this.comparableGaitIds.map(id => this.gaitMap[id])?.filter(g => !!g);
    },
    scatterChartFeatures: function(){
      const ANKLE_TARGETS = [
        {name: 'ストライド長さ (m)', key: 'stride'},
        {name: 'ストライド周期 (sec)', key: 'strideDuration'},
        {name: '歩行速度 (m/sec)', key: 'velocity'},
        {name: '持ち上げの高さ (cm)', key: 'height', scale: 100},
        {name: 'ステップ時間 (sec)', key: 'stepDuration'},
        {name: '足振り強度 (dps)', key: 'swingStrength'},
        {name: '遊脚期 (sec)', key: 'swingPhaseDuration'},
        {name: '立脚期 (sec)', key: 'stancePhaseDuration'},
        {name: '片脚支持期 (sec)', key: 'singleStancePhaseDuration'},
      ]

      const TRUNK_TARGETS = [
        {name: '両脚支持期 (sec)', key: 'doubleStancePhaseDuration'},
        {name: '左側 横揺れの大きさ (cm)', key: 'trunkSwingLeftWidth', scale: 100},
        {name: '右側 横揺れの大きさ (cm)', key: 'trunkSwingRightWidth', scale: 100},
        {name: '横揺れの大きさ (cm)', key: 'trunkSwingWidth', scale: 100},
        {name: '左側 持ち上げの高さ (cm)', key: 'trunkSwingLeftHeight', scale: 100},
        {name: '右側 持ち上げの高さ (cm)', key: 'trunkSwingRightHeight', scale: 100},
        {name: '持ち上げの高さ (cm)', key: 'trunkSwingHeight', scale: 100},
      ]

      
      let rtn = [];
      ANKLE_TARGETS.forEach(t => {
        let leftValMap = {
          [this.groupId]: this.leftTrajectories.map(trajectory => trajectory.features[t.key]),
        };

        let rightValMap = {
          [this.groupId]: this.rightTrajectories.map(trajectory => trajectory.features[t.key]),
        };

        if(this.comparableGroupId != null && this.comparableGroupId != this.groupId){
          leftValMap[this.comparableGroupId] = this.comparableLeftTrajectories.map(trajectory => trajectory.features[t.key]);
          rightValMap[this.comparableGroupId] = this.comparableRightTrajectories.map(trajectory => trajectory.features[t.key]);
        }

        rtn.push({
          name: `右${t.name}`,
          valMap: leftValMap,
        })

        rtn.push({
          name: `左${t.name}`,
          valMap: rightValMap,
        })
      })

      TRUNK_TARGETS.forEach(t => {
        let valMap = {
          [this.groupId]: this.leftTrajectories.map(trajectory => trajectory.features[t.key]),
        };

        if(this.comparableGroupId != null && this.comparableGroupId != this.groupId){
          valMap[this.comparableGroupId] = this.comparableLeftTrajectories.map(trajectory => trajectory.features[t.key]);
        }

        rtn.push({
          name: t.name,
          valMap: valMap,
        })
      })
      /*
      features.map(f => {
        let vals = [
          {
            name: `グループID: ${this.groupId}`,
            vals: f.
          },
        ];

        return {
          label: f.name,
          vals: vals,
        }
      })
      */

      return rtn;
    },
    leftTrajectories: function(){
      return this.gaits.map(g => g.trajectory.left);
    },
    rightTrajectories: function(){
      return this.gaits.map(g => g.trajectory.right);
    },
    trunkTrajectories: function(){
      return this.gaits.map(g => g.trajectory.trunk.positions);
    },
    averagedTrunkTrajectories: function(){
      return [getAveragedTrajectory(this.trunkTrajectories.map(t => ({positions: t}))).positions];
    },
    averagedComparableTrunkTrajectories: function(){
      return [getAveragedTrajectory(this.comparableTrunkTrajectories.map(t => ({positions: t}))).positions];
    },
    comparableLeftTrajectories: function(){
      return this.comparableGaits.map(g => g.trajectory.left);
    },
    comparableRightTrajectories: function(){
      return this.comparableGaits.map(g => g.trajectory.right);
    },
    comparableTrunkTrajectories: function(){
      return this.comparableGaits.map(g => g.trajectory.trunk.positions);
    },
    averagedLeftTrajectory: function(){
      return getAveragedTrajectory(this.leftTrajectories.filter(t => !t.isInvalid));
    },
    averagedRightTrajectory: function(){
      return getAveragedTrajectory(this.rightTrajectories.filter(t => !t.isInvalid));
    },
    averagedComparableLeftTrajectory: function(){
      return getAveragedTrajectory(this.comparableLeftTrajectories.filter(t => !t.isInvalid));
    },
    averagedComparableRightTrajectory: function(){
      return getAveragedTrajectory(this.comparableRightTrajectories.filter(t => !t.isInvalid));
    },
    ankleHorizontalOffset: function(){
      return average([
        ...this.leftTrajectories, 
        ...this.rightTrajectories
      ].map(t => t.features.stride))/2;
    },
    ankleVerticalOffset: function(){
      return average([
        ...this.leftTrajectories, 
        ...this.rightTrajectories
      ].map(t => t.features.height))/2;
    },
    comparableAnkleHorizontalOffset: function(){
      return average([
        ...this.comparableLeftTrajectories, 
        ...this.comparableRightTrajectories
      ].map(t => t.features.stride))/2;
    },
    comparableAnkleVerticalOffset: function(){
      return average([
        ...this.comparableLeftTrajectories, 
        ...this.comparableRightTrajectories
      ].map(t => t.features.height))/2;
    },
    ankleFeatures: function(){
      let rtn = getAnkleFeatures(
        this.leftTrajectories.filter(t => !t.isInvalid),
        this.rightTrajectories.filter(t => !t.isInvalid),
      );
      return rtn;
    },
    comparableAnkleFeatures: function(){
      let rtn = getAnkleFeatures(
        this.comparableLeftTrajectories.filter(t => !t.isInvalid),
        this.comparableRightTrajectories.filter(t => !t.isInvalid),
      );
      return rtn;
    },
  },
  watch: {
    gaitIds: {
      handler: function(){
        this.fetchGaitDetails();
      },
      immediate: true,
      deep: true,
    },
    comparableGaitIds: {
      handler: function(){
        this.fetchComparableGaitDetails();
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    ...mapActions([
      'incrementLoadingCount',
      'decrementLoadingCount',
    ]),
    fetchGaitDetails: function(){
      this.gaitIds.forEach(id => {
        this.fetchGaitDetail(id);
      });
    },
    fetchComparableGaitDetails: function(){
      this.comparableGaitIds.forEach(id => {
        this.fetchGaitDetail(id);
      });
    },
    fetchGaitDetail: function(gaitId){
      if(gaitId in this.gaitMap){
        return;
      }

      this.incrementLoadingCount();
      this.axios.get(`/api/gait/${gaitId}`).then(response => {
        const left = response.data.trajectory?.splitted?.left ?? {};
        const right = response.data.trajectory?.splitted?.right ?? {};
        const trunk = getSplittedTrunkTrajectories(response.data.trajectory);
        this.$set(this.gaitMap, gaitId, {
          gait: response.data.gait,
          trajectory: {
            left: getAveragedTrajectory(left),
            right: getAveragedTrajectory(right),
            trunk: getAveragedTrajectory(trunk),
          },
        });
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.decrementLoadingCount();
      })
    },
  },
}
</script>