<template>
  <v-row v-if="!comparableId">
    <v-col cols="3">
      <v-row dense>
        <v-col class="header karte-cell text-center text-caption">
          アカウント名
        </v-col>
      </v-row>
      <v-row dense>
        <v-col 
          class="karte-cell text-center text-caption"
        >
          {{ participant ? participant.name : '' }}
        </v-col>
      </v-row>

      <v-row dense>
        <v-col class="mt-1 header karte-cell text-center text-caption">
          分析対象区間 [s]
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="karte-cell text-center text-caption">
          {{ selectedDuration.toFixed(1) }}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="5">
      <v-row dense>
        <v-col class="header karte-cell text-center text-caption">
          レポート作成日時
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="karte-cell text-center text-caption">
          {{ gait ? gait.createdTime : '' }}
        </v-col>
      </v-row>

      <v-row dense>
        <v-col class="mt-1 header karte-cell text-center text-caption">
          分析対象歩数 [歩]
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="karte-cell text-center text-caption">
          {{ selectedLeftTrajectories.length + selectedRightTrajectories.length }}
        </v-col>
      </v-row>

    </v-col>
    <v-col cols="4">
      <v-row dense>
        <v-col class="header karte-cell text-center text-caption">
          データタイトル
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="karte-cell text-center text-caption">
          {{ gait ? gait.name : '' }}
        </v-col>
      </v-row>

      <v-row dense>
        <v-col class="mt-1 header karte-cell text-center text-caption">
          プライバシーポリシー
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="karte-cell text-center text-caption">
          {{ participant && participant.hasPrivacyPolicyAccepted ? '同意' : '同意なし' }}
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <v-row v-else>
    <v-col cols="6">
      <v-row dense>
        <v-col class="text-center text-caption">ID: {{ id }}</v-col>
      </v-row>
      <v-row dense>
        <v-col cols="5">
          <v-row dense>
            <v-col class="header karte-cell text-center text-caption">
              アカウント名
            </v-col>
          </v-row>
          <v-row dense>
            <v-col 
              class="karte-cell text-center text-caption"
            >
              {{ participant ? participant.name : '' }}
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="mt-1 header karte-cell text-center text-caption">
              分析対象区間 [s]
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="karte-cell text-center text-caption">
              {{ selectedDuration.toFixed(1) }}
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="mt-1 header karte-cell text-center text-caption">
              分析対象歩数 [歩]
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="karte-cell text-center text-caption">
              {{ selectedLeftTrajectories.length + selectedRightTrajectories.length }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="7" class="pl-3">
          <v-row dense>
            <v-col class="header karte-cell text-center text-caption">
              レポート作成日時
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="karte-cell text-center text-caption">
              {{ gait ? gait.createdTime : '' }}
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="mt-1 header karte-cell text-center text-caption">
              データタイトル
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="karte-cell text-center text-caption">
              {{ gait ? gait.name : '' }}
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="mt-1 header karte-cell text-center text-caption">
              プライバシーポリシー
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="karte-cell text-center text-caption">
              {{ participant && participant.hasPrivacyPolicyAccepted ? '同意' : '同意なし' }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="6">
      <v-row dense>
        <v-col class="text-center text-caption">ID: {{ comparableId }}</v-col>
      </v-row>
      <v-row dense>
        <v-col cols="5">
          <v-row dense>
            <v-col class="header comparable karte-cell text-center text-caption">
              アカウント名
            </v-col>
          </v-row>
          <v-row dense>
            <v-col 
              class="karte-cell text-center text-caption"
            >
              {{ comparableParticipant ? comparableParticipant.name : '' }}
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="mt-1 header comparable karte-cell text-center text-caption">
              分析対象区間 [s]
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="karte-cell text-center text-caption">
              {{ selectedComparableDuration.toFixed(1) }}
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="mt-1 header comparable karte-cell text-center text-caption">
              分析対象歩数 [歩]
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="karte-cell text-center text-caption">
              {{ selectedComparableLeftTrajectories.length + selectedComparableRightTrajectories.length }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="7" class="pl-3">
          <v-row dense>
            <v-col class="header comparable karte-cell text-center text-caption">
              レポート作成日時
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="karte-cell text-center text-caption">
              {{ comparableGait ? comparableGait.createdTime : '' }}
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="mt-1 header comparable karte-cell text-center text-caption">
              データタイトル
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="karte-cell text-center text-caption">
              {{ comparableGait ? comparableGait.name : '' }}
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="mt-1 header comparable karte-cell text-center text-caption">
              プライバシーポリシー
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="karte-cell text-center text-caption">
              {{ comparableParticipant && comparableParticipant.hasPrivacyPolicyAccepted ? '同意' : '同意なし' }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    id: {
      required: true,
    },
    comparableId: {
      default: null,
    },
    gait: {

    },
    comparableGait: {

    },
    selectedGaitDuration: {
    },
    selectedComparableGaitDuration: {
    },
    gaitTrajectory: {
    },
    comparableGaitTrajectory: {
    },
  },
  data: function(){
    return {
    }
  },
  computed: {
    ...mapState([
      'participants',
    ]),
    participant: function(){
      return this.participants.find(p => p.id == this.gait?.participantId);
    },
    leftTrajectories: function(){
      return this.gaitTrajectory?.splitted?.left ?? [];
    },
    rightTrajectories: function(){
      return this.gaitTrajectory?.splitted?.right ?? [];
    },
    comparableLeftTrajectories: function(){
      return this.comparableGaitTrajectory?.splitted?.left ?? [];
    },
    comparableRightTrajectories: function(){
      return this.comparableGaitTrajectory?.splitted?.right ?? [];
    },
    comparableParticipant: function(){
      return this.participants.find(p => p.id == this.comparableGait?.participantId);
    },
    selectedDuration: function(){
      let indexFrom = this.selectedGaitDuration?.from ?? 0;
      let indexTo = this.selectedGaitDuration?.to ?? 0;

      return (indexTo - indexFrom)*0.01;
    },
    selectedComparableDuration: function(){
      let indexFrom = this.selectedComparableGaitDuration?.from ?? 0;
      let indexTo = this.selectedComparableGaitDuration?.to ?? 0;

      return (indexTo - indexFrom)*0.01;
    },
    selectedLeftTrajectories: function(){
      let indexFrom = this.selectedGaitDuration?.from ?? 0;
      let indexTo = this.selectedGaitDuration?.to ?? 0;
      return this.leftTrajectories.filter(t => indexFrom <= t.positions[0].index && t.positions.slice(-1)[0].index < indexTo);
    },
    selectedRightTrajectories: function(){
      let indexFrom = this.selectedGaitDuration?.from ?? 0;
      let indexTo = this.selectedGaitDuration?.to ?? 0;
      return this.rightTrajectories.filter(t => indexFrom <= t.positions[0].index && t.positions.slice(-1)[0].index < indexTo);
    },
    selectedComparableLeftTrajectories: function(){
      let indexFrom = this.selectedComparableGaitDuration?.from ?? 0;
      let indexTo = this.selectedComparableGaitDuration?.to ?? 0;
      return this.comparableLeftTrajectories.filter(t => indexFrom <= t.positions[0].index && t.positions.slice(-1)[0].index < indexTo);
    },
    selectedComparableRightTrajectories: function(){
      let indexFrom = this.selectedComparableGaitDuration?.from ?? 0;
      let indexTo = this.selectedComparableGaitDuration?.to ?? 0;
      return this.comparableRightTrajectories.filter(t => indexFrom <= t.positions[0].index && t.positions.slice(-1)[0].index < indexTo);
    },
  }
}
</script>