<template>
  <VglMeshBasicMaterial
    ref="material"
    v-bind="$attrs"
  >
  </VglMeshBasicMaterial>
</template>

<script>
import { 
  VglMeshBasicMaterial,
} from 'vue-gl';

export default {
  components: {
    VglMeshBasicMaterial,
  },
  props: {
    opacity: {
      default: 1,
    },
  },
  watch: {
    opacity: function(newVal){
      this.$refs.material.inst.opacity = newVal;
    },
  },
  mounted: function(){
    this.$refs.material.inst.opacity = this.opacity;
    this.$refs.material.inst.transparent = true;
  }
}
</script>