<template>
  <v-col 
    v-show="visible" 
    v-bind="$attrs"
    v-resize="resizeContainer"
  >
    <v-row v-if="noMenu == null" dense>
      <v-chip
        class="ml-3"
        :color="color"
        outlined
      >
        {{ title }}
      </v-chip>

      <v-col cols="3" class="pa-0 ma-0">
        <v-select 
          outlined
          dense
          hide-details
          class="ml-3"
          label="横軸"
          :items="features"
          item-text="name"
          item-value="valMap"
          v-model="horizonalAxis"
        ></v-select>
      </v-col>

      <v-col cols="3" class="pa-0 ma-0">
        <v-select 
          outlined
          dense
          hide-details
          class="ml-3"
          label="縦軸"
          :items="features"
          item-text="name"
          item-value="valMap"
          v-model="verticalAxis"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col 
        class="pt-1"
      >
        <div 
          ref="container"
          class="chart-container"
          :style="{
            height: containerHeight + 'px',
          }"
          v-observe-visibility="isVisible => (isVisible ? resizeContainer() : false)"
        >
          <ScatterChart
            ref="chart"
            :style="{
              height: containerHeight + 'px',
            }"
          />
        </div>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import ScatterChart from '@components/parts/charts/ScatterChart.vue'

export default {
  components: {
    ScatterChart,
  },
  props: {
    visible: {
      default: true,
    },
    noMenu: {
      default: null,
    },
    color: {
      default: 'secondary',
    },
    title: {
    },
    aspectRatio: {
      default: 1.92
    },
    features: {
      default: () => [],
    }
  },
  data: function(){
    return {
      containerWidth: 0,
      horizonalAxis: null,
      verticalAxis: null,
    }
  },
  computed: {
    containerHeight: function(){
      return parseInt(this.containerWidth/this.aspectRatio);
    },
  },
  watch: {
    horizonalAxis: {
      handler(){
        this.updateChartdata();
      },
      immediate: true,
    },
    verticalAxis: {
      handler(){
        this.updateChartdata();
      },
      immediate: true,
    },
    aspectRatio: {
      handler(){
        this.resizeContainer();
        this.updateChartdata();
        
      },
      immediate: true,
    }
  },
  methods: {
    resizeContainer: function(){
      this.containerWidth = this.$refs.container?.clientWidth ?? 0;
      this.$refs.chart?.resize(this.containerWidth - 24);
    },
    updateChartdata: function(){
      const chart = this.$refs.chart;
      if(!chart){
        return;
      }

      if(this.features?.length <= 0){
        chart.dataCollection.datasets = [];
        chart.rerender();
        return;
      }
      const COLORS = [
        '#4444CC',
        '#CC4444',
        '#44CC44',
        '#CC44CC',
        '#44CCCC',
        '#CCCC44',
      ]

      const ids = Object.keys(this.features[0].valMap);
      chart.dataCollection.datasets = ids.map((id, index) => {
        const xValues = this.horizonalAxis ? this.horizonalAxis[id] : [];
        const yValues = this.verticalAxis ? this.verticalAxis[id] : [];
        
        const length = Math.min(xValues?.length ?? 0, yValues?.length ?? 0);
        let data = [];
        for(let i=0; i<length; i++){
          data.push({
            x: xValues[i],
            y: yValues[i],
          })
        }
        return {
          label: `ID: ${id}`,
          data: data,
          backgroundColor: COLORS[index],
        }
      });
      chart.rerender();
    },
  },
}
</script>

<style lang="scss" scoped>
.chart-container{
  ::v-deep{
    canvas {
      height: 100% !important;
      width: 100% !important;
    }
  }
}
</style>