<template>
  <v-row dense>
    <v-col class="mt-3">
      <v-row dense>
        <v-col 
          cols="3" 
          class="karte-cell header"
          :class="{
            neutral: comparableId
          }"
        ></v-col>
        <template v-if="!comparableId">
          <v-col class="karte-cell header text-center text-caption">平均</v-col>
          <v-col class="karte-cell header text-center text-caption">標準偏差</v-col>
        </template>
        <template v-else>
          <v-col class="karte-cell header text-center text-caption">ID: {{ id }}</v-col>
          <v-col class="karte-cell header comparable text-center text-caption">ID: {{ comparableId }}</v-col>

          <v-col class="karte-cell header neutral text-center text-caption">差 {{ id }}-{{ comparableId }}</v-col>
          <v-col class="karte-cell header neutral comparable text-center text-caption">比 {{ id}}/{{ comparableId }}</v-col>
        </template>
      </v-row>
      <v-row dense>
        <v-col 
          cols="3" 
          class="karte-cell header"
          :class="{
            neutral: comparableId
          }"
        ></v-col>
        <template v-if="!comparableId">
          <v-col 
            v-for="label in ['左', '右', '両', '差 L-R', '比 L-R']"
            class="karte-cell header text-center text-caption"
            :key="`ave${label}`"
          >
            {{ label }}
          </v-col>
          <v-col 
            v-for="label in ['左', '右', '両', '差 L-R', '比 L-R']"
            class="karte-cell header text-center text-caption"
            :key="`sd${label}`"
          >
            {{ label }}
          </v-col>
        </template>
        <template v-else>
          <template v-for="(c, i) in ['', 'comparable', 'neutral', 'neutral']">
            <v-col 
              v-for="label in ['左', '右', '両']"
              class="karte-cell header text-center text-caption"
              :class="c"
              :key="`${i}-${label}`"
            >
              {{ label }}
            </v-col>
          </template>
        </template>
      </v-row>
      <v-row dense v-for="feature in gaitFeaturesForKarte" :key="feature.name">
        <v-col cols="3" class="karte-cell text-start text-caption">
          {{ feature.name}}
        </v-col>
        <v-col 
          v-for="(val, i) in feature.vals"
          :key="`${feature.name}-${i}`"
          class="karte-cell text-center text-caption"
        >
          {{ val.toFixed(2) }}
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { 
  average,
  getStandardDeviation,
} from '@js/utils/StatisticsUtil.js';
import { mapState } from "vuex";

export default {
  props: {
    id: {
      required: true,
    },
    comparableId: {
      default: null,
    },
    selectedGaitDuration: {
    },
    selectedComparableGaitDuration: {
    },
    gaitTrajectory: {
    },
    comparableGaitTrajectory: {
    },
  },
  data: function(){
    return {
      ankleFeatureTargetsForKarte: [
        {name: 'ストライド長', attr: 'stride', unit: 'm'},
        {name: 'ストライド周期', attr: 'strideDuration', unit: 'sec'},
        {name: '歩行速度', attr: 'velocity', unit: 'm/sec'},
        {name: '足の持ち上げの高さ', attr: 'height', scale: 100, unit: 'cm'},
        {name: '足の横の広がり', attr: 'width', scale: 100, unit: 'cm'},
      ],
      trunkFeatureTargetsForKarte: [
        {name: '腰の持ち上げ量', leftAttr: 'trunkSwingLeftHeight', rightAttr: 'trunkSwingRightHeight', attr: 'trunkSwingHeight', scale: 100, unit: 'cm'},
        {name: '腰の横揺れの大きさ', leftAttr: 'trunkSwingLeftWidth', rightAttr: 'trunkSwingRightWidth', attr: 'trunkSwingWidth', scale: 100, unit: 'cm'},
      ],
    }
  },
  computed: {
    ...mapState([
    ]),
    gaitFeaturesForKarte: function(){
      let rtn = [];

      this.ankleFeatureTargetsForKarte.forEach(t => {
        let leftFeatures = this.selectedLeftTrajectories.map(_t => _t.features[t.attr]).filter(v => v != null).map(v => v*(t.scale ?? 1));
        let rightFeatures = this.selectedRightTrajectories.map(_t => _t.features[t.attr]).filter(v => v != null).map(v => v*(t.scale ?? 1));

        let both = [...leftFeatures, ...rightFeatures];
        let averagedLeft = average(leftFeatures);
        let averagedRight = average(rightFeatures);
        let averaged = average(both);

        if(this.comparableId){
          let leftComparableFeatures = this.selectedComparableLeftTrajectories.map(_t => _t.features[t.attr]).filter(v => v != null).map(v => v*(t.scale ?? 1));
          let rightComparableFeatures = this.selectedComparableRightTrajectories.map(_t => _t.features[t.attr]).filter(v => v != null).map(v => v*(t.scale ?? 1));

          let bothComparable = [...leftComparableFeatures, ...rightComparableFeatures];
          let averagedLeftComparable = average(leftComparableFeatures);
          let averagedRightComparable = average(rightComparableFeatures);
          let averagedComparable = average(bothComparable);

          rtn.push({
            name: `${t.name} (${t.unit})`,
            vals: [
              averagedLeft,
              averagedRight,
              averaged,
              averagedLeftComparable,
              averagedRightComparable,
              averagedComparable,
              averagedLeft - averagedLeftComparable,
              averagedRight - averagedRightComparable,
              averaged - averagedComparable,
              averagedLeft/averagedLeftComparable,
              averagedRight/averagedRightComparable,
              averaged/averagedComparable,
            ],
          });
        }else{
          let sdLeft = getStandardDeviation(leftFeatures);
          let sdRight = getStandardDeviation(rightFeatures);
          let sd = getStandardDeviation(both);

          rtn.push({
            name: `${t.name} (${t.unit})`,
            vals: [
              averagedLeft,
              averagedRight,
              averaged,
              averagedLeft - averagedRight,
              averagedLeft/averagedRight,

              sdLeft,
              sdRight,
              sd,
              sdLeft - sdRight,
              sdLeft/sdRight,
            ]
          });
        }
      })

      this.trunkFeatureTargetsForKarte.forEach(t => {
        let leftFeatures = this.selectedLeftTrajectories.map(_t => _t.features[t.leftAttr]).filter(v => v != null).map(v => v*(t.scale ?? 1));
        let rightFeatures = this.selectedLeftTrajectories.map(_t => _t.features[t.rightAttr]).filter(v => v != null).map(v => v*(t.scale ?? 1));
        let features = this.selectedLeftTrajectories.map(_t => _t.features[t.attr]).filter(v => v != null).map(v => v*(t.scale ?? 1));

        let averagedLeft = average(leftFeatures);
        let averagedRight = average(rightFeatures);
        let averaged = average(features);

        if(this.comparableId){
          let leftComparableFeatures = this.selectedComparableLeftTrajectories.map(_t => _t.features[t.leftAttr]).filter(v => v != null).map(v => v*(t.scale ?? 1));
          let rightComparableFeatures = this.selectedComparableLeftTrajectories.map(_t => _t.features[t.rightAttr]).filter(v => v != null).map(v => v*(t.scale ?? 1));
          let featuresComparable = this.selectedComparableLeftTrajectories.map(_t => _t.features[t.attr]).filter(v => v != null).map(v => v*(t.scale ?? 1));

          let averagedLeftComparable = average(leftComparableFeatures);
          let averagedRightComparable = average(rightComparableFeatures);
          let averagedComparable = average(featuresComparable);

          rtn.push({
            name: `${t.name} (${t.unit})`,
            vals: [
              averagedLeft,
              averagedRight,
              averaged,
              averagedLeftComparable,
              averagedRightComparable,
              averagedComparable,
              averagedLeft - averagedLeftComparable,
              averagedRight - averagedRightComparable,
              averaged - averagedComparable,
              averagedLeft/averagedLeftComparable,
              averagedRight/averagedRightComparable,
              averaged/averagedComparable,
            ],
          });
        }else{
          let sdLeft = getStandardDeviation(leftFeatures);
          let sdRight = getStandardDeviation(rightFeatures);
          let sd = getStandardDeviation(features);

          rtn.push({
            name: `${t.name} (${t.unit})`,
            vals: [
              averagedLeft,
              averagedRight,
              averaged,
              averagedLeft - averagedRight,
              averagedLeft/averagedRight,

              sdLeft,
              sdRight,
              sd,
              sdLeft - sdRight,
              sdLeft/sdRight,
            ]
          });
        }
      });

      return rtn;
    },

    leftTrajectories: function(){
      return this.gaitTrajectory?.splitted?.left ?? [];
    },
    rightTrajectories: function(){
      return this.gaitTrajectory?.splitted?.right ?? [];
    },
    comparableLeftTrajectories: function(){
      return this.comparableGaitTrajectory?.splitted?.left ?? [];
    },
    comparableRightTrajectories: function(){
      return this.comparableGaitTrajectory?.splitted?.right ?? [];
    },
    selectedDuration: function(){
      let indexFrom = this.selectedGaitDuration?.from ?? 0;
      let indexTo = this.selectedGaitDuration?.to ?? 0;

      return (indexTo - indexFrom)*0.01;
    },
    selectedComparableDuration: function(){
      let indexFrom = this.selectedComparableGaitDuration?.from ?? 0;
      let indexTo = this.selectedComparableGaitDuration?.to ?? 0;

      return (indexTo - indexFrom)*0.01;
    },
    selectedLeftTrajectories: function(){
      let indexFrom = this.selectedGaitDuration?.from ?? 0;
      let indexTo = this.selectedGaitDuration?.to ?? 0;
      return this.leftTrajectories.filter(t => !t.isInvalid && indexFrom <= t.positions[0].index && t.positions.slice(-1)[0].index < indexTo);
    },
    selectedRightTrajectories: function(){
      let indexFrom = this.selectedGaitDuration?.from ?? 0;
      let indexTo = this.selectedGaitDuration?.to ?? 0;
      return this.rightTrajectories.filter(t => !t.isInvalid && indexFrom <= t.positions[0].index && t.positions.slice(-1)[0].index < indexTo);
    },
    selectedComparableLeftTrajectories: function(){
      let indexFrom = this.selectedComparableGaitDuration?.from ?? 0;
      let indexTo = this.selectedComparableGaitDuration?.to ?? 0;
      return this.comparableLeftTrajectories.filter(t => !t.isInvalid && indexFrom <= t.positions[0].index && t.positions.slice(-1)[0].index < indexTo);
    },
    selectedComparableRightTrajectories: function(){
      let indexFrom = this.selectedComparableGaitDuration?.from ?? 0;
      let indexTo = this.selectedComparableGaitDuration?.to ?? 0;
      return this.comparableRightTrajectories.filter(t => !t.isInvalid && indexFrom <= t.positions[0].index && t.positions.slice(-1)[0].index < indexTo);
    },
  }
}
</script>