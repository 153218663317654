<template>
  <span class="switch-container">
    <v-switch
      v-bind="$attrs"
      v-model="isAuthrized"
      :class="switchClass"
      color="primary"
    />

    <Dialog
      width="540px"
      v-if="isMine && isAuthrized"
      activator-class="event-mask"
      @apply="deleteAuth"
      outlined
    >
      <template #title>
        計測参加者アクセス権削除
      </template>
      <template #contents>
        <div class="text-subtitle-1 pt-5">
          アクセス権を削除すると、プロジェクト管理者など他ユーザがアクセス権を付与するまで、あなたはこの計測参加者の計測・閲覧・編集が出来なくなります。<br/>
          <br/>
          アクセス権を削除いたしますか？
        </div>
      </template>
      <template #apply>
        削除
      </template>
    </Dialog>
  </span>
</template>

<script>
import { mapState } from 'vuex';
import Dialog from "@components/parts/dialogs/Dialog.vue";

export default {
  components: {
    Dialog,
  },
  props: {
    participant: {
      required: true,
    },
    user: {
      required: true,
    },
    authorizedUsersMap: {
      required: true,
    },
    switchClass: {
      default: null,
    }
  },
  computed: {
    ...mapState([
      'project',
    ]),
    currentUser: function(){
      return this.$store.state.user;
    },
    isMine: function(){
      return this.currentUser?.id == this.user.id;
    },
    isAuthrized: {
      get(){
        return this.authorizedUsersMap[this.participant.id]?.some(id => id == this.user.id) ?? false
      },
      set(newVal){
        let request = newVal
          ? this.axios.post
          : this.axios.delete;
        
        this.loading = true;
        request(`/api/participant_authorized_user/${this.project.id}/${this.participant.id}/${this.user.id}`).then(() => {

        }).catch(error => {
          console.log(error);
        }).finally(() => {
          this.axios.get(`/api/participant_authorized_users/${this.project.id}/${this.participant.id}`).then(response => {
            this.authorizedUsersMap[this.participant.id] = response.data.authorizedUsers;
          }).finally(() => {
            this.loading = false;
          });
        });
      }
    }
  },
  watch: {
  },
  methods: {
    deleteAuth: function(){
        this.loading = true;
        this.axios.delete(`/api/participant_authorized_user/${this.project.id}/${this.participant.id}/${this.user.id}`).then(() => {

        }).catch(error => {
          console.log(error);
        }).finally(() => {
          this.$emit('delete')
        });
    }
  },
}
</script>

<style lang="scss" scoped>
.switch-container{
  position: relative;

  ::v-deep .event-mask{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
</style>