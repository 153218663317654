<template>
  <v-container fluid>
    <v-row justify="center" dense>
      <v-btn 
        absolute 
        left 
        icon 
        large 
        color="primary" 
        class="mr-3" 
        @click="fetchAll"
      >
        <v-icon>mdi-reload</v-icon>
      </v-btn>

      <Dialog
        ref="clientDeletionDialog"
        width="480px"
        @apply="switchClientDeleted"
        dialog-only
        outlined
      >
        <template #title>
          顧客{{ target.isDeleted ? '有効化' : '無効化' }}
        </template>

        <template #contents>
          <v-container dense>
            <v-row dense align="center">
              <v-col dense>
                {{ target.isDeleted ? '以下の顧客を有効にしますか？' : '以下の顧客を無効にしますか？' }}
              </v-col>
            </v-row>
            <v-row dense align="center">
              <v-col dense class="text-h5 pl-5">
                {{ target.name }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <MarkdownEditor
                  v-model="target.description"
                  label="説明"
                  outlined
                  readonly
                />
              </v-col>
            </v-row>
          </v-container>
        </template>
        
        <template #apply>
          {{ target.isDeleted ? '有効化' : '無効化' }}
        </template>
      </Dialog>


      <Dialog
        ref="newClientDialog"
        width="960px"
        outlined
        @open="initNewClient"
        :disabled="!isNewClientValid"
        @apply="createNewClient"
      >
        <template>
          <v-icon class="mr-2">
            mdi-handshake-outline
          </v-icon>
          顧客追加
        </template>

        <template #title>
          顧客追加
        </template>

        <template #contents>
          <v-container dense>
            <v-form 
              ref="newClientForm" 
              @submit.prevent
              autocomplete="off" 
              lazy-validation
            >
              <v-row dense align="center">
                <v-col>
                  <v-text-field 
                    class="mt-3"
                    dense
                    outlined
                    v-model="newClientData.name" 
                    label="名前"
                    maxlength="256"
                    :rules="[required]"
                  />
                </v-col>
              </v-row>
              <v-row dense align="center">
                <v-col>
                  <v-text-field 
                    dense
                    outlined
                    v-model="newClientData.clientCode"
                    maxlength="256"
                    label="クライアントコード"
                    :rules="[required]"
                  />
                </v-col>
              </v-row>
              <v-row dense align="center">
                <v-col>
                  <v-text-field 
                    dense
                    outlined
                    v-model="newClientData.applicationCode"
                    maxlength="256"
                    label="アプリケーションコード"
                    :rules="[required]"
                  />
                </v-col>
              </v-row>
              <v-row dense align="center">
                <v-col
                  v-for="(applicationFunction, i) in applicationFunctions"
                  :key="`function${i}`"
                  cols="3"
                >
                  <v-switch
                    dense
                    outlined
                    hide-details
                    v-model="newClientData[applicationFunction.key]"
                    :label="applicationFunction.name"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <MarkdownEditor
                    class="mt-5"
                    label="説明"
                    v-model="newClientData.description"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </template>

        <template #apply>追加</template>
      </Dialog>
    </v-row>
    <v-row dense>
      <v-col>
        <v-data-table
          fixed-header
          class="mt-3 elevation-3"
          height="calc(100vh - 196px)"
          :loading="isLoading"
          :items="filteredClients"
          :headers="clientHeaders"
          :item-class="rowClasses"
          :custom-sort="customSort"
        >
          <template v-slot:body.prepend>
            <tr>
              <td 
                v-for="header in clientHeaders" 
                :key="header.value"
              >
                <template v-if="header.value === 'id'">
                  <div class="d-flex">
                    <v-text-field
                      v-model="filterParams['id']"
                      dense
                      hide-details
                      class="ml-1"
                    />
                  </div>
                </template>
                <template v-else-if="header.value === 'isDeleted'">
                  <div class="d-flex">
                    <v-select
                      v-model="filterParams['isDeleted']"
                      dense
                      hide-details
                      class="text-no-wrap mr-1"
                      :items="[
                        {label: '', value: null}, 
                        {label: '有効', value: false},
                        {label: '無効', value: true},
                      ]"
                      item-text="label"
                      item-value="value"
                    ></v-select>
                  </div>
                </template>
                <v-select
                  v-else-if="booleanHeaders.some(h => h === header.value)"
                  v-model="filterParams[header.value]"
                  dense
                  hide-details
                  class="text-no-wrap"
                  :items="[{label: '', value: null}, {label: 'ON', value: true}, {label: 'OFF', value: false}]"
                  item-text="label"
                  item-value="value"
                ></v-select>
                <v-text-field
                  v-else-if="header.filterable"
                  dense
                  hide-details
                  v-model="filterParams[header.value]"
                  :hint="header.text"
                  single-line
                ></v-text-field>
              </td>
            </tr>
          </template>
          <template v-slot:item.id="props">
            <div class="d-flex align-center">
              <v-btn 
                icon 
                small 
                color="accent" 
                class="mr-auto"
                :to="`/client/${props.item.id}`"
              >
                <v-icon>mdi-arrow-top-right-bold-box-outline</v-icon>
              </v-btn>
              {{ props.item.id }}
            </div>
          </template>

          <template v-slot:item.isDeleted="props">
            <v-btn 
              @click="openClientDeleteModal(props.item)" 
              icon 
              small 
              color="primary" 
              class="mr-auto"
            >
              <v-icon>{{ props.item.isDeleted ? 'mdi-delete-off' : 'mdi-delete' }}</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.activationCode="props">
            {{ props.item.activationCode }}
            <v-btn
              icon
              small
              class="ml-3"
              color="primary"
              @click.stop="downloadActivationFile(props.item)"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>

          <template 
            v-for="applicationFunction in applicationFunctions"
            v-slot:[`item.${applicationFunction.key}`]="props"
          >
            <v-switch
              v-model="props.item[applicationFunction.key]"
              dense
              hide-details
              class="ml-5 mt-0"
              :key="applicationFunction.key"
              @change="updateClientAttribute(props.item, applicationFunction.key)"
            />
          </template>

          <template v-slot:item.name="props">
            {{ props.item.name }}
            <v-tooltip 
              right
              v-if="props.item.description && props.item.description.length > 0"
              color="secondary darken-4"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon 
                  class="ml-2"
                  color="secondary"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-comment-text-outline
                </v-icon>
              </template>
              <MarkdownEditor
                v-model="props.item.description"
                readonly
              />
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { saveAs } from "file-saver";

import MarkdownEditor from "@components/inputs/MarkdownEditor.vue";
import Dialog from "@components/parts/dialogs/Dialog.vue";

export default {
  components: {
    MarkdownEditor,
    Dialog,
  },
  data: function(){
    return {
      loadingCount: 0,
      filterParams: {
        'isDeleted': [0],
      },
      clients: [],
      headers: [
        {
          text: 'ID', 
          value: 'id',
          width: 120,
          sortable: true,
          align: 'end',
          filterable: true,
          filter: value => !this.filterParams['id']?.length || String(value).indexOf(this.filterParams['id']) >= 0,
        },
        {
          text: '顧客名', 
          value: 'name',
          sortable: true,
          filterable: true,
          filter: value => !this.filterParams['name']?.length || value?.indexOf(this.filterParams['name']) >= 0,
          width: 200,
        },
        {
          text: '顧客コード', 
          value: 'clientCode',
          sortable: true,
          filterable: true,
          filter: value => !this.filterParams['clientCode']?.length || value?.indexOf(this.filterParams['clientCode']) >= 0,
          width: 200,
        },
        {
          text: 'アプリケーションコード', 
          value: 'applicationCode',
          sortable: true,
          filterable: true,
          filter: value => !this.filterParams['applicationCode']?.length || value?.indexOf(this.filterParams['applicationCode']) >= 0,
          width: 200,
        },
        {
          text: 'アクティベーションコード', 
          value: 'activationCode',
          sortable: true,
          filterable: true,
          filter: value => !this.filterParams['activationCode']?.length || value?.indexOf(this.filterParams['activationCode']) >= 0,
          width: 280,
        },
        {
          text: '更新日時', 
          value: 'updatedDatetime',
          sortable: true,
          align: 'end',
          width: 120,
        },
        {
          text: '作成日時', 
          value: 'createdDatetime',
          sortable: true,
          align: 'end',
          width: 120,
        },
        {
          text: '有効/無効', 
          value: 'isDeleted',
          width: 120,
          sortable: true,
          filterable: true,
          align: 'center',
          filter: value => this.filterParams['isDeleted'] == null || value == this.filterParams['isDeleted'],
        },
      ],
      booleanHeaders: [],
      required: value => !!value || "※必須",
      newClientData: {},
      isNewClientValid: false,
      target: {},
    }
  },
  computed: {
    ...mapState([
      'user',
      'applicationFunctions',
    ]),
    clientHeaders: function(){
      return [
        ...this.headers.slice(0, -3),
        ...this.applicationFunctions.map(f => {
          return {
            text: f.name, 
            value: f.key,
            sortable: true,
            align: 'center',
            width: 120,
          }
        }),
        ...this.headers.slice(-3),
      ];
    },
    filteredClients: function(){
      let rtn = [...this.clients];
      if(this.filterParams['isDeleted'] != null){
        rtn = rtn.filter(c => c.isDeleted == this.filterParams['isDeleted']);
      }
      return rtn;
    },
    rowClasses: function(){
      return item => item.isDeleted ? 'blue-grey lighten-4' : null;
    },
    isLoading: function(){
      return this.loadingCount > 0;
    },
  },
  watch: {
    newClientData: {
      handler: function(){
        this.$nextTick(() => {
          this.isNewClientValid = this.$refs.newClientForm?.validate();
        });
      },
      deep: true,
    },
  },
  destroyed: function(){
  },
  mounted: function(){
    if(!this.user?.role?.isAdmin){
      this.$router.push('/');
      return;
    }

    this.fetchAll();
  },
  methods: {
    customSort(items, index, isDesc) {
      let isAsc = !isDesc[0];
      let target = index[0];
      if(!target){
        return items;
      }
      items.sort((a, b) => {
        if (target === "users") {
          return isAsc 
            ? a.users.length - b.users.length
            : b.users.length - a.users.length;
        } else {
          if (isAsc) {
            return a[target] < b[target] ? -1 : 1;
          } else {
            return b[target] < a[target] ? -1 : 1;
          }
        }
      });
      return items;
    },
    initNewClient: function(){
      this.newClientData = {
        name: null,
        description: "",
      };
      this.$nextTick(() => {
        this.isNewClientValid = this.$refs.newClientForm.validate();
      });
    },
    fetchAll: function(){
      this.fetchClients();
    },
    fetchClients: function(){
      this.loadingCount++;
      this.axios.get(`/api/clients`).then(response => {
        this.clients = response.data.clients;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.loadingCount--;
      })
    },
    createNewClient: function(){
      this.axios.post(`/api/client`, this.newClientData).then(() => {
        this.fetchClients();
      }).catch(error => {
        console.log(error);
        alert('顧客作成に失敗しました');
      });
    },
    openClientDeleteModal: function(client){
      this.target = client;
      this.$refs.clientDeletionDialog.open();
    },
    updateClientAttribute: function(client, attribute){
      this.axios.put(`/api/client/${attribute}`, client).then(response => {
        client.activationCode = response.data.client.activationCode;
      }).catch(error => {
        console.log(error);
        alert('顧客情報更新に失敗しました');
      }).finally(() => {
      });
    },
    switchClientDeleted: function(){
      let newVal = !this.target.isDeleted;
      this.axios.put(`/api/client/isDeleted`, {
        ...this.target,
        isDeleted: newVal
      }).then(response => {
        if(response.data.result === 'not_found'){
          alert('該当の顧客が存在しません');
          return;
        }

        this.target.isDeleted = newVal;
      }).catch(error => {
        console.log(error);
        alert(newVal ? '顧客無効化に失敗しました' : '顧客有効化に失敗しました');
      }).finally(() => {
        this.$refs.clientDeletionDialog.close();
      });
    },
    downloadActivationFile: function(client){
      const blob = new Blob(
        [client.activationCode], 
        { type: 'application/plain' }
      );
      saveAs(blob, 'activation');
    },
  }
}
</script>

<style lang="scss" scoped>

</style>