<template>
  <VglGeometry   
    :draw-range-start="drawingFrom"
    :draw-range-count="drawingTo - drawingFrom"
  >
    <template #position>
      <VglFloat32Attribute
        :array="points"
        :item-size="3"
      />
    </template>
  </VglGeometry>
</template>

<script>
import { 
  VglGeometry,
  VglFloat32Attribute,
} from 'vue-gl';

export default {
  components: {
    VglGeometry,
    VglFloat32Attribute,
  },
  props: {
    trajectory: {
      required: true,
    },
    selection: {

    },
  },
  computed: {
    startIndex: function(){
      return this.trajectory[0].index ?? 0;
    },
    drawingMax: function(){
      return this.trajectory.length;
    },
    drawingFrom: function(){
      let rtn = this.selection?.from ?? 0;
      rtn = parseInt(rtn) - this.startIndex;
      return rtn < 0 ? 0 : Math.min(this.drawingMax, rtn);
    },
    drawingTo: function(){
      let rtn = this.selection?.to ?? this.drawingMax;
      rtn = parseInt(rtn) - this.startIndex;
      return rtn < 0 ? 0 : Math.min(this.drawingMax, rtn);
    },
    points: function(){
      let rtn = [];
      this.trajectory.forEach(p => {
        rtn.push(p.x);
        rtn.push(p.y);
        rtn.push(p.z);
      });
      return rtn;
    },
  },
}
</script>