<template>
  <tbody style="position: relative;">
    <tr>
      <th :class="color"></th>
      <th 
        v-for="coordinate in coordinates"
        class="text-center"
        :class="color"
        :key="`coordinate${coordinate.key}`"
        :colspan="viewTypes.length"
      >
        {{ coordinate.label }}
      </th>
    </tr>
    <tr v-if="only == null">
      <th :class="color"></th>
      <template v-for="coordinate in coordinates">
        <th 
          v-for="viewType in viewTypes"
          class="text-center"
          :class="color"
          :key="`coordinate${coordinate.key}-viewType${viewType.key}`"
        >
          {{ viewType.label }}
        </th>
      </template>
    </tr>

    <tr
      v-for="feature in geometricFeatures"
      :key="`geometricFeature${feature.name}`"
    >
      <td>{{ feature.name }}</td>
      <td 
        v-for="(val, i) in feature.vals"
        :key="`${feature.name}-${i}`"
        class="text-center"
      >
        {{ val != null ? val.toExponential(2) : null }}
      </td>
    </tr>

    <tr>
      <th :class="color"></th>
      <th 
        v-for="coordinate in coordinates"
        class="text-center"
        :class="color"
        :key="`coordinate${coordinate.key}`"
        :colspan="directionTypes.length"
      >
        {{ coordinate.label }}
      </th>
    </tr>
    <tr>
      <th :class="color"></th>
      <template v-for="coordinate in coordinates">
        <th
          v-for="directionType in directionTypes"
          class="text-center"
          :class="color"
          :key="`coordinate${coordinate.key}-directionType${directionType.key}`"
        >
          {{ directionType.label }}
        </th>
      </template>
    </tr>
    <tr
      v-for="feature in directionalFeatures"
      :key="`directionalFeature${feature.name}`"
    >
      <td>{{ feature.name }}</td>
      <td 
        v-for="(val, i) in feature.vals"
        :key="`${feature.name}-${i}`"
        class="text-center"
      >
        {{ val != null ? val.toExponential(2) : null }}
      </td>
    </tr>

    <v-overlay :value="loading" absolute>
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </tbody>
</template>

<script>
export default {
  props: {
    only: {
      default: null,
    },
    color: {
      default: 'primary darken-4 white--text'
    },
    coordinates: {
      default: () => [
        {key: 'coordinate', label: '位置'},
        {key: 'velocity', label: '速度'},
        {key: 'acceleration', label: '角速度'},
      ]
    },
    viewTypes: {
      default: () => [
        {key: 'side', label: '前後・上下'},
        {key: 'front', label: '左右・上下'},
        {key: 'top', label: '左右・前後'},
      ],
    },
    directionTypes: {
      default: () => [
        {key: 'x', label: '左右'},
        {key: 'y', label: '上下'},
        {key: 'z', label: '前後'},
      ],
    },
    geometricFeatures: {
      default: () => []
    },
    directionalFeatures: {
      default: () => []
    },
    loading: {
      default: false,
    }
  },
}
</script>