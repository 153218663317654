<template>
  <v-col v-show="visible" v-bind="$attrs">
    <v-row v-if="noMenu == null">
      <v-chip
        class="ml-3"
        :color="color"
        outlined
      >
        {{ title }}
      </v-chip>
    </v-row>
    <v-row :dense="dense != null">
      <v-col class="pt-0 mt-0">
        <div 
          ref="chartContainer"
          class="chart-container"
          :style="{
            height: chartHeight + 'px',
          }"
          v-observe-visibility="isVisible => (isVisible ? resizeContainer() : false)"
        >
          <TimeChart 
            ref="chart"
            :style="{
              height: chartHeight + 'px',
            }"
            :hide-legends="hideLegends"
          />
        </div>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
//import moment from "moment";
import TimeChart from '@components/parts/charts/TimeChart.vue'

export default {
  components: {
    TimeChart,
  },
  props: {
    visible: {
      default: true,
    },
    features: {
      required: true,
    },
    color: {
      default: 'secondary'
    },
    title: {
    },
    aspectRatio: {
      default: 1.92
    },
    noMenu: {
      default: null,
    },
    hideLegends: {
			default: null,
		},
    dense: {
      default: null,
    }
  },
  data: function(){
    return {
      chartWidth: 0,
    }
  },
  watch: {
    features: {
			handler(){
        this.$nextTick(() => {
          this.updateChart();
        });
			},
			deep: true,
			immediate: true,
		},
    aspectRatio: function(){
      this.$nextTick(() => {
        this.updateChart();
      });
    }
  },
  mounted() {
    this.resizeContainer();
    this.updateChart();
	},
  computed: {
    chartHeight: function(){
      return parseInt(this.chartWidth/this.aspectRatio);
    },
  },
  methods: {
    resizeContainer: function(){
      this.chartWidth = this.$refs.chartContainer?.clientWidth ?? 0;
    },
    updateChart: function(){
      if(!this.$refs.chart){
        return;
      }

      this.$refs.chart.dataCollection.datasets = this.createLineChartData();
      this.$forceUpdate();
    },
    createLineChartData: function(){
      let rtn = [];

      const defaultData = {
        fill: false,
        backgroundColor: 'hsla(0, 77%, 28%, 0.2)',
        borderColor: 'hsla(0, 77%, 28%, 0.2)',
        borderWidth: 2,
        pointRadius: 2,
        label: '',
      };

      const colors = [
        this.getLineChartColor(0),
        this.getLineChartColor(230),
        'hsla(0, 0%, 28%, 0.4)',
      ];


      this.features.forEach((feature, i) => {
        const color = colors[i];
        rtn.push({
          ...defaultData,
          label: feature.name,
          backgroundColor: color,
          borderColor: color,
          data: feature.vals.map(val => ({
            x: val.time,
            y: val.value
          })),
        });
      })

      return rtn;
    },
    getLineChartColor: function(hue){
      return `hsla(${hue}, 77%, 28%, 0.4)`;
    },
  },
}
</script>

<style lang="scss" scoped>
.chart-container{
  ::v-deep{
    canvas {
      height: 100% !important;
    }
  }
}
</style>