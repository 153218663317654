<template>
  <VglGeometry
    :draw-range-start="drawingFrom"
    :draw-range-count="drawingTo - drawingFrom"
  >
    <template #position>
      <VglFloat32Attribute
        :array="points"
        :item-size="3"
      />
    </template>
  </VglGeometry>
</template>

<script>
import { 
  VglGeometry,
  VglFloat32Attribute,
} from 'vue-gl';

export default {
  components: {
    VglGeometry,
    VglFloat32Attribute,
  },
  props: {
    trajectory: {
      required: true,
    },
    selection: {
    },
  },
  computed: {
    indexOffset: function(){
      return this.trajectory.features.indexOffset;
    },
    drawingMax: function(){
      return this.trajectory.x.length;
    },
    drawingFrom: function(){
      let rtn = this.selection?.from ?? 0;
      rtn = parseInt(rtn) - this.indexOffset;
      return rtn < 0 ? 0 : Math.min(this.drawingMax, rtn);
    },
    drawingTo: function(){
      let rtn = this.selection?.to ?? 0;
      rtn = parseInt(rtn) - this.indexOffset;
      return rtn < 0 ? 0 : Math.min(this.drawingMax, rtn);
    },
    points: function(){
      let rtn = [];

      for(let i=0; i<this.drawingMax; i++){
        rtn.push(this.trajectory.x[i]);
        rtn.push(this.trajectory.y[i]);
        rtn.push(-this.trajectory.z[i]);
      }
      return rtn;
    },
  },
}
</script>