<template>
  <v-parallax 
    :height="$vuetify.breakpoint.xs ? 120 : 240"
    :src="`https://storage.googleapis.com/walkmate-public/images/wmgc_web_eyecatch_${$vuetify.breakpoint.xs ? 'md' : 'lg'}.jpg`"
    class="mb-5"
  >
    <v-row justify="center" dense>
      <v-col 
      class="d-flex align-center justify-center text-md-h2 text-sm-h3 text-h5 font-weight-black"
        style="font-family: 'Roboto Condensed' !important;"
      >
        WM GAIT CHECKER Web
      </v-col>
    </v-row>
  </v-parallax>
</template>

<script>
export default {
}
</script>