<template>
	<LineChart
		ref="chart"
		:height="height"
	/>
</template>

<script>
import LineChart from '@components/parts/charts/LineChart.vue'

export default {
	components: {
		LineChart,
	},
	props: {
		rawData: {
			required: true,
		},
		selection: {
		},
		height: {
			default: 240,
		},
	},
	data: function() {
		return {
		};
	},
	mounted() {
		this.$refs.chart.dataCollection.datasets = this.createLineChartData(this.rawData);
	},
	watch: {
		selection: {
			handler(newVal){
				this.updateChartRange(newVal);
			},
			deep: true,
			immediate: true,
		},
    rawData: {
      handler(newVal){
        this.$nextTick(() => {
          this.$refs.chart.dataCollection.datasets = this.createLineChartData(newVal);
          this.updateChartRange(this.selection);
        });
      },
			immediate: true,
    },
	},
	methods: {
		updateChartRange: function(duration){
      let _duration = {
        from: duration?.from != null ? duration.from*10 : null,
        to: duration?.to != null ? duration.to*10 : null,
      }
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs.chart?.setRangeX(_duration);
        });
      })
    },
		createLineChartData: function(src, caption){
      let rtn = [];

      let currentIndex = 0;

      const defaultData = {
        fill: false,
        backgroundColor: 'hsla(0, 77%, 28%, 0.2)',
        borderColor: 'hsla(0, 77%, 28%, 0.2)',
        borderWidth: 2,
        label: '',
      };

      let labels = [
        'Acc X', 'Acc Y', 'Acc Z',
        'Gyr X', 'Gyr Y', 'Gyr Z',
      ]
      labels.forEach((l, i) => {
        let color = this.getLineChartColor((i*120 + (i >= 3 ? 60 : 0) + currentIndex*10));
        rtn.push({
          ...defaultData,
          label: caption ? `${caption} ${l}` : l,
          backgroundColor: color,
          borderColor: color,
          data: [],
        });
      })
      
      src.forEach(line => {
        if(line.length < 8){
          return;
        }

        if(line[0] === 'time(msec)'){
          return;
        }

        let time = parseInt(line[0]);
        for(let i=0; i<6; i++){
          rtn[currentIndex + i].data.push({
            x: time,
            y: parseInt(line[1 + i])
          })
        }
      })
      return rtn;
    },
		getLineChartColor: function(hue){
      return `hsla(${hue}, 77%, 28%, 0.2)`;
    },
	}
}
</script>