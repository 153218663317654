<template>
  <v-row dense>
    <v-col cols="4">
      <v-row dense>
        <v-col class="text-center text-caption">
          {{ comparableId ? `ID: ${id} ` : '' }}重心位置
        </v-col>
      </v-row>
      <v-row dense>
        <SwayTrajectoryGraphCard
          :id="`kartePositionCoordinate${id}`"
          no-menu
          :use="`positionCoordinate${tag}`"
          :selection="selectedSwayDuration"
          :trajectory="swayTrajectory.position.coordinate"
          :polygon-data="selectedTrajectorysPolygon.position.coordinate"
          :line-scale="100"
          :initial-view-point="view"
          :aspect-ratio="graphAspectRatio"
          :polygon-mode="polygonMode"
        />
      </v-row>

      <v-row dense v-if="comparableId">
        <v-col class="text-center text-caption">
          ID: {{ comparableId }} 重心位置
        </v-col>
      </v-row>
      <v-row dense v-if="comparableId && comparableSwayTrajectory && selectedComparableTrajectorysPolygon">
        <SwayTrajectoryGraphCard
          :id="`karteComparablePositionCoordinate${id}`"
          no-menu
          use="positionCoordinateComparable"
          :selection="selectedComparableSwayDuration"
          :trajectory="comparableSwayTrajectory.position.coordinate"
          :polygon-data="selectedComparableTrajectorysPolygon.position.coordinate"
          :line-scale="100"
          :initial-view-point="view"
          :aspect-ratio="graphAspectRatio"
          :polygon-mode="polygonMode"
        />
      </v-row>

      <v-row dense>
        <v-col class="mt-1 text-center text-caption">
          {{ comparableId ? `ID: ${id} ` : '' }}重心位置周波数スペクトル
        </v-col>
      </v-row>
      <v-row dense v-if="swaySpectrums">
        <SwaySpectrumChartCard
          dense
          :trajectory="swaySpectrums.position.coordinate"
          no-menu
          :aspect-ratio="spectrumAspectRatio"
          :only="view"
        />
      </v-row>

      <v-row dense v-if="comparableId">
        <v-col class="text-center text-caption">
          ID: {{ comparableId }} 重心位置周波数スペクトル
        </v-col>
      </v-row>

      <v-row dense v-if="comparableSwaySpectrums">
        <SwaySpectrumChartCard
          dense
          :trajectory="comparableSwaySpectrums.position.coordinate"
          no-menu
          :aspect-ratio="spectrumAspectRatio"
          :only="view"
        />
      </v-row>
    </v-col>

    <v-col cols="4">
      <v-row dense>
        <v-col class="text-center text-caption">
          {{ comparableId ? `ID: ${id} ` : '' }}重心速度
        </v-col>
      </v-row>
      <v-row dense>
        <SwayTrajectoryGraphCard
          :id="`kartePositionVelocity${id}`"
          no-menu
          :use="`positionVelocity${tag}`"
          :selection="selectedSwayDuration"
          :trajectory="swayTrajectory.position.velocity"
          :polygon-data="selectedTrajectorysPolygon.position.velocity"
          :line-scale="10"
          :initial-view-point="view"
          :aspect-ratio="graphAspectRatio"
          :polygon-mode="polygonMode"
        />
      </v-row>

      <v-row dense v-if="comparableId">
        <v-col class="text-center text-caption">
          ID: {{ comparableId }} 重心速度
        </v-col>
      </v-row>
      <v-row dense v-if="comparableId && comparableSwayTrajectory && selectedComparableTrajectorysPolygon">
        <SwayTrajectoryGraphCard
          :id="`karteComparablePositionVelocity${id}`"
          no-menu
          use="positionVelocityComparable"
          :selection="selectedComparableSwayDuration"
          :trajectory="comparableSwayTrajectory.position.velocity"
          :polygon-data="selectedComparableTrajectorysPolygon.position.velocity"
          :line-scale="10"
          :initial-view-point="view"
          :aspect-ratio="graphAspectRatio"
          :polygon-mode="polygonMode"
        />
      </v-row>

      <v-row dense>
        <v-col class="mt-1 text-center text-caption">
          {{ comparableId ? `ID: ${id} ` : '' }}重心速度周波数スペクトル
        </v-col>
      </v-row>
      <v-row dense v-if="swaySpectrums">
        <SwaySpectrumChartCard
          dense
          :trajectory="swaySpectrums.position.velocity"
          no-menu
          :aspect-ratio="spectrumAspectRatio"
          :only="view"
        />
      </v-row>

      <v-row dense v-if="comparableId">
        <v-col class="text-center text-caption">
          ID: {{ comparableId }} 重心速度周波数スペクトル
        </v-col>
      </v-row>

      <v-row dense v-if="comparableSwaySpectrums">
        <SwaySpectrumChartCard
          dense
          :trajectory="comparableSwaySpectrums.position.velocity"
          no-menu
          :aspect-ratio="spectrumAspectRatio"
          :only="view"
        />
      </v-row>
    </v-col>

    <v-col cols="4">
      <v-row dense>
        <v-col class="text-center text-caption">
          {{ comparableId ? `ID: ${id} ` : '' }}重心加速度
        </v-col>
      </v-row>
      <v-row dense>
        <SwayTrajectoryGraphCard
          :id="`kartePositionAcceleration${id}`"
          no-menu
          :use="`positionAcceleration${tag}`"
          :selection="selectedSwayDuration"
          :trajectory="swayTrajectory.position.acceleration"
          :polygon-data="selectedTrajectorysPolygon.position.acceleration"
          :initial-view-point="view"
          :aspect-ratio="graphAspectRatio"
          :polygon-mode="polygonMode"
        />
      </v-row>

      <v-row dense v-if="comparableId">
        <v-col class="text-center text-caption">
          ID: {{ comparableId }} 重心加速度
        </v-col>
      </v-row>
      <v-row dense v-if="comparableId && comparableSwayTrajectory && selectedComparableTrajectorysPolygon">
        <SwayTrajectoryGraphCard
          :id="`karteComparablePositionAcceleration${id}`"
          no-menu
          use="positionAccelerationComparable"
          :selection="selectedComparableSwayDuration"
          :trajectory="comparableSwayTrajectory.position.acceleration"
          :polygon-data="selectedComparableTrajectorysPolygon.position.acceleration"
          :initial-view-point="view"
          :aspect-ratio="graphAspectRatio"
          :polygon-mode="polygonMode"
        />
      </v-row>

      <v-row dense>
        <v-col class="mt-1 text-center text-caption">
          {{ comparableId ? `ID: ${id} ` : '' }}重心加速度周波数スペクトル
        </v-col>
      </v-row>
      <v-row dense v-if="swaySpectrums">
        <SwaySpectrumChartCard
          dense
          :trajectory="swaySpectrums.position.acceleration"
          no-menu
          :aspect-ratio="spectrumAspectRatio"
          :only="view"
        />
      </v-row>

      <v-row dense v-if="comparableId">
        <v-col class="text-center text-caption">
          ID: {{ comparableId }} 重心加速度周波数スペクトル
        </v-col>
      </v-row>

      <v-row dense v-if="comparableSwaySpectrums">
        <SwaySpectrumChartCard
          dense
          :trajectory="comparableSwaySpectrums.position.acceleration"
          no-menu
          :aspect-ratio="spectrumAspectRatio"
          :only="view"
        />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

import { getTrajectoryPolygonData } from '@js/utils/StatisticsUtil.js';

import SwayTrajectoryGraphCard from "@components/parts/cards/SwayTrajectoryGraphCard.vue";
import SwaySpectrumChartCard from "@components/parts/cards/SwaySpectrumChartCard.vue";

export default {
  components: {
    SwayTrajectoryGraphCard,
    SwaySpectrumChartCard,
  },
  props: {
    tag: {
      default: '',
    },
    id: {
      required: true,
    },
    comparableId: {
      default: null,
    },
    view: {
      default: 'top',
    },
    selectedSwayDuration: {
    },
    selectedComparableSwayDuration: {
    },
    swayTrajectory: {
    },
    comparableSwayTrajectory: {
    },
    swaySpectrums: {
    },
    comparableSwaySpectrums: {
    },
    polygonMode: {
      default: "octa",
    },
  },
  data: function(){
    return {
      
    }
  },
  computed: {
    ...mapState([
    ]),
    graphAspectRatio: function(){
      return this.comparableId ? 2 : 1;
    },
    spectrumAspectRatio: function(){
      return this.comparableId ? 1.9 : 0.85;
    },
    selectedTrajectorysPolygon: function(){
      return getTrajectoryPolygonData(this.swayTrajectory, this.selectedSwayDuration);
    },
    selectedComparableTrajectorysPolygon: function(){
      return getTrajectoryPolygonData(this.comparableSwayTrajectory, this.selectedComparableSwayDuration);
    },
  }
}
</script>