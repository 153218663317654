<template>
  <v-container fluid class="fill-height pt-0 pr-0">
    <div class="d-flex align-center">
      <v-btn 
        class="mt-2"
        icon
        large
        color="primary" 
        @click="fetchAll"
      >
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </div>

    <v-row 
      v-if="client"
      class="px-3 mt-1" 
      style="height: 100%; overflow: hidden;"
    >
      <v-col 
        cols="6" 
        md="4" 
        lg="3"
        class="pa-0 pr-3 d-flex flex-column"
      >
        <simplebar style="flex: 1; height: 0; overflow-x: hidden;">
          <v-card 
            class="pa-0 mt-2 elevation-0"
            outlined
          >
            <v-card-title 
              class="secondary white--text py-1"
            >
              ID: {{ client.id }}
            </v-card-title>
            <v-card-text>
              <v-container class="px-0 pt-5">
                <v-row>
                  <v-col>
                    <v-text-field
                      label="名前"
                      v-model="client.name"
                      hide-details
                      outlined
                      @change="updateClientAttribute('name')"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <MarkdownEditor
                      label="顧客コード"
                      v-model="client.clientCode"
                      readonly
                      outlined
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <MarkdownEditor
                      label="アプリケーションコード"
                      v-model="client.applicationCode"
                      readonly
                      outlined
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="d-flex align-end">
                    <MarkdownEditor
                      style="flex: 1;"
                      label="アクティベーションコード"
                      v-model="client.activationCode"
                      readonly
                      outlined
                    />

                    <v-btn
                      icon
                      small
                      class="mr-1 ml-2 mb-2"
                      color="primary"
                      @click.stop="downloadActivationFile"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <MarkdownEditor
                      label="説明"
                      v-model="client.description"
                      outlined
                      @change="updateClientAttribute('description')"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <div class="caption">有効機能</div>
                  </v-col>
                  <v-col
                    v-for="(applicationFunction, i) in applicationFunctions"
                    :key="`function${i}`"
                    cols="6"
                    dense
                  >
                    <v-switch
                      class="mt-0"
                      dense
                      outlined
                      hide-details
                      v-model="client[applicationFunction.key]"
                      :label="applicationFunction.name"
                      @change="updateClientAttribute(applicationFunction.key)"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      label="有効/無効"
                      v-model="client.isDeleted"
                      hide-details
                      @change="updateClientAttribute('isDeleted')"
                      :items="[
                        {label: '有効', value: false},
                        {label: '無効', value: true}
                      ]"
                      outlined
                      item-text="label"
                      item-value="value"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>

          <CommentsCard
            class="pa-0 mt-2 elevation-0"
            outlined
            :id="id"
            target="client"
          />
        </simplebar>
      </v-col>

      <v-col 
        cols="6" 
        md="4" 
        lg="3"
        class="pa-0 pr-3 d-flex flex-column"
      >
        <simplebar style="flex: 1; height: 0; overflow-x: hidden;">
          <v-card 
            class="pa-0 mt-2 elevation-0"
            outlined
          >
            <v-card-title 
              class="secondary white--text py-1"
            >
              登録センサ
            </v-card-title>
            <v-card-text>
              <v-btn
                block
                color="primary"
                dense
                class="mt-3 mb-0"
                small
                @click="downloadSensorsActivationFile"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
              <v-container class="px-0 pt-0 mt-0">
                <v-row
                  v-for="position in sensorPositions"
                  :key="position.key"
                  dense
                  class="mt-1"
                > 
                  <v-col cols="12">
                    <span class="text-h6">
                      {{ position.name }}
                    </span>
                  </v-col>
                  <v-col 
                    cols="12"
                    v-for="(sensor, i) in sensorsMap[position.key]"
                    :key="`sensor-${sensor.id}`"
                  >
                    <v-divider v-if="0 < i" class="mb-3"/>
                    <div class="d-flex align-center">
                      <div class="flex-grow-1">
                        <v-text-field
                          dense
                          hide-details
                          readonly
                          outlined
                          label="MACアドレス"
                          :value="getMacAddress(sensor.macAddress)"
                        />
                        <v-text-field
                          class="mt-3"
                          dense
                          hide-details
                          readonly
                          outlined
                          label="アクティベーションコード"
                          :value="sensor.activationCode"
                        />
                      </div>
                      <v-btn
                        icon
                        class="ml-3"
                        color="primary"
                        @click="deleteSensor(sensor)"
                      >
                        <v-icon>mdi-delete</v-icon>                        
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col 
                    cols="12"
                    class="mt-1"
                  >
                    <NewSensorDialog
                      :client="client"
                      :position="position"
                      @create-sensor="appendSensor"
                    >
                    </NewSensorDialog>
                    <v-divider class="mt-3"/>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>

        </simplebar>
      </v-col>

      <v-col 
        cols="6" 
        md="4" 
        lg="3"
        class="pa-0 pr-3 d-flex flex-column"
      >
        <simplebar style="flex: 1; height: 0; overflow-x: hidden;">
          <v-card 
            class="pa-0 mt-2 elevation-0"
            outlined
          >
            <v-card-title 
              class="secondary white--text py-1"
            >
              対象APK
            </v-card-title>
            <v-card-text>
              <v-container class="px-0 pt-5">
                <v-row>
                  <v-col cols="12">
                    <v-btn 
                      :color="client.targetApkId ? 'lightgray' : 'primary'"
                      depressed
                      @click="setTargetApk(null)"
                    >
                      デフォルト
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="pt-0 text-center text-subtitle-2">
                    現在の公開APK: {{ releasedApk ? releasedApk.name : '-' }}
                  </v-col>
                </v-row>
                <v-divider class="my-3"/>
                <v-row>
                  <v-col cols="12">
                    <Dialog
                      width="90vw"
                      :color="client.targetApkId ? 'primary' : 'lightgray'"
                      depressed
                      ref="targetApkSelectionDialog"
                    >
                      <template #title>
                        対象APK選択
                      </template>
                      <template #contents>
                        <ApksTable
                          height="calc(100vh - 196px)"
                          :items="apks"
                          :filter-params="apkFilterParams"
                          :invisible-headers-values="[
                            'isReleased',
                            'isDeleted',
                          ]"
                          selectable
                          @click-apk="setTargetApk"
                        >
                        </ApksTable>
                      </template>
                      <template #actions>
                        <span></span>
                      </template>
                      個別設定
                    </Dialog>
                  </v-col>
                  <v-col cols="12" class="pt-0 text-center text-subtitle-2">
                    {{ targetApk ? targetApk.name : '-' }}
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>

        </simplebar>
      </v-col>
      <v-col 
        cols="6" 
        md="4" 
        lg="3"
        class="pa-0 pr-3 d-flex flex-column"
      >
        <simplebar style="flex: 1; height: 0; overflow-x: hidden;">
          <v-card 
            class="pa-0 mt-2 elevation-0"
            outlined
          >
            <v-card-title 
              class="secondary white--text py-1"
            >
              更新履歴
            </v-card-title>
            <v-card-text>
              <v-list dense class="px-0 pt-5">
                <v-list-item
                  dense
                  v-for="request in requests"
                  :key="request.id"
                >
                  <v-list-item-content>
                    <v-list-item-title class="d-flex align-center">
                      <span class="text-h6">
                        {{ request.apk.name }}
                      </span>
                      <v-chip label small color="secondary" class="ml-3">
                        {{ request.apk.versionCode }}  
                      </v-chip>
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ request.updatedDatetime }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>

        </simplebar>
      </v-col>
    </v-row>
    <v-overlay
      absolute
      :value="loadingCount > 0"
      opacity="0.5"
    >
      <v-progress-circular
        indeterminate
        size="32"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { saveAs } from "file-saver";

import MarkdownEditor from "@components/inputs/MarkdownEditor.vue";
import CommentsCard from "@components/parts/cards/CommentsCard.vue";
import NewSensorDialog from "@components/parts/dialogs/NewSensorDialog.vue";
import Dialog from "@components/parts/dialogs/Dialog.vue";
import ApksTable from "@components/parts/tables/ApksTable.vue";

export default {
  components: {
    MarkdownEditor,
    CommentsCard,
    NewSensorDialog,
    Dialog,
    ApksTable,
  },
  props: {
    id: {
      required: true,
    }
  },
  data: function(){
    return {
      loadingCount: 0,
      client: null,
      filterParams: {
        'isDeleted': [0],
      },
      apks: [],
      sensors: [],
      requests: [],
      sensorPositions: [
        {
          key: 'left',
          name: '左足首',
        },
        {
          key: 'right',
          name: '右足首',
        },
        {
          key: 'trunk',
          name: '腰',
        },
      ],
      apkFilterParams: {
        'isDeleted': false,
      }
    }
  },
  computed: {
    ...mapState([
      'user',
      'applicationFunctions',
    ]),
    sensorsMap: function(){
      let rtn = {};
      this.sensorPositions.forEach(p => {
        rtn[p.key] = this.sensors.filter(s => s.position == p.key);
      });
      return rtn;
    },
    releasedApk: function(){
      return this.apks.find(a => a.isReleased)
    },
    targetApk: function(){
      return this.apks.find(a => a.id == this.client.targetApkId)
    },
  },
  watch: {
  },
  destroyed: function(){
  },
  mounted: function(){
    if(!this.user?.role?.isAdmin){
      this.$router.push('/');
      return;
    }

    this.fetchAll();
  },
  methods: {
    getMacAddress: function(macAddress){
      if(!macAddress){
        return '';
      }
      let splitted = [];
      for(let i=0, iMax=macAddress.length; i < iMax; i+=2) {
        splitted.push(macAddress.slice(i, i + 2));
      }

      return splitted.join(':');
    },
    fetchAll: function(){
      this.fetchApks();
      this.fetchClient();
    },
    fetchApks: function(){
      this.loadingCount++;
      this.axios.get(`/api/apks`).then(response => {
        this.apks = response.data.apks;
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        this.loadingCount--;
      });
    },
    fetchClient: function(){
      this.loadingCount++;
      this.axios.get(`/api/client/${this.id}`).then(response => {
        this.client = response.data.client;
        this.sensors = response.data.sensors;
        this.requests = response.data.requests;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.loadingCount--;
      })
    },
    updateClientAttribute: function(key){
      this.axios.put(`/api/client/${key}`, this.client).then(response => {
        this.client.activationCode = response.data.client.activationCode;
      }).catch(error => {
        alert('顧客情報更新に失敗しました');
        console.log(error);
      });
    },
    downloadActivationFile: function(){
      const blob = new Blob(
        [this.client.activationCode], 
        { type: 'application/plain' }
      );
      saveAs(blob, 'activation');
    },
    appendSensor: function(sensor){
      this.sensors.push(sensor);
    },
    deleteSensor: function(sensor){
      this.axios.delete(`/api/client_sensor/${sensor.id}`).then(() => {
        this.sensors = this.sensors.filter(s => s.id != sensor.id);
      })
    },
    downloadSensorsActivationFile: function(){
      const blob = new Blob(
        this.sensors.map(s => s.activationCode),
        { type: 'application/plain' }
      );
      saveAs(blob, 'sensors');
    },
    setTargetApk: function(apk){
      this.client.targetApkId = apk?.id;
      this.updateClientAttribute('targetApkId');
      this.$refs.targetApkSelectionDialog.close();
    },
  }
}
</script>

<style lang="scss" scoped>

</style>