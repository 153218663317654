<template>
  <v-col 
    v-show="visible" 
    v-bind="$attrs"
    v-resize="resizeContainer"
  >
    <v-row v-if="noMenu == null" dense>
      <v-chip
        class="ml-3"
        :color="color"
        outlined
      >
        {{ title }}
      </v-chip>
    </v-row>
    <v-row>
      <v-col 
        class="pt-1"
      >
        <div 
          ref="container"
          class="chart-container"
          :style="{
            height: containerHeight + 'px',
          }"
          v-observe-visibility="isVisible => (isVisible ? resizeContainer() : false)"
        >
          <l-map 
            v-if="containerHeight > 0"
            :style="{height: containerHeight + 'px'}" 
            :zoom="zoom" 
            :center="center" 
            ref="map"
          >
            <l-tilelayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></l-tilelayer>
            <l-marker :lat-lng="center">
            </l-marker>
          </l-map>
        </div>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import * as Vue2Leaflet from 'vue2-leaflet'

export default {
  components: {
    'l-map': Vue2Leaflet.LMap,
    'l-tilelayer': Vue2Leaflet.LTileLayer,
    'l-marker': Vue2Leaflet.LMarker,
    /*
		'l-polyline': Vue2Leaflet.LPolyline,
		'l-polygon': Vue2Leaflet.LPolygon,
		'l-popup': Vue2Leaflet.LPopup,
    */
  },
  props: {
    value: {
      default: {},
    },
    visible: {
      default: true,
    },
    noMenu: {
      default: null,
    },
    color: {
      default: 'secondary',
    },
    title: {
    },
    aspectRatio: {
      default: 1.92
    },
  },
  data: function(){
    return {
      containerWidth: 0,
      zoom: 16,
    }
  },
  computed: {
    containerHeight: function(){
      return parseInt(this.containerWidth/this.aspectRatio);
    },
    center: function(){
      return [this.value?.latitude, this.value?.longitude];
    },
  },
  watch: {
  },
  mounted: function(){
  },
  methods: {
    resizeContainer: function(){
      this.containerWidth = this.$refs.container.clientWidth;
    },
  },
}
</script>