<template>
  <v-container fluid>
    <v-row justify="center" dense>
      <v-btn 
        absolute 
        left 
        icon 
        large 
        color="primary" 
        class="mr-3" 
        @click="fetchAll"
      >
        <v-icon>mdi-reload</v-icon>
      </v-btn>

      <Dialog
        ref="participantDeletionDialog"
        width="480px"
        @apply="switchParticipantDeleted"
        dialog-only
        outlined
      >
        <template #title>
          計測参加者{{ target.isDeleted ? '有効化' : '無効化' }}
        </template>

        <template #contents>
          <v-container dense>
            <v-row dense align="center">
              <v-col dense>
                {{ target.isDeleted ? '以下の計測参加者を有効にしますか？' : '以下の計測参加者を無効にしますか？' }}
              </v-col>
            </v-row>
            <v-row dense align="center">
              <v-col dense class="text-h5 pl-5">
                {{ target.name }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <MarkdownEditor
                  v-model="target.description"
                  label="説明"
                  outlined
                  readonly
                />
              </v-col>
            </v-row>
          </v-container>
        </template>
        
        <template #apply>
          {{ target.isDeleted ? '有効化' : '無効化' }}
        </template>
      </Dialog>


      <Dialog
        ref="newParticipantDialog"
        width="480px"
        @open="initNewParticipant"
        :disabled="!isNewParticipantValid"
        @apply="createNewParticipant"
        outlined
      >
        <template>
          <v-icon class="mr-2">mdi-clipboard-text-outline</v-icon>
          計測参加者追加
        </template>

        <template #title>
          計測参加者追加
        </template>

        <template #contents>
          <v-container dense>
            <v-form 
              ref="newParticipantForm" 
              @submit.prevent
              autocomplete="off" 
              lazy-validation
            >
              <v-row dense align="center">
                <v-col>
                  <v-text-field 
                    dense
                    class="mt-5" 
                    autocomplete="new-name"
                    v-model="newParticipantData.name" 
                    label="名前"
                    :rules="[required]"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <MarkdownEditor
                    label="説明"
                    v-model="newParticipantData.description"
                    outlined
                  />
                </v-col>
              </v-row>
              <v-row dense align="center">
                <v-col>
                  <v-select
                    dense
                    class="mt-5" 
                    v-model="newParticipantData.isSharedByProject" 
                    label="プロジェクト内共有"
                    :items="[
                      {label: '共有', value: true},
                      {label: '非共有', value: false}
                    ]"
                    item-text="label"
                    item-value="value"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </template>

        <template #apply>追加</template>
      </Dialog>
    </v-row>

    <v-row dense>
      <v-col>
        <v-data-table
          fixed-header
          class="mt-3 elevation-3"
          height="calc(100vh - 196px)"
          :loading="isLoading"
          :items="filteredParticipants"
          :headers="validHeaders"
          :item-class="rowClasses"
          :custom-sort="customSort"
        >
          <template v-slot:body.prepend>
            <tr>
              <td 
                v-for="header in validHeaders" 
                :key="header.value"
              >
                <template v-if="header.value === 'id'">
                  <div class="d-flex">
                    <v-text-field
                      v-model="filterParams['id']"
                      dense
                      hide-details
                      class="ml-1"
                    />
                  </div>
                </template>
                <template v-else-if="header.user">
                  <div class="d-flex">
                    <v-select
                      v-model="filterParams[header.value]"
                      dense
                      hide-details
                      class="text-no-wrap mr-1"
                      :items="[
                        {label: '', value: null}, 
                        {label: '可', value: true},
                        {label: '不可', value: false},
                      ]"
                      item-text="label"
                      item-value="value"
                    ></v-select>
                  </div>
                </template>
                <template v-else-if="header.value === 'isDeleted'">
                  <div class="d-flex">
                    <v-select
                      v-model="filterParams['isDeleted']"
                      dense
                      hide-details
                      class="text-no-wrap mr-1"
                      :items="[
                        {label: '', value: null}, 
                        {label: '有効', value: false},
                        {label: '無効', value: true},
                      ]"
                      item-text="label"
                      item-value="value"
                    ></v-select>
                  </div>
                </template>
                <v-select
                  v-else-if="booleanHeaders.some(h => h === header.value)"
                  v-model="filterParams[header.value]"
                  dense
                  hide-details
                  class="text-no-wrap"
                  :items="[{label: '', value: null}, {label: 'ON', value: true}, {label: 'OFF', value: false}]"
                  item-text="label"
                  item-value="value"
                ></v-select>
                <v-text-field
                  v-else-if="header.filterable"
                  dense
                  hide-details
                  v-model="filterParams[header.value]"
                  :hint="header.text"
                  single-line
                ></v-text-field>
              </td>
            </tr>
          </template>
          <template v-slot:item.id="props">
            <div class="d-flex align-center">
              <v-btn 
                icon 
                small 
                color="accent" 
                class="mr-auto"
                :to="`/participant/${props.item.id}`"
              >
                <v-icon>mdi-arrow-top-right-bold-box-outline</v-icon>
              </v-btn>
              {{ props.item.id }}
            </div>
          </template>

          
          <template 
            v-for="_user in users"
            v-slot:[`item.user${_user.id}`]="props"
          >
            <v-tooltip
              v-if="props.item.isSharedByProject || _user.role.isAdmin || _user.affiliation.isManager"
              :key="`shared-${_user.id}-${props.item.id}`"
              color="primary darken-4"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="d-flex align-center justify-center"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-switch
                    dense
                    hide-details
                    class="ma-auto pa-0"
                    :input-value="true"
                    color="primary"
                    disabled
                  />
                </div>
              </template>
              <span v-if="_user.role.isAdmin">
                このユーザはシステム管理者のため閲覧不可に設定できません
              </span>
              <span v-else-if="_user.affiliation.isManager">
                このユーザはプロジェクト管理者のため閲覧不可に設定できません。
              </span>
              <span v-else-if="props.item.isSharedByProject">
                プロジェクト内共有の場合は個別設定はできません
              </span>
            </v-tooltip>
            <div
              v-else
              :key="`${_user.id}-${props.item.id}`"
              class="d-flex align-center justify-center"
            >
              <AuthrizedUserSwitch
                dense
                switch-class="ma-auto pa-0"
                hide-details
                :participant="props.item"
                :user="_user"
                :authorized-users-map="authorizedUsersMap"
                @delete="fetchAll"
              />
            </div>
          </template>

          <template v-slot:item.isSharedByProject="props">
            <div class="d-flex align-center justify-center">
              <v-switch
                dense
                hide-details
                class="ma-auto pa-0"
                v-model="props.item.isSharedByProject"
                @change="updateParticipantAttribute(props.item, 'isSharedByProject')"
              />
            </div>
          </template>


          <template v-slot:item.isDeleted="props">
            <v-btn 
              @click="openParticipantDeleteModal(props.item)" 
              icon 
              small 
              color="primary" 
              class="mr-auto"
            >
              <v-icon>{{ props.item.isDeleted ? 'mdi-delete-off' : 'mdi-delete' }}</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.name="props">
            {{ props.item.name }}
            <v-tooltip 
              right
              v-if="props.item.description && props.item.description.length > 0"
              color="secondary darken-4"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon 
                  class="ml-2"
                  color="secondary"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-comment-text-outline
                </v-icon>
              </template>
              <MarkdownEditor
                v-model="props.item.description"
                readonly
              />
            </v-tooltip>
          </template>

          <template v-slot:item.users="props">
            {{ props.item.users.length }}
            <v-tooltip 
              right
              v-if="props.item.users.length > 0"
              color="secondary darken-4"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon 
                  class="ml-2"
                  color="secondary"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-account-group
                </v-icon>
              </template>
              <div  
                v-for="_user in props.item.users"
                :key="_user.id"
              >
                {{ user.name }}
              </div>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

import MarkdownEditor from "@components/inputs/MarkdownEditor.vue";
import Dialog from "@components/parts/dialogs/Dialog.vue";
import AuthrizedUserSwitch from "@components/inputs/AuthrizedUserSwitch.vue";



export default {
  components: {
    MarkdownEditor,
    Dialog,
    AuthrizedUserSwitch,
  },
  data: function(){
    return {
      loadingCount: 0,
      filterParams: {},
      users: [],
      affiliations: [],
      headers: [
        {
          text: 'ID', 
          value: 'id',
          width: 120,
          sortable: true,
          align: 'end',
          filterable: true,
          filter: value => !this.filterParams['id']?.length || String(value).indexOf(this.filterParams['id']) >= 0,
        },
        {
          text: '名前', 
          value: 'name',
          sortable: true,
          filterable: true,
          filter: value => !this.filterParams['name']?.length || value?.indexOf(this.filterParams['name']) >= 0,
        },
        {
          text: 'プロジェクト内共有', 
          value: 'isSharedByProject',
          width: 120,
          sortable: true,
          filterable: true,
          align: 'center',
          filter: value => this.filterParams['isSharedByProject'] == null || value == this.filterParams['isSharedByProject'],
        },
        {
          text: '更新日時', 
          value: 'updatedTime',
          sortable: true,
          align: 'end',
          width: 120,
        },
        {
          text: '作成日時', 
          value: 'createdTime',
          sortable: true,
          align: 'end',
          width: 120,
        },
        {
          text: '有効/無効', 
          value: 'isDeleted',
          width: 120,
          sortable: true,
          filterable: true,
          align: 'center',
          filter: value => this.filterParams['isDeleted'] == null || value == this.filterParams['isDeleted'],
        },
      ],
      booleanHeaders: [],
      required: value => !!value || "※必須",
      newParticipantData: {},
      isNewParticipantValid: false,
      isParticipantEditValid: false,
      target: {},
    }
  },
  computed: {
    ...mapState([
      'user',
      'project',
      'participants',
      'authorizedUsersMap',
    ]),
    validHeaders: function(){
      let rtn = [...this.headers];

      this.users.forEach(u => {
        let key = `user${u.id}`;
        rtn.push({
          text: u.name, 
          value: key,
          width: 120,
          sortable: true,
          filterable: true,
          user: true,
          userId: u.id,
          align: 'center',
          filter: value => this.filterParams[key] == null || value == this.filterParams[key],
        })
      })

      return rtn;
    },
    filteredParticipants: function(){
      let rtn = [...this.participants];
      if(this.filterParams['isDeleted'] != null){
        rtn = rtn.filter(p => p.isDeleted == this.filterParams['isDeleted']);
      }
      return rtn;
    },
    rowClasses: function(){
      return item => item.isDeleted ? 'blue-grey lighten-4' : null;
    },
    isLoading: function(){
      return this.loadingCount > 0;
    },
  },
  watch: {
    newParticipantData: {
      handler: function(){
        this.$nextTick(() => {
          this.isNewParticipantValid = this.$refs.newParticipantForm?.validate();
        });
      },
      deep: true,
    },
    project: {
      handler: function(newVal){
        if(newVal){
          this.fetchAll();
        }
      },
    }
  },
  destroyed: function(){
  },
  mounted: function(){
    if(!this.project){
      this.$router.push('/');
      return;
    }

    this.fetchAll();
  },
  methods: {
    ...mapActions([
      'fetchParticipants',
    ]),
    customSort(items, index, isDesc) {
      let isAsc = !isDesc[0];
      let target = index[0];
      if(!target){
        return items;
      }
      items.sort((a, b) => {
        if (target === "users") {
          return isAsc 
            ? a.users.length - b.users.length
            : b.users.length - a.users.length;
        } else {
          if (isAsc) {
            return a[target] < b[target] ? -1 : 1;
          } else {
            return b[target] < a[target] ? -1 : 1;
          }
        }
      });
      return items;
    },
    initNewParticipant: function(){
      this.newParticipantData = {
        name: null,
        description: "",
        isSharedByProject: false,
      };
      this.$nextTick(() => {
        this.isNewParticipantValid = this.$refs.newParticipantForm.validate();
      });
    },
    fetchAll: function(){
      this.fetchParticipants();
      this.fetchUsers();
    },
    fetchUsers: function(){
      this.loadingCount++;
      this.axios.get(`/api/users/${this.project.id}`).then(response => {
        this.users = response.data.users;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.loadingCount--;
      })
    },
    createNewParticipant: function(){
      this.axios.post(`/api/participant`, {
        ...this.newParticipantData,
        projectId: this.project.id
      }).then(() => {
        this.fetchParticipants();
      }).catch(error => {
        console.log(error);
        alert('計測参加者作成に失敗しました');
      });
    },
    openParticipantDeleteModal: function(participant){
      this.target = participant;
      this.$refs.participantDeletionDialog.open();
    },
    switchParticipantDeleted: function(){
      let newVal = !this.target.isDeleted;
      this.axios.put(`/api/participant/isDeleted`, {
        ...this.target,
        projectId: this.project.id,
        isDeleted: newVal
      }).then(response => {
        if(response.data.result === 'not_found'){
          alert('該当の計測参加者が存在しません');
          return;
        }

        this.target.isDeleted = newVal;
      }).catch(error => {
        console.log(error);
        alert(newVal ? '計測参加者無効化に失敗しました' : '計測参加者有効化に失敗しました');
      }).finally(() => {
        this.$refs.participantDeletionDialog.close();
      });
    },
    updateParticipantAttribute: function(participant, key){
      this.axios.put(`/api/participant/${key}`, participant).then(() => {

      }).catch(error => {
        alert('非計測者情報の更新に失敗しました');
        console.log(error);
      });
    },
  }
}
</script>

<style lang="scss" scoped>
</style>