<template>
  <v-data-table
    fixed-header
    :height="height"
    :loading="loading"
    :items="filteredUsers"
    :headers="visibleHeaders"
    :item-class="rowClasses"
    page.sync="_currentPage"
    :items-per-page.sync="_itemsPerPage"
    :sort-by="_sortedBy"
    :sort-desc="_sortedDesc"
    @update:sort-by="_sortedBy=$event"
    @update:sort-desc="_sortedDesc=$event"
    class="elevation-3"
    :footer-props="{
      'items-per-page-options' : [15, 30, 50, 100, -1]
    }"
  >
    <template v-slot:body.prepend>
      <tr>
        <td v-for="header in visibleHeaders" :key="header.value">
          <v-select
            v-if="header.value === 'roleId'"
            v-model="filterParams['roleId']"
            dense
            hide-details
            class="text-no-wrap"
            :items="roleFilterItems"
            item-text="label"
            item-value="value"
          ></v-select>
          <template v-else-if="header.value === 'id'">
            <div class="d-flex">
              <v-text-field
                v-model="filterParams['id']"
                dense
                hide-details
                class="ml-1"
              />
            </div>
          </template>
          <v-select
            v-else-if="header.value === 'isDeleted'"
            v-model="filterParams['isDeleted']"
            dense
            hide-details
            class="text-no-wrap mr-1"
            :items="[
              {label: '', value: null}, 
              {label: '有効', value: false},
              {label: '無効', value: true}, 
            ]"
            item-text="label"
            item-value="value"
          ></v-select>
          <v-select
            v-else-if="functionHeaders.some(h => h === header.value)"
            v-model="filterParams[header.value]"
            dense
            hide-details
            class="text-no-wrap"
            :items="[
              {label: '', value: null}, 
              {label: 'ON', value: true}, 
              {label: 'OFF', value: false}
            ]"
            item-text="label"
            item-value="value"
          ></v-select>
          <v-text-field
            v-else-if="header.filterable"
            dense
            hide-details
            v-model="filterParams[header.value]"
            :hint="header.text"
            single-line
          ></v-text-field>
        </td>
      </tr>
    </template>
    <template v-slot:item.id="props">
      <slot name="itemPrepend" :props="props">
      </slot>
      {{ props.item.id }}
    </template>
    <template v-slot:item.roleId="props">
      {{ roleName(props.item.roleId) }}
    </template>

    <template
      v-for="header in functionHeaders"
      v-slot:[`item.${header}`]="props"
    >
      <div
        class="d-flex align-center justify-center"
        :key="`${header}-${props.item.id}`"
      >
        <v-switch 
          dense
          hide-details
          class="ma-auto pa-0"
          v-model="props.item[header]" 
          @change="updateWMGCSetting(props.item)"
        />
      </div>
    </template>

    <template v-slot:item.sensors="props">
      <Dialog
        outlined
        width="960px"
        @apply="saveSensors(props.item)"
        @open="fetchSensors(props.item)"
      >
        <template>
          <v-icon color="primary">mdi-pencil</v-icon>
        </template>
        <template #title>
          センサ設定 {{ props.item.name }}
        </template>
        <template #contents>
          <v-container fluid>
            <v-row dense>
              <v-col cols="4">
                <v-card dense tile>
                  <v-card-title 
                    class="text-subtitle-1 py-1 secondary lighten-3 justify-start white--text"
                  >
                    左足首センサ
                  </v-card-title>

                  <v-card-text class="px-1 pb-1">
                    <v-list dense>
                      <v-list-item
                        v-for="sensor, i in leftSensors"
                        :key="`left-${i}`"
                      >
                        <v-btn @click="leftSensors.splice(i, 1)" small icon color="secondary" class="mr-2">
                          <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                        <div class="d-flex flex-column mr-0 flex-grow-1 my-3">
                          <v-text-field
                            dense
                            hide-details
                            label="名前"
                            v-model="leftSensors[i].name"
                            class="mt-2"
                          ></v-text-field>
                          <v-text-field
                            dense
                            hide-details
                            label="MACアドレス"
                            v-model="leftSensors[i].macAddress"
                            class="mt-5"
                          ></v-text-field>
                        </div>
                      </v-list-item>
                      <v-list-item>
                        <v-btn block class="secondary mt-2" @click="leftSensors.push({})">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card dense tile>
                  <v-card-title 
                    class="text-subtitle-1 py-1 secondary lighten-3 justify-start white--text"
                  >
                    右足首センサ
                  </v-card-title>

                  <v-card-text class="px-1 pb-1">
                    <v-list dense>
                      <v-list-item
                        v-for="sensor, i in rightSensors"
                        :key="`left-${i}`"
                      >
                        <v-btn @click="rightSensors.splice(i, 1)" small icon color="secondary" class="mr-2">
                          <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                        <div class="d-flex flex-column mr-0 flex-grow-1 my-3">
                          <v-text-field
                            dense
                            hide-details
                            label="名前"
                            v-model="rightSensors[i].name"
                            class="mt-2"
                          ></v-text-field>
                          <v-text-field
                            dense
                            hide-details
                            label="MACアドレス"
                            v-model="rightSensors[i].macAddress"
                            class="mt-5"
                          ></v-text-field>
                        </div>
                      </v-list-item>
                      <v-list-item>
                        <v-btn block class="secondary mt-2" @click="rightSensors.push({})">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card dense tile>
                  <v-card-title 
                    class="text-subtitle-1 py-1 secondary lighten-3 justify-start white--text"
                  >
                    腰センサ
                  </v-card-title>

                  <v-card-text class="px-1 pb-1">
                    <v-list dense>
                      <v-list-item
                        v-for="sensor, i in trunkSensors"
                        :key="`left-${i}`"
                      >
                        <v-btn @click="trunkSensors.splice(i, 1)" small icon color="secondary" class="mr-2">
                          <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                        <div class="d-flex flex-column mr-0 flex-grow-1 my-3">
                          <v-text-field
                            dense
                            hide-details
                            label="名前"
                            v-model="trunkSensors[i].name"
                            class="mt-2"
                          ></v-text-field>
                          <v-text-field
                            dense
                            hide-details
                            label="MACアドレス"
                            v-model="trunkSensors[i].macAddress"
                            class="mt-5"
                          ></v-text-field>
                        </div>
                      </v-list-item>
                      <v-list-item>
                        <v-btn block class="secondary mt-2" @click="trunkSensors.push({})">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template #apply>
          保存
        </template>
      </Dialog>
    </template>

    <template v-slot:item.isDeleted="props">
      <Dialog
        outlined
        width="480px"
        @apply="switchUserDeleted(props.item)"
      >
        <template>
          <v-icon color="primary">
            {{ props.item.isDeleted ? 'mdi-delete-off' : 'mdi-delete' }}
          </v-icon>
        </template>
        <template #title>
          アカウント{{ props.item.isDeleted ? '有効化' : '無効化' }}
        </template>
        <template #contents>
          <v-container dense>
            <v-row dense align="center">
              <v-col dense>
                {{ props.item.isDeleted ? '以下のアカウントを有効にしますか？' : '以下のアカウントを削除いたしますか？' }}
              </v-col>
            </v-row>
            <v-row dense align="center">
              <v-col dense class="text-h5 pl-5">
                {{ props.item.mailAddress }}
              </v-col>
            </v-row>
            <v-row dense align="center">
              <v-col dense class="text-h5 pl-5">
                {{ props.item.name }}
              </v-col>
            </v-row>
          </v-container>
        </template>
      </Dialog>
    </template>
    <template
      v-for="header in additionalHeaders"
      v-slot:[`item.${header.value}`]="props"
    >
      <slot
        :name="`item.${header.value}`" 
        :props="props"
      >
      </slot>
    </template>
  </v-data-table>
</template>

<script>
import Dialog from "@components/parts/dialogs/Dialog.vue";

export default {
  components: {
    Dialog,
  },
  props: {
    users: {
      default: () => [],
    },
    roles: {
      default: () => [],
    },
    additionalHeaders: {
      default: () => [],
    },
    height: {
      default: null,
    },
    loading: {
      default: false,
    },
    invisibleHeadersValues: {
      default: () => []
    },
    filterParams: {
      default: () => ({})
    },
    itemsPerPage: {
      default: 0,
    },
    currentPage: {
      default: 0,
    },
    sortedBy: {
      default: 'userId'
    },
    sortedDesc: {
      default: true,
    },
  },
  data: function(){
    return {
      leftSensors: [],
      rightSensors: [],
      trunkSensors: [],

      headers: [
        {
          text: 'ID', 
          value: 'id',
          width: 120,
          sortable: true,
          filterable: true,
          filter: value => !this.filterParams['id']?.length || String(value).indexOf(this.filterParams['id']) >= 0,
        },
        {
          text: 'メールアドレス', 
          value: 'mailAddress',
          width: 240,
          sortable: true,
          filterable: true,
          filter: value => !this.filterParams['mailAddress']?.length || value?.indexOf(this.filterParams['mailAddress']) >= 0,
        },
        {
          text: '名前', 
          value: 'name',
          width: 160,
          sortable: true,
          filterable: true,
          filter: value => !this.filterParams['name']?.length || value?.indexOf(this.filterParams['name']) >= 0,
        },
        {
          text: '権限', 
          value: 'roleId',
          width: 160,
          sortable: true,
          filterable: true,
          filter: value => this.filterParams['roleId'] == null || value == this.filterParams['roleId'],
        },

        {
          text: '有効/無効', 
          value: 'isDeleted',
          width: 120,
          sortable: true,
          filterable: true,
          align: 'center',
          filter: value => this.filterParams['isDeleted'] == null || value == this.filterParams['isDeleted'],
        },
        

        {
          text: 'センサ', 
          value: 'sensors',
          width: 120,
          sortable: true,
        },

        {
          text: 'プロ', 
          value: 'isProMode',
          width: 80,
          sortable: true,
          filterable: true,
          filter: value => this.filterParams['isProMode'] == null || value == this.filterParams['isProMode'],
        },
        {
          text: 'カルテ', 
          value: 'isKarteEnable',
          width: 80,
          sortable: true,
          filterable: true,
          filter: value => this.filterParams['isKarteEnable'] == null || value == this.filterParams['isKarteEnable'],
        },
        {
          text: '地磁気', 
          value: 'isMagEnable',
          width: 80,
          sortable: true,
          filterable: true,
          filter: value => this.filterParams['isMagEnable'] == null || value == this.filterParams['isMagEnable'],
        },
        {
          text: '異常設定', 
          value: 'isAbnormalTrajectorySettingEnable',
          width: 80,
          sortable: true,
          filterable: true,
          filter: value => this.filterParams['isAbnormalTrajectorySettingEnable'] == null || value == this.filterParams['isAbnormalTrajectorySettingEnable'],
        },
        {
          text: '重心動揺', 
          value: 'isSwayModeEnable',
          width: 80,
          sortable: true,
          filterable: true,
          filter: value => this.filterParams['isSwayModeEnable'] == null || value == this.filterParams['isSwayModeEnable'],
        },
        {
          text: '連続軌道', 
          value: 'isContinuousTrajectoryEnable',
          width: 80,
          sortable: true,
          filterable: true,
          filter: value => this.filterParams['isContinuousTrajectoryEnable'] == null || value == this.filterParams['isContinuousTrajectoryEnable'],
        },
        {
          text: '連測軌道分離', 
          value: 'isContinuousTrajectorySeparated',
          width: 80,
          sortable: true,
          filterable: true,
          filter: value => this.filterParams['isContinuousTrajectorySeparated'] == null || value == this.filterParams['isContinuousTrajectorySeparated'],
        },
        {
          text: '姿勢計測', 
          value: 'isPostureEnable',
          width: 80,
          sortable: true,
          filterable: true,
          filter: value => this.filterParams['isPostureEnable'] == null || value == this.filterParams['isPostureEnable'],
        },
        {
          text: 'アシスト', 
          value: 'isAssistEnable',
          width: 80,
          sortable: true,
          filterable: true,
          filter: value => this.filterParams['isAssistEnable'] == null || value == this.filterParams['isAssistEnable'],
        },
        {
          text: '2次元', 
          value: 'is2DAccEnable',
          width: 80,
          sortable: true,
          filterable: true,
          filter: value => this.filterParams['is2DAccEnable'] == null || value == this.filterParams['is2DAccEnable'],
        },
        {
          text: 'すくみ', 
          value: 'isFreezingIdexesOutputEnable',
          width: 80,
          sortable: true,
          filterable: true,
          filter: value => this.filterParams['isFreezingIdexesOutputEnable'] == null || value == this.filterParams['isFreezingIdexesOutputEnable'],
        },
        {
          text: '生データ', 
          value: 'isRawDataOutputEnable',
          width: 80,
          sortable: true,
          filterable: true,
          filter: value => this.filterParams['isRawDataOutputEnable'] == null || value == this.filterParams['isRawDataOutputEnable'],
        },
        {
          text: 'レポート出力', 
          value: 'isReportOutputEnable',
          width: 80,
          sortable: true,
          filterable: true,
          filter: value => this.filterParams['isReportOutputEnable'] == null || value == this.filterParams['isReportOutputEnable'],
        },
      ],
      functionHeaders: [
        'isProMode',
        'isKarteEnable',
        'isMagEnable',
        'isAbnormalTrajectorySettingEnable',
        'isSwayModeEnable',
        'isContinuousTrajectoryEnable',
        'isContinuousTrajectorySeparated',
        'isPostureEnable',
        'isAssistEnable',
        'is2DAccEnable',
        'isFreezingIdexesOutputEnable',
        'isRawDataOutputEnable',
        'isReportOutputEnable',
      ],
    };
  },
  computed: {
    filteredUsers: function(){
      let rtn = [...this.users];
      if(this.filterParams['isDeleted'] != null){
        rtn = rtn.filter(u => u.isDeleted == this.filterParams['isDeleted']);
      }
      return rtn;
    },
    visibleHeaders: function(){
      let rtn = [...this.headers, ...this.additionalHeaders];
      return rtn.filter(h => !this.invisibleHeadersValues.some(v => v === h.value));
    },
    rowClasses: function(){
      return item => item.isDeleted ? 'blue-grey lighten-4' : null;
    },
    roleFilterItems: function(){
      return [
        {label: '', value: null},
        ...this.roles.map(r => ({label: r.name, value: r.id}))
      ]
    },
    roleName: function(){
      return roleId => this.roles.find(r => r.id == roleId)?.name;
    },
    _itemsPerPage: {
      get(){
        return this.itemsPerPage;
      },
      set(newVal){
        this.$emit('update:itemsPerPage', newVal)
      }
    },
    _currentPage: {
      get(){
        return this.currentPage;
      },
      set(newVal){
        this.$emit('update:currentPage', newVal)
      }
    },
    _sortedBy: {
      get(){
        return this.sortedBy;
      },
      set(newVal){
        this.$emit('update:sortedBy', newVal)
      }
    },
    _sortedDesc: {
      get(){
        return this.sortedDesc;
      },
      set(newVal){
        this.$emit('update:sortedDesc', newVal)
      }
    },
  },
  methods: {
    updateWMGCSetting: function(user){
      this.axios.put(`/api/wmgc_setting`, user).then(response => {
        if(response.data.result === 'not_found'){
          alert('該当のアカウントが存在しません');
          return;
        }
      }).catch(error => {
        console.log(error);
        alert('アカウント更新に失敗しました');
      }).finally(() => {
        this.isAccountEditModalOpened = false;
      });
    },
    switchUserDeleted: function(user){
      if(user.isDeleted){
        this.axios.put(`/api/user/${user.id}/activate`).then(response => {
          if(response.data.result === 'not_found'){
            alert('該当のアカウントが存在しません');
            return;
          }
          user.isDeleted = false;
        }).catch(error => {
          console.log(error);
          alert('アカウント有効化に失敗しました');
        }).finally(() => {

        });
      }else{
        this.axios.delete(`/api/user/${user.id}`).then(response => {
        if(response.data.result === 'not_found'){
          alert('該当のアカウントが存在しません');
          return;
        }
        user.isDeleted = true;
      }).catch(error => {
        console.log(error);
        alert('アカウント無効化に失敗しました');
      }).finally(() => {
      });
      }


    },
    saveSensors: function(user){
      this.axios.post(`/api/user_sensors`, {
        userId: user.id,
        leftSensors: this.leftSensors,
        rightSensors: this.rightSensors,
        trunkSensors: this.trunkSensors,
      }).then(() => {
        
      }).catch(error => {
        console.log(error);
        alert('センサの登録に失敗しました');
      })
    },
    fetchSensors: function(user){
      this.leftSensors = [];
      this.rightSensors = [];
      this.trunkSensors = [];

      this.axios.get(`/api/user_sensors/${user.id}`).then(response => {
        const sensors = response.data.sensors;
        this.leftSensors = sensors.filter(s => s.position === 'left');
        this.rightSensors = sensors.filter(s => s.position === 'right');
        this.trunkSensors = sensors.filter(s => s.position === 'trunk');
      }).catch(error => {
        console.log(error);
        alert('センサ一覧の取得に失敗しました');
      });
    },
  }
}
</script>