var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.project)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6","md":"4","lg":"3"}},[_c('v-btn',{staticClass:"mb-0",attrs:{"icon":"","large":"","color":"primary"},on:{"click":_vm.fetchAll}},[_c('v-icon',[_vm._v("mdi-reload")])],1),_c('v-card',{staticClass:"pa-0 elevation-0 mr-3",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"primary darken-3 white--text py-1"},[_vm._v(" ID: "+_vm._s(_vm.project.id)+" ")]),_c('v-card-text',[_c('v-container',{staticClass:"px-0"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-text-field',{attrs:{"label":"プロジェクト名","hide-details":""},on:{"change":function($event){return _vm.updateProjectAttribute('name')}},model:{value:(_vm.project.name),callback:function ($$v) {_vm.$set(_vm.project, "name", $$v)},expression:"project.name"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('MarkdownEditor',{staticClass:"mt-3",attrs:{"label":"説明","outlined":""},on:{"change":function($event){return _vm.updateProjectAttribute('description')}},model:{value:(_vm.project.description),callback:function ($$v) {_vm.$set(_vm.project, "description", $$v)},expression:"project.description"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-text-field',{attrs:{"label":"最大ユーザ数","hide-details":"","type":"number"},on:{"change":function($event){return _vm.updateProjectAttribute('maxUserNumber')}},model:{value:(_vm.project.maxUserNumber),callback:function ($$v) {_vm.$set(_vm.project, "maxUserNumber", $$v)},expression:"project.maxUserNumber"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-select',{attrs:{"label":"有効/無効","hide-details":"","items":[
                    {label: '有効', value: false},
                    {label: '無効', value: true}
                  ],"item-text":"label","item-value":"value"},on:{"change":function($event){return _vm.updateProjectAttribute('isDeleted')}},model:{value:(_vm.project.isDeleted),callback:function ($$v) {_vm.$set(_vm.project, "isDeleted", $$v)},expression:"project.isDeleted"}})],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"6","md":"8","lg":"9"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"7"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-chip',{attrs:{"outlined":"","color":"primary"}},[_vm._v(" プロジェクト内アカウント ")])],1),_c('UsersTable',{staticClass:"mt-3",attrs:{"height":"calc(100vh - 196px)","loading":_vm.loadingCount > 0,"users":_vm.usersInProject,"roles":_vm.roles,"filter-params":_vm.filterParamsForUsersInProject,"invisible-headers-values":_vm.invisibleUserHeadersValues,"additional-headers":[{
              text: '管理者',
              value: 'isManager',
              width: 100,
              align: 'center',
              sortable: true,
            }]},scopedSlots:_vm._u([{key:"itemPrepend",fn:function(ref){
            var props = ref.props;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","color":"primary","small":""},on:{"click":function($event){return _vm.removeFromProject(props.item)}}},[_c('v-icon',[_vm._v("mdi-account-minus-outline")])],1)]}},{key:"item.isManager",fn:function(ref){
            var props = ref.props;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-switch',{on:{"change":function($event){return _vm.updateProjectManager(props.item)}},model:{value:(props.item.affiliation.isManager),callback:function ($$v) {_vm.$set(props.item.affiliation, "isManager", $$v)},expression:"props.item.affiliation.isManager"}})],1)]}}],null,false,324800044)})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-chip',{attrs:{"outlined":"","color":"secondary"}},[_vm._v(" 他アカウント ")])],1),_c('UsersTable',{staticClass:"mt-3",attrs:{"height":"calc(100vh - 196px)","loading":_vm.loadingCount > 0,"users":_vm.otherUsers,"roles":_vm.roles,"filter-params":_vm.filterParamsForOtherUsers,"invisible-headers-values":_vm.invisibleUserHeadersValues},scopedSlots:_vm._u([{key:"itemPrepend",fn:function(ref){
            var props = ref.props;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","color":"primary","small":""},on:{"click":function($event){return _vm.addToProject(props.item)}}},[_c('v-icon',[_vm._v("mdi-account-plus-outline")])],1)]}}],null,false,777437023)})],1)],1)],1)],1):_vm._e(),_c('v-overlay',{attrs:{"absolute":"","value":_vm.loadingCount > 0,"opacity":"0.5"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"32"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }