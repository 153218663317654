<template>
  <v-container fluid>
    <v-tabs 
      v-model="currentTabIndex"
      class="mb-3 primary"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.key"
      >{{ tab.label }}</v-tab>
    </v-tabs>

    <v-row justify="center" dense>
      <v-btn absolute left icon large color="primary" class="mr-3" @click="fetchAll">
        <v-icon>mdi-reload</v-icon>
      </v-btn>

      <Dialog
        width="600px"
        :disabled="!isGaitUploadable"
        @apply="uploadGait"
        outlined
        v-if="currentTabKey === 'reports'"
      >
        <v-icon class="mr-2">mdi-upload</v-icon>
        アップロード

        <template #title>
          歩行計測データ選択
        </template>

        <template #contents>
          <v-container 
            dense
            fluid 
            @dragleave.prevent 
            @dragover.prevent 
            @drop.prevent="selectFiles"
          >
            <v-row>
              <v-col>
                <v-select
                  label="計測参加者"
                  :items="participants"
                  item-text="name"
                  item-value="id"
                  v-model="participantId"
                />
              </v-col>
            </v-row>
            <v-row 
              dense
              align="center"
            >
              <v-col cols="12">
                <v-file-input
                  accept=".csv"
                  dense
                  hide-details
                  label="左足首"
                  outlined
                  v-model="leftAnkleFile"
                />
              </v-col>
              <v-col cols="12">
                <v-file-input
                  accept=".csv"
                  dense
                  hide-details
                  label="右足首"
                  outlined
                  v-model="rightAnkleFile"
                />
              </v-col>
              <v-col cols="12">
                <v-file-input
                  accept=".csv"
                  dense
                  hide-details
                  label="腰"
                  outlined
                  v-model="trunkFile"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  dense
                  v-model="gaitName"
                  label="登録名"
                />
              </v-col>
            </v-row>
          </v-container>
        </template>

        <template #apply>アップロード</template>
      </Dialog>

      <Dialog
        width="600px"
        :disabled="!isNewGaitGroupValid"
        @apply="createGaitGroup"
        outlined
        v-if="currentTabKey === 'groups'"
      >
        <v-icon class="mr-2">mdi-account-group</v-icon>
        グループ作成

        <template #title>
          歩行レポートグループ作成
        </template>

        <template #contents>
          <v-container 
            dense
            fluid 
            @dragleave.prevent 
            @dragover.prevent 
            @drop.prevent="selectFiles"
          >
            <v-row>
              <v-col>
                <v-text-field
                  v-model="newGroup.name"
                  class="mt-3"
                  dense
                  outlined
                  label="名前"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="newGroup.description"
                  dense
                  outlined
                  label="説明"
                  hide-details
                />
              </v-col>
            </v-row>

            <v-alert 
              v-if="!newGroup.name"
              type="error"
              dense
              class="mt-3 mb-0"
            >
              名前が未記入です
            </v-alert>
          </v-container>
        </template>

        <template #apply>作成</template>
      </Dialog>
    </v-row>

    <v-row dense>
      <v-col>
        <GaitsTable
          v-if="currentTabKey === 'reports'"
          :loading="isLoading"
          :gaits="gaits"
          height="calc(100vh - 196px)"
          :items-per-page.sync="itemsPerPage"
          :page.sync="currentPage"
          :filter-params="filterParams"
          class="mt-3"
        />

        <GaitGroupsTable
          v-if="currentTabKey === 'groups'"
          :loading="isLoading"
          :groups="gaitGroups"
          height="calc(100vh - 196px)"
          :items-per-page.sync="itemsPerPage"
          :page.sync="currentPage"
          :filter-params="filterParams"
          class="mt-3"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

import GaitGroupsTable from "@components/parts/tables/GaitGroupsTable.vue";
import GaitsTable from "@components/parts/tables/GaitsTable.vue";
import Dialog from "@components/parts/dialogs/Dialog.vue";

export default {
  components: {
    GaitGroupsTable,
    GaitsTable,
    Dialog,
  },
  data: function(){
    return {
      itemsPerPage: 15,
      currentPage: 1,
      isUploading: false,
      participantId: null,
      rightAnkleFile: null,
      leftAnkleFile: null,
      trunkFile: null,
      gaitName: null,
      filterParams: {
        isDeleted: false,
      },
      currentTabIndex: 0,
      tabs: [
        {label: 'レポート一覧', key: 'reports'},
        {label: 'グループ一覧', key: 'groups'},
      ],
      newGroup: {
        name: null,
        description: null,
      },
    }
  },
  computed: {
    ...mapState([
      'user',
      'project',
      'participants',
      'gaits',
      'gaitGroups',
      'loadingCount',
    ]),
    isNewGaitGroupValid: function(){
      return !!this.newGroup?.name;
    },
    currentTabKey: function(){
      return this.currentTab?.key;
    },
    currentTab: function(){
      return this.tabs[this.currentTabIndex];
    },
    isLoading: function(){
      return this.loadingCount > 0;
    },
    isGaitUploadable: function(){
      if(!this.gaitName || !this.trunkFile || !this.rightAnkleFile || !this.leftAnkleFile || !this.participantId){
        return false;
      }

      return true;
    },
  },
  watch: {
    rightAnkleFile: function(newVal){
      if(newVal){
        if(newVal.name.slice(-4).toLowerCase() !== '.csv'){
          this.rightAnkleFile = null;
          return;
        }

        if(this.gaitName?.length > 0){
          return;
        }

        let gaitName = newVal.name.slice(0, -4);
        if(gaitName.endsWith('right')){
          gaitName = gaitName.slice(0, -('right'.length));
        }

        this.gaitName = gaitName;
      }
    },
    leftAnkleFile: function(newVal){
      if(newVal){
        if(newVal.name.slice(-4).toLowerCase() !== '.csv'){
          this.leftAnkleFile = null;
          return;
        }

        if(this.gaitName?.length > 0){
          return;
        }

        let gaitName = newVal.name.slice(0, -4);
        if(gaitName.endsWith('left')){
          gaitName = gaitName.slice(0, -('left'.length));
        }

        this.gaitName = gaitName;
      }
    },
    trunkFile: function(newVal){
      if(newVal){
        if(newVal.name.slice(-4).toLowerCase() !== '.csv'){
          this.trunkFile = null;
          return;
        }

        if(this.gaitName?.length > 0){
          return;
        }

        let gaitName = newVal.name.slice(0, -4);
        if(gaitName.endsWith('trunk')){
          gaitName = gaitName.slice(0, -('trunk'.length));
        }

        this.gaitName = gaitName;
      }
    },
  },
  destroyed: function(){
  },
  mounted: function(){
    if(!this.project){
      this.$router.push('/');
      return;
    }

    this.fetchAll();
  },
  methods: {
    ...mapActions([
      'fetchParticipants',
      'fetchGaits',
      'fetchGaitGroups',
    ]),
    fetchAll: function(){
      this.fetchParticipants();
      this.fetchGaits();
      this.fetchGaitGroups();
    },
    openFileSelection: function(){
      this.$refs.fileInput.click();
    },
    selectFiles: function(e){
      var files = Array.from(e.dataTransfer.files);
      files = files.filter(f => f.name.slice(-4).toLowerCase() === '.csv');
      if(files.length == 0){
        return;
      }

      let gaitName = null;
      files.forEach(f => {        
        if(f.name.toLowerCase().endsWith("left.csv")){
          gaitName = f.name.slice(0, -("left.csv".length));
          this.leftAnkleFile = f;
        }

        if(f.name.toLowerCase().endsWith("right.csv")){
          gaitName = f.name.slice(0, -("right.csv".length));
          this.rightAnkleFile = f;
        }

        if(f.name.toLowerCase().endsWith("trunk.csv")){
          gaitName = f.name.slice(0, -("trunk.csv".length));
          this.trunkFile = f;
        }
      });
      if(this.gaitName?.length > 0 || !gaitName){
        return;
      }
      this.gaitName = gaitName;
    },
    createGaitGroup: function(){
      this.axios.post('/api/gait_group', {
        name: this.newGroup?.name,
        description: this.newGroup?.description,
        projectId: this.project.id,
      }).then(() => {
        this.fetchGaitGroups();
      }).catch(error => {
        console.log(error);
      }).finally(() => {
      })
    },
    uploadGait: async function(){
      const reader = new FileReader();
      reader.readAsText(this.trunkFile);
      let trunkData = [];
      await new Promise(resolve => reader.onload = () => {
        reader.result.split('\n').forEach(l => {
          trunkData.push(l.split(','));
        });
        resolve();
      });

      reader.readAsText(this.rightAnkleFile);
      let rightAnkleData = [];
      await new Promise(resolve => reader.onload = () => {
        reader.result.split('\n').forEach(l => {
          rightAnkleData.push(l.split(','));
        });
        resolve();
      });

      reader.readAsText(this.leftAnkleFile);
      let leftAnkleData = [];
      await new Promise(resolve => reader.onload = () => {
        reader.result.split('\n').forEach(l => {
          leftAnkleData.push(l.split(','));
        });
        resolve();
      });

      this.axios.post('/api/gait', {
        name: this.gaitName,
        trunk: trunkData,
        right: rightAnkleData,
        left: leftAnkleData,
        projectId: this.project.id,
        participantId: this.participantId,
      }).then(() => {
        this.fetchGaits();
      }).catch(error => {
        console.log(error);
      }).finally(() => {
      })
    },
  }
}
</script>

<style lang="scss" scoped>

</style>