<template>
  <div
    class="background"
    style="position: relative;"
    :style="{
      height: `${containerHeight}px`
    }"
    @mousedown.prevent="startDragging"
    @mousemove.prevent="rotateGraph"
    @wheel.ctrl.prevent="scaleGraph"
    ref="container"
    v-resize="resizeContainer"
    v-tooltip="{
      content: 'ドラッグで移動<br/>ctrl+wheelまたはマウスオーバctrl+「+」/ctrl+「-」で拡大/縮小',
      delay: {
        show: 2000,
        hide: 0
      },
    }"
    @mouseenter="addKeyEventListeners"
    @mouseleave="removeKeyEventListeners"
  >
    <VglDefs ref="defs" v-if="isGraphVisible">
      <template v-slot:[`grid${id}`]>
        <VglLine v-for="(line, index) in subGridLines" :key="`sub-grid-line-${index}`">
          <template #geometry>
            <VglGeometry>
              <template #position>
                <VglFloat32Attribute
                  :array="line"
                  :item-size="3"
                />
              </template>
            </VglGeometry>
          </template>
          <template #material>
            <VglLineBasicMaterial
              :linewidth="2"
              color="#DDDDDD"
            />
          </template>
        </VglLine>

        <VglLine v-for="(line, index) in gridLines" :key="`grid-line-${index}`">
          <template #geometry>
            <VglGeometry>
              <template #position>
                <VglFloat32Attribute
                  :array="line"
                  :item-size="3"
                />
              </template>
            </VglGeometry>
          </template>
          <template #material>
            <VglLineBasicMaterial
              :linewidth="12"
              color="#888888"
            />
          </template>
        </VglLine>
      </template>
    </VglDefs>
    <VglRenderer 
      v-observe-visibility="isVisible => (isVisible ? resizeContainer() : false)"
      v-if="isGraphVisible"
      style="width: 100%; height: 100%;"
      :alpha="true"
      :antialias="true"
      :preserve-drawing-buffer="true"
      ref="renderer"
      :id="`swayTrajectory${id}`"
    >
      <template #scene>
        <VglScene ref="scene">
          <VglGroup
            rotation="quaternion"
            :scale-x="scaleX"
            :scale-y="scaleY"
            :scale-z="scaleZ"
            :position-x="horizontalOffset"
            :position-y="verticalOffset"
            :rotation-w="quaternion[0]"
            :rotation-x="quaternion[1]"
            :rotation-y="quaternion[2]"
            :rotation-z="quaternion[3]"
          >
            <VglUse v-if="grid" :href="`grid${id}`" />
            <VglGroup
              :scale-x="lineScale"
              :scale-y="lineScale"
              :scale-z="lineScale"
            >
              <VglLine>
                <template #geometry>
                  <VglUse
                    :href="use"
                  />
                </template>
                <template #material>
                  <TransparentLineMaterial
                    :linewidth="1"
                    color="#4444CC"
                    :opacity="lineOpacity"
                  />
                </template>
              </VglLine>
              <template v-if="polygonMode === 'tetra'">
                <VglMesh
                  v-for="(point, index) in polygonData.tetra[gridMode]"
                  :key="`tetragon${index}`"
                  :position-x="point.x"
                  :position-y="point.y"
                  :position-z="-point.z"
                >
                  <template #geometry>
                    <VglSphereGeometry
                      :radius="0.02*cameraPosition/lineScale"
                      :width-segments="16"
                      :height-segments="16"
                    />
                  </template>
                  <template #material>
                    <TransparentMeshMaterial
                      color="#CC4444"
                      :opacity="polygonOpacity"
                    />
                  </template>
                </VglMesh>
              </template>

              <template v-if="polygonMode === 'octa'">
                <VglMesh
                  v-for="(point, index) in polygonData.octa[gridMode]"
                  :key="`octagon${index}`"
                  :position-x="point.x"
                  :position-y="point.y"
                  :position-z="-point.z"
                >
                  <template #geometry>
                    <VglSphereGeometry
                      :radius="0.02*cameraPosition/lineScale"
                      :width-segments="16"
                      :height-segments="16"
                    />
                  </template>
                  <template #material>
                    <TransparentMeshMaterial
                      color="#CC4444"
                      :opacity="polygonOpacity"
                    />
                  </template>
                </VglMesh>
              </template>
            </VglGroup>
          </VglGroup>

          <VglMesh v-if="polygonMode === 'tetra'">
            <template #geometry>
              <VglShapeGeometry :curve-segments="4">
                <template #shapes>
                  <VglShape 
                    :d="tetraAreaPath"
                  />
                </template>
              </VglShapeGeometry>
            </template>
            <template #material>
              <TransparentMeshMaterial
                color="#CC4444"
                :opacity="polygonOpacity"
              />
            </template>
          </VglMesh>

          <VglMesh v-if="polygonMode === 'octa'">
            <template #geometry>
              <VglShapeGeometry :curve-segments="3">
                <template #shapes>
                  <VglShape 
                    :d="octaAreaPath"
                  />
                </template>
              </VglShapeGeometry>
            </template>
            <template #material>
              <TransparentMeshMaterial
                color="#CC4444"
                :opacity="polygonOpacity"
              />
            </template>
          </VglMesh>

          <VglGroup
            v-for="bin in horizontalHistogramBins"
            :key="`horizontalBin${bin.position}`"
            :position-x="translationX + (bin.position + 0.5)*binInterval*cameraPosition"
            :position-y="translationY - cameraPosition/aspectRatio"
            :scale-x="binInterval*cameraPosition"
            :scale-y="cameraPosition*bin.size"
          >
            <VglMesh>
              <template #geometry>
                <VglPlaneGeometry
                  ref="plane"
                  :width="1"
                  :height="1"
                />
              </template>
              <template #material>
                <TransparentMeshMaterial
                  color="#4444CC"
                  :opacity="0.6"
                />
              </template>
            </VglMesh>
          </VglGroup>

          <VglGroup
            v-for="bin in verticalHistogramBins"
            :key="`verticalBin${bin.position}`"
            :position-x="translationX - cameraPosition"
            :position-y="translationY + (bin.position + 0.5)*binInterval*cameraPosition"
            :scale-x="cameraPosition*bin.size"
            :scale-y="binInterval*cameraPosition"
          >
            <VglMesh>
              <template #geometry>
                <VglPlaneGeometry
                  :width="1"
                  :height="1"
                />
              </template>
              <template #material>
                <TransparentMeshMaterial
                  color="#4444CC"
                  :opacity="0.6"
                />
              </template>
            </VglMesh>
          </VglGroup>
        </VglScene>
      </template>
      <template #camera>
        <VglPerspectiveCamera 
          v-if="perspective" 
          :position-z="cameraPosition" 
          :rotation="isRotatable ? 'lookAt' : null" 
        />
        <VglOrthographicCamera 
          v-else
          :left="-cameraPosition" 
          :right="cameraPosition" 
          :top="cameraPosition/aspectRatio" 
          :bottom="-cameraPosition/aspectRatio"
          :position-x="translationX"
          :position-y="translationY"
          :position-z="cameraPosition" 
          :rotation="isRotatable ? 'lookAt' : null"
        />
      </template>
    </VglRenderer>

    <v-overlay :value="loading || isLoading" absolute>
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>


<script>
import { calcQuaternionFromEulerAngles, calcQuaternionMultiplication } from '@js/utils/MatrixUtil.js';

import TransparentMeshMaterial from '@components/parts/materials/TransparentMeshMaterial.vue';
import TransparentLineMaterial from '@components/parts/materials/TransparentLineMaterial.vue';

import { 
  VglDefs,
  VglGroup,
  VglUse,
  VglRenderer,
  VglScene, 
  VglPerspectiveCamera,
  VglOrthographicCamera,
  VglLine,
  VglMesh,
  VglShapeGeometry,
  VglShape,
  VglLineBasicMaterial,
  VglGeometry,
  VglPlaneGeometry,
  VglFloat32Attribute,
  VglSphereGeometry,
} from 'vue-gl';

import { mapState } from 'vuex';


export default {
  components: {
    VglDefs,
    VglGroup,
    VglUse,
    VglRenderer,
    VglScene,
    VglPerspectiveCamera,
    VglOrthographicCamera,
    VglLine,
    VglMesh,
    VglLineBasicMaterial,
    VglGeometry,
    VglPlaneGeometry,
    VglFloat32Attribute,
    VglSphereGeometry,
    VglShapeGeometry,
    VglShape,
    TransparentMeshMaterial,
    TransparentLineMaterial,
  },
  props: {
    id: {
      default: null,
    },
    use: {
      required: true,
    },
    trajectory: {
      required: true,
    },
    selection: {
      required: true,
    },
    perspective: {
      default: false,
    },
    lineScale: {
      default: 1,
    },
    initialRoll: {
      default: 25,
    },
    initialPitch: {
      default: 45,
    },
    initialYaw: {
      default: 0,
    },
    draggingMode: {
      default: 'rotation', // rotation | translation
    },
    grid: {
      default: true,
    },
    gridMode: {
      default: 'all', // top | side | front | all
    },
    initialCameraPosition: {
      default: 0.5,
    },
    maxCameraPosition: {
      default: 5.0,
    },
    minCameraPosition: {
      default: 0.1,
    },
    scaleX: {
      default: 1,
    },
    scaleY: {
      default: 1,
    },
    scaleZ: {
      default: 1,
    },
    loading: {
      default: false,
    },

    verticalOffset: {
      default: 0,
    },
    horizontalOffset: {
      default: 0,
    },

    aspectRatio: {
      default: 1
    },
    lineOpacity: {
      default: 0.25,
    },

    binInterval: {
      default: 0.1,
    },
    polygonData: {
    },
    polygonOpacity: {
      default: 0.35,
    },
    polygonMode: {
      default: 'tetra',
    },
  },
  data: function(){
    return {
      cameraPosition: 1.0,
      isDragging: false,
      quaternion: [1, 0, 0, 0],
      previousQuaternion: [1, 0, 0, 0],
      containerWidth: 0,
      translationX: 0,
      translationY: 0,
      gridInterval: 0.1,

      loadingCount: 0,
    }
  },
  computed: {
    ...mapState([
      'isGraphVisible',
    ]),
    containerHeight: function(){
      return parseInt(this.containerWidth/this.aspectRatio);
    },
    isLoading: function(){
      return this.loadingCount > 0;
    },
    isRotatable: function(){
      return this.draggingMode === 'rotation';
    },

    maxGridX: function(){
      return 10;
    },
    minGridX: function(){
      return -10;
    },
    maxGridY: function(){
      return 10;
    },
    minGridY: function(){
      return -10;
    },
    maxGridZ: function(){
      return 10;
    },
    minGridZ: function(){
      return -10;
    },
    subGridLines: function(){
      let rtn = [];
      const interval = this.gridInterval;

      if(['top', 'all'].some(m => m === this.gridMode)){
        for(let i=this.minGridX/interval, iMax=this.maxGridX/interval; i<=iMax; i++){
          rtn.push([
            interval*i, 
            0, 
            this.minGridZ, 

            interval*i, 
            0, 
            this.maxGridZ,
          ]);
        }

        for(let i=this.minGridZ/interval, iMax=this.maxGridZ/interval; i<=iMax; i++){
          rtn.push([
            this.minGridX, 
            0, 
            interval*i, 

            this.maxGridX, 
            0, 
            interval*i,
          ]);
        }
      }

      if(['front', 'all'].some(m => m === this.gridMode)){
        for(let i=this.minGridY/interval, iMax=this.maxGridY/interval; i<=iMax; i++){
          rtn.push([
            this.minGridX, 
            interval*i, 
            this.minGridZ, 

            this.minGridX, 
            interval*i, 
            this.maxGridZ,
          ]);
        }

        for(let i=this.minGridZ/interval, iMax=this.maxGridZ/interval; i<=iMax; i++){
          rtn.push([
            this.minGridX, 
            this.minGridY, 
            interval*i, 

            this.minGridX, 
            this.maxGridY, 
            interval*i,
          ]);
        }
      }

      if(['side', 'all'].some(m => m === this.gridMode)){
        for(let i=this.minGridX/interval, iMax=this.maxGridX/interval; i<=iMax; i++){
          rtn.push([
            interval*i, 
            this.minGridY, 
            this.minGridZ, 

            interval*i, 
            this.maxGridY, 
            this.minGridZ,
          ]);
        }

        for(let i=this.minGridY/interval, iMax=this.maxGridY/interval; i<=iMax; i++){
          rtn.push([
            this.minGridX, 
            interval*i, 
            this.minGridZ, 
            
            this.maxGridX, 
            interval*i, 
            this.minGridZ,
          ]);
        }
      }

      return rtn;
    },
    gridLines: function(){
      let rtn = [];

      if(['top', 'all'].some(m => m === this.gridMode)){
        rtn.push([
          0, 
          0, 
          this.minGridZ, 

          0, 
          0, 
          this.maxGridZ,
        ]);

        rtn.push([
          this.minGridX, 
          0, 
          0, 

          this.maxGridX, 
          0, 
          0,
        ]);

      }

      if(['front', 'all'].some(m => m === this.gridMode)){
        rtn.push([
          this.minGridX, 
          0, 
          this.minGridZ, 

          this.minGridX, 
          0, 
          this.maxGridZ,
        ]);

        rtn.push([
          this.minGridX, 
          this.minGridY, 
          0, 

          this.minGridX, 
          this.maxGridY, 
          0,
        ]);
      }


      if(['side', 'all'].some(m => m === this.gridMode)){
        rtn.push([
          this.minGridX, 
          0, 
          this.minGridZ, 

          this.maxGridX, 
          0, 
          this.minGridZ,
        ]);

        rtn.push([
          0, 
          this.minGridY, 
          this.minGridZ, 
          
          0, 
          this.maxGridY, 
          this.minGridZ,
        ]);
      }

      return rtn;
    },
    indexFrom: function(){
      return Math.max(this.selection.from - this.trajectory.features.indexOffset, 0);
    },
    indexTo: function(){
      return Math.min(this.selection.to - this.trajectory.features.indexOffset, this.trajectory.x.length);
    },
    tetraAreaPath: function(){
      switch(this.gridMode){
        case 'top':
          return "M" + this.polygonData.tetra.top.map(p => {
            return `${p.x*this.lineScale} ${p.z*this.lineScale} `
          }).join("L");
        case 'side':
          return "M" + this.polygonData.tetra.side.map(p => {
            return `${p.x*this.lineScale} ${p.y*this.lineScale} `
          }).join("L");
        case 'front':
          return "M" + this.polygonData.tetra.front.map(p => {
            return `${-p.z*this.lineScale} ${p.y*this.lineScale} `
          }).join("L");
      }

      return "";
    },
    octaAreaPath: function(){
      switch(this.gridMode){
        case 'top':
          return "M" + this.polygonData.octa.top.map(p => {
            return `${p.x*this.lineScale} ${p.z*this.lineScale} `
          }).join("L");
        case 'side':
          return "M" + this.polygonData.octa.side.map(p => {
            return `${p.x*this.lineScale} ${p.y*this.lineScale} `
          }).join("L");
        case 'front':
          return "M" + this.polygonData.octa.front.map(p => {
            return `${-p.z*this.lineScale} ${p.y*this.lineScale} `
          }).join("L");
      }

      return "";
    },
    horizontalHistogramBins: function(){
      let rtn = [];

      let maxBin = this.indexTo - this.indexFrom;
      let vals = [];
      switch(this.gridMode){
        case 'top':
        case 'side':
          vals = this.trajectory.x.slice(this.indexFrom, this.indexTo);
          break;
        case 'front':
          vals = this.trajectory.z.slice(this.indexFrom, this.indexTo).map(v => -v);
          break;
      }

      for(let i=-10; i<10; i++){
        let valFrom = (this.translationX - this.horizontalOffset + i*this.binInterval*this.cameraPosition)/this.lineScale;
        let valTo = (this.translationX - this.horizontalOffset + (i + 1)*this.binInterval*this.cameraPosition)/this.lineScale;
        rtn.push({
          position: i,
          size: vals.filter(v => valFrom <= v && v < valTo).length/maxBin,
        });
      }

      return rtn;
    },
    verticalHistogramBins: function(){
      let rtn = [];

      let maxBin = this.indexTo - this.indexFrom;
      let vals = [];
      switch(this.gridMode){
        case 'top':
          vals = this.trajectory.z.slice(this.indexFrom, this.indexTo);
          break;
        case 'front':
        case 'side':
          vals = this.trajectory.y.slice(this.indexFrom, this.indexTo);
          break;
      }

      for(let i=-10; i<10; i++){
        let valFrom = (this.translationY - this.verticalOffset + i*this.binInterval*this.cameraPosition)/this.lineScale;
        let valTo = (this.translationY - this.verticalOffset + (i + 1)*this.binInterval*this.cameraPosition)/this.lineScale;
        rtn.push({
          position: i,
          size: vals.filter(v => valFrom <= v && v < valTo).length/maxBin,
        });
      }

      return rtn;
    },
  },
  beforeDestroy: function(){
    if(this.$root.$_vglDefs){
      this.$root.$_vglDefs.use = 0;
    }
  },
  mounted: function(){
    this.refreshCamera();
    this.$nextTick(() => {
      this.containerWidth = this.$refs.container.clientWidth;
    });
  },
  watch: {
    selection: {
      handler(){
        this.$forceUpdate();
      },
      deep: true,
      immediate: true,
    },
    gridMode: function(){
      this.refreshCamera();
    },
  },
  methods: {
    startDragging: function(){
      this.previousQuaternion = [...this.quaternion];
      this.dx = 0;
      this.dy = 0;

      this.isDragging = true;
      window.addEventListener('mouseup', this.endDragging);
    },
    rotateGraph: function(e){
      if(this.isDragging){
        if(this.isRotatable){
          this.dx += e.movementX;
          this.dy += e.movementY;

          const dx = this.dx/this.containerWidth;
          const dy = this.dy/this.containerHeight;
          const distance = Math.sqrt(dx*dx + dy*dy);
          if(distance != 0){
            const theta = distance*Math.PI;
            const sinTheta = Math.sin(theta)/distance;
            this.quaternion = calcQuaternionMultiplication(
              [
                Math.cos(theta), 
                dy*sinTheta,
                dx*sinTheta, 
                0.0
              ],
              this.previousQuaternion
            )
          }
        }else{
          this.translationX -= 2*this.cameraPosition*e.movementX/this.containerWidth;
          this.translationY += 2/this.aspectRatio*this.cameraPosition*e.movementY/this.containerHeight;
        }
      }
    },
    scaleGraph: function(e){
      let val = this.cameraPosition*(1.0 + e.deltaY*0.001);
      this.cameraPosition = Math.max(Math.min(this.maxCameraPosition, val), this.minCameraPosition);
    },
    endDragging: function(){
      window.removeEventListener('mouseup', this.endDragging);
      this.isDragging = false;
    },
    refreshCamera: function(){
      this.translationX = 0;
      this.translationY = 0;
      this.quaternion = calcQuaternionFromEulerAngles(
        this.initialRoll*Math.PI/180, 
        this.initialPitch*Math.PI/180, 
        this.initialYaw*Math.PI/180
      );
      this.cameraPosition = this.initialCameraPosition;
    },
    setCameraAngle: function(roll, pitch, yaw, cameraPosition=null){
      this.quaternion = calcQuaternionFromEulerAngles(
        roll*Math.PI/180, 
        pitch*Math.PI/180, 
        yaw*Math.PI/180
      );
      if(cameraPosition != null){
        this.cameraPosition = cameraPosition;
      }
    },
    resizeContainer: function(){
      this.containerWidth = this.$refs.container.clientWidth;
    },
    addKeyEventListeners: function(){
      window.addEventListener('keydown', this.executeKeyEvent);
    },
    removeKeyEventListeners: function(){
      window.removeEventListener('keydown', this.executeKeyEvent);
    },
    zoomIn: function(){
      this.scaleGraph({deltaY: -100.0});
    },
    zoomOut: function(){
      this.scaleGraph({deltaY: 100.0});
    },
    executeKeyEvent: function(e){
      if(e.ctrlKey){
        switch(e.key){
          case '+':
            e.preventDefault();
            this.zoomIn();
            break;
          case '-':
            e.preventDefault();
            this.zoomOut();
            break;
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.background{
//  background: black;
  border: 1px solid black;
}
</style>