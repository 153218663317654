
export function average(list) {
  if(list.length == 0){
    return 0;
  }
  return list.reduce((a, b) => (a + b))/list.length;
}


export function variance(list) {
  if(list.length == 0){
    return 0;
  }

  let _mean = average(list);
  return list.reduce((accumulator, current) => accumulator + Math.pow(current - _mean, 2), 0)/list.length;
}


export function getStandardDeviation(list) {
  if(list.length == 0){
    return 0;
  }

  return Math.sqrt(variance(list));
}

export function getCV(list) {
  if(list.length == 0){
    return 0;
  }

  return getStandardDeviation(list)/average(list);
}

export function getSymmetryRatio(left, right) {
  return left/right;
}

export function getSymmetryIndex(left, right) {
  return 100*2*(left - right)/(left + right);
}

export function getGaitAsymmetry(left, right) {
  return 100*Math.log(left/right);
}

export function getSymmetryAngle(left, right) {
  return 100*(45 - Math.atan(left/right)*180/Math.PI)/90;
}

export function max(list) {
  return Math.max(...list);
}


export function min(list) {
  return Math.min(...list);
}


export function getRange(list) {
  return max(list) - min(list);
}

const RESAMPLING_NUMBER = 101;
export function getResampledTrajectory(trajectory) {
  let lastIndex = trajectory.positions.length - 1;
  let resamplingTimes = [];
  for(let i=0; i<RESAMPLING_NUMBER; i++){
    resamplingTimes.push(i*lastIndex/(RESAMPLING_NUMBER - 1));
  }

  let rtn = {
    positions: [],
  };
  let positionKeys = ['x', 'y', 'z'];
  rtn.positions.push({...trajectory.positions[0]})
  for(let i=1; i<RESAMPLING_NUMBER - 1; i++){
    let neighbor = parseInt(resamplingTimes[i]);
    let weight1 = resamplingTimes[i] - neighbor;
    let weight2 = 1 - weight1;

    let pos1 = trajectory.positions[neighbor];
    let pos2 = trajectory.positions[neighbor + 1];

    if(!pos1 || !pos2){
      continue;
    }

    let position = {};
    positionKeys.forEach(key => {
      position[key] = weight2*pos1[key] + weight1*pos2[key];
    })
    rtn.positions.push(position)
  }
  rtn.positions.push({...trajectory.positions[lastIndex]})
  return rtn;
}

export function getAveragedTrajectory(trajectories) {
  let rtn = {
    positions: [],
    features: {},
  }

  for(let i=0; i<RESAMPLING_NUMBER; i++){
    rtn.positions.push({x: 0, y: 0, z: 0, index: i});
  }

  trajectories.forEach(t => {
    let _t = getResampledTrajectory(t);
    for(let i=0; i<RESAMPLING_NUMBER; i++){
      if(!rtn.positions[i] || !_t.positions[i]){
        continue;
      }
      rtn.positions[i].x += _t.positions[i].x;
      rtn.positions[i].y += _t.positions[i].y;
      rtn.positions[i].z += _t.positions[i].z;
    }
  })

  const size = trajectories.length;
  for(let i=0; i<RESAMPLING_NUMBER; i++){
    if(!rtn.positions[i]){
      continue;
    }
    rtn.positions[i].x /= size;
    rtn.positions[i].y /= size;
    rtn.positions[i].z /= size;
  }

  const features = {};
  trajectories.forEach(t => {
    for(const key in t.features){
      if(!(key in features)){
        features[key] = [];
      }
      const val = t.features[key];

      if(val == null){
        continue;
      }
      features[key].push(val)
    }
  });

  for(const key in features){
    rtn.features[key] = average(features[key]);
  }

  return rtn;
}

export function getAveragedPositionsInAngularArea(vals, indexFrom, indexTo){
  let rtn = [];
  vals.forEach(positions => {
    let selectedPositions = positions.filter(p => indexFrom <= p.index && p.index < indexTo);

    let averaged = {
      x: 0,
      y: 0,
      z: 0,
    }
    
    selectedPositions.forEach(p => {
      averaged.x += p.x;
      averaged.y += p.y;
      averaged.z += p.z;
    });

    let size = selectedPositions.length;
    if(size > 0){
      averaged.x /= size;
      averaged.y /= size;
      averaged.z /= size;
    }

    rtn.push(averaged);
  });
  return rtn;
}

export function getTrajectoryPolygonData(swayTrajectory, selection){
  let rtn = {};
  if(!swayTrajectory){
    return rtn;
  }

  const from = selection?.from ?? 0;
  const to = selection?.to ?? Number.MAX_SAFE_INTEGER;

  const types = ['angle', 'position'];
  const coordinates = ['coordinate', 'velocity', 'acceleration'];

  const polyTypes = ['tetra', 'octa'];
  const viewTypes = ['top', 'side', 'front'];

  types.forEach(t => {
    rtn[t] = {};
    coordinates.forEach(c => {
      const trajectory = swayTrajectory[t][c];
      const indexOffset = trajectory.features.indexOffset;
      const indexFrom = Math.max(from - indexOffset, 0);
      const indexTo = Math.min(to - indexOffset, trajectory.x.length);
      rtn[t][c] = {};
      polyTypes.forEach(pt => {
        rtn[t][c][pt] = {};
        viewTypes.forEach(vt => {
          rtn[t][c][pt][vt] = getAveragedPositionsInAngularArea(
            trajectory.angularArea[pt][vt],
            indexFrom,
            indexTo,
          );
        })
      });
    })
  });

  return rtn;
}

export function getAnkleFeatures(leftTrajectories, rightTrajectories){
  let targets = [
    {name: 'ストライド長さ', attr: 'stride', unit: 'm'},
    {name: 'ストライド周期', attr: 'strideDuration', unit: 'sec'},
    {name: '歩行速度', attr: 'velocity', unit: 'm/sec'},
    {name: '持ち上げの高さ', attr: 'height', scale: 100, unit: 'cm'},
    {name: 'ステップ時間', attr: 'stepDuration', unit: 'sec'},
    {name: '足振り強度', attr: 'swingStrength', unit: 'dps'},
    {name: '遊脚期', attr: 'swingPhaseDuration', unit: 'sec'},
    {name: '立脚期', attr: 'stancePhaseDuration', unit: 'sec'},
    {name: '片脚支持期', attr: 'singleStancePhaseDuration', unit: 'sec'},
  ]

  
  let rtn = [];
  targets.forEach(t => {
    let features = getAnkleFeatureValues(leftTrajectories, rightTrajectories, t.attr, t.scale);
    rtn.push({
      name: `左 ${t.name} (${t.unit})`,
      vals: features.left
    });
    rtn.push({
      name: `右 ${t.name} (${t.unit})`,
      vals: features.right
    });
  })

  let doubleStancePhaseDurationFeatures = leftTrajectories.map(t => t.features.doubleStancePhaseDuration);
  doubleStancePhaseDurationFeatures = doubleStancePhaseDurationFeatures.filter(v => v != null);
  rtn.push({
    name: '両脚支持期 (sec)',
    vals: getAnkleStatisticsVals(doubleStancePhaseDurationFeatures),
  });
  return rtn;
}

export function getAnkleFeatureValues(leftTrajectories, rightTrajectories, attr, scale=1){
  let rtn = {};
  let target = {
    left: leftTrajectories,
    right: rightTrajectories,
  }

  for (let key in target) {
    let trajectories = target[key];
    let features = trajectories.map(t => t.features[attr]);
    features = features.filter(v => v != null);
    features = features.map(v => v*scale);
    rtn[key] = getAnkleStatisticsVals(features);
  }

  let ga = getGaitAsymmetry(rtn.left[0], rtn.right[0]);
  let ratio = getSymmetryRatio(rtn.left[0], rtn.right[0]);
  let si = getSymmetryIndex(rtn.left[0], rtn.right[0]);
  let sa = getSymmetryAngle(rtn.left[0], rtn.right[0]);

  rtn.left.push(ga);
  rtn.left.push(ratio);
  rtn.left.push(si);
  rtn.left.push(sa);

  return {
    ...rtn,
  }
}

export function getAnkleStatisticsVals(vals){
  return [
    average(vals),
    getCV(vals),
    getStandardDeviation(vals),
  ]
}

export function getTrunkStatisticsVals(vals){
  return [
    average(vals),
    getCV(vals),
    getStandardDeviation(vals),
  ]
}


export function getTrunkFeatures(trajectories){
  let targets = [
    {name: '横揺れの大きさ', attr: 'trunkSwingWidth', leftAttr: 'trunkSwingLeftWidth', rightAttr: 'trunkSwingRightWidth', scale: 100, unit: 'cm'},
    {name: '持ち上げの高さ', attr: 'trunkSwingHeight', leftAttr: 'trunkSwingLeftHeight', rightAttr: 'trunkSwingRightHeight', scale: 100, unit: 'cm'},
  ]

  let rtn = [];
  targets.forEach(t => {
    let features = getTrunkFeatureValues(trajectories, t.leftAttr, t.rightAttr, t.scale);

    rtn.push({
      name: `左側 ${t.name} (${t.unit})`,
      vals: features.left
    });
    rtn.push({
      name: `右側 ${t.name} (${t.unit})`,
      vals: features.right
    });

    let _features = trajectories.map(_t => _t.features[t.attr]);
    _features = _features.filter(v => v != null);
    _features = _features.map(v => v*(t.scale ?? 1));
    rtn.push({
      name: `${t.name} (${t.unit})`,
      vals: [...getTrunkStatisticsVals(_features), null, null, null, null]
    })
  });

  return rtn;
}

export function getTrunkFeatureValues(trajectories, leftAttr, rightAttr, scale=1){
  let rtn = {};
  let target = {
    left: leftAttr,
    right: rightAttr,
  }

  for (let key in target) {
    let attr = target[key];
    let features = trajectories.map(t => t.features[attr]);
    features = features.filter(v => v != null);
    features = features.map(v => v*scale);
    rtn[key] = getTrunkStatisticsVals(features);
  }

  let ga = getGaitAsymmetry(rtn.left[0], rtn.right[0]);
  let ratio = getSymmetryRatio(rtn.left[0], rtn.right[0]);
  let si = getSymmetryIndex(rtn.left[0], rtn.right[0]);
  let sa = getSymmetryAngle(rtn.left[0], rtn.right[0]);

  rtn.left.push(ga);
  rtn.left.push(ratio);
  rtn.left.push(si);
  rtn.left.push(sa);

  return {
    ...rtn,
  }
}

export function getSwayDirectionalFeatures(
  trajectory,
  spectrum,
  selection,
  coordinates = [
    {key: 'coordinate', label: '位置'},
    {key: 'velocity', label: '速度'},
    {key: 'acceleration', label: '角速度'},
  ],
  directionTypes = [
    {key: 'x', label: '左右'},
    {key: 'y', label: '上下'},
    {key: 'z', label: '前後'},
  ]
){
  let averagedVals = []
  let standardDeviation = [];
  let averagedSectrums = [];
  const from = selection?.from ?? 0;
  const to = selection?.to ?? Number.MAX_SAFE_INTEGER; 
  coordinates.forEach(c => {
    let indexOffset = trajectory[c.key].features.indexOffset;
    const indexFrom = Math.max(from - indexOffset, 0);
    const indexTo = Math.min(to - indexOffset, trajectory[c.key].x.length);
    directionTypes.forEach(d => {
      let series = trajectory[c.key][d.key].slice(indexFrom, indexTo);
      averagedVals.push(average(series));
      standardDeviation.push(getStandardDeviation(series));
      averagedSectrums.push(average(spectrum[c.key][d.key]));
    })
  });

  let rtn = [];
  rtn.push({
    name: '平均中心変位',
    vals: averagedVals,
  })

  rtn.push({
    name: '振幅確率密度分布標準偏差',
    vals: standardDeviation,
  })

  rtn.push({
    name: 'パワースペクトル全平均',
    vals: averagedSectrums,
  })
  return rtn;
}


export function getSwayGeometricFeatures(
  trajectory, 
  polygon, 
  selection, 
  coordinates = [
    {key: 'coordinate', label: '位置'},
    {key: 'velocity', label: '速度'},
    {key: 'acceleration', label: '角速度'},
  ],
  viewTypes = [
    {key: 'side', label: '前後・上下'},
    {key: 'front', label: '左右・上下'},
    {key: 'top', label: '左右・前後'},
  ]
){
  let envAreas = [];
  let squredAreas = [];
  let rmsAreas = [];
  let lngs = [];
  let lngsPerTime = [];
  let lngsPerEnvArea = [];

  let octaMaxs = [];
  let octaMins = [];
  let tetraMaxs = [];
  let tetraMins = [];

  const dTheta = Math.sin(3*Math.PI/180)/2;
  coordinates.forEach(c => {
    const octaPolygon = polygon[c.key].octa;
    const tetraPolygon = polygon[c.key].tetra;

    let indexOffset = trajectory[c.key].features.indexOffset;
    const from = selection?.from ?? 0;
    const to = selection?.to ?? Number.MAX_SAFE_INTEGER;
    const indexFrom = Math.max(from - indexOffset, 0);
    const indexTo = Math.min(to - indexOffset, trajectory[c.key].x.length);
    const xSeries = trajectory[c.key].x.slice(indexFrom, indexTo);
    const ySeries = trajectory[c.key].y.slice(indexFrom, indexTo);
    const zSeries = trajectory[c.key].z.slice(indexFrom, indexTo);
    
    const averagedX = average(xSeries);
    const averagedY = average(ySeries);
    const averagedZ = average(zSeries);

    const rangeX = getRange(xSeries);
    const rangeY = getRange(ySeries);
    const rangeZ = getRange(zSeries);

    viewTypes.forEach(v => {
      let horizontals = [];
      let verticals = [];
      let horizontalAverage, verticalAverage;
      let horizontalRange, verticalRange;

      const octaPolygonPostions = octaPolygon[v.key];
      const tetraPolygonPostions = tetraPolygon[v.key];
      let octaPositions = [];
      let tetraPositions = [];
      
      switch(v.key){
        case 'top':
          horizontals = xSeries;
          verticals = zSeries;
          horizontalRange = rangeX;
          verticalRange = rangeZ;
          horizontalAverage = averagedX;
          verticalAverage = averagedZ;
          octaPositions = octaPolygonPostions.map(p => ({
            h: p.x,
            v: p.z
          }));
          tetraPositions = tetraPolygonPostions.map(p => ({
            h: p.x,
            v: p.z
          }));
          break;
        case 'side':
          horizontals = xSeries;
          verticals = ySeries;
          horizontalRange = rangeX;
          verticalRange = rangeY;
          horizontalAverage = averagedX;
          verticalAverage = averagedY;
          octaPositions = octaPolygonPostions.map(p => ({
            h: p.x,
            v: p.y
          }));
          tetraPositions = tetraPolygonPostions.map(p => ({
            h: p.x,
            v: p.y
          }));
          break;
        case 'front':
          horizontals = zSeries;
          verticals = ySeries;
          horizontalRange = rangeZ;
          verticalRange = rangeY;
          horizontalAverage = averagedZ;
          verticalAverage = averagedY;
          octaPositions = octaPolygonPostions.map(p => ({
            h: p.z,
            v: p.y
          }));
          tetraPositions = tetraPolygonPostions.map(p => ({
            h: p.z,
            v: p.y
          }));
          break;
      }

      let octaRs = octaPositions.map(p => Math.sqrt((p.h - horizontalAverage)**2 + (p.v - verticalAverage)**2));
      let tetraRs = tetraPositions.map(p => Math.sqrt((p.h - horizontalAverage)**2 + (p.v - verticalAverage)**2));

      octaMaxs.push(Math.max(...octaRs));
      octaMins.push(Math.min(...octaRs));
      tetraMaxs.push(Math.max(...tetraRs));
      tetraMins.push(Math.min(...tetraRs));

      squredAreas.push(horizontalRange*verticalRange);
      let rs = [];
      for(let i=0; i<120; i++){
        rs.push(0);
      }

      let rmsArea = 0;
      let lng = 0;
      let previousH = null;
      let previousV = null;
      horizontals.forEach((h, i) => {
        const v = verticals[i];
        let theta = Math.atan2(v - verticalAverage, h - horizontalAverage)*180/Math.PI;
        while(theta < 0){
          theta += 360;
        }
        let thetaIndex = parseInt(theta/3);
        let rms = (v - verticalAverage)**2 + (h - horizontalAverage)**2;
        let r = Math.sqrt(rms);
        rs[thetaIndex] = Math.max(r, rs[thetaIndex]);

        rmsArea += rms;

        if(previousH != null && previousV != null){
          lng += Math.sqrt((h - previousH)**2 + (v - previousV)**2);
        }

        previousH = h;
        previousV = v;
      });

      lngs.push(lng);
      if(horizontals.length > 0){
        rmsArea /= horizontals.length;
      }
      rmsAreas.push(rmsArea);
      let envArea = rs[0]*rs[119]*dTheta;
      for(let i=1; i<120; i++){
        envArea += rs[i - 1]*rs[i]*dTheta;
      }
      envAreas.push(envArea);

      lngsPerTime.push(horizontals.length > 0 ? 100*lng/horizontals.length : 0);
      lngsPerEnvArea.push(lng/envArea);
    })
  });

  let rtn = [];
  rtn.push({
    name: '外周面積',
    vals: envAreas,
  })

  rtn.push({
    name: '矩形面積',
    vals: squredAreas,
  })

  rtn.push({
    name: '実効値面積',
    vals: rmsAreas,
  })

  rtn.push({
    name: '総軌跡長',
    vals: lngs,
  })

  rtn.push({
    name: '単位時間軌跡長',
    vals: lngsPerTime,
  })

  rtn.push({
    name: '単位面積軌跡長',
    vals: lngsPerEnvArea,
  })

  rtn.push({
    name: '4方向平均ベクトル長最大値',
    vals: tetraMaxs,
  })

  rtn.push({
    name: '4方向平均ベクトル長最小値',
    vals: tetraMins,
  })

  rtn.push({
    name: '8方向平均ベクトル長最大値',
    vals: octaMaxs,
  })

  rtn.push({
    name: '8方向平均ベクトル長最小値',
    vals: octaMins,
  })
  return rtn;
}