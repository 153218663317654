<template>
  <v-container fluid>
    <v-row justify="center" dense>
      <v-btn 
        absolute 
        left 
        icon 
        large 
        color="primary" 
        class="mr-3" 
        @click="fetchAll"
      >
        <v-icon>mdi-reload</v-icon>
      </v-btn>

      <v-tooltip
        color="primary darken-4"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <div 
            v-bind="attrs"
            v-on="on"
          >
            <v-btn outlined disabled>
              メンバー追加
            </v-btn>
          </div>
        </template>
        メンバーの追加はシステム管理者にお問い合わせください
      </v-tooltip>
    </v-row>

    <UsersTable
      class="mt-5"
      height="calc(100vh - 196px)"
      :loading="loadingCount > 0"
      :users="validUsers"
      :filter-params="filterParams"
      :invisible-headers-values="invisibleUserHeadersValues"
      :additional-headers="[{
        text: '管理者',
        value: 'isManager',
        width: 100,
        align: 'center',
        sortable: true,
      }]"
    >
      <template #item.isManager="{ props }">
        <div
          class="d-flex align-center justify-center"
        >
          <v-switch
            dense
            hide-details
            class="ma-auto pa-0"
            v-model="props.item.affiliation.isManager"
            @change="updateProjectManager(props.item)"
          />
        </div>
      </template>
    </UsersTable>

    <v-overlay
      absolute
      :value="loadingCount > 0"
      opacity="0.5"
    >
      <v-progress-circular
        indeterminate
        size="32"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import UsersTable from "@components/parts/tables/UsersTable.vue";

export default {
  components: {
    UsersTable,
  },
  props: {},
  data: function(){
    return {
      loadingCount: 0,
      users: [],
      affiliations: [],
      filterParams: {
        'isDeleted': [0],
      },
      invisibleUserHeadersValues: [
        'roleId',
        'isDeleted',
        'sensors',
        'isProMode',
        'isKarteEnable',
        'isMagEnable',
        'isAbnormalTrajectorySettingEnable',
        'isSwayModeEnable',
        'isContinuousTrajectoryEnable',
        'isContinuousTrajectorySeparated',
        'isPostureEnable',
        'isAssistEnable',
        'is2DAccEnable',
        'isFreezingIdexesOutputEnable',
        'isRawDataOutputEnable',
        'isReportOutputEnable',
      ],
    }
  },
  computed: {
    ...mapState([
      'user',
      'project',
    ]),
    validUsers: function(){
      return this.users.map(u => ({
        ...u, 
        affiliation: this.affiliations.find(a => a.userId == u.id)})
      ).filter(u => u.affiliation);
    },
  },
  watch: {
    project: {
      handler: function(newVal){
        if(newVal){
          this.fetchAll();
        }
      },
    },
  },
  destroyed: function(){
  },
  mounted: function(){
    if(!this.user?.role?.isAdmin && !this.project?.affiliation?.isManager){
      this.$router.push('/');
      return;
    }

    this.fetchAll();
  },
  methods: {
    fetchAll: function(){
      this.fetchUsers();
      this.fetchProject();
    },
    fetchUsers: function(){
      this.loadingCount++;
      this.axios.get(`/api/users/${this.project.id}`).then(response => {
        this.users = response.data.users;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.loadingCount--;
      })
    },
    fetchProject: function(){
      this.loadingCount++;
      this.axios.get(`/api/project/${this.project.id}`).then(response => {
        this.affiliations = response.data.affiliations;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.loadingCount--;
      })
    },
    updateProjectManager: function(user){
      this.axios.put(
        `/api/user_project_affiliation`,
        user.affiliation
      ).then(() => {

      }).catch(error => {
        console.log(error);
        alert("プロジェクト内アカウント情報の更新に失敗しました");
        this.fetchAll();
      }).finally(() => {
      })
    },
  }
}
</script>

<style lang="scss" scoped>

</style>