<template>
  <div 
    class="d-flex align-center" 
  >
    <div class="d-flex align-center mr-3" v-if="iconHidden == null">
      <v-btn 
        color="accent"
        :disabled="hasFFT"
        height="40" 
        @click="$emit('reload'); hasFFT=true"
      >
        周波数
        <v-icon class="ml-2" small>mdi-reload</v-icon>
      </v-btn>
    </div>
    <div 
      class="lighten-5 phase-graph-container" 
      style="height: 48px;"
      ref="phaseGraphContainer"
      v-tooltip="{
        content: `ctrl+wheelまたはマウスオーバしてctrl+「+」/ctrl+「-」で選択範囲の拡大/縮小<br/>ドラッグまたはshift+wheelで選択範囲の移動<br/>領域両端部分ドラッグで選択開始/終了点調整<br/>`,
        delay: {
          show: 2000,
          hide: 0
        },
      }"
      @click.shift="startShift"
      @wheel.ctrl.prevent="scaleSelection"
      @wheel.shift.prevent="moveSelection"
      @mousemove="adjustSelection"
      @mouseenter="setKeyEventListeners"
      @mouseleave="removeKeyEventListeners"
    >
      <div 
        v-if="currentSelection"
        class="selected-area"
        :class="color"
        :style="{
          left: `${100*currentSelection.from/duration}%`,
          width: `${100*(currentSelection.to - currentSelection.from)/duration}%`,
        }"
        @mousedown="grabSelection($event)"
      >
        <div 
          class="grip start" 
          @mousedown.left.stop="grabSelection($event, 'start')"
        >
        </div>
        <div 
          class="grip end" 
          @mousedown.left.stop="grabSelection($event, 'end')"
        >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    trajectory: {
      required: true,
    },
    selection: {
    },
    color: {
      default: 'primary darken-3',
    },
    iconHidden: {
      default: null,
    },
  },
  data: function(){
    return {
      grabbingMode: 'move', // move  | start | end
      grabbingStartX: 0,
      phaseGraphContainerWidth: 0,
      grabbingStartSelection: {},
      isGrabbing: false,
      currentSelection: {},

      hasFFT: true,
    }
  },
  computed: {
    duration: function(){
      return this.trajectory.angle.coordinate.x.length - 1;
    },
  },
  watch: {
    selection: {
      handler(){
        this.hasFFT = false;
      },
      deep: true,
    },
    trajectory: function(){
      this.$nextTick(() => {
        this.hasFFT = true;
      })
    },
  },
  mounted: function(){
    this.currentSelection = {...this.selection};
  },
  methods: {
    scaleSelection: function(e){
      if(!this.currentSelection){
        return;
      }
      let delta = 0.0001*e.deltaY*this.duration;

      let from = Math.max(-delta + this.currentSelection.from, 0);
      let to = Math.min(delta + this.currentSelection.to, this.duration);

      if(to < from){
        let mid = (from + to)/2;
        from = mid;
        to = mid;
      }
      
      this.currentSelection.from = from;
      this.currentSelection.to = to;
    },
    moveSelection: function(e){
      if(!this.currentSelection){
        return;
      }
      let delta = 0.0003*e.deltaY*this.duration;
      delta = this.currentSelection.from + delta < 0 ? -this.currentSelection.from : delta;
      delta = this.duration < this.currentSelection.to + delta ? this.duration - this.currentSelection.to : delta;

      let from = Math.max(delta + this.currentSelection.from, 0);
      let to = Math.min(delta + this.currentSelection.to, this.duration);

      if(to < from){
        let mid = (from + to)/2;
        from = mid;
        to = mid;
      }

      this.currentSelection.from = from;
      this.currentSelection.to = to;
    },
    grabSelection: function(event, mode="move"){
      this.grabbingStartX = event.x;
      this.grabbingStartSelection = {
        ...this.currentSelection
      };
      this.phaseGraphContainerWidth = this.$refs.phaseGraphContainer.clientWidth;

      this.isGrabbing = true;
      this.grabbingMode = mode;
      window.addEventListener('mouseup', this.releaseSelection);
    },
    releaseSelection: function(){
      this.isGrabbing = false;
      this.updateSelection();
    },
    adjustSelection: function(e){
      if(!this.isGrabbing){
        return;
      }

      let delta = this.duration*(e.x - this.grabbingStartX)/this.phaseGraphContainerWidth;
      if(this.grabbingMode === 'move'){
        delta = this.grabbingStartSelection.from + delta < 0 ? -this.grabbingStartSelection.from : delta;
        delta = this.duration < this.grabbingStartSelection.to + delta ? this.duration - this.grabbingStartSelection.to : delta;
      }
      let from = Math.max(delta + this.grabbingStartSelection.from, 0);
      let to = Math.min(delta + this.grabbingStartSelection.to, this.duration);

      if(to < from){
        let mid = (from + to)/2;
        from = mid;
        to = mid;
      }
      
      if(this.grabbingMode !== 'end'){
        this.currentSelection.from = from;
      }

      if(this.grabbingMode !== 'start'){
        this.currentSelection.to = to;
      }
    },
    updateSelection: function(){
      this.selection.from = this.currentSelection.from;
      this.selection.to = this.currentSelection.to;
    },
    onKeyDown: function(e){
      switch(e.key.toLowerCase()){
        case 'shift':
          break;
        case 'control':
          break;
      }

      if(e.ctrlKey){
        switch(e.key){
          case '+':
            e.preventDefault();
            this.zoomIn();
            break;
          case '-':
            e.preventDefault();
            this.zoomOut();
            break;
        }
      }
    },
    onKeyUp: function(e){
      switch(e.key.toLowerCase()){
        case 'shift':
        case 'control':
          this.updateSelection();
          break;
      }
    },
    setKeyEventListeners: function(){
      window.addEventListener("keydown", this.onKeyDown);
      window.addEventListener("keyup", this.onKeyUp);
    },
    removeKeyEventListeners: function(){
      window.removeEventListener("keydown", this.onKeyDown);
      window.removeEventListener("keyup", this.onKeyUp);
    },
    updateCurrentSelection: function(){
      this.currentSelection = {...this.selection};
    },
    zoomIn: function(){
      this.scaleSelection({deltaY: -100.0});
    },
    zoomOut: function(){
      this.scaleSelection({deltaY: 100.0});
    },
  },
}
</script>

<style lang="scss" scoped>
.phase-graph-container{
  flex: 1; 
  height: 100%;
  border: 1px solid black;
  position: relative;
  user-select: none;
}

.ground-phase{
  position: absolute;
  height: 30%;
  &.left{
    top: 15%;
  }
  &.right{
    bottom: 15%;
  }
}

.selected-area{
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.75;

  cursor: grab;

  .grip{
    background: transparent;
    width: 8px;
    height: 100%;
    position: absolute;
    cursor: ew-resize;;
    &.start{
      left: 0;
    }
    &.end{
      right: 0;
    }
  }
}
</style>