<template>
  <v-container fluid class="pa-0">
    <EyeCatch />
    <v-row justify="center" dense class="pa-3 pt-5">
      <v-col v-bind="contentColParams" align="center">
        <v-row justify="center" dense v-for="menu in menusWithoutHome" :key="menu.url">
          <v-col dense cols=12 sm=8 md=6 lg=4>
            <v-tooltip
              color="primary darken-4"
              bottom
              :key="menu.title"
              :disabled="!isMenuDisabled(menu)"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  large
                  v-bind="attrs"
                  v-on="on"
                  block
                  class="my-3" 
                  :to="isMenuDisabled(menu) ? null : menu.url"
                  :color="isMenuDisabled(menu) ? 'grey lighten-3' : (menu.bottom ? 'secondary lighten-1' : 'accent')"
                  :depressed="isMenuDisabled(menu)"
                >
                  <v-icon 
                    class="mr-3" 
                    
                    color="white"
                  >{{ menu.icon }}</v-icon>
                  <span class="text-h6 white--text">{{ menu.title }}</span>
                </v-btn>
              </template>
              ヘッダーメニューでプロジェクトを選択してください
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row class="mt-5" justify="center" dense>
          <v-col dense cols=12 sm=8 md=6 lg=4>
            <v-dialog
              ref="dialog"
              v-model="isAccountEditModalOpened"
              width="600px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  color="gray" 
                  class="mt-5 font-weight-bold primary--text" 
                  v-bind="attrs"
                  v-on="on"
                  text
                >
                  アカウント情報変更
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="info font-weight-bold justify-start">
                  アカウント情報変更
                </v-card-title>
                <v-container dense>
                  <v-form 
                    ref="form" 
                    @submit.prevent
                    lazy-validation
                  >
                    <v-row dense align="center">
                      <v-col dense>
                        {{ accountData.mailAddress }}
                        <v-text-field
                          v-if="false"
                          dense 
                          class="mt-5" 
                          v-model="accountData.mailAddress" 
                          label="メールアドレス"
                          :rules="[required, emailValidation]"
                        />
                      </v-col>
                    </v-row>
                    <v-row dense align="center">
                      <v-col dense>
                        <v-text-field 
                          dense 
                          class="mt-5" 
                          v-model="accountData.name" 
                          label="名前"
                          :rules="[required]"
                        />
                      </v-col>
                    </v-row>
                    <v-row dense align="center">
                      <v-col dense>
                        <v-text-field 
                          dense
                          type="password"
                          class="mt-5" 
                          v-model="accountData.password" 
                          label="現在のパスワード"
                          :rules="[required]"
                        />
                      </v-col>
                    </v-row>
                    <v-row dense align="center">
                      <v-col dense>
                        <v-text-field 
                          dense
                          type="password"
                          class="mt-5" 
                          v-model="newPassword" 
                          label="変更後のパスワード"
                          :rules="[required]"
                        />
                      </v-col>
                    </v-row>
                    <v-row dense align="center">
                      <v-col dense>
                        <v-text-field 
                          dense
                          type="password"
                          class="mt-5" 
                          v-model="passwordConfirmation" 
                          label="パスワード確認用"
                          :rules="[passwordConfirmationRule]"
                        />
                      </v-col>
                    </v-row>
                    <v-row dense align="center">
                      <v-col dense align="center">
                        <v-btn 
                          color="primary"
                          :disabled="!isValid"
                          @click="updateAccountData"
                        >
                          変更
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-overlay :value="isLoading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import EyeCatch from '@parts/EyeCatch.vue';

export default {
  components: {
    EyeCatch,
  },
  data: function(){
    return {
      isLoading: false,
      contentColParams: {
        cols: 12,
        sm: 12,
        md: 10,
        lg: 8
      },
      isAccountEditModalOpened: false,
      accountData: {},
      newPassword: null,
      passwordConfirmation: null,
      required: value => !!value || "※必須",
      emailValidation: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || '※メールアドレスが正しくありません',
      passwordConfirmationRule: v => !this.newPassword || v === this.newPassword || '※パスワードが一致しません',
      isValid: false,
    }
  },
  computed: {
    ...mapState([
      'user',
      'project',
    ]),
    ...mapGetters([
      'validMenus',
    ]),
    menusWithoutHome: function(){
      return this.validMenus.filter(m => m.url !== '/');
    },
    isMenuDisabled: function(){
      return (menu) => {
        if(menu.project && !this.project){
          return true;
        }
        return false;
      }
    },
  },
  watch: {
    accountData: {
      handler: function(){
        this.$nextTick(() => {
          this.isValid = this.$refs.form?.validate();
        });
      },
      deep: true,
    },
    newPassword: {
      handler: function(){
        this.$nextTick(() => {
          this.isValid = this.$refs.form?.validate();
        });
      },
    },
    passwordConfirmation: {
      handler: function(){
        this.$nextTick(() => {
          this.isValid = this.$refs.form?.validate();
        });
      },
    },
    isAccountEditModalOpened: function(newVal){
      if(newVal){
        this.accountData = {
          ...this.user
        };
        this.passwordConfirmation = null;
        this.$nextTick(() => {
          this.isValid = this.$refs.form.validate();
        });
      }
    },
  },
  destroyed: function(){
  },
  mounted: function(){
  },
  methods: {
    updateAccountData: function(){
      this.axios.put('/api/my_account', {
        ...this.accountData,
        newPassword: this.newPassword
      }).then(() => {
        this.isAccountEditModalOpened = false;
        this.user.name = this.accountData.name;
      }).catch(error => {
        if (error.response) {
          switch(error.response.status){
            case 403:
              alert("入力情報が不正です");
              return;
            case 404:
              alert("該当のアカウントが見つかりません");
              return;
          }
        }
        alert("アカウント情報更新に失敗しました");
      })
    },
  }
}
</script>

<style lang="scss" scoped>

</style>