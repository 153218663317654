<template>
  <v-card
    :outlined="outlined"
  >
    <slot name="titleContainer">
      <v-card-title 
        class="secondary white--text py-1 mb-3"
      >
        <slot name="title">
          コメント
        </slot>
      </v-card-title>
    </slot>
    <v-card-text
      class="py-0"
      v-for="comment in comments"
      :key="`comment-${comment.id}`"
    >
      <MarkdownEditor
        v-model="comment.comment"
        :hint="getCommentHint(comment)"
        outlined
        readonly
      />

      <CommentFileDialog
        v-if="comment.filename"
        :comment="comment"
      />
    </v-card-text>
    <v-card-text class="py-0">
      <MarkdownEditor
        v-model="newComment"
        outlined
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-file-input
        class="mr-3"
        accept=".pdf,image/*"
        dense
        hide-details
        label="添付ファイル（画像/PDF）"
        show-size
        single-line
        v-model="attachedFile"
      />
      <v-btn
        class="mr-2"
        color="secondary"
        @click="sendNewComment"
        :disabled="!isCommentValid"
      >
        コメント送信
      </v-btn>
    </v-card-actions>

    <V-alert 
      v-if="!isAttachedFileValid"
      type="error"
      dense
      class="mx-5"
    >
      ファイルサイズの上限は20MBまでです
    </V-alert>

    <v-overlay
      absolute
      :value="isCommentLoading"
      opacity="0.25"
    >
      <v-progress-circular
        indeterminate
        size="32"
      ></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import MarkdownEditor from "@components/inputs/MarkdownEditor.vue";
import CommentFileDialog from "@components/parts/dialogs/CommentFileDialog.vue";

export default {
  components: {
    MarkdownEditor,
    CommentFileDialog,
  },
  props: {
    id: {
      required: true,
    },
    target: {
      required: true,
    },
    outlined: {
      default: null,
    }
  },
  data: function(){
    return {
      newComment: null,
      attachedFile: null,
      isCommentLoading: false,
      comments: [],
    }
  },
  computed: {
    getCommentHint: function(){
      return (comment) => {
        return comment.createdDatetime + " " + comment.commenter;
      }
    },
    isCommentValid: function(){
      if(!this.isAttachedFileValid){
        return false;
      }

      return this.newComment?.length > 0 || this.attachedFile;
    },
    isAttachedFileValid: function(){
      return !this.attachedFile || this.attachedFile.size < 20*1000*1000;
    },
  },
  watch: {
    id: {
      handler: function(){
        this.fetchAll();
      },
      immediate: true,
    },
  },
  destroyed: function(){
  },
  mounted: function(){
    this.fetchAll();
  },
  methods: {
    fetchAll: function(){
      this.fetchComments();
    },
    fetchComments: function(){
      this.isCommentLoading = true;
      this.axios.get(`/api/comments/${this.target}/${this.id}`).then(response => {
        this.comments = response.data.comments;
      }).catch(error => {
        console.log(error);
        alert('コメント取得に失敗しました');
      }).finally(() => {
        this.isCommentLoading = false;
      });
    },
    sendNewComment: function(){
      this.isCommentLoading = true;

      const params = new FormData();
      if(this.attachedFile){
        params.append('file', this.attachedFile);
      }
      params.append('comment', this.newComment ?? '');

      this.axios.post(`/api/comment/${this.target}/${this.id}`, params, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }).then(response => {
        this.comments.push(response.data.comment);
        this.newComment = null;
        this.attachedFile = null;
      }).catch(error => {
        console.log(error);
        alert("コメント作成に失敗しました");
      }).finally(() => {
        this.isCommentLoading = false;
      });
    },
  }
}
</script>