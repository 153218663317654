<template>
  <v-container fluid class="fill-height pt-0 pr-0">
    <template v-if="swayGroup">
      <v-row class="px-3 mt-1" style="height: 100%; overflow: hidden;">
        <v-col
          v-if="isSideMenuOpened"
          cols="12"
          md="4"
          lg="3"
          class="pa-0 pr-3 d-flex flex-column"
        >
          <div class="d-flex align-center">
            <v-btn 
              icon
              small
              color="primary" 
              @click="fetchAll"
              class="mb-1"
            >
              <v-icon>mdi-reload</v-icon>
            </v-btn>
            <v-spacer />
            <v-btn
              v-if="false"
              depressed
              small
              color="primary" 
              @click="isSideMenuOpened = false"
            >
              <v-icon>mdi-cog</v-icon>
              <v-icon>mdi-arrow-collapse-left</v-icon>
            </v-btn>
          </div>

          <simplebar style="flex: 1; height: 0; overflow-x: hidden;">
            <v-card 
              class="pa-0 mt-2 elevation-0"
              outlined
            >
              <v-card-title 
                class="primary darken-3 white--text py-1"
              >
                ID: {{ swayGroup.id }}
                <v-spacer/>
                <Dialog 
                  color="accent"
                  ref="groupSelectorDiablog"
                  small
                >
                  <v-icon>mdi-swap-horizontal</v-icon>
                  <template #title>歩行グループ切替</template>
                  <template #contents>
                    <SwayGroupsTable
                      class="mt-3"
                      height="calc(100vh - 320px)"
                      :groups="swayGroups"
                      elevation="0"
                      selectable
                      :filter-params="{
                        isDeleted: false,
                      }"
                      :invisible-headers-values="[
                        'isDeleted',
                      ]"
                      @select="
                        $refs.groupSelectorDiablog.close(); 
                        selectSwayGroup($event);
                      "
                    />
                  </template>
                  <template #actions>
                    <span></span>
                  </template>
                </Dialog>
                
              </v-card-title>
              <v-card-text>
                <v-container class="px-0 pb-0">
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        label="グループ名"
                        v-model="swayGroup.name"
                        hide-details
                        @change="updateGroupAttribute('name')"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-textarea
                        label="説明"
                        class="mt-3"
                        v-model="swayGroup.description"
                        @change="updateGroupAttribute('description')"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-select
                        label="有効/無効"
                        v-model="swayGroup.isDeleted"
                        dense
                        hide-details
                        @change="updateGroupAttribute('isDeleted')"
                        :items="[
                          {label: '有効', value: false},
                          {label: '無効', value: true}
                        ]"
                        item-text="label"
                        item-value="value"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        label="作成日時"
                        v-model="swayGroup.createdDatetime"
                        class="mt-5"
                        dense
                        hide-details
                        readonly
                        outlined
                      />
                    </v-col>
                  </v-row>

                  <v-btn
                    color="primary darken-2 my-2 py-1"
                    block
                    v-if="!isGroupCommentsOpened"
                    @click="isGroupCommentsOpened=true"
                  >
                    <v-icon>mdi-comment-text-multiple</v-icon>
                    <v-icon>mdi-unfold-more-horizontal</v-icon>
                  </v-btn>
                  <CommentsCard
                    class="pa-0 my-2 elevation-0"
                    :id="id"
                    target="sway_group"
                    outlined
                    v-if="isGroupCommentsOpened"
                  >
                    <template #titleContainer>
                      <v-card-title 
                        class="primary darken-2 text-subtitle-1 white--text py-1 mb-3"
                        style="cursor: pointer;"
                        @click="isGroupCommentsOpened=false"
                      >
                        <v-spacer></v-spacer>
                        <v-icon color="white">mdi-comment-text-multiple</v-icon>
                        <v-icon color="white">mdi-unfold-less-horizontal</v-icon>
                        <v-spacer></v-spacer>
                      </v-card-title>
                    </template>
                  </CommentsCard>
                  <v-row dense>
                    <v-col>
                      <v-btn
                        v-if="comparableId"
                        color="accent"
                        block
                        :to="`/sway_group/${id}`"
                      >
                        単一グループ
                      </v-btn>
                      <Dialog
                        v-else
                        color="accent"
                        block
                      >
                        グループ比較
                        <template #title>比較グループ選択</template>
                        <template #contents>
                          <SwayGroupsTable
                            class="mt-3"
                            height="calc(100vh - 320px)"
                            :groups="swayGroups"
                            elevation="0"
                            selectable
                            :filter-params="{
                              isDeleted: false,
                            }"
                            :invisible-headers-values="[
                              'isDeleted',
                            ]"
                            @select="selectComparableSwayGroup($event)"
                          />
                        </template>
                        <template #actions>
                          <span></span>
                        </template>
                      </Dialog>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>

            <v-card 
              class="pa-0 mt-2 elevation-0"
              outlined
              v-if="comparableId && comparableSwayGroup"
            >
              <v-card-title 
                class="tertiary darken-3 white--text py-1"
              >
                ID: {{ comparableSwayGroup.id }}
                <v-spacer/>
                <Dialog 
                  color="accent"
                  ref="groupSelectorDiablog"
                  small
                >
                  <v-icon>mdi-swap-horizontal</v-icon>
                  <template #title>歩行グループ切替</template>
                  <template #contents>
                    <SwayGroupsTable
                      class="mt-3"
                      height="calc(100vh - 320px)"
                      :groups="swayGroups"
                      elevation="0"
                      selectable
                      :filter-params="{
                        isDeleted: false,
                      }"
                      :invisible-headers-values="[
                        'isDeleted',
                      ]"
                      @select="
                        $refs.groupSelectorDiablog.close(); 
                        selectComparableSwayGroup($event);
                      "
                    />
                  </template>
                  <template #actions>
                    <span></span>
                  </template>
                </Dialog>
                
              </v-card-title>
              <v-card-text>
                <v-container class="px-0 pb-0">
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        label="グループ名"
                        v-model="comparableSwayGroup.name"
                        hide-details
                        @change="updateComparableGroupAttribute('name')"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-textarea
                        label="説明"
                        class="mt-3"
                        v-model="comparableSwayGroup.description"
                        @change="updateComparableGroupAttribute('description')"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-select
                        label="有効/無効"
                        v-model="comparableSwayGroup.isDeleted"
                        dense
                        hide-details
                        @change="updateComparableGroupAttribute('isDeleted')"
                        :items="[
                          {label: '有効', value: false},
                          {label: '無効', value: true}
                        ]"
                        item-text="label"
                        item-value="value"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        label="作成日時"
                        v-model="comparableSwayGroup.createdDatetime"
                        class="mt-5"
                        dense
                        hide-details
                        readonly
                        outlined
                      />
                    </v-col>
                  </v-row>

                  <v-btn
                    color="tertiary darken-2 my-2 py-1"
                    block
                    v-if="!isComparableGroupCommentsOpened"
                    @click="isComparableGroupCommentsOpened=true"
                  >
                    <v-icon color="white">mdi-comment-text-multiple</v-icon>
                    <v-icon color="white">mdi-unfold-more-horizontal</v-icon>
                  </v-btn>
                  <CommentsCard
                    class="pa-0 my-2 elevation-0"
                    :id="comparableId"
                    target="sway_group"
                    outlined
                    v-if="isComparableGroupCommentsOpened"
                  >
                    <template #titleContainer>
                      <v-card-title 
                        class="tertiary darken-2 text-subtitle-1 white--text py-1 mb-3"
                        style="cursor: pointer;"
                        @click="isGroupCommentsOpened=false"
                      >
                        <v-spacer></v-spacer>
                        <v-icon color="white">mdi-comment-text-multiple</v-icon>
                        <v-icon color="white">mdi-unfold-less-horizontal</v-icon>
                        <v-spacer></v-spacer>
                      </v-card-title>
                    </template>
                  </CommentsCard>
                  <v-row dense>
                    <v-col>
                      <v-btn
                        color="accent"
                        block
                        :to="`/sway_group/${comparableId}`"
                      >
                        単一グループ
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </simplebar>
        </v-col>

        <v-col 
          cols="12" 
          :md="isSideMenuOpened ? 8 : null" 
          :lg="isSideMenuOpened ? 9 : null"
          class="d-flex flex-column pb-0"
        >
          <v-row 
            v-if="!isSideMenuOpened"
            justify="start" 
            align="start" 
            style="flex: initial;" 
          >
            <v-btn
              depressed
              large
              color="primary" 
              class="mr-3" 
              @click="isSideMenuOpened = true"
            >
              <v-icon>mdi-cog</v-icon>
              <v-icon>mdi-arrow-collapse-right</v-icon>
            </v-btn>

            <v-btn 
              icon 
              large 
              color="primary" 
              class="mr-3" 
              @click="fetchAll"
            >
              <v-icon>mdi-reload</v-icon>
            </v-btn>
            <v-col cols="2">
              <div>
                <v-text-field
                  class="text-h6"
                  v-model="swayGroup.name"
                  dense
                  label="名前"
                  hide-details
                  @change="updateGroupAttribute('name')"
                />
              </div>
            </v-col>
            <v-col cols="6">
              <div>
                <v-text-field
                  class="text-h6"
                  v-model="swayGroup.description"
                  dense
                  label="説明"
                  hide-details
                  @change="updateGroupAttribute('description')"
                />
              </div>
            </v-col>
            <v-col class="ml-auto mr-2" justify="center" align="end" cols="2">
              <v-text-field
                class="text-h6"
                v-model="swayGroup.createdDatetime"
                dense
                label="作成日時"
                hide-details
                readonly
                outlined
              />
            </v-col>
          </v-row>

          <v-row class="d-flex align-center pr-3" style="flex: initial;">
            <v-tabs 
              v-model="currentTabIndex" 
              class="mb-5"
              background-color="primary darken-2"
              dark
            >
              <v-tab
                v-for="tab in tabs"
                :key="tab.key"
              >{{ tab.label }}</v-tab>
            </v-tabs>
          </v-row>

          <simplebar ref="scrollbar" style="flex: 1; height: 0; overflow-x: hidden; margin-left: -12px; margin-top: -8px;">
            <v-container 
              fluid 
              class="pt-3 pr-5"
              v-if="currentTabKey === 'setting'"
            >
              <v-row>
                <v-col cols="6">
                  <div class="d-flex align-center mb-3">
                    <v-chip
                      outlined
                      class="mr-5"
                      color="primary"
                    >グループ内歩行一覧</v-chip>

                    <v-spacer/>
                    <v-tooltip 
                      top
                      color="secondary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="secondary"
                          v-bind="attrs"
                          v-on="on"
                          @click="removeFilteredFromGroup"
                        >一括除去</v-btn>
                      </template>
                      フィルタ条件に合致する分析を全てグループから外します
                    </v-tooltip>
                  </div>
                  <SwayListTable
                    :sway-list="swayListInGroup"
                    ref="swayListInGroup"
                  >
                    <template #itemPrepend="{ props }">
                      <v-btn
                        icon
                        color="primary"
                        small
                        class="mr-1"
                        @click="removeFromGroup(props.item)"
                      >
                        <v-icon>mdi-note-minus</v-icon>
                      </v-btn>
                    </template>
                  </SwayListTable>
                </v-col>
                <v-col cols="6">
                  <div class="d-flex align-center mb-3">
                    <v-chip
                      outlined
                      class="mr-5"
                      color="primary"
                    >その他</v-chip>

                    <v-spacer/>
                    <v-tooltip 
                      top
                      color="secondary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="secondary"
                          v-bind="attrs"
                          v-on="on"
                          @click="addFilteredToGroup"
                        >一括追加</v-btn>
                      </template>
                      フィルタ条件に合致する分析を全てグループに追加します
                    </v-tooltip>
                  </div>
                  <SwayListTable
                    ref="swayListNotInGroup"
                    :sway-list="swayListNotInGroup"
                  >
                    <template #itemPrepend="{ props }">
                      <v-btn
                        icon
                        color="primary"
                        small
                        class="mr-1"
                        @click="addToGroup(props.item)"
                      >
                        <v-icon>mdi-note-plus</v-icon>
                      </v-btn>
                    </template>
                  </SwayListTable>
                </v-col>
              </v-row>
            </v-container>

            <SwayGroupReport
              v-if="currentTabKey === 'detail'"
              :group-id="id"
              :comparable-group-id="comparableId"
              :sway-ids="swayIds"
              :comparable-sway-ids="comparableSwayIds"
            ></SwayGroupReport>
          </simplebar>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import SwayGroupReport from "@components/pages/SwayGroupReport.vue";
import SwayGroupsTable from "@components/parts/tables/SwayGroupsTable.vue";
import SwayListTable from "@components/parts/tables/SwayListTable.vue";
import CommentsCard from "@components/parts/cards/CommentsCard.vue";
import Dialog from "@components/parts/dialogs/Dialog.vue";

export default {
  components: {
    SwayGroupReport,
    SwayGroupsTable,
    SwayListTable,
    CommentsCard,
    Dialog,
  },
  props: {
    id: {
      required: true,
    },
    comparableId: {
      default: null,
    },
  },
  data: function(){
    return {
      isSideMenuOpened: true,
      isGroupCommentsOpened: false,
      isComparableGroupCommentsOpened: false,
      currentTabIndex: 0,
      tabs: [
        {label: '設定', key: 'setting'},
        {label: '詳細', key: 'detail'},
      ]
    }
  },
  computed: {
    ...mapState([
      'swayGroup',
      'comparableSwayGroup',
      'swayGroups',
      'swayList',

      'loadingCount',
    ]),
    isLoading: function(){
      return this.loadingCount > 0;
    },
    currentTab: function(){
      return this.tabs[this.currentTabIndex];
    },
    currentTabKey: function(){
      return this.currentTab?.key;
    },
    swayIds: function(){
      return this.swayGroup?.swayIds ?? [];
    },
    comparableSwayIds: function(){
      return this.comparableSwayGroup?.swayIds ?? [];
    },
    swayListInGroup: function(){
      return this.swayList.filter(g => this.swayIds.includes(g.id));
    },
    swayListNotInGroup: function(){
      return this.swayList.filter(g => !this.swayIds.includes(g.id));
    },
  },
  watch: {
    id: function(){
      this.fetchAll();
    },
    comparableId: function(){
      this.fetchAll();
    },
  },
  mounted: function(){
    this.fetchAll();
  },
  methods: {
    ...mapActions([
      'fetchSwayGroup',
      'fetchSwayList',
      'fetchComparableSwayGroup',
      'fetchSwayGroups',
    ]),
    fetchAll: function(){
      this.fetchSwayList();
      this.fetchSwayGroups();

      if(this.group?.id != this.id){
        this.fetchSwayGroup(this.id);
      }

      if(this.comparableId != null && this.comparableSwayGroup?.id != this.comparableId){
        this.fetchComparableSwayGroup(this.comparableId);
      }
    },
    selectSwayGroup: function(group){
      if(this.id == group.id){
        return;
      }
      this.$router.push(
        this.comparableId 
          ? `/sway_group/${group.id}/${this.comparableId}` 
          : `/sway_group/${group.id}`
        );
    },
    selectComparableSwayGroup: function(group){
      if(this.comparableId == group.id){
        return;
      }
      this.$router.push(`/sway_group/${this.id}/${group.id}`);
    },
    updateGroupAttribute: function(key){
      this.axios.put(`/api/sway_group/${key}`, this.swayGroup).then(() => {
      }).catch(error => {
        console.log(error);
        alert('歩行グループ更新に失敗しました');
      })
    },
    updateComparableGroupAttribute: function(key){
      this.axios.put(`/api/sway_group/${key}`, this.comparableSwayGroup).then(() => {
      }).catch(error => {
        console.log(error);
        alert('歩行グループ更新に失敗しました');
      })
    },
    fetchSwayGroupAfflications: function(){
      this.axios.get(`/api/sway_group_affiliations/${this.swayGroup.id}`).then(response => {
        this.$set(
          this.swayGroup, 'swayIds', response.data.affiliations.map(a => a.swayId)
        );
      }).catch(error => {
        alert("歩行グループ情報取得に失敗しました");
        console.log(error);
      });
    },
    removeFromGroup: function(sway){
      this.axios.delete(`/api/sway_group_affiliation/${this.swayGroup.id}/${sway.id}`).then(() => {
        this.fetchSwayGroupAfflications();
      }).catch(error => {
        alert("歩行グループ更新に失敗しました");
        console.log(error);
      });
    },
    addToGroup: function(sway){
      this.axios.post(`/api/sway_group_affiliation/${this.swayGroup.id}/${sway.id}`).then(() => {
        this.fetchSwayGroupAfflications();
      }).catch(error => {
        alert("歩行グループ更新に失敗しました");
        console.log(error);
      })
    },
    removeFilteredFromGroup: function(){
      const swayList = this.$refs.swayListInGroup?.getFilteredItems() ?? [];
      swayList.forEach(g => this.removeFromGroup(g));
    },
    addFilteredToGroup: function(){
      const swayList = this.$refs.swayListNotInGroup?.getFilteredItems() ?? [];
      swayList.forEach(g => this.addToGroup(g));
    },
  },
}
</script>