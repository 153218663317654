<template>
	<LineChart
		ref="chart"
		:height="height"
		:plugins="plugins"
	/>
</template>

<script>
import LineChart from '@components/parts/charts/LineChart.vue'

export default {
	components: {
		LineChart,
	},
	props: {
		trajectory: {
			required: true,
		},
		freezingIndexes: {
			required: true,
		},
		selection: {
		},
		height: {
			default: 320,
		},
		windowSize: {
			default: 400,
		},
	},
	data: function() {
		return {
		};
	},
	mounted() {
		this.$refs.chart.dataCollection.datasets = this.createLineChartData(this.freezingIndexes);
	},
	watch: {
		freezingIndexes: {
			handler(newVal){
				this.$nextTick(() => {
					this.$refs.chart.dataCollection.datasets = this.createLineChartData(newVal);
					this.updateChartsRange(this.selection);
				});
			},
			deep: true,
			immediate: true,
		},
		selection: {
			handler(newVal){
				this.updateChartsRange(newVal);
			},
			deep: true,
			immediate: true,
		}
	},
	computed: {
		selectedDuration: function(){
			return this.selection.to - this.selection.from;
		},
		colors: function(){
			let colors = [];
						
			const leftHeelStrikeIndexes = this.trajectory?.left?.heelStrikeIndexes ?? [];
			const leftToeOffIndexes = this.trajectory?.left?.toeOffIndexes ?? [];
			const rightHeelStrikeIndexes = this.trajectory?.right?.heelStrikeIndexes ?? [];
			const rightToeOffIndexes = this.trajectory?.right?.toeOffIndexes ?? [];
			
			let isFirst = true;
			let previousPhase = null;
			let previoutPhaseIndex = 0;
			for(let i=0, iMax=this.trajectory.left.x.length; i<iMax; i++){
				let previousLeftHeelStrikeIndex = leftHeelStrikeIndexes.findIndex(_i => i < _i);
				let previousLeftHeelStrike = previousLeftHeelStrikeIndex >= 1
					? leftHeelStrikeIndexes[previousLeftHeelStrikeIndex - 1]
					: 0;

				let previousLeftToeOffIndex = leftToeOffIndexes.findIndex(_i =>  i < _i);
				let previousLeftToeOff = previousLeftToeOffIndex >= 1
					? leftToeOffIndexes[previousLeftToeOffIndex - 1]
					: 0;

				let previousRightHeelStrikeIndex = rightHeelStrikeIndexes.findIndex(_i =>  i < _i);
				let previousRightHeelStrike = previousRightHeelStrikeIndex >= 1
					? rightHeelStrikeIndexes[previousRightHeelStrikeIndex - 1]
					: 0;

				let previousRightToeOffIndex = rightToeOffIndexes.findIndex(_i =>  i < _i);
				let previousRightToeOff = previousRightToeOffIndex >= 1
					? rightToeOffIndexes[previousRightToeOffIndex - 1]
					: 0;
				
				let isLeftOn = previousLeftToeOff < previousLeftHeelStrike;
				let isRightOn = previousRightToeOff < previousRightHeelStrike;

				let currentPhase = null;
				if(isLeftOn && !isRightOn){
					currentPhase = "green";
				}else if(!isLeftOn && isRightOn){
					currentPhase = "yellow";
				}else if(previousRightHeelStrike < previousLeftHeelStrike){
					currentPhase = "blue";
				}else{
					currentPhase = "red";
				}

				if(currentPhase !== previousPhase && previousPhase){
					if(!isFirst){
						colors.push({
							color: previousPhase,
							from: previoutPhaseIndex,
							to: i
						})
					}

					isFirst = false;
				}

				if(currentPhase !== previousPhase){
					previoutPhaseIndex = i;
				}

				previousPhase = currentPhase;
			}

			return colors;
		},
		validColors: function(){
			return this.colors.filter(c => !(this.selection.to < c.from || c.to < this.selection.from));
		},
		plugins: function(){
			return [{
					id: 'backgrounds',
					beforeDraw: (chart) => {
						const {
							ctx,
							chartArea,
						} = chart;

						const width = chartArea.right - chartArea.left;
						const scale = width/this.selectedDuration;

						this.validColors.forEach(color => {
							let from = chartArea.left + Math.max(0, (color.from - this.selection.from))*scale;
							ctx.save();
							ctx.fillStyle = this.getColor(color.color);
							ctx.fillRect(
								from, 
								chartArea.top, 
								Math.min((color.to - color.from)*scale, width - from),
								chartArea.bottom - chartArea.top
							);
							ctx.restore();
						})
					}
				}]
		},
	},
	methods: {
		getColor: function(color){
			const s = 80;
			const v = 95;
			const a = 0.5;

			switch(color){
				case 'red':
					return `hsla(0, ${s}%, ${v}%, ${a})`;
				case 'blue':
					return `hsla(240, ${s}%, ${v}%, ${a})`;
				case 'yellow':
					return `hsla(60, ${s}%, ${v}%, ${a})`;
				case 'green':
					return `hsla(140, ${s}%, ${v}%, ${a})`;
			}

			return 'hsla(0, 0%, 28%, 0.2)';
		},
		updateChartsRange: function(duration){
      let _duration = {
        from: duration?.from != null ? duration.from*10 : null,
        to: duration?.to != null ? duration.to*10 : null,
      }
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs.chart?.setRangeX(_duration);
        });
      })
    },
		createLineChartData: function(freezingIndexes){
      let rtn = [];

      const defaultData = {
        fill: false,
        backgroundColor: 'hsla(0, 77%, 28%, 0.2)',
        borderColor: 'hsla(0, 77%, 28%, 0.2)',
        borderWidth: 2,
        label: '',
      };

      let labels = [
        'Acc X', 'Acc Y', 'Acc Z',
        'Gyr X', 'Gyr Y', 'Gyr Z',
      ]
      labels.forEach((l, i) => {
        let color = this.getLineChartColor((i*120 + (i >= 3 ? 60 : 0)));
        rtn.push({
          ...defaultData,
          label: `${l}`,
          backgroundColor: color,
          borderColor: color,
          data: [],
        });
      })
      
			let targets = [
				{
					sensor: 'acc',
					axes: ['x', 'y', 'z'],
				},
				{
					sensor: 'gyr',
					axes: ['x', 'y', 'z'],
				},
			]

			let currentIndex = 0;
			targets.forEach(target => {
				target.axes.forEach(axis => {
					freezingIndexes[target.sensor][axis][this.windowSize].forEach((freezingIndex, index) => {
						rtn[currentIndex].data.push({
							x: (this.windowSize/2 + index)*10,
							y: freezingIndex
						})
					});

					currentIndex++;
				});
			})
      return rtn;
    },
		getLineChartColor: function(hue){
      return `hsla(${hue}, 87%, 24%, 0.5)`;
    },
	}
}
</script>