<template>
  <v-container fluid>
    <v-row justify="center" dense>
      <v-btn absolute left icon large color="primary" class="mr-3" @click="fetchAll">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
      <v-text-field
        v-if="notificationSetting"
        outlined
        hide-details
        dense
        class="mr-5"
        label="アプリ更新時通知先"
        style="max-width: 240px;"
        v-model="notificationSetting.value"
        @input="updateNotificationSetting"
      />
      <Dialog
        width="600px"
        @apply="createNewApk"
        outlined
        :disabled="!isNewApkValid"
        :openable="!isUploading"
      >
        <template>
          <v-icon class="mr-2">mdi-android</v-icon>
          APK追加
        </template>
        <template #title>
          プロジェクト{{ target.isDeleted ? '有効化' : '無効化' }}
        </template>
        <template #contents>
          <v-container dense>
            <v-form 
              ref="newApkForm" 
              @submit.prevent
              autocomplete="off" 
              lazy-validation
            >
              <v-row dense align="center">
                <v-col dense>
                  <v-text-field
                    dense 
                    outlined
                    class="mt-5" 
                    autocomplete="new-apk-name"
                    v-model="newApkData.name" 
                    label="名前"
                    :rules="[required]"
                  />
                </v-col>
              </v-row>
              <v-row dense align="center">
                <v-col dense>
                  <v-text-field 
                    dense 
                    outlined
                    autocomplete="new-apk-version-code"
                    v-model="newApkData.versionCode" 
                    label="バージョンコード"
                    :rules="[required]"
                  />
                </v-col>
              </v-row>
              <v-row dense align="center">
                <v-col dense>
                  <v-file-input
                    dense 
                    outlined
                    autocomplete="new-apk-version-code"
                    v-model="newApkData.apkFile" 
                    label="APKファイル"
                    accept=".apk"
                    :rules="[required]"
                  />
                </v-col>
              </v-row>
              <v-row dense align="center">
                <v-col dense>
                  <MarkdownEditor
                    label="説明"
                    v-model="newApkData.description"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </template>
      </Dialog>
    </v-row>

    <ApksTable
      class="mt-5"
      height="calc(100vh - 196px)"
      :items="apks"
      :filter-params="filterParams"
    >
    </ApksTable>

    <v-snackbar v-model="isUploading">
      Uploading...
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

import ApksTable from "@components/parts/tables/ApksTable.vue";
import Dialog from "@components/parts/dialogs/Dialog.vue";
import MarkdownEditor from "@components/inputs/MarkdownEditor.vue";

export default {
  components: {
    ApksTable,
    Dialog,
    MarkdownEditor,
  },
  data: function(){
    return {
      required: value => !!value || "※必須",
      newApkData: {},
      isNewApkValid: false,
      target: {},
      apks: [],
      isUploading: false,
      filterParams: {
        'isDeleted': false,
      },
      notificationSetting: null,
    }
  },
  computed: {
    ...mapState([
      'user',
      'loadingCount',
    ]),
    isLoading: function(){
      return this.loadingCount > 0;
    },
  },
  watch: {
    newApkData: {
      handler: function(){
        this.$nextTick(() => {
          this.isNewApkValid = this.$refs.newApkForm?.validate();
        });
      },
      deep: true,
    },
    target: {
      handler: function(){
      },
      deep: true,
    },
  },
  destroyed: function(){
  },
  mounted: function(){
    if(!this.user?.role?.isAdmin){
      this.$router.push('/');
      return;
    }

    this.fetchAll();
  },
  methods: {
    ...mapActions([
      'incrementLoadingCount',
      'decrementLoadingCount',
    ]),
    fetchAll: function(){
      this.fetchApks();
      this.fetchNotificationSetting();
    },
    fetchApks: function(){
      this.incrementLoadingCount();
      this.axios.get(`/api/apks`).then(response => {
        this.apks = response.data.apks;
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        this.decrementLoadingCount();
      });
    },
    fetchNotificationSetting: function(){
      this.incrementLoadingCount();
      this.axios.get(`/api/setting/apk_update_notification`).then(response => {
        this.notificationSetting = response.data.setting;
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        this.decrementLoadingCount();
      });
    },
    updateNotificationSetting: function(){
      this.axios.put(`/api/setting/apk_update_notification`, this.notificationSetting).then(response => {
        this.notificationSetting = response.data.setting;
      }).catch(error => {
        console.error(error);
      }).finally(() => {
      });
    },
    createNewApk: function(){
      const params = new FormData();
      params.append('file', this.newApkData.apkFile);
      params.append('apk_data_form', 
        JSON.stringify({
          name: this.newApkData.name,
          versionCode: this.newApkData.versionCode,
          description: this.newApkData.description,
        })
      );

      this.isUploading = true;
      this.incrementLoadingCount();
      this.axios.post(
        `/api/apk`,
        params,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
      ).then(response => {
        this.apks.push(response.data.apk);
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        this.isUploading = false;
        this.decrementLoadingCount();
        this.newApkData = {};
      });
    },
  }
}
</script>

<style lang="scss" scoped>

</style>