<template>
  <div
    class="background"
    style="position: relative;"
    :style="{
      height: `${containerHeight}px`
    }"
    @mousedown.prevent="startDragging"
    @mousemove.prevent="rotateGraph"
    @wheel.ctrl.prevent="scaleGraph"
    ref="container"
    v-resize="resizeContainer"
    v-tooltip="{
      content: `ドラッグで${isRotatable ? '回転' : '移動'}<br/>ctrl+wheel、またはマウスオーバしてctrl+「+」/ctrl+「-」で拡大/縮小`,
      delay: {
        show: 2000,
        hide: 0
      },
    }"
    @mouseenter="addKeyEventListeners"
    @mouseleave="removeKeyEventListeners"
  >
    <VglDefs>
      <template v-slot:[`trunkGrid${id}`]>
        <VglLine v-for="(line, index) in subGridLines" :key="`sub-grid-line-${index}`">
          <template #geometry>
            <VglGeometry>
              <template #position>
                <VglFloat32Attribute
                  :array="line"
                  :item-size="3"
                />
              </template>
            </VglGeometry>
          </template>
          <template #material>
            <VglLineBasicMaterial
              :linewidth="2"
              color="#DDDDDD"
            />
          </template>
        </VglLine>

        <VglLine v-for="(line, index) in gridLines" :key="`grid-line-${index}`">
          <template #geometry>
            <VglGeometry>
              <template #position>
                <VglFloat32Attribute
                  :array="line"
                  :item-size="3"
                />
              </template>
            </VglGeometry>
          </template>
          <template #material>
            <VglLineBasicMaterial
              :linewidth="12"
              color="#888888"
            />
          </template>
        </VglLine>
      </template>

      <template 
        v-for="(trajectory, i) in coloredTrajectories.red" 
        v-slot:[`redTrajectory${id}-${i}`]
      >
        <TrunkTrajectoryGeometry
          :trajectory="trajectory"
          :key="`redTrajectory${i}`"
          :selection="selection"
        />
      </template>

      <template 
        v-for="(trajectory, i) in coloredTrajectories.blue" 
        v-slot:[`blueTrajectory${id}-${i}`]
      >
        <TrunkTrajectoryGeometry
          :trajectory="trajectory"
          :key="`blueTrajectory${i}`"
          :selection="selection"
        />
      </template>

      <template 
        v-for="(trajectory, i) in coloredTrajectories.green" 
        v-slot:[`greenTrajectory${id}-${i}`]
      >
        <TrunkTrajectoryGeometry
          :trajectory="trajectory"
          :key="`greenTrajectory${i}`"
          :selection="selection"
        />
      </template>

      <template 
        v-for="(trajectory, i) in coloredTrajectories.yellow" 
        v-slot:[`yellowTrajectory${id}-${i}`]
      >
        <TrunkTrajectoryGeometry
          :trajectory="trajectory"
          :key="`yellowTrajectory${i}`"
          :selection="selection"
        />
      </template>

    </VglDefs>
    
    <VglDefs v-if="isSplitted">
      <template 
        v-for="(trajectory, i) in trunk" 
        v-slot:[`splittedTrajectory${id}-${i}`]
      >
        <TrunkTrajectoryGeometry
          :trajectory="trajectory"
          :key="`splittedTrajectory${i}`"
          :selection="selection"
        />
      </template>
    </VglDefs>

    <VglRenderer 
      v-observe-visibility="isVisible => (isVisible ? resizeContainer() : false)"
      v-if="isGraphVisible"
      style="width: 100%; height: 100%;" 
      :alpha="true"
      :antialias="true"
      :preserve-drawing-buffer="true"
      ref="renderer"
      :id="`trunkTrajectory${id}`"
    >
      <template #scene>
        <VglScene>
          <VglGroup
            rotation="quaternion"
            :scale-x="scaleX"
            :scale-y="scaleY"
            :scale-z="scaleZ"
            :rotation-w="quaternion[0]"
            :rotation-x="quaternion[1]"
            :rotation-y="quaternion[2]"
            :rotation-z="quaternion[3]"
          >
            <VglUse v-if="grid" :href="`trunkGrid${id}`" />

            <template v-if="!isAveraged">
              <template v-if="isSplitted">
                <VglLine 
                  v-for="i of trunk.length"
                  :key="`splittedTrajectory${i - 1}`"
                >
                  <template #geometry>
                    <VglUse :href="`splittedTrajectory${id}-${i - 1}`"/>
                  </template>
                  <template #material>
                    <TransparentLineMaterial
                      :linewidth="2"
                      :color="color"
                      :opacity="lineOpacity"
                    />
                  </template>
                </VglLine>
              </template>


              <VglLine 
                v-for="i of coloredTrajectories.red.length"
                :key="`redTrajectory${i}`"
              >
                <template #geometry>
                  <VglUse :href="`redTrajectory${id}-${i - 1}`"/>
                </template>
                <template #material>
                  <TransparentLineMaterial
                    :linewidth="2"
                    color="#CC4444"
                    :opacity="lineOpacity"
                  />
                </template>
              </VglLine>

              <VglLine 
                v-for="i of coloredTrajectories.blue.length"
                :key="`blueTrajectory${i}`"
              >
                <template #geometry>
                  <VglUse :href="`blueTrajectory${id}-${i - 1}`"/>
                </template>
                <template #material>
                  <TransparentLineMaterial
                    :linewidth="2"
                    color="#4444CC"
                    :opacity="lineOpacity"
                  />
                </template>
              </VglLine>

              <VglLine 
                v-for="i of coloredTrajectories.green.length"
                :key="`greenTrajectory${i}`"
              >
                <template #geometry>
                  <VglUse :href="`greenTrajectory${id}-${i - 1}`"/>
                </template>
                <template #material>
                  <TransparentLineMaterial
                    :linewidth="2"
                    color="#44CC44"
                    :opacity="lineOpacity"
                  />
                </template>
              </VglLine>

              <VglLine 
                v-for="i of coloredTrajectories.yellow.length"
                :key="`yellowTrajectory${i}`"
              >
                <template #geometry>
                  <VglUse :href="`yellowTrajectory${id}-${i - 1}`"/>
                </template>
                <template #material>
                  <TransparentLineMaterial
                    :linewidth="2"
                    color="#CCCC44"
                    :opacity="lineOpacity"
                  />
                </template>
              </VglLine>
            </template>
            <template v-else>
              <VglLine >
                <template #geometry>
                  <VglUse :href="`averaged${comparable != null ? 'Comparable' : ''}TrunkTrajectory-${gaitId}`"/>
                </template>
                <template #material>
                  <TransparentLineMaterial
                    :linewidth="2"
                    color="#4444CC"
                    :opacity="1.0"
                  />
                </template>
              </VglLine>
            </template>
          </VglGroup>
        </VglScene>
      </template>
      <template #camera>
        <VglPerspectiveCamera 
          v-if="perspective" 
          :position-z="cameraPosition" 
          :rotation="isRotatable ? 'lookAt' : null" 
        />
        <VglOrthographicCamera 
          v-else
          :left="-cameraPosition" 
          :right="cameraPosition" 
          :top="cameraPosition/aspectRatio" 
          :bottom="-cameraPosition/aspectRatio"
          :position-x="translationX"
          :position-y="translationY"
          :position-z="cameraPosition" 
          :rotation="isRotatable ? 'lookAt' : null"
        />
      </template>
    </VglRenderer>

    <v-overlay :value="loading" absolute>
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>


<script>
import { calcQuaternionFromEulerAngles, calcQuaternionMultiplication } from '@js/utils/MatrixUtil.js';

import TrunkTrajectoryGeometry from '@components/parts/graph/geometories/TrunkTrajectoryGeometry.vue'
import TransparentLineMaterial from '@components/parts/materials/TransparentLineMaterial.vue';

import {
  VglDefs,
  VglGroup,
  VglUse,
  VglRenderer, 
  VglScene, 
  VglPerspectiveCamera,
  VglOrthographicCamera,
  VglLine,
  VglLineBasicMaterial,
  VglGeometry,
  VglFloat32Attribute,
} from 'vue-gl';
import { mapState } from 'vuex';


export default {
  components: {
    VglDefs,
    VglGroup,
    VglUse,
    VglRenderer,
    VglScene,
    VglPerspectiveCamera,
    VglOrthographicCamera,
    VglLine,
    VglLineBasicMaterial,
    VglGeometry,
    VglFloat32Attribute,

    TrunkTrajectoryGeometry,
    TransparentLineMaterial,
  },
  props: {
    id: {
      default: null,
    },
    gaitId: {
      default: null,
    },
    trajectory: {
      required: true,
    },
    selection: {
    },
    perspective: {
      default: false,
    },
    initialRoll: {
      default: 25,
    },
    initialPitch: {
      default: 45,
    },
    initialYaw: {
      default: 0,
    },
    draggingMode: {
      default: 'translation',
    },
    scaleX: {
      default: 1.0,
    },
    scaleY: {
      default: 1.0,
    },
    scaleZ: {
      default: 1.0,
    },
    grid: {
      default: true,
    },
    gridMode: {
      default: 'all', // top | side | front | all
    },
    initialCameraPosition: {
      default: 0.15,
    },
    maxCameraPosition: {
      default: 1.0,
    },
    minCameraPosition: {
      default: 0.1,
    },
    loading: {
      default: false,
    },

    verticalOffset: {
      default: 0,
    },
    horizontalOffset: {
      default: 0,
    },
    

    aspectRatio: {
      default: 1.92
    },
    lineOpacity: {
      default: 0.65,
    },

    comparable: {
      default: null,
    },
    averaged: {
      default: null,
    },

    color: {
      default: '#4444CC',
    },
  },
  data: function(){
    return {
      cameraPosition: 0.1,
      isDragging: false,
      quaternion: [1, 0, 0, 0],
      previousQuaternion: [1, 0, 0, 0],
      containerWidth: 0,
      translationX: 0,
      translationY: 0,
      gridInterval: 0.01,

      loadingCount: 0,
    }
  },
  computed: {
    ...mapState([
      'isGraphVisible',
      'movingAverageWidth',
    ]),
    isAveraged: function(){
      return this.averaged != null;
    },
    containerHeight: function(){
      return parseInt(this.containerWidth/this.aspectRatio);
    },
    isLoading: function(){
      return this.loadingCount > 0;
    },
    isRotatable: function(){
      return this.draggingMode === 'rotation';
    },

    maxGridX: function(){
      return 0.5;
    },
    minGridX: function(){
      return -0.5;
    },
    maxGridY: function(){
      return 0.3;
    },
    minGridY: function(){
      return -0.3;
    },
    maxGridZ: function(){
      return 0;
    },
    minGridZ: function(){
      return 0;
    },

    subGridLines: function(){
      let rtn = [];
      const interval = this.gridInterval;

      if(['top', 'all'].some(m => m === this.gridMode)){
        for(let i=this.minGridX/interval, iMax=this.maxGridX/interval; i<=iMax; i++){
          rtn.push([
            -this.horizontalOffset + interval*i, 
            -this.verticalOffset, 
            this.minGridZ, 

            -this.horizontalOffset + interval*i, 
            -this.verticalOffset, 
            this.maxGridZ,
          ]);
        }

        for(let i=this.minGridZ/interval, iMax=this.maxGridZ/interval; i<=iMax; i++){
          rtn.push([
            this.minGridX - this.horizontalOffset, 
            -this.verticalOffset, 
            interval*i, 

            this.maxGridX - this.horizontalOffset, 
            -this.verticalOffset, 
            interval*i,
          ]);
        }
      }

      if(['front', 'all'].some(m => m === this.gridMode)){
        for(let i=this.minGridY/interval, iMax=this.maxGridY/interval; i<=iMax; i++){
          rtn.push([
            this.minGridX - this.horizontalOffset, 
            -this.verticalOffset + interval*i, 
            this.minGridZ, 

            this.minGridX - this.horizontalOffset, 
            -this.verticalOffset + interval*i, 
            this.maxGridZ,
          ]);
        }

        for(let i=this.minGridZ/interval, iMax=this.maxGridZ/interval; i<=iMax; i++){
          rtn.push([
            this.minGridX - this.horizontalOffset, 
            -this.verticalOffset + this.minGridY, 
            interval*i, 

            this.minGridX - this.horizontalOffset, 
            -this.verticalOffset + this.maxGridY, 
            interval*i,
          ]);
        }
      }

      if(['side', 'all'].some(m => m === this.gridMode)){
        for(let i=this.minGridX/interval, iMax=this.maxGridX/interval; i<=iMax; i++){
          rtn.push([
            -this.horizontalOffset + interval*i, 
            -this.verticalOffset + this.minGridY, 
            this.minGridZ, 

            -this.horizontalOffset + interval*i, 
            -this.verticalOffset + this.maxGridY, 
            this.minGridZ,
          ]);
        }

        for(let i=this.minGridY/interval, iMax=this.maxGridY/interval; i<=iMax; i++){
          rtn.push([
            this.minGridX - this.horizontalOffset, 
            -this.verticalOffset + interval*i, 
            this.minGridZ, 
            
            this.maxGridX - this.horizontalOffset, 
            -this.verticalOffset + interval*i, 
            this.minGridZ,
          ]);
        }
      }

      return rtn;
    },
    gridLines: function(){
      let rtn = [];

      if(['top', 'all'].some(m => m === this.gridMode)){
        rtn.push([
          -this.horizontalOffset, 
          -this.verticalOffset, 
          this.minGridZ, 

          -this.horizontalOffset, 
          -this.verticalOffset, 
          this.maxGridZ,
        ]);

        rtn.push([
          this.minGridX - this.horizontalOffset, 
          -this.verticalOffset, 
          0, 

          this.maxGridX - this.horizontalOffset, 
          -this.verticalOffset, 
          0,
        ]);
      }

      if(['front', 'all'].some(m => m === this.gridMode)){
        rtn.push([
          this.minGridX - this.horizontalOffset, 
          -this.verticalOffset, 
          this.minGridZ, 

          this.minGridX - this.horizontalOffset, 
          -this.verticalOffset, 
          this.maxGridZ,
        ]);

        rtn.push([
          this.minGridX - this.horizontalOffset, 
          -this.verticalOffset + this.minGridY, 
          0, 

          this.minGridX - this.horizontalOffset, 
          -this.verticalOffset + this.maxGridY, 
          0,
        ]);
      }


      if(['side', 'all'].some(m => m === this.gridMode)){
        rtn.push([
          this.minGridX - this.horizontalOffset, 
          -this.verticalOffset, 
          this.minGridZ, 

          this.maxGridX - this.horizontalOffset, 
          -this.verticalOffset, 
          this.minGridZ,
        ]);

        rtn.push([
          -this.horizontalOffset, 
          -this.verticalOffset + this.minGridY, 
          this.minGridZ, 
          
          -this.horizontalOffset, 
          -this.verticalOffset + this.maxGridY, 
          this.minGridZ,
        ]);
      }

      return rtn;
    },
    isSplitted: function(){
      return Array.isArray(this.trajectory.trunk);
    },
    trunk: function(){
      return this.trajectory.trunk;
    },
    coloredTrajectories: function(){
      let rtn = {
        red: [],
        blue: [],
        green: [],
        yellow: [],
      };

      if(this.isSplitted){
        return rtn;
      }

      const leftHeelStrikeIndexes = this.trajectory.left?.heelStrikeIndexes ?? [];
      const leftToeOffIndexes = this.trajectory.left?.toeOffIndexes ?? [];

      const rightHeelStrikeIndexes = this.trajectory.right?.heelStrikeIndexes ?? [];
      const rightToeOffIndexes = this.trajectory.right?.toeOffIndexes ?? [];

      const MODIFYING_MOVING_AVERAGE = 150;

      let currentTrajectories = [];
      let previousPhase = null;
      for(let i=0, iMax=this.trunk.x.length; i<iMax; i++){
        let previousLeftHeelStrikeIndex = leftHeelStrikeIndexes.findIndex(_i => i + MODIFYING_MOVING_AVERAGE < _i);
        let previousLeftHeelStrike = previousLeftHeelStrikeIndex >= 1
          ? leftHeelStrikeIndexes[previousLeftHeelStrikeIndex - 1]
          : 0;

        let previousLeftToeOffIndex = leftToeOffIndexes.findIndex(_i =>  i + MODIFYING_MOVING_AVERAGE < _i);
        let previousLeftToeOff = previousLeftToeOffIndex >= 1
          ? leftToeOffIndexes[previousLeftToeOffIndex - 1]
          : 0;

        let previousRightHeelStrikeIndex = rightHeelStrikeIndexes.findIndex(_i =>  i + MODIFYING_MOVING_AVERAGE < _i);
        let previousRightHeelStrike = previousRightHeelStrikeIndex >= 1
          ? rightHeelStrikeIndexes[previousRightHeelStrikeIndex - 1]
          : 0;

        let previousRightToeOffIndex = rightToeOffIndexes.findIndex(_i =>  i + MODIFYING_MOVING_AVERAGE < _i);
        let previousRightToeOff = previousRightToeOffIndex >= 1
          ? rightToeOffIndexes[previousRightToeOffIndex - 1]
          : 0;
        
        let isLeftOn = previousLeftToeOff < previousLeftHeelStrike;
        let isRightOn = previousRightToeOff < previousRightHeelStrike;
        
        let currentPhase = null;
        if(isLeftOn && !isRightOn){
          currentPhase = "green";
        }else if(!isLeftOn && isRightOn){
          currentPhase = "yellow";
        }else if(previousRightHeelStrike < previousLeftHeelStrike){
          currentPhase = "blue";
        }else{
          currentPhase = "red";
        }

        let position = {
          x: this.trunk.y[i],
          y: this.trunk.x[i],
          z: this.trunk.z[i],
          index: i,
        };

        if(currentPhase !== previousPhase && currentTrajectories.length > 0){
          rtn[previousPhase].push([...currentTrajectories, position]);
          currentTrajectories = [];
        }

        previousPhase = currentPhase;
        currentTrajectories.push(position);
      }

      if(currentTrajectories.length > 0){
        rtn[previousPhase].push([...currentTrajectories]);
      }
      return rtn;
    },
  },
  redLines: function(){

  },
  beforeDestroy: function(){
  },
  mounted: function(){
    this.refreshCamera();
  },
  watch: {
    selection: {
      handler(){
        this.$forceUpdate();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    startDragging: function(){
      this.previousQuaternion = [...this.quaternion];
      this.dx = 0;
      this.dy = 0;


      this.isDragging = true;
      window.addEventListener('mouseup', this.endDragging);
    },
    rotateGraph: function(e){
      if(this.isDragging){
        if(this.isRotatable){
          this.dx += e.movementX;
          this.dy += e.movementY;

          const dx = this.dx/this.containerWidth;
          const dy = this.dy/this.containerHeight;
          const distance = Math.sqrt(dx*dx + dy*dy);
          if(distance != 0){
            const theta = distance*Math.PI;
            const sinTheta = Math.sin(theta)/distance;
            this.quaternion = calcQuaternionMultiplication(
              [
                Math.cos(theta), 
                dy*sinTheta,
                dx*sinTheta, 
                0.0
              ],
              this.previousQuaternion
            )
          }
        }else{
          this.translationX -= 2*this.cameraPosition*e.movementX/this.containerWidth;
          this.translationY += 2/this.aspectRatio*this.cameraPosition*e.movementY/this.containerHeight;
        }
      }
    },
    scaleGraph: function(e){
      let val = this.cameraPosition*(1.0 + e.deltaY*0.001);
      this.cameraPosition = Math.max(Math.min(this.maxCameraPosition, val), this.minCameraPosition);
    },
    endDragging: function(){
      window.removeEventListener('mouseup', this.endDragging);
      this.isDragging = false;
    },
    refreshCamera: function(){
      this.translationX = 0;
      this.translationY = 0;
      this.quaternion = calcQuaternionFromEulerAngles(
        this.initialRoll*Math.PI/180, 
        this.initialPitch*Math.PI/180, 
        this.initialYaw*Math.PI/180
      );
      this.cameraPosition = this.initialCameraPosition;
    },
    setCameraAngle: function(roll, pitch, yaw, cameraPosition=null){
      this.quaternion = calcQuaternionFromEulerAngles(
        roll*Math.PI/180, 
        pitch*Math.PI/180, 
        yaw*Math.PI/180
      );
      if(cameraPosition != null){
        this.cameraPosition = cameraPosition;
      }
    },
    resizeContainer: function(){
      this.containerWidth = this.$refs.container.clientWidth;
    },
    addKeyEventListeners: function(){
      window.addEventListener('keydown', this.executeKeyEvent);
    },
    removeKeyEventListeners: function(){
      window.removeEventListener('keydown', this.executeKeyEvent);
    },
    zoomIn: function(){
      this.scaleGraph({deltaY: -100.0});
    },
    zoomOut: function(){
      this.scaleGraph({deltaY: 100.0});
    },
    executeKeyEvent: function(e){
      if(e.ctrlKey){
        switch(e.key){
          case '+':
            e.preventDefault();
            this.zoomIn();
            break;
          case '-':
            e.preventDefault();
            this.zoomOut();
            break;
        }
      }
    },
  }
}
</script>


<style lang="scss" scoped>
.background{
  border: 1px solid black;
}
</style>