<template>
  <v-container fluid>
    <template v-if="posture">
      <v-row justify="start" align="center" dense>
        <v-btn icon large color="accent" class="mr-3" :to="`/postures`">
          <v-icon>mdi-arrow-left-bold-circle</v-icon>
        </v-btn>
        <v-btn icon large color="primary" class="mr-3" @click="fetchPostureDetail">
          <v-icon>mdi-reload</v-icon>
        </v-btn>
        <v-col cols="2">
          <div>
            <v-text-field
              class="text-h6"
              v-model="posture.name"
              hint="名前"
              persistent-hint
              @change="updatePosture"
            />
          </div>
        </v-col>
        <v-col cols="6">
          <div>
            <v-text-field
              class="text-h6"
              v-model="posture.description"
              hint="説明"
              persistent-hint
            />
          </div>
        </v-col>
        <v-col class="ml-auto mr-2" justify="center" align="end" cols="2">
          作成日時: {{ posture.createdTime }}
        </v-col>
      </v-row>

      <v-row justify="center" dense v-if="posture.isAnalyzed">
        <v-col cols="3" dense>
          <v-row justify="center" align="center" dense>
            <v-btn 
              :href="isOutput ? outputPath : inputPath" 
              download 
              icon 
              color="primary"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
            <v-switch
              class="pa-0 ma-0 ml-auto"
              dense
              v-model="isOutput"
              color="primary"
              hide-details
            ></v-switch>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <video 
                v-if="posture.isVideo"
                ref="video"
                style="width: 100%;"
                type="video/mp4"
                :src="isOutput ? outputPath : inputPath"
                @timeupdate="updateCurrentVideoPosition"
                controls
                muted
              >
                ブラウザがビデオの対応していません
              </video>
              <v-img
                v-else
                style="width: 100%;"
                :src="isOutput ? outputPath : inputPath"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" dense>
          <v-row justify="center" align="center" dense>
            <v-btn icon color="primary" @click="downloadPose">
              <v-icon>mdi-download</v-icon>
            </v-btn>
            <v-spacer />
            <v-switch
              class="pa-0 ma-0 ml-auto mr-5"
              dense
              v-if="posture.isVideo"
              v-model="isCurrentPoseVisible"
              label="現姿勢"
              color="primary"
              hide-details
            ></v-switch>

            <v-switch
              class="pa-0 ma-0 ml-auto"
              dense
              v-if="posture.isVideo"
              v-model="isAveragedPoseVisible"
              label="平均姿勢"
              color="gray"
              hide-details
            ></v-switch>
          </v-row>
          <v-row dense>
            <v-col 
              cols="12"
            >
              <div
                @mousedown.stop="startDragging"
                @mousemove.prevent.stop="rotatePosture"
                @wheel.prevent="scaleGraph"
              >
                <VglRenderer 
                  style="width: 100%;" 
                  id="canvas"
                  :antialias="true"
                  :preserve-drawing-buffer="true"
                >
                  <VglDefs>
                    <template #averagedPoses>
                      <VglLine v-for="(bone, index) in bones" :key="`bone-${index}`">
                        <template #geometry>
                          <VglGeometry>
                            <template #position>
                              <VglFloat32Attribute
                                :array="bone"
                                :item-size="3"
                              />
                            </template>
                          </VglGeometry>
                        </template>
                        <template #material>
                          <VglLineBasicMaterial
                            :linewidth="2"
                            color="white"
                          />
                        </template>
                      </VglLine>
                      <VglMesh 
                        v-for="(pose, index) in averagedPoses" 
                        :key="`pose-${index}`"
                        :position-x="pose.x"
                        :position-y="-pose.y" 
                        :position-z="-pose.z"
                      >
                        <template #geometry>
                          <VglSphereGeometry 
                            :radius="0.01"
                          />
                        </template>
                        <template #material>
                          <VglMeshStandardMaterial />
                        </template>
                      </VglMesh>
                    </template>

                    <template #currentPoses>
                      <VglLine v-for="(bone, index) in currentBones" :key="`current-bone-${index}`">
                        <template #geometry>
                          <VglGeometry>
                            <template #position>
                              <VglFloat32Attribute
                                :array="bone"
                                :item-size="3"
                              />
                            </template>
                          </VglGeometry>
                        </template>
                        <template #material>
                          <VglLineBasicMaterial
                            :linewidth="2"
                            color="#8888CC"
                          />
                        </template>
                      </VglLine>
                      <VglMesh 
                        v-for="(pose, index) in currentPoses" 
                        :key="`current-pose-${index}`"
                        :position-x="pose.x"
                        :position-y="-pose.y" 
                        :position-z="-pose.z"
                      >
                        <template #geometry>
                          <VglSphereGeometry 
                            :radius="0.01"
                          />
                        </template>
                        <template #material>
                          <VglMeshStandardMaterial
                            color="#8888CC"
                          />
                        </template>
                      </VglMesh>
                    </template>
                  </VglDefs>
                  <template #scene>
                    <VglScene>

                      <VglUse 
                        v-if="isAveragedPoseVisible"
                        href="averagedPoses"
                      />
                      <VglUse 
                        v-if="posture.isVideo && isCurrentPoseVisible" 
                        href="currentPoses"
                      />
                      
                      <VglDirectionalLight :position-x="2" :position-y="1.5" :position-z="1" />
                      <VglAmbientLight :intensity="0.4"/>
                    </VglScene>
                  </template>
                  <template #camera>
                    <VglPerspectiveCamera 
                      :position-x="rotatedCameraPosition.x" 
                      :position-y="rotatedCameraPosition.y" 
                      :position-z="rotatedCameraPosition.z" 
                      rotation="lookAt" 
                    />
                  </template>
                </VglRenderer>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <template v-if="posture.isVideo">
        <v-row 
          dense
          v-for="trackingPositionName in trackingPositionNames"
          :key="trackingPositionName"
        >
          <v-col dense>
            <LineChart
              :ref="`${trackingPositionName}LineChart`"
              :height="240"
            />
          </v-col>
        </v-row>
      </template>

      <v-alert v-if="!posture.isAnalyzed" class="mt-5" type="info" color="primary">
        分析中です。しばらくしてからリロードしてください。
      </v-alert>
    </template>

    <v-overlay :value="isLoading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { 
  VglDefs,
  VglUse,
  VglRenderer, 
  VglScene, 
  VglMesh, 
  VglMeshStandardMaterial, 
  VglDirectionalLight, 
  VglAmbientLight,
  VglPerspectiveCamera,
  VglSphereGeometry,
  VglLine,
  VglLineBasicMaterial,
  VglGeometry,
  VglFloat32Attribute,
} from 'vue-gl';

import LineChart from '@components/parts/charts/LineChart.vue'

import { saveAs } from "file-saver";

export default {
  components: {
    LineChart,
    VglDefs,
    VglUse,
    VglRenderer,
    VglScene,
    VglMesh,
    VglMeshStandardMaterial,
    VglDirectionalLight,
    VglPerspectiveCamera,
    VglSphereGeometry,
    VglAmbientLight,
    VglLine,
    VglLineBasicMaterial,
    VglGeometry,
    VglFloat32Attribute,
  },
  props: {
    id: {
      required: true,
    }
  },
  data: function(){
    return {
      isLoading: false,
      posture: null,
      poses: [],
      isOutput: true,
      roll: -0*Math.PI/180, 
      pitch: -0*Math.PI/180, 
      yaw: -0*Math.PI/180,
      isDragging: false,
      cameraPosition: 2.5,
      currentVideoPosition: 0,
      isCurrentPoseVisible: true,
      isAveragedPoseVisible: true,
      trackingPositionNames: [
        "nose",
        "left_eye_inner",
        "left_eye",
        "left_eye_outer",
        "right_eye_inner",
        "right_eye",
        "right_eye_outer",
        "left_ear",
        "right_ear",
        "mouth_left",
        "mouth_right",
        "left_shoulder",
        "right_shoulder",
        "left_elbow",
        "right_elbow",
        "left_wrist",
        "right_wrist",
        "left_pinky",
        "right_pinky",
        "left_index",
        "right_index",
        "left_thumb",
        "right_thumb",
        "left_hip",
        "right_hip",
        "left_knee",
        "right_knee",
        "left_ankle",
        "right_ankle",
        "left_heel",
        "right_heel",
        "left_foot_index",
        "right_foot_index",
      ]
    }
  },
  computed: {
    ...mapState([
      'user',
      'rootPath',
    ]),
    rotatedCameraPosition: function(){
      const sinRoll = Math.sin(this.roll);
      const sinPitch = Math.sin(this.pitch);
      const sinYaw = Math.sin(this.yaw);

      const cosRoll = Math.cos(this.roll);
      const cosPitch = Math.cos(this.pitch);
      const cosYaw = Math.cos(this.yaw);


      let xWeights = [
        cosYaw*cosPitch,
        cosYaw*sinPitch*sinRoll - sinYaw*cosRoll,
        cosYaw*sinPitch*cosRoll + sinYaw*sinRoll,
      ];
      let yWeights = [
        sinYaw*cosPitch,
        sinYaw*sinPitch*sinRoll + cosYaw*cosRoll,
        sinYaw*sinPitch*cosRoll - cosYaw*sinRoll,
      ];
      let zWeights = [
        -sinPitch,
        cosPitch*sinRoll,
        cosPitch*cosRoll,
      ];

     return {
        x: xWeights[2]*this.cameraPosition,
        y: yWeights[2]*this.cameraPosition,
        z: zWeights[2]*this.cameraPosition,
      }
    },
    averagedPoses: function(){
      if(this.poses.length == 0){
        return [];
      }

      if(!this.posture.isVideo){
        return this.poses[0];
      }

      let averagedPositions = [];
      for(let i=0; i<33; i++){
        let averagedPosition = {
          x: 0,
          y: 0,
          z: 0,
        };
        let validCount = 0;
        this.poses.forEach(p => {
          if(p[i].visibility > 0.7){
            validCount++;
            averagedPosition.x += p[i].x;
            averagedPosition.y += p[i].y;
            averagedPosition.z += p[i].z;
          }
        });
        if(validCount > 0){
          averagedPosition.x /= validCount;
          averagedPosition.y /= validCount;
          averagedPosition.z /= validCount;
        }

        averagedPositions.push(
          averagedPosition
        );
      }

      for(let k=0; k<3; k++){
        let averagedDiffs = [];
        for(let i=0; i<33; i++){
          averagedDiffs.push([]);
        }
        this.poses.forEach(pose => {
          let diff = this.getDiff(
            this.getCenter(averagedPositions),
            this.getCenter(pose)
          );

          let transform = {
            dx: diff.x,
            dy: diff.y,
            dz: diff.z,
            dRoll: 0,
            dPitch: 0,
            dYaw: 0,
          };

          let step = 0.1;
          for(let i=0; i<3; i++){
            transform = {
              dx: transform.dx + this.getOptimizedParam(averagedPositions, pose, transform, 0, step),
              dy: transform.dy + this.getOptimizedParam(averagedPositions, pose, transform, 1, step),
              dz: transform.dz + this.getOptimizedParam(averagedPositions, pose, transform, 2, step),
              dRoll: transform.dRoll + this.getOptimizedParam(averagedPositions, pose, transform, 3, 1*step),
              dPitch: transform.dPitch + this.getOptimizedParam(averagedPositions, pose, transform, 4, 1*step),
              dYaw: transform.dYaw + this.getOptimizedParam(averagedPositions, pose, transform, 5, 1*step),
            }
            step *= 0.1;
          }


          for(let i=0; i<33; i++){
            if(pose[i].visibility > 0.7){
              let transformed = this.getTransformedPosition(
                pose[i], 
                transform.dx, transform.dy, transform.dz, 
                transform.dRoll, transform.dPitch, transform.dYaw,
              );
              averagedDiffs[i].push(this.getDiff(
                averagedPositions[i],
                transformed
              ));
            }
          }
        });
        let averagedDiff = [];
        for(let i=0; i<33; i++){
          let c = averagedDiffs[i].length;
          if(c <= 0){
            c = 1;
          }
          averagedDiff.push({
            x: averagedDiffs[i].reduce((prev,current) => prev + current.x, 0)/c,
            y: averagedDiffs[i].reduce((prev,current) => prev + current.y, 0)/c,
            z: averagedDiffs[i].reduce((prev,current) => prev + current.z, 0)/c,
          })

          averagedPositions[i] = {
            x: averagedPositions[i].x - averagedDiff[i].x,
            y: averagedPositions[i].y - averagedDiff[i].y,
            z: averagedPositions[i].z - averagedDiff[i].z,
          }
        }
      }

      return averagedPositions;
    },
    
    currentPoses: function(){
      if(this.poses.length == 0){
        return [];
      }

      let currentIndex = parseInt(this.currentVideoPosition*(this.poses.length - 1));
      return this.poses[currentIndex];
    },
    bones: function(){
      return this.createBones(this.averagedPoses);
    },
    currentBones: function(){
      return this.createBones(this.currentPoses);
    },
  },
  watch: {
    poses: {
      handler: function(newVal){
        if(!newVal){
          return;
        }

        const attributes = [
          "x", "y", "z", 
          //"visibility",
        ];
        const defaultData = {
          fill: false,
          backgroundColor: 'hsla(0, 77%, 28%, 0.2)',
          borderColor: 'hsla(0, 77%, 28%, 0.2)',
          borderWidth: 2,
          label: '',
        };

        this.trackingPositionNames.forEach((n, i) => {
          let chartData = [];
          attributes.forEach((a, j) => {
            const color = `hsla(${120*j}, 77%, 28%, 0.2)`;
            chartData.push({
              ...defaultData,
              label: `${n} ${a}`,
              backgroundColor: color,
              borderColor: color,
              data: newVal.map((v, x) => ({
                x: x,
                y: v[i][a],
              })),
            });
          })

          this.$nextTick(() => {
            let components = this.$refs[`${n}LineChart`] ?? [];
            if(components.length == 0){
              return;
            }
            components[0].dataCollection.datasets = chartData;
            components[0].updateChart();
          });
        });
      },
      immediate: true,
    }
  },
  destroyed: function(){
  },
  mounted: function(){
    this.fetchPostureDetail();
  },
  beforeDestroy: function(){
  },
  methods: {
    getCenter: function(pose){
      const size = pose.length;
      return {
        x: pose.reduce((prev,current) => prev + current.x, 0)/size,
        y: pose.reduce((prev,current) => prev + current.y, 0)/size,
        z: pose.reduce((prev,current) => prev + current.z, 0)/size 
      }
    },
    getOptimizedParam: function(src, dst, transform, axis, step=0.1){
      let minRms = Number.MAX_SAFE_INTEGER;
      let minV = 0;
      for(let d=-10; d<=10; d++){
        let v = d*step;
        let _transform = {...transform};
        switch(axis){
          case 0:
            _transform.dx += v;
            break;
          case 1:
            _transform.dy += v;
            break;
          case 2:
            _transform.dz += v;
            break;
          case 3:
            _transform.dRoll += v;
            break;
          case 4:
            _transform.dPitch += v;
            break;
          case 5:
            _transform.dYaw += v;
            break;
        }

        let rms = 0;
        let validCount = 0;
        for(let i=0; i<33; i++){
          if(dst[i].visibility > 0.7){
            let transformed = this.getTransformedPosition(
              dst[i], 
              _transform.dx, _transform.dy, _transform.dz, 
              _transform.dRoll, _transform.dPitch, _transform.dYaw
            );
            rms += this.getSquaredDistance(transformed, src[i]);
            validCount++;
          }
        }
        rms = Math.sqrt(rms/validCount);
        if(rms < minRms){
          minRms = rms;
          minV = v;
        }
      }
      return minV;
    },
    getDiff: function(a, b){
      return {
        x: a.x - b.x,
        y: a.y - b.y,
        z: a.z - b.z,
      }
    },
    getSquaredDistance: function(pos1, pos2){
      return Math.pow(pos1.x - pos2.x, 2) + Math.pow(pos1.y - pos2.y, 2) + Math.pow(pos1.z - pos2.z, 2);
    },
    getTransformedPosition: function(srcPosition, dx, dy, dz, roll, pitch, yaw){
      const sinRoll = Math.sin(roll);
      const sinPitch = Math.sin(pitch);
      const sinYaw = Math.sin(yaw);

      const cosRoll = Math.cos(roll);
      const cosPitch = Math.cos(pitch);
      const cosYaw = Math.cos(yaw);

      let xWeights = [
        cosYaw*cosPitch,
        cosYaw*sinPitch*sinRoll - sinYaw*cosRoll,
        cosYaw*sinPitch*cosRoll + sinYaw*sinRoll,
      ];
      let yWeights = [
        sinYaw*cosPitch,
        sinYaw*sinPitch*sinRoll + cosYaw*cosRoll,
        sinYaw*sinPitch*cosRoll - cosYaw*sinRoll,
      ];
      let zWeights = [
        -sinPitch,
        cosPitch*sinRoll,
        cosPitch*cosRoll,
      ];

      return {
        x: srcPosition.x*xWeights[0] + srcPosition.y*xWeights[1] + srcPosition.z*xWeights[2] + dx,
        y: srcPosition.x*yWeights[0] + srcPosition.y*yWeights[1] + srcPosition.z*yWeights[2] + dy,
        z: srcPosition.x*zWeights[0] + srcPosition.y*zWeights[1] + srcPosition.z*zWeights[2] + dz,
      }
    },
    getBone: function(poses, from, to){
      return [
        poses[from].x, -poses[from].y, -poses[from].z, 
        poses[to].x, -poses[to].y, -poses[to].z, 
      ]
    },
    fetchPostureDetail: function(){
      this.isLoading = true;
      this.axios.get(`/api/posture/${this.id}`).then(response => {
        this.posture = response.data.posture;
        this.poses = response.data.poses;
        this.inputPath = response.data.inputPath;
        this.outputPath = response.data.outputPath;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.isLoading = false;
      })
    },
    startDragging: function(){
      this.isDragging = true;
      window.addEventListener('mouseup', this.endDragging);
    },
    rotatePosture: function(e){
      if(this.isDragging){
        this.pitch -= e.movementX*0.005;
        this.roll -= e.movementY*0.005;
      }
    },
    scaleGraph: function(e){
      this.cameraPosition *= (1.0 + e.deltaY*0.0005);
    },
    endDragging: function(){
      window.removeEventListener('mouseup', this.endDragging);
      this.isDragging = false;
    },
    updatePosture: function(){
      this.axios.put('/api/posture', this.posture).then(() => {

      }).catch(error => {
        console.log(error);
      })
    },
    downloadPose: function(){
      this.axios.get(
        `/api/download/posture/${this.id}`,
        {
          responseType: "blob"
        },
      ).then(response => {
        const blob = new Blob([response.data], {
          type: response.data.type
        });
        let fileName = `${this.posture.name}.xlsx`;
        saveAs(blob, fileName);
      });
    },
    createBones: function(poses){
      let rtn = [];
      if(poses.length == 0){
        return rtn;
      }
      rtn.push(this.getBone(poses, 11, 12));
      rtn.push(this.getBone(poses, 11, 23));
      rtn.push(this.getBone(poses, 24, 23));
      rtn.push(this.getBone(poses, 24, 12));

      rtn.push(this.getBone(poses, 23, 25));
      rtn.push(this.getBone(poses, 25, 27));
      rtn.push(this.getBone(poses, 27, 29));
      rtn.push(this.getBone(poses, 27, 31));
      rtn.push(this.getBone(poses, 29, 31));

      rtn.push(this.getBone(poses, 24, 26));
      rtn.push(this.getBone(poses, 26, 28));
      rtn.push(this.getBone(poses, 28, 30));
      rtn.push(this.getBone(poses, 28, 32));
      rtn.push(this.getBone(poses, 30, 32));
      
      rtn.push(this.getBone(poses, 11, 13));
      rtn.push(this.getBone(poses, 13, 15));
      rtn.push(this.getBone(poses, 15, 21));
      rtn.push(this.getBone(poses, 15, 17));
      rtn.push(this.getBone(poses, 15, 19));
      rtn.push(this.getBone(poses, 17, 19));

      rtn.push(this.getBone(poses, 12, 14));
      rtn.push(this.getBone(poses, 16, 14));
      rtn.push(this.getBone(poses, 16, 22));
      rtn.push(this.getBone(poses, 16, 18));
      rtn.push(this.getBone(poses, 16, 20));
      rtn.push(this.getBone(poses, 18, 20));

      
      rtn.push(this.getBone(poses, 0, 4));
      rtn.push(this.getBone(poses, 5, 4));
      rtn.push(this.getBone(poses, 5, 6));
      rtn.push(this.getBone(poses, 8, 6));

      rtn.push(this.getBone(poses, 0, 1));
      rtn.push(this.getBone(poses, 2, 1));
      rtn.push(this.getBone(poses, 2, 3));
      rtn.push(this.getBone(poses, 7, 3));
      
      rtn.push(this.getBone(poses, 9, 10));
      return rtn;
    },
    updateCurrentVideoPosition: function(e){
      const video = e?.target;
      if(!video || isNaN(video.currentTime) || isNaN(video.duration)){
        this.currentVideoPosition = 0;
        return;
      }
      this.currentVideoPosition = video.currentTime/video.duration;
    },
  },
}
</script>

<style lang="scss" scoped>

</style>