import { render, staticRenderFns } from "./FeaturesScatterChartCard.vue?vue&type=template&id=15b1f229&scoped=true&"
import script from "./FeaturesScatterChartCard.vue?vue&type=script&lang=js&"
export * from "./FeaturesScatterChartCard.vue?vue&type=script&lang=js&"
import style0 from "./FeaturesScatterChartCard.vue?vue&type=style&index=0&id=15b1f229&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15b1f229",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VChip,VCol,VRow,VSelect})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})
